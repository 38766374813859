import { Box, IconButton, makeStyles, MenuItem, Select, TextField } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import React from 'react'

interface Props {
    onChangeSelect?: (e: React.ChangeEvent<{ name?: string | undefined, value: unknown }>) => void
    onClickSearch: () => void
    keyword: string
    onChangeKeyword: (e: React.ChangeEvent<HTMLInputElement>) => void
    placeholder?: string
    selectItem?: {
        label: string
        value: string
    }[]
    error?: boolean
    helperText?: string
}

const SearchInput: React.FC<Props> = ({
                                          onChangeKeyword, onChangeSelect, onClickSearch, keyword, placeholder, selectItem, error, helperText
                                      }) => {

    const classes = useStyles()

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key==='Enter'){
            onClickSearch()
        }
    }

    return (
        <Box className={classes.searchBox}>
            {selectItem && (
                <Select
                    onChange={onChangeSelect}
                    defaultValue={selectItem[0].value}
                >
                    {selectItem.map(item => (
                        <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                    ))}
                </Select>
            )}
            <TextField
                fullWidth
                placeholder={placeholder}
                value={keyword}
                onChange={onChangeKeyword}
                onKeyPress={handleKeyPress}
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={onClickSearch}>
                            <Search/>
                        </IconButton>
                    )
                }}
                error={error}
                helperText={helperText}
            />
        </Box>
    )
}

export default SearchInput

const useStyles = makeStyles((theme) => ({
    searchBox: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 16,
        width: '100%',
        padding: 16,
        boxSizing: 'border-box',
        display: 'flex',
        columnGap: 16,
        paddingTop: 8
    }
}))