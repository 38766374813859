import LifeStyleType from "../../models/api/enum/lifeStyle";

const lifeStyleFilter = (lifeStyle: LifeStyleType) => {
    switch(lifeStyle){
        case 'LS1':
            return '1인 가구';
        case 'LS2':
            return '룸메이트와 함께 지내요';
        case 'LS3':
            return '반려동물과 함께 지내요';
        case 'LS4':
            return '기숙사∙숙소에서 지내요';
        case 'LS5':
            return '가족과 함께 살아요';
        case 'LS7':
            return '아침형 인간';
        case 'LS8':
            return '새벽형 인간 / 올빼미형';
        case 'LS9':
            return '규칙적인 생활';
        case 'LS10':
            return '집에 있는 걸 좋아해요';
        case 'LS11':
            return '외출을 좋아해요';
        case 'LS12':
            return '자녀가 있어요';
        case 'LS13':
            return '돌싱이에요';
        case 'LS14':
            return '주말에 일 해요';
        case 'LS15':
            return '평일에 일 해요';
        case 'LS16':
            return '규칙적인 근무 시간';
        case 'LS17':
            return '유연한 근무 시간';
        case 'LS18':
            return '야간 근무';
        case 'LS19':
            return '교대 근무';
        case 'LS20':
            return '진보적인 정치 성향';
        case 'LS21':
            return '보수적인 정치 성향';
        case 'LS22':
            return '중도적인 정치 성향';
        default:
            return lifeStyle
    }
}

export default lifeStyleFilter