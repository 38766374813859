import BodyType from "../../models/api/enum/bodyType"

const bodyTypeFilter = (bodyType: BodyType) => {
    switch(bodyType){
        case 'BM00':
            return '마른'
        case 'BF00':
            return '마른'
        case 'BM01':
            return '슬림근육'
        case 'BF01':
            return '슬림'
        case 'BM02':
            return '보통'
        case 'BF02':
            return '보통'
        case 'BM03':
            return '근육질'
        case 'BF03':
            return '다소볼륨'
        case 'BM04':
            return '통통'
        case 'BF04':
            return '글래머'
        case 'BM05':
            return '우람'
        case 'BF05':
            return '통통'
        default:
            return bodyType
    }
}

export default bodyTypeFilter