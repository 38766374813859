import axios from 'axios';
import { env } from '../config';
import PATHS from '../constants/paths';
import { convertDataToCamel, convertDataToSnake } from '../utils/api';
import { env as prodEnv } from '../config/env/prod'

export const api = axios.create({
    baseURL: env.BASE_API_URL
})

api.interceptors.request.use(
    async config => {
        const token = localStorage.getItem('accessToken')
        const env = localStorage.getItem('env')
        if(env==='staging'){
            config.baseURL = prodEnv.BASE_API_URL
        }
        if(token){
            config.headers['Authorization'] = `Bearer ${token}`
        }
        if(config.data){
            return {...config, data: convertDataToSnake(config.data)}
        }
        return config
    },
    async error => {
        return Promise.reject(error)
    }
)

api.interceptors.response.use(
    async response => {
        return {...response, data: convertDataToCamel(response.data)}
    },
    async error => {
        if(error.response.status===401 && window.location.pathname!==PATHS.LOGIN){
            window.location.href = PATHS.LOGIN
        }
        return Promise.reject(error)
    }
)