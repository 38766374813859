import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { ReceiptResponse } from '../../../../models/api/buzz/receipt'
import { replacePlaceholderImage } from '../../../../utils/common'
import fullPathFilter from '../../../../utils/filter/fullPathFilter'
import kstFilter from '../../../../utils/filter/kstFilter'

interface Props extends ReceiptResponse {
    onClickImage: (path: string) => void
    onClickNickname: (id: number) => void
}  

const ReceiptListItem: React.FC<Props> = ({
    receiptCode, store, createdAt, onClickImage, onClickNickname
}) => {

    const classes = useStyles()

    return (
        <Box className={classes.container}>
            <Box className={classes.contentBox}>
                <Typography variant='h6' color='secondary' style={{ fontWeight: 'bold' }}>{receiptCode}</Typography>
                <Typography variant='body1' color='textPrimary'>{store.increase}젬 구매</Typography>
            </Box>
            <Box className={classes.contentBox} style={{ display: 'flex' }}>
                <img src={fullPathFilter(store.user?.pictures?.[0]?.pathSmall)} onError={replacePlaceholderImage} onClick={() => onClickImage(store.user?.pictures?.[0]?.path)} className={classes.userImage}/>
                <Box>
                    <Typography color='secondary' variant='body1' style={{ cursor: 'pointer' }} onClick={() => onClickNickname(store.user?.id)}>{store.user?.nickname}</Typography>
                    <Typography color='textPrimary' variant='body2'>{kstFilter(createdAt)}에 구매</Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default ReceiptListItem

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        borderRadius: 8,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        marginBottom: 24,
        padding: 24,
        boxSizing: 'border-box'
    },
    contentBox: {
        width: '50%',
    },
    userImage: {
        width: 110,
        height: 110,
        objectFit: 'cover',
        border: `1px solid ${theme.palette.divider}`,
        marginRight: 30,
        cursor: 'pointer'
    }
}))