import React from 'react'
import Router from './Router'
import { Provider } from 'mobx-react'
import { ThemeProvider, createTheme, PaletteType } from '@material-ui/core'
import { QueryClientProvider, QueryClient } from 'react-query'
import { RecoilRoot } from 'recoil'
import useSnackbar from './hooks/useSnackbar'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider as MuiThemeProvider, createTheme as createMuiTheme  } from '@mui/material/styles'
import { PaletteMode } from '@mui/material'

const App = () => {

    const { openSnackbar } = useSnackbar()

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                retry: false,
                onError: (error: any) => {
                    openSnackbar({
                        message: error.response.status,
                        severity: 'error'
                    })
                },
            },
            mutations: {
                onSettled: (data: any, error: any) => {
                    if(data){
                        openSnackbar({
                            message: '성공',
                            severity: 'success'
                        })
                    } else if(error){
                        let errorMessage = ''
                        if(error.response?.data?.error){
                            errorMessage = error.response.data.error
                        }
                        openSnackbar({
                            message: error.response.status + ' ' + errorMessage,
                            severity: 'error'
                        })
                    }
                },
            }
        },
        
    })

    const theme = createTheme({
        palette: {
            type: localStorage.getItem('theme') as PaletteType || 'light',
            secondary: {
                dark: '#E10098',
                light: '#E10098',
                main: '#E10098'
            },
        },
        typography: {
            fontFamily: [
                'Pretendard',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
    })

    const mode = localStorage.getItem('theme') as PaletteMode || 'light'

    const muiTheme = createMuiTheme({
        palette: {
            mode: mode,
            secondary: {
                dark: '#E10098',
                light: '#E10098',
                main: '#E10098'
            },
            background: {
                default: mode==='light' ? '#fafafa' : '#303030',
                paper: mode==='light' ? '#ffffff' : '#424242'
            }
        },
        typography: {
            fontFamily: [
                'Pretendard',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
    })

    return (
        <QueryClientProvider client={queryClient}>
            <MuiThemeProvider theme={muiTheme}>
                <ThemeProvider theme={theme}>
                    <Provider>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Router/>
                        </MuiPickersUtilsProvider>
                    </Provider>
                </ThemeProvider>
            </MuiThemeProvider>
        </QueryClientProvider>
    )
}

export default App