import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { AdminActionType, AppealReportResponse, AppealReportState, AppealResponse } from '../../../../models/api/buzz/appeal'
import { replacePlaceholderImage } from '../../../../utils/common'
import ageFilter from '../../../../utils/filter/ageFilter'
import contextCodeFilter from '../../../../utils/filter/contextCodeFilter'
import dateFilter from '../../../../utils/filter/dateFilter'
import fullPathFilter from '../../../../utils/filter/fullPathFilter'
import kstFilter from '../../../../utils/filter/kstFilter'
import reportCodeFilter from '../../../../utils/filter/reportCodeFilter'

interface Props extends AppealReportResponse {
    onClickUser: (id: number) => void
    onClickAction: (id: number, state: AdminActionType, lastAdminAction: AppealReportState) => void
    onClickDetail: (id: number) => void
}

const AppealReportWaitListItem: React.FC<Props> = ({
    id, onClickUser, onClickAction, onClickDetail, appeal, comment, context, createdAt, state, isSystemReport
}) => {

    const classes = useStyles()

    return (
        <Box className={classes.container}>
            {
                (comment && comment.id) ? (
                  <Typography variant='body2' color='textPrimary' className={classes.reportHead}>댓글 신고 {id}</Typography>
                ) : <Typography variant='body2' color='textPrimary' className={classes.reportHead}>커뮤니티
                    신고 {id}</Typography>
            }
            {
                (comment && comment.id) && (
                <>
                    <Box className={classes.header}>
                        <Typography variant='body2' color='textPrimary'
                                    className={classes.appealId}>댓글# {comment.id}</Typography>
                    </Box>
                    <Box className={classes.articleBox}>
                        <Box className={classes.article}>
                            <Typography variant='body2' color='textPrimary' className={classes.articleBody}>{comment.body}</Typography>
                        </Box>
                    </Box>
                    <Box className={classes.userInfoBox}>
                        <Box>
                            <Typography variant='subtitle1' color='textPrimary'>유저# {comment.userId}</Typography>
                            <Typography variant='subtitle1' color='textPrimary' className={classes.username}>{comment.user?.nickname}</Typography>
                        </Box>
                        <Box className={classes.userInfo}>
                            <Box>
                                <Typography color='textSecondary' variant='body1'>{comment.user?.region} | {comment.user?.age}</Typography>
                                <Typography color='textSecondary' variant='body1'>{comment.user?.address}</Typography>
                                <Typography color='textSecondary' variant='body1'>{comment.user?.job}</Typography>
                            </Box>
                            <img onClick={() => onClickUser(comment.userId)} src={fullPathFilter(comment.user?.pictures[0]?.pathSmall)} onError={replacePlaceholderImage} className={classes.userImage}/>
                        </Box>
                        <Typography variant='body1' color='textSecondary'>{dateFilter(comment.user?.createdAt)} 가입</Typography>
                    </Box>
                </>
              )
            }
            <Box className={classes.header}>
                <Typography variant='body2' color='textPrimary' className={classes.appealId}>게시글# {appeal.id}</Typography>
                <Typography variant='body2' className={classes.topic}>어필</Typography>
            </Box>
            <Button className={classes.detailButton} onClick={() => onClickDetail(appeal.id)}>게시글 상세 보기 (댓글 {appeal.commentsCount}개)</Button>
            {appeal.article && (
                <Box className={classes.articleBox} onClick={() => onClickDetail(appeal.id)}>
                    <Box className={classes.article}>
                        <Typography variant='body2' color='textPrimary' className={classes.articleBody}>{appeal?.article?.body}</Typography>
                    </Box>
                </Box>
            )}
            <Box className={classes.attachmentBox}>
                {appeal.attachments?.map(attachment => (
                    <Box key={attachment.id} className={classes.attachment} onClick={() => onClickDetail(appeal.id)}>
                        <img src={fullPathFilter(attachment.pathSmall)} className={classes.attachmentImage} onError={replacePlaceholderImage}/>
                    </Box>
                ))}
            </Box>
            <Typography variant='body2' color='textPrimary' className={classes.createdAt}>{dateFilter(appeal.createdAt)} 작성 ({appeal.createdAt})</Typography>
            {Boolean(appeal.reportCount) && <Typography variant='body2' color='textPrimary' className={classes.createdAt} style={{ fontWeight: 'bold' }}>{appeal.reportCount}회 신고됨</Typography>}
            <Box className={classes.userInfoBox}>
                <Box>
                    <Typography variant='subtitle1' color='textPrimary'>유저# {appeal.userId}</Typography>
                    <Typography variant='subtitle1' color='textPrimary' className={classes.username}>{appeal.user?.nickname}</Typography>
                </Box>
                <Box className={classes.userInfo}>
                    <Box>
                        <Typography color='textSecondary' variant='body1'>{appeal.user?.region} | {appeal.user?.age}</Typography>
                        <Typography color='textSecondary' variant='body1'>{appeal.user?.address}</Typography>
                        <Typography color='textSecondary' variant='body1'>{appeal.user?.job}</Typography>
                    </Box>
                    <img onClick={() => onClickUser(appeal.userId)} src={fullPathFilter(appeal.user?.pictures[0]?.pathSmall)} onError={replacePlaceholderImage} className={classes.userImage}/>
                </Box>
                <Typography variant='body1' color='textSecondary'>{dateFilter(appeal.user?.createdAt)} 가입</Typography>
            </Box>
            <Box className={classes.reportInfoBox}>
                <Typography variant='body1' color='textSecondary'>#{id} {isSystemReport ? `시스템 리포트 (${reportCodeFilter(context)})` : `신고 사유: ${reportCodeFilter(context)}`}</Typography>
                <Typography variant='body1' color='textSecondary'>{createdAt}</Typography>
            </Box>
            <Box className={classes.buttonBox}>
                <Button onClick={() => onClickAction(id, 'Approval', state)} className={classes.button} style={{ backgroundColor: '#1976d2', color: '#ffffff' }}>인증</Button>
                <Button onClick={() => onClickAction(id, 'Hide', state)} className={classes.button} style={{ backgroundColor: '#fb8c00', color: '#ffffff' }}>숨김 & 제재</Button>
                <Button onClick={() => onClickAction(id, 'Delete', state)} className={classes.button} style={{ backgroundColor: '#ff5252', color: '#ffffff' }}>삭제</Button>
                <Button onClick={() => onClickAction(id, 'Skip', state)} className={classes.button} style={{}}>스킵</Button>
            </Box>
        </Box>
    )
}

export default AppealReportWaitListItem

const useStyles = makeStyles((theme) => ({
    reportHead: {
        fontWeight: 'bold',
        marginTop: 10,
        marginBottom: 20,
        padding: '0 16px',
        boxSizing: 'border-box'
    },
    container: {
        width: `calc((100% - 80px) / 6)`,
        minWidth: 340,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 12,
        boxSizing: 'border-box',
        padding: '16px 0',
        position: 'relative',
        marginBottom: 16
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 16px',
        boxSizing: 'border-box'
    },
    appealId: {
        fontWeight: 'bold'
    },
    topic: {
        color: '#f44336',
        border: `1px solid #f44336`,
        borderRadius: 16,
        boxSizing: 'border-box',
        padding: '4px 12px'
    },
    detailButton: {
        borderRadius: 6,
        border: `1px solid ${theme.palette.divider}`,
        width: 'calc(100% - 32px)',
        marginTop: 8,
        paddingLeft: 16,
        paddingRight: 16,
        boxSizing: 'border-box',
        marginRight: 16,
        marginLeft: 16
    },
    articleBox: {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 8,
        width: '100%',
        padding: '0 16px',
        boxSizing: 'border-box'
    },
    article: {
        width: '100%',
        backgroundColor: theme.palette.action.selected,
        borderRadius: 4,
        boxSizing: 'border-box',
        padding: 10
    },
    articleBody: {

    },
    createdAt: {
        marginTop: 8,
        padding: '0 16px',
        boxSizing: 'border-box'
    },
    attachmentBox: {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 8,
        width: '100%',
        padding: '0 16px',
        boxSizing: 'border-box'
    },
    attachment: {
        width: '100%'
    },
    attachmentImage: {
        width: '100%',
        objectFit: 'contain',
        cursor: 'pointer'
    },
    userInfoBox: {
        marginTop: 16,
        padding: '0 16px',
        boxSizing: 'border-box',
        borderTop: `1px solid ${theme.palette.divider}`,
        paddingTop: 16
    },
    reportInfoBox: {
        padding: 16,
        marginTop: 16,
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 15,
        marginBottom: 60,
    },
    username: {
        fontWeight: 'bold'
    },
    userInfo: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 16,
        position: 'relative',
        marginBottom: 16
    },
    userImage: {
        borderRadius: '50%',
        border: `4px solid green`,
        width: 70,
        height: 70,
        position: 'absolute',
        bottom: 0,
        right: 0,
        cursor: 'pointer'
    },
    buttonBox: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: 8,
        position: 'absolute',
        bottom: 16,
        padding: '0 16px',
        boxSizing: 'border-box'
    },
    button: {
        backgroundColor: theme.palette.action.selected,
    }
}))
