import { useState } from "react"

interface Props {
    initalPage: number
    lastPage?: number
}

const usePagination = (initalValue: Props) => {

    const { initalPage, lastPage } = initalValue

    const [ page, setPage ] = useState<number>(initalPage)
    const [ pageLength, setPageLength ] = useState<number>(lastPage || 0)
    
    return { page, pageLength, setPage, setPageLength }
}

export default usePagination