import React from 'react'
import { TableRow, TableCell, Typography, makeStyles } from '@material-ui/core'
import { BannerResponse } from '../../../../models/api/buzz/banner'
import fullPathFilter from '../../../../utils/filter/fullPathFilter'

interface Props extends BannerResponse {
    onClick: (type: 'create' | 'update', value: BannerResponse) => void
}

const BannerListItem: React.FC<Props> = (props) => {

    const { id, title, lang, type, bannerImage, webpage, isProgress, onClick, eventBannerId } = props

    const classes = useStyles()

    return (
        <TableRow style={{ cursor: 'pointer' }} onClick={() => onClick('update', props)}>
            <TableCell>{id}</TableCell>
            <TableCell>{isProgress==='Y' ? '진행중' : '중지'}</TableCell>
            <TableCell>{lang==='ko' ? '한국' : '미국'}</TableCell>
            <TableCell>{title}</TableCell>
            <TableCell>
                <img className={classes.bannerImage} src={fullPathFilter(bannerImage)}/>
            </TableCell>
            <TableCell>
                <Typography component='a' href={webpage} target='_blank'>링크</Typography>
            </TableCell>
        </TableRow>
    )
}

export default BannerListItem

const useStyles = makeStyles((theme) => ({
    bannerImage: {
        height: 50,
        objectFit: 'cover'
    }
}))