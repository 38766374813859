import { Box, makeStyles, Typography } from '@material-ui/core'
import React, { forwardRef } from 'react'
import { useMutation } from 'react-query'
import buzzEndpoints from '../../../../../api/buzz'
import { api } from '../../../../../api/common'
import EditLayout from '../EditLayout'

interface Props {
    userId: number
    close: () => void
    refetch: () => void
    phoneNumber: string
}

const RemovePhoneNumber: React.FC<Props> = forwardRef(({
    userId, close, refetch, phoneNumber
}, ref) => {

    const classes = useStyles()

    const { mutate: removePhoneNumber } = useMutation(
        () => api.post(`${buzzEndpoints.deleteSms()}`, { user_id: userId, phoneNumber: phoneNumber }),
        {
            onSuccess: () => {
                close()
                refetch()
            }
        }
    )

    return (
        <EditLayout title={'전화번호 삭제'} onSubmit={() => removePhoneNumber()} close={close}>
            <Box className={classes.contentBox}>
                <Typography color='textPrimary' variant='subtitle1'>기존 번호를 삭제하시겠습니까?</Typography>
            </Box>
        </EditLayout>
    )
})

export default RemovePhoneNumber

const useStyles = makeStyles((theme) => ({
    contentBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 24,
        boxSizing: 'border-box'
    },
}))