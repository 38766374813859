import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { snackbarAtom } from '../store/user'

const useSnackbar = () => {
    const [ snackbar, setSnackbar ] = useRecoilState(snackbarAtom)

    const openSnackbar = (props: {
        message: string
        severity: '' | 'success' | 'error'
    }) => {
        setSnackbar({
            ...props,
            open: true
        })
        setTimeout(() => {
            setSnackbar({
                open: false,
                message: '',
                severity: ''
            })
        }, 3000)
    }

    return { openSnackbar }
}

export default useSnackbar