import IntroductionType from "../../models/api/enum/introduction";

const introductionFilter = (introduction: IntroductionType) => {
    switch(introduction){
        case 'Join':
            return '가입소개';
        case 'Regular':
            return '정규소개';
        case 'Reward':
            return '추천인보상소개';
        case 'Additional':
            return '추가소개';
        case 'Fill':
            return '소개보장';
        case 'Irregular':
            return '비정규소개';
        case 'AdditionalWithType':
            return '맞춤소개';
        case 'General':
            return '정규소개';
        case 'FillFromNew':
            return '소개보장(New)';
        default:
            return introduction
    }
}

export default introductionFilter