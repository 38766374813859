import { Box, makeStyles, Switch, TableCell, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import { ParameterResponse } from '../../../../models/api/buzz/parameter'
import BooleanType from '../../../../models/api/enum/boolean'

interface Props extends ParameterResponse {
    handleActivate: (key: string, value: BooleanType) => void
    title: string
    onClickTitle: (type: 'create' | 'update', key: string, description?: string, value?: BooleanType) => void
}

const FlagListItem: React.FC<Props> = ({
    key, description, value, handleActivate, title, onClickTitle
}) => {

    const classes = useStyles()

    return (
        <TableRow>
            <TableCell style={{ cursor: 'pointer' }} onClick={() => onClickTitle('update', title, description, value as BooleanType)}>
                <Typography color='textPrimary' style={{ color: '#388e3c' }}>{title}</Typography>
            </TableCell>
            <TableCell>
                <Typography color='textPrimary'>{description}</Typography>
            </TableCell>
            <TableCell>
                <Switch color={value==='Y' ? 'primary' : 'default'} onClick={() => handleActivate(title, value as BooleanType)} checked={value==='Y'}/>
            </TableCell>
        </TableRow>
    )
}

export default FlagListItem

const useStyles = makeStyles((theme) => ({

}))