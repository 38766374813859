import { useState } from 'react'

const useImageModal = () => {
    const [ imageModalOpened, setImageModalOpened ] = useState<boolean>(false)
    const [ imagePath, setImagePath ] = useState<string>('')

    const handleImageModal = (path?: string) => {
        setImagePath(path || '')
        setImageModalOpened(open => !open)
    }

    return { imageModalOpened, setImageModalOpened, imagePath, setImagePath, handleImageModal }
}

export default useImageModal