import EducationType from "../../models/api/enum/education"

const educationFilter = (education: EducationType) => {
    switch(education){
        case 'UE01':
            return '고등학교'
        case 'UE02':
            return '전문대'
        case 'UE03':
            return '대학교'
        case 'UE04':
            return '석사'
        case 'UE05':
            return '박사'
        case 'UE06':
            return '기타'
        default:
            return education
    }
}

export default educationFilter