import ContextCodeType from "../../models/api/enum/contextCode";

const contextCodeFilter = (code: ContextCodeType) => {
    if (code === 'AMW000') {
        return '허위 계정';
    } else if (code === 'AMW001') {
        return '부적절한 사진';
    } else if (code === 'AMW002') {
        return '부적절한 프로필 내용';
    } else if (code === 'AMW003') {
        return '욕설 및 비방';
    } else if (code === 'AMP000') {
        return '부적절한 메인 사진';
    } else if (code === 'AMP001') {
        return '부적절한 서브 사진';
    } else if (code === 'AMP002') {
        return '비슷한 사진의 반복';
    } else if (code === 'AMP003') {
        return '본인 확인 불가';
    } else if (code === 'AMP004') {
        return '부적절한 캡쳐';
    } else if (code === 'AMI000') {
        return '부적절한 메인 사진';
    } else if (code === 'AMI001') {
        return '부적절한 서브 사진';
    } else if (code === 'AMI002') {
        return '부적절한 프로필 내용';
    } else if (code === 'AMI003') {
        return '성별 불일치';
    } else if (code === 'AMB000') {
        return '허위 계정';
    } else if (code === 'AMB001') {
        return '지속적인 가입 요청';
    } else if (code === 'AMB002') {
        return '부적절한 프로필 내용';
    } else if (code === 'AMB003') {
        return '성별 불일치';
    } else if (code === 'AMR009') {
        return '악성 유저';
    } else if (code === 'AMR000') {
        return '부적절한 사진';
    } else if (code === 'AMR001') {
        return '허위 프로필';
    } else if (code === 'AMR002') {
        return '다른 이 사칭';
    } else if (code === 'AMR003') {
        return '욕설 및 비방';
    } else if (code === 'AMR004') {
        return '젬 불법 환불';
    } else if (code === 'AMR005') {
        return '연락처 기재';
    } else if (code === 'AMR006') {
        return '미성년자';
    } else if (code === 'AMR007') {
        return '기혼자';
    } else if (code === 'AMR008') {
        return '다수의 유저 신고';
    } else if (code === 'AMR010') {
        return '기타 사유';
    } else if (code === 'AMR011') {
        return '사진 인증 기간 만료';
    } else if (code === 'AMR012') {
        return '[라이브] 불쾌한 노출';
    } else if (code === 'AMR013') {
        return '[라이브] 불쾌한 언행';
    } else if (code === 'AMP005') {
        return '사진 도용이 의심되니 다른 사진으로 올려주세요';
    } else if (code === 'AMP006') {
        return '얼굴(눈, 코, 입)이 보이지 않는 사진을 피해주세요';
    } else if (code === 'AMP007') {
        return '선글라스, 마스크 등 얼굴 가린 사진은 피해주세요';
    } else if (code === 'AMP008') {
        return '지나친 필터(스노우 등)가 적용된 사진을 피해주세요';
    } else if (code === 'AMP009') {
        return '첫번째 사진은 뚜렷한 정면 사진이어야 합니다';
    } else if (code === 'AMP010') {
        return '동일하거나 유사한 사진은 피해주세요';
    } else if (code === 'AMP011') {
        return '지나친 클로즈업 사진은 피해주세요';
    } else if (code === 'AMP012') {
        return '사진을 회전해서 다시 올려주세요';
    } else if (code === 'AMP013') {
        return '현재 본인이 아닌 사진은 피해주세요';
    } else if (code === 'AMP014') {
        return '흐리거나 저화질 또는 어두운 사진을 수정해주세요';
    } else if (code === 'AMP015') {
        return '본인 구분이 힘든 단체 사진은 피해주세요';
    } else if (code === 'AMP016') {
        return '부적절한 사진을 피해주세요';
    } else if (code === 'AMP017') {
        return '사진을 다시 올려주세요';
    } else if (code === 'AMP018') {
        return '얼굴이(눈, 코, 입) 선명하게 보이게 찍어주세요';
    } else if (code === 'AMP019') {
        return '프로필과 동일 인물이어야 해요.';
    } else if (code === 'AMP020') {
        return '예시 포즈와 동일한 포즈를 취해주세요';
    } else if (code === 'AMP021') {
        return '선명하게 얼굴이 보이는 프로필 사진을 올린 후 다시 인증해 주세요';
    } else if (code === 'nickname') {
        return '닉네임';
    } else if (code === 'birthday') {
        return '생일';
    } else if (code === 'gender') {
        return '성별';
    } else if (code === 'height') {
        return '키';
    } else if (code === 'job') {
        return '직업';
    } else if (code === 'single') {
        return '싱글';
    } else if (code === 'picture_0') {
        return '사진1';
    } else if (code === 'picture_1') {
        return '사진2';
    } else if (code === 'picture_2') {
        return '사진3';
    } else if (code === 'picture_3') {
        return '사진4';
    } else if (code === 'picture_4') {
        return '사진5';
    } else if (code === 'picture_5') {
        return '사진6';
    }

    return code;
}

export default contextCodeFilter