import AttractionType from "../../models/api/enum/attraction";

const attractionFilter = (attraction: AttractionType) => {
    switch(attraction){
        case 'AG1':
            return '요리를 잘해요';
        case 'AG2':
            return '패션 감각';
        case 'AG3':
            return '유머 감각';
        case 'AG4':
            return '다리가 예뻐요';
        case 'AG5':
            return '경제력';
        case 'AG6':
            return '다정해요';
        case 'AG7':
            return '허세 없어요';
        case 'AG8':
            return '재주가 많아요';
        case 'AG9':
            return '엉덩이가 예뻐요';
        case 'AG10':
            return '대화를 잘해요';
        case 'AG11':
            return '피부가 좋아요';
        case 'AG12':
            return '손이 예뻐요';
        case 'AG13':
            return '장난기가 많아요';
        case 'AG14':
            return '웃는게 예뻐요';
        case 'AG15':
            return '목소리가 좋아요';
        case 'AG17':
            return '보조개';
        case 'AG18':
            return '이야기를 잘 들어줘요';
        case 'AG19':
            return '노래를 잘해요';
        case 'AG20':
            return '비율이 좋아요';
        case 'AG22':
            return '큰 눈';
        case 'AG23':
            return '긍정적 마인드';
        case 'AG24':
            return '솔직해요';
        case 'AG25':
            return '예의가 발라요';
        case 'AG26':
            return '표현을 잘해요';
        case 'AG27':
            return '배려심이 깊어요';
        case 'AG28':
            return '한 사람만 봐요';
        case 'AG29':
            return '웃음이 많아요';
        case 'AG30':
            return '뭐든 잘 먹어요';
        case 'AG31':
            return '동안이에요';
        case 'AG32':
            return '운동 감각';
        case 'AG34':
            return '섹시한 두뇌';
        case 'AG35':
            return '시사에 밝아요';
        case 'AG36':
            return '섹시한 타투';
        case 'AG37':
            return '체력이 좋음 ';
        case 'AG40':
            return '뛰어난 커리어';
        case 'AG41':
            return '워커홀릭';
        case 'AG42':
            return '혼자 잘 놀아요';
        case 'AG43':
            return '게임을 잘해요';
        case 'AG44':
            return '섬세해요';
        case 'AM1':
            return '넓은 어깨';
        case 'AF1':
            return '골반 미녀';
        case 'AG16':
            return '쌍커풀 없는 눈';
        case 'AG21':
            return '애교가 많아요';
        case 'AG33':
            return '오똑한 콧날';
        case 'AG38':
            return '짙은 눈썹';
        case 'AG39':
            return '털털해요';
        default:
            return attraction
    }
}

export default attractionFilter