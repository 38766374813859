import { makeStyles, Typography, Box, Button, TextField, useTheme } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import React, { ReactElement, useState } from 'react'
import BooleanType from '../../../../models/api/enum/boolean'
import { TargetEventResponse } from '../../../../models/api/potato/event'
import { convertDataToSnake } from '../../../../utils/api'
import { UpdateData } from '../../../../pages/Event/Target'

const gems = [
    [5, 40],
    [3, 30],
    [250, 2000],
    [150, 1500],
    [150, 1000],
]

interface Props {
    onClickToggle: (id: number, data: UpdateData, isActivate: BooleanType) => void
    onClickUpdate: (data: UpdateData) => void
    image: any
    index: number
    data: TargetEventResponse
}

const TargetEventListItem: React.FC<Props> = (props) => {

    const { onClickToggle, onClickUpdate, data, image, index } = props
    const { eventName, isActivate, id, expireHour, eventPoints: prevEventPoints, category } = data

    const classes = useStyles()

    const theme = useTheme()

    const [ isUpdate, setIsUpdate ] = useState<boolean>(false)
    const [ eventPoints, setEventPoints ] = useState<any[]>(prevEventPoints.map(eventPoints => convertDataToSnake(eventPoints)))

    const handleChange = (version: number, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target
        if(!isNaN(Number(value))){
            setEventPoints(eventPoints => eventPoints.map(eventPoint => (eventPoint.version!==version && eventPoint.version!==version + 2)? eventPoint : ({
                ...eventPoint,
                [name]: eventPoint.version===version + 2 ? Number(value) * 50 : Number(value)
            })))
        }
        
    }
    const handleUpdate = () => {
        setIsUpdate(false)
        onClickUpdate({
            category: category,
            event_points: eventPoints,
            id: id,
            is_activate: isActivate
        })
    }
    return (
        <Box className={classes.container}>
            <Box className={classes.numberBox}>
                <Typography color='textPrimary' style={{ fontWeight: 'bold' }}>{eventName}</Typography>
                <ToggleButtonGroup onChange={(e, value) => {
                    onClickToggle(id, {
                        category: category,
                        event_points: eventPoints,
                        id: id,
                        is_activate: value
                    }, value)
                }} exclusive>
                    <ToggleButton value='Y' className={classes.toggleOn} style={isActivate==='Y' ? { color: '#ffffff', backgroundColor: '#449d44' } : {}}>ON</ToggleButton>
                    <ToggleButton value='N' className={classes.toggleOff} style={isActivate==='N' ? { color: '#ffffff', backgroundColor: '#c9302c' } : {}}>OFF</ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Box className={classes.contentBox}>
                <img src={image} className={classes.eventImage}/>
                <Box className={classes.contentItemBox}>
                    <Box display='flex' justifyContent='space-between' gridColumnGap={20}>
                        <Box width='50%'>
                            <Typography color='textSecondary' variant='body2'>대상</Typography>
                            <Typography color='textPrimary' variant='body2'>{index===0 ? '신규 가입 유저' : '가입 후 결제 이력 없는 유저'}</Typography>
                        </Box>
                        <Box width='50%'>
                            <Typography color='textSecondary' variant='body2'>기간</Typography>
                            <Typography color='textPrimary' variant='body2'>가입 완료 후 ~ {expireHour}시간 노출</Typography>
                        </Box>
                    </Box>
                    <Box marginTop='10px'>
                        <Typography color='textSecondary' variant='body2'>상품 구성</Typography>
                        {eventPoints.map((eventPoint, index) => (
                            <Box key={index} marginTop="12px">
                                <Typography variant='body2' color='textSecondary'>v{eventPoint.version}</Typography>
                                <Box className={classes.tableContainer}>
                                    <Box display='flex' className={classes.tableHead}>
                                        {['iOS', '보너스', 'Android', '보너스'].map((text, index) => (
                                            <Typography className={classes.tableCell} color='textPrimary' variant='body2' style={{ fontWeight: 'bold' }} key={index}>{text}</Typography>
                                        ))}
                                    </Box>
                                    <Box display='flex'>
                                        <Typography className={classes.tableCell} color='textPrimary' variant='body2'>{gems[index][0]}</Typography>
                                        {isUpdate ? (
                                            <TextField 
                                                variant='outlined' 
                                                value={eventPoint.ios_bonus_1}
                                                className={classes.input}
                                                inputProps={{
                                                    style: {
                                                        padding: '12px 14px',
                                                        color: theme.palette.secondary.main,
                                                        backgroundColor: (eventPoint.version > 2 && eventPoint.version < 5) ? theme.palette.action.selected : theme.palette.background.paper
                                                    }
                                                }}
                                                name='ios_bonus_1'
                                                onChange={e => handleChange(eventPoint.version, e)}
                                                disabled={eventPoint.version > 2 && eventPoint.version < 5}
                                            />
                                        ) : (
                                            <Typography className={classes.tableCell} color='textPrimary' variant='body2'>{eventPoint.ios_bonus_1}</Typography>
                                        )}
                                        <Typography className={classes.tableCell} color='textPrimary' variant='body2'>{gems[index][0]}</Typography>
                                        {isUpdate ? (
                                            <TextField 
                                                variant='outlined' 
                                                value={eventPoint.android_bonus_1} 
                                                className={classes.input}
                                                inputProps={{
                                                    style: {
                                                        padding: '12px 14px',
                                                        color: theme.palette.secondary.main,
                                                        backgroundColor: (eventPoint.version > 2 && eventPoint.version < 5) ? theme.palette.action.selected : theme.palette.background.paper
                                                    }
                                                }}
                                                name='android_bonus_1'
                                                onChange={e => handleChange(eventPoint.version, e)}
                                                disabled={eventPoint.version > 2 && eventPoint.version < 5}
                                            />
                                        ) : (
                                            <Typography className={classes.tableCell} color='textPrimary' variant='body2'>{eventPoint.android_bonus_1}</Typography>
                                        )}
                                    </Box>
                                    <Box display='flex'>
                                        <Typography className={classes.tableCell} color='textPrimary' variant='body2'>{gems[index][1]}</Typography>
                                        {isUpdate ? (
                                            <TextField 
                                                variant='outlined' 
                                                value={eventPoint.ios_bonus_3} 
                                                className={classes.input}
                                                inputProps={{
                                                    style: {
                                                        padding: '12px 14px',
                                                        color: theme.palette.secondary.main,
                                                        backgroundColor: (eventPoint.version > 2 && eventPoint.version < 5) ? theme.palette.action.selected : theme.palette.background.paper
                                                    }
                                                }}
                                                name='ios_bonus_3'
                                                onChange={e => handleChange(eventPoint.version, e)}
                                                disabled={eventPoint.version > 2 && eventPoint.version < 5}
                                            />
                                        ) : (
                                            <Typography className={classes.tableCell} color='textPrimary' variant='body2'>{eventPoint.ios_bonus_3}</Typography>
                                        )}
                                        <Typography className={classes.tableCell} color='textPrimary' variant='body2'>{gems[index][1]}</Typography>
                                        {isUpdate ? (
                                            <TextField 
                                                variant='outlined' 
                                                value={eventPoint.android_bonus_3} 
                                                className={classes.input}
                                                inputProps={{
                                                    style: {
                                                        padding: '12px 14px',
                                                        color: theme.palette.secondary.main,
                                                        backgroundColor: (eventPoint.version > 2 && eventPoint.version < 5) ? theme.palette.action.selected : theme.palette.background.paper
                                                    }
                                                }}
                                                name='android_bonus_3'
                                                onChange={e => handleChange(eventPoint.version, e)}
                                                disabled={eventPoint.version > 2 && eventPoint.version < 5}
                                            />
                                        ) : (
                                            <Typography className={classes.tableCell} color='textPrimary' variant='body2'>{eventPoint.android_bonus_3}</Typography>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
            {isUpdate ? (
                <Box className={classes.buttonBox}>
                    <Button className={classes.submitButton} onClick={handleUpdate}>확인</Button>
                    <Button variant='outlined' onClick={() => setIsUpdate(false)}>취소</Button>
                </Box>
            ) : (
                <Box className={classes.buttonBox}>
                    <Button variant='outlined' onClick={() => setIsUpdate(true)}>수정</Button>
                </Box>
            )}
        </Box>
    )
}

export default TargetEventListItem

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 8,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        marginBottom: 24
    },
    numberBox: {
        backgroundColor: theme.palette.action.selected,
        padding: 16,
        boxSizing: 'border-box',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        display: 'flex',
        justifyContent: 'space-between'
    },
    toggleOn: {
        "&:hover": {
            color: '#ffffff',
            backgroundColor: '#449d44'
        },
        padding: '0 10px'
    },
    toggleOff: {
        "&:hover": {
            color: '#ffffff',
            backgroundColor: '#c9302c'         
        },
        padding: '0 10px'
    },
    contentBox: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: 24,
        boxSizing: 'border-box',
        alignItems: 'flex-start'
    },
    eventImage: {
        width: 70,
        objectFit: 'contain',
        border: `1px solid ${theme.palette.divider}`
    },
    contentItemBox: {
        width: `calc(100% - 100px)`
    },
    tableContainer: {
        boxSizing: 'border-box',
        borderTop: `1px solid ${theme.palette.divider}`,
        borderLeft: `1px solid ${theme.palette.divider}`,
        marginTop: 6
    },
    tableHead: {
        backgroundColor: theme.palette.action.selected
    },
    tableCell: {
        boxSizing: 'border-box',
        padding: 8,
        width: '25%',
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderRight: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        alignItems: 'center'
    },
    buttonBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        columnGap: 12,
        padding: 24,
        boxSizing: 'border-box',
        borderTop: `1px solid ${theme.palette.divider}`
    },
    submitButton: {
        backgroundColor: '#5cb85c',
        color: '#ffffff',
        "&:hover": {
            backgroundColor: '#5cb85c'
        }
    },
    input: {
        boxSizing: 'border-box',
        padding: 8,
        width: '25%',
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderRight: `1px solid ${theme.palette.divider}`,
    }
}))