export const camelToSnake = (str: string): string => str.replace(/[A-Z]/g, group => `_${group.toLowerCase()}`)

export const convertDataToSnake = (data: any): any => {
    const newData: any = {}
    Object.keys(data).forEach((key: string) => {
        newData[camelToSnake(key)] = data[key]
    })
    return newData
}

export const convertDataToCamel = (data: any): any => {
    if (data instanceof Array) {
        return data.map((item) => convertDataToCamel(item))
    } else if (data instanceof Object) {
        const newData: any = {}
        Object.keys(data).forEach((key) => {
            if(key==='children'){
                newData['childrenProp'] = convertDataToCamel(data[key])
            } else {
                newData[snakeToCamel(key)] = convertDataToCamel(data[key])
            }
        })
        return newData
    } else {
        return data
    }
}

export const snakeToCamel = (str: string): string => str.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase()
        .replace('-', '')
        .replace('_', '')
);

export const getParams = (obj?: object) => {
    return obj ? `?${Object.entries(obj).map(([key, value]) => value ? `${key}=${encodeURIComponent(value)}` : '').filter(str => str).join('&')}` : ''
}

export const getParamsWithOutEncoded = (obj?: object) => {
    return obj ? `?${Object.entries(obj).map(([key, value]) => value ? `${key}=${value}` : '').filter(str => str).join('&')}` : ''
}
