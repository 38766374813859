import { env } from "../../config"
import { env as prodEnv } from '../../config/env/prod'

const fullPathFilter = (url?: string) => {
    if(url){
        if(url.includes('https://')){
            return url
        } else {
            if(localStorage.getItem('env')==='staging'){
                return prodEnv.BASE_STORAGE_URL + url
            } else {
                return env.BASE_STORAGE_URL + url
            }
        }   
    } else {
        return ''
    }
}

export default fullPathFilter