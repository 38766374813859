import React, {useState} from 'react'
import Layout from '../../../components/Layout'
import {makeStyles, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core'
import {useMutation, useQuery} from 'react-query'
import {api} from '../../../api/common'
import buzzEndpoints from '../../../api/buzz'
import {SmsVerificationBlocksResponse} from "../../../models/api/buzz/sms-verification";
import SmsVerificationBlockListItem from "../../../components/User/SmsVerification/SmsVerificationBlockItem";

const SmsVerificationBlock = () => {

  const classes = useStyles()

  const [ blocks, setBlocks ] = useState<SmsVerificationBlocksResponse>()

  const { isLoading, isFetching, isRefetching, refetch } = useQuery<SmsVerificationBlocksResponse>(
    ['smsVerificationBlock'],
    async () => api.get(`${buzzEndpoints.smsVerificationBlocks()}`).then(res => res.data),
    {
      onSuccess: (data) => {
        setBlocks(data)
      }
    }
  )

  const {mutate: invalidate} = useMutation((data: { value: string }) => api.delete(`${buzzEndpoints.smsVerificationBlocks()}`, { data: { nationalPhoneNumber: data.value } }),
    {
      onSuccess: () => {
        refetch()
      }
    })

  const handleInvalidate = (value: string) => {
    invalidate({
      value: value
    })
  }

  return (
    <Layout level='회원 관리' title='문자 인증 차단 리스트' loading={isLoading || isFetching || isRefetching}>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {['핸드폰 번호', '처리'].map(text => (
                <TableCell key={text} className={classes.tableHeadCell}>{text}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {blocks?.data?.phoneNumberList?.map(data => (
              <SmsVerificationBlockListItem
                {...data}
                onClickInvalidate={handleInvalidate}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Layout>
  )
}

export default SmsVerificationBlock

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    borderRadius: 16,
    marginTop: 24,
    boxSizing: 'border-box',
  },
  tableHeadCell: {
    background: theme.palette.background.paper
  },
  modalContainer: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paginationContainer: {
    padding: 24,
    boxSizing: 'border-box',
    float: 'right'
  }
}))
