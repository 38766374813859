import { Box, Button, FormControl, InputLabel, makeStyles, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import buzzEndpoints from '../../../api/buzz'
import { api } from '../../../api/common'
import slinkEndpoints from '../../../api/slink'
import TextModal from '../../../components/common/Modal/TextModal'
import Layout from '../../../components/Layout'
import adminPermissionFilter from '../../../utils/filter/adminPermissionFilter'

const Util = () => {

    const classes = useStyles()

    const [ location, setLocation ] = useState<string>('')
    const [ settingUserId, setSettingUserId ] = useState<string>('')
    const [ locationUserId, setLocationUserId ] = useState<string>('')
    const [ checkSettingModalOpened, setCheckSettingModalOpened ] = useState<boolean>(false)
    const [ checkLocationModalOpened, setCheckLocationModalOpened ] = useState<boolean>(false)
    const [ settingUserError, setSettingUserError ] = useState<boolean>(false)
    const [ locationUserError, setLocationUserError ] = useState<boolean>(false)
    const [ locationSelectError, setLocationSelectError ] = useState<boolean>(false)

    const { mutate: syncMatchSetting } = useMutation(
        () => api.post(`${slinkEndpoints.syncMatchSetting(Number(settingUserId))}`).then(res => res.data),
        {
            onSuccess: () => handleSendSetting()
        }
    )
    const { mutate: updateLocality } = useMutation(
        () => api.post(`${buzzEndpoints.updateUserLocality(Number(locationUserId))}`, { locality: location }).then(res => res.data),
        {
            onSuccess: () => handleChangeLocation()
        }
    )

    const handleLocationChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        setLocation(e.target.value as string)
    }
    const handleChangeSettingId = (e: React.ChangeEvent<HTMLInputElement>) => {
        let regex = /^[0-9]*$/
        if(regex.test(e.target.value)){
            setSettingUserId(e.target.value)
            setSettingUserError(false)
        }
    }
    const handleChangeLocationId = (e: React.ChangeEvent<HTMLInputElement>) => {
        let regex = /^[0-9]*$/
        if(regex.test(e.target.value)){
            setLocationUserId(e.target.value)
            setLocationUserError(false)
            setLocationSelectError(false)
        }
    }

    const handleSendSetting = () => settingUserId ? setCheckSettingModalOpened(open => !open) : setSettingUserError(true)
    const handleChangeLocation = () => {
        if(!Boolean(location)){
            setLocationSelectError(true)
        }
        if(!locationUserId){
            setLocationUserError(true)
        }
        if(Boolean(location) && Boolean(locationUserId)){
            setCheckLocationModalOpened(open => !open)
        }
    }


    return (
        <Layout title='서비스 유틸' level='서비스 관리' subtitle='각종 긴급 대응 기능 모음'>
            <Box className={classes.container}>
                {adminPermissionFilter('SyncMatchSetting') && (
                    <Paper className={classes.contentBox}>
                        <Typography variant='h6' color='textPrimary'>매치 셋팅 동기화</Typography>
                        <Typography variant='body1' color='textSecondary'>매치 셋팅이 동기화 되지 않았을 경우 임시적으로 동기화 데이터를 추가한다.</Typography>
                        <Box className={classes.inputBox}>
                            <TextField
                                variant='outlined'
                                placeholder='유저 아이디'
                                fullWidth
                                value={settingUserId}
                                onChange={handleChangeSettingId}
                                error={settingUserError}
                                helperText={settingUserError ? '필수' : ''}
                            />
                        </Box>
                        <Box className={classes.buttonBox}>
                            <Button className={classes.submitButton} onClick={handleSendSetting}>전송</Button>
                        </Box>
                    </Paper>
                )}
                {adminPermissionFilter('UserProfileChangeBasicInfo') && (
                    <Paper className={classes.contentBox}>
                        <Typography variant='h6' color='textPrimary'>위치 정보 업데이트</Typography>
                        <Typography variant='body1' color='textSecondary'><b>위치 정보가 없거나, 업데이트 되지 않는 분에 한하여 처리해주는 용도입니다 </b></Typography>
                        <Typography variant='body1' color='textSecondary'>모든 지역은 <b>그 지역의 시청</b>을 기준으로 합니다.</Typography>
                        <Box className={classes.inputBox}>
                            <TextField
                                variant='outlined'
                                placeholder='유저 아이디'
                                style={{ width: 400 }}
                                value={locationUserId}
                                onChange={handleChangeLocationId}
                                error={locationUserError}
                                helperText={locationUserError ? '필수' : ''}
                            />
                            <FormControl variant='outlined' className={classes.selectBox} error={locationSelectError}>
                                <InputLabel id='location'>지역</InputLabel>
                                <Select id='location' value={location} onChange={handleLocationChange}>
                                    {locations.map(location => (
                                        <MenuItem key={location} value={location}>{location}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className={classes.buttonBox}>
                            <Button className={classes.submitButton} onClick={handleChangeLocation}>변경</Button>
                        </Box>
                    </Paper>
                )}
            </Box>
            <TextModal
                open={checkSettingModalOpened}
                onClose={handleSendSetting}
                children={<Typography color='textPrimary'>유저 아이디 "{settingUserId}"님의 매치 셋팅을 동기화 하시겠습니까?</Typography>}
                onSubmit={() => syncMatchSetting()}
            />
            <TextModal
                open={checkLocationModalOpened}
                onClose={handleChangeLocation}
                children={(
                    <Box>
                        <Typography color='textPrimary'>유저 아이디 "{locationUserId}"님의 위치정보를</Typography>
                        <Typography color='textPrimary'>[{location}] (으)로 변경합니다.</Typography>
                    </Box>
                )}
                onSubmit={() => updateLocality()}
            />
        </Layout>
    )
}

export default Util

const useStyles = makeStyles((theme) => ({
    modalContainer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 24,
        boxSizing: 'border-box'
    },
    contentBox: {
        borderRadius: 16,
        width: '100%',
        padding: 24,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        marginTop: 24,
    },
    inputBox: {
        display: 'flex',
        marginTop: 10,
        columnGap: 10
    },
    buttonBox: {
        marginTop: 5
    },
    selectBox: {
        width: 400
    },
    submitButton: {
        backgroundColor: '#1976d2',
        color: '#ffffff',
        "&:hover": {
            backgroundColor: '#1976d2',
        },
    },
}))

const locations = [
    '서울특별시',
    '대전광역시',
    '대구광역시',
    '부산광역시',
    '광주광역시',
    '인천광역시',
    '수원시',
    '안양시',
    '제주시',
    '세종시',
    '창원시'
]
