import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import React from 'react'

const Loading = () => {

    const classes = useStyles()

    return (
        <Box className={classes.progressBox}>
            <CircularProgress size={80} className={classes.progress}/>
        </Box>
    )
}

export default Loading

const useStyles = makeStyles((theme) => ({
    progressBox: {
        width: `calc(100vw - 328px)`,
        height: '90%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
    },
    progress: {
        zIndex: 10,
    }
}))