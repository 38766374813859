import { Box, Button, makeStyles, OutlinedInput, Paper, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { api } from '../../../api/common'
import Layout from '../../../components/Layout'
import buzzEndpoints from '../../../api/buzz'

const resultText = {
    success: '정상',
    error: '😱 실패',
    already: '😲 이미 동기화 되어 있습니다.',
    resultSuccess: (userId: number, targetUserId: number) => `😃 ${userId}님과
                    ${targetUserId}님 차단 관계가 동기화 됐습니다.`
};

const Exclusion = () => {

    const classes = useStyles()

    const { mutate: getExclusion } = useMutation(
        (data: { userId: number, targetUserId: number }) => api.post(`${buzzEndpoints.syncBlockedRelation()}`, { user_id: data.userId, target_id: data.targetUserId }).then(res => res.data),
    )

    const [ userId, setUserId ] = useState<string>('')
    const [ userIdError, setUserIdError ] = useState<boolean>(false)
    const [ targetUserId, setTargetUserId ] = useState<string>('')
    const [ targetUserIdError, setTargetUserIdError ] = useState<boolean>(false)
    const [ result, setResult ] = useState<boolean | string | undefined>()

    const handleExclusion = () => {
        getExclusion({
            userId: Number(userId),
            targetUserId: Number(targetUserId)
        }, {
            onSuccess: (data: { result: any }) => {
                setResult(data.result)
            },
            onError: () => {
                setResult('error')
            }
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let regex = /^[0-9]*$/
        if(regex.test(e.target.value)){
            if(e.target.name==='userId'){
                setUserId(e.target.value)
                if(e.target.value===''){
                    setUserIdError(true)
                } else {
                    setUserIdError(false)
                }
            } else {
                setTargetUserId(e.target.value)
                if(e.target.value===''){
                    setTargetUserIdError(true)
                } else {
                    setTargetUserIdError(false)
                }
            }
        }
    }

    const resultFilter = (result: boolean | string | undefined) => {
        if(result===undefined){
            return ''
        }
        if(!result){
            return resultText.already
        }
        if(result==='error'){
            return resultText.error
        }
        return resultText.resultSuccess(Number(userId), Number(targetUserId))
    }

    return (
        <Layout title='차단관계 동기화' subtitle='이미 연결되어 만들어진 대화방에 영향을 주지 않습니다. (단, 유저가 스스로 상대를 차단할 때 대화방이 자동으로 나가집니다)' level='서비스 관리'>
            <Box className={classes.container}>
                <Paper className={classes.tableContainer}>
                    <TextField 
                        placeholder='유저 아이디' 
                        fullWidth
                        value={userId}
                        error={userIdError}
                        name='userId'
                        onChange={handleChange}    
                        helperText={userIdError ? '아이디를 입력해 주세요' : ''}
                        variant='outlined'
                    />
                    <TextField
                        placeholder='대상 유저 아이디' 
                        fullWidth
                        value={targetUserId}
                        error={targetUserIdError}
                        name='targetUserId'
                        onChange={handleChange}
                        helperText={targetUserIdError ? '아이디를 입력해 주세요' : ''}
                        variant='outlined'
                    />
                    <Box className={classes.buttonBox}>
                        <Button className={classes.submitButton} onClick={handleExclusion} fullWidth disabled={(!Boolean(userId) || !Boolean(targetUserId))}>차단관계 동기화</Button>
                    </Box>
                </Paper>
                <Typography variant='subtitle1' color='textPrimary'>{resultFilter(result)}</Typography>
            </Box>
        </Layout>
    )
}

export default Exclusion

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: `calc(100% - 60px)`
    },
    tableContainer: {
        borderRadius: 16,
        marginTop: 24,
        width: 1000,
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 24,
        marginBottom: 120
    },
    buttonBox: {
        borderTop: `1px solid ${theme.palette.divider}`,
        paddingTop: 24
    },
    submitButton: {
        backgroundColor: '#4caf50',
        color: '#ffffff',
        "&:disabled": {
            backgroundColor: theme.palette.action.disabled
        },
        "&:hover": {
            backgroundColor: '#4caf50',
        },
        paddingTop: 10,
        paddingBottom: 10
    },
}))