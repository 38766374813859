import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'

const Production = () => {

    const classes = useStyles()

    return (
        <Box className={classes.container}>
            <Typography className={classes.env} variant='h6'>Production</Typography>
        </Box>
    )
}

export default Production

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: '#f44336',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
    },
    env: {
        color: '#ffffff',
        fontWeight: 'bold'
    }
}))