
export const argosUserPageLink = (env: string, userId: number) => {
    switch (env) {
      case "local":
      case "dev":
      case "staging":
        return `https://argos.cupist.dev/new-glam/user?userId=${userId}`;
      default:
        return `https://argos.cupist.io/new-glam/user?userId=${userId}`;
    }
  };