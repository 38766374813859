import { Box, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core'
import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import buzzEndpoints from '../../../../api/buzz'
import { MessageResponse } from '../../../../models/api/buzz/message'
import { ReportResponse } from '../../../../models/api/buzz/report'
import contextCodeFilter from '../../../../utils/filter/contextCodeFilter'
import messageFilter from '../../../../utils/filter/messageFilter'
import EditLayout from './EditLayout'
import reportCodeFilter from '../../../../utils/filter/reportCodeFilter'
import { PaginationResponse } from '../../../../models/api/buzz'
import { getParams } from '../../../../utils/api'
import { useQuery } from 'react-query'
import { api } from '../../../../api/common'
import usePagination from '../../../../hooks/usePagination'
import { Pagination } from '@material-ui/lab'
import kstFilter from '../../../../utils/filter/kstFilter'

const ReportItem: React.FC<ReportResponse> = ({
    context, createdAt, userReported
}) => {
    return (
        <TableRow>
            <TableCell>{reportCodeFilter(context)}</TableCell>
            <TableCell>{kstFilter(createdAt)}</TableCell>
            <TableCell>{userReported.nickname}</TableCell>
        </TableRow>
    )
}

interface Props {
    userId: number
    close: () => void
}

const ReportHistory: React.FC<Props> = forwardRef(({
    userId, close
}, ref) => {

    const [ reports, setReports ] = useState<ReportResponse[]>([])

    const { page, setPage, pageLength, setPageLength } = usePagination({
        initalPage: 1
    })

    const { isLoading, isFetching, isRefetching } = useQuery<{ data: ReportResponse[], pagination: PaginationResponse }>(
        ['userReports', page],
        async () => api.get(`${buzzEndpoints.getVideoCallReports(userId)}${getParams({ page: page })}`).then(res => res.data),
        {
            onSuccess: (data) => {
                setReports(data.data)
                setPageLength(data.pagination.totalPages)
            }
        }
    )

    const classes = useStyles()

    const isEmpty = useMemo(() => {
        if(!isLoading && !isFetching && !isRefetching && !reports.length){
            return true
        } else {
            return false
        }
    }, [reports, isLoading, isFetching, isRefetching])

    return (
        <EditLayout title='신고 내역' onSubmit={close} close={close}>
            <Box className={classes.container}>
                <Box className={classes.contentBox}>
                    <Typography variant='h6' color='textPrimary'>신고 내역</Typography>
                    {isEmpty && <Typography color='textPrimary' variant='subtitle1'>데이터가 없습니다.</Typography>}
                    {!isEmpty && (
                        <TableContainer className={classes.tableBox}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>사유</TableCell>
                                        <TableCell>시간</TableCell>
                                        <TableCell>신고자</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reports.map(report => (
                                        <ReportItem key={report.id} {...report}/>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                    {!isEmpty && (
                        <Pagination
                            page={page}
                            count={pageLength}
                            onChange={(_, value) => setPage(value)}
                            className={classes.paginationBox}
                        />
                    )}
                </Box>
            </Box>
        </EditLayout>
    )
})

export default ReportHistory

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    contentBox: {
        padding: 24,
        boxSizing: 'border-box'
    },
    tableBox: {
        marginTop: 10,
        height: '40vh',
        overflowY: 'scroll'
    },
    paginationBox: {
        height: 60,
        display: 'flex',
        justifyContent: 'flex-end'
    }
}))