import CharacterType from "../../models/api/enum/character"

const characterFilter = (character: CharacterType) => {
    switch(character){
        case 'UC00':
            return '수줍은'
        case 'UC01':
            return '낙천적인'
        case 'UC02':
            return '활발한'
        case 'UC03':
            return '꼼꼼한'
        case 'UC04':
            return '정직한'
        case 'UC05':
            return '감성적인'
        case 'UC06':
            return '친절한'
        case 'UC08':
            return '털털한'
        case 'UC09':
            return '애교있는'
        case 'UC10':
            return '도도한'
        case 'UC11':
            return '도발적인'
        case 'UC13':
            return '웃긴'
        case 'UC14':
            return '열정적인'
        case 'UC15':
            return '단순한'
        case 'UC16':
            return '엉뚱한'
        case 'UC18':
            return '성실한'
        case 'UC19':
            return '주도적인'
        case 'UC20':
            return '조용한'
        default:
            return character
    }
}

export default characterFilter