const potatoEndpointPrefix = '/admin/potato'

const potatoEndpoints = {
    callSelfAuthentication: (id: number) => potatoEndpointPrefix + `/authentication/self/${id}`,
    login: () => `/potato/admin` + `/login`,
    changePassword: () => potatoEndpointPrefix + `/password`,
    resetLoginCount: (id: number) => potatoEndpointPrefix + `/login/counts/${id}`,

    getUsers: () => potatoEndpointPrefix + `/user`,
    getUser: (id: number) => potatoEndpointPrefix + `/user/${id}`,

    getUserReportsWait: () => potatoEndpointPrefix + `/reports`,
    getUserReportsDone: () => potatoEndpointPrefix + `/reports/approval/history`,
    approveUserReport: (id: number) => potatoEndpointPrefix + `/reports/approval/${id}`,
    getUserReports: (id: number) => potatoEndpointPrefix + `/reports/${id}`,

    getSelfAuth: () => potatoEndpointPrefix + `/authentication/self`,
    reviewSelfAuth: (id: number) => potatoEndpointPrefix + `/authentication/self/${id}/review`,

    getSelfVerification: () => potatoEndpointPrefix + `/verification/photo`,
    photoVerification: (id: number) => potatoEndpointPrefix + `/verification/photo/${id}`,
    createSelfVerification: (id: number) => potatoEndpointPrefix + `/authentication/self/${id}`,

    getVideoCallReportsWait: () => potatoEndpointPrefix + `/reports`,
    getVideoCallReportsDone: () => potatoEndpointPrefix + `/reports/approval/history`,

    //event
    getEvents: () => potatoEndpointPrefix + `/events`,
    updateEvent: (id: number) => potatoEndpointPrefix + `/events/${id}`,
    createEvent: () => potatoEndpointPrefix + `/events`,
    getTargetEvents: () => potatoEndpointPrefix + `/events/target`,
    updateTargetEvent: () => potatoEndpointPrefix + `/events/target`
}

export default potatoEndpoints