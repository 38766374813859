import { Box, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import React, { forwardRef, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import buzzEndpoints from '../../../../../api/buzz'
import { api } from '../../../../../api/common'
import { UserDetailResponse } from '../../../../../models/api/buzz'
import { FreeConnectionResponse, FreeLikeResponse } from '../../../../../models/api/buzz/freeHistory'
import freeHistoryContextFilter from '../../../../../utils/filter/freeHistoryContextFilter'
import kstFilter from '../../../../../utils/filter/kstFilter'
import EditLayout from '../EditLayout'

interface Props {
    close: () => void
    userId: number
    userData: UserDetailResponse
}

const FreeHistory: React.FC<Props> = forwardRef(({
    close, userId, userData
}, ref) => {

    const classes = useStyles()

    const [ freeLikes, setFreeLikes ] = useState<FreeLikeResponse[]>([])
    const [ freeConnections, setFreeConnections ] = useState<FreeConnectionResponse[]>([])

    const {  } = useQuery<{ data: FreeLikeResponse[] }>(
        ['freeLikes', userId],
        () => api.get(`${buzzEndpoints.freeLikeHistory(userId)}`).then(res => res.data),
        {
            onSuccess: (data) => setFreeLikes(data.data)
        }
    )
    const {  } = useQuery<{ data: FreeConnectionResponse[] }>(
        ['freeConnections', userId],
        () => api.get(`${buzzEndpoints.freeConnectionHistory(userId)}`).then(res => res.data),
        {
            onSuccess: (data) => setFreeConnections(data.data)
        }
    )

    return (
        <EditLayout close={close} title='무료 이용 및 충전 내역' onSubmit={close}>
            <Box className={classes.contentBox}>
                <Box className={classes.itemBox}>
                    <Box className={classes.titleBox}>
                        <Typography color='textPrimary' className={classes.title}>좋아요</Typography>
                        {/* <Box style={{ display: 'flex' }}>
                            <Typography color='textPrimary'>충전까지&nbsp;</Typography>
                            <Typography color={userData.freeLikeInfo.remainSeconds===-2 ? 'textSecondary' : 'secondary'} className={classes.desc}>
                                {userData.freeLikeInfo.remainSeconds===-2 && '사용 안 함'}
                                {userData.freeLikeInfo.remainSeconds===-1 && '00:00:00'}
                                {userData.freeLikeInfo.remainSeconds>=0 && freeLikeRemainTime}
                            </Typography>
                        </Box> */}
                    </Box>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHeadCell}>일시</TableCell>
                                    <TableCell className={classes.tableHeadCell}>내역</TableCell>
                                    <TableCell className={classes.tableHeadCell}>잔여</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {freeLikes.map(freeLike => (
                                    <TableRow key={freeLike.id}>
                                        <TableCell>{kstFilter(freeLike?.createdAt)}</TableCell>
                                        <TableCell>
                                            {freeLike.context==='FH000' ? (
                                                <Typography variant='body2' color='textSecondary'>{freeLike?.relatedUser?.nickname}에게 사용</Typography>
                                            ) : (
                                                <Typography variant='body2' color='textPrimary'>{freeHistoryContextFilter(freeLike.context)}</Typography>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant='body2' color='secondary'>{freeLike?.remains}</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box className={classes.itemBox}>
                    <Box className={classes.titleBox}>
                        <Typography color='textPrimary' className={classes.title}>대화 열기</Typography>
                        {/* <Box style={{ display: 'flex' }}>
                            <Typography color='textPrimary'>충전까지&nbsp;</Typography>
                            <Typography color={userData.freeConnectionInfo.remainSeconds===-2 ? 'textSecondary' : 'secondary'} className={classes.desc}>
                                {userData.freeConnectionInfo.remainSeconds===-2 && '사용 안 함'}
                                {userData.freeConnectionInfo.remainSeconds===-1 && '00:00:00'}
                                {userData.freeConnectionInfo.remainSeconds>=0 && freeConnectionRemainTime}
                            </Typography>
                        </Box> */}
                    </Box>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHeadCell}>일시</TableCell>
                                    <TableCell className={classes.tableHeadCell}>내역</TableCell>
                                    <TableCell className={classes.tableHeadCell}>잔여</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {freeConnections.map(freeConnection => (
                                    <TableRow key={freeConnection.id}>
                                        <TableCell>{kstFilter(freeConnection?.createdAt)}</TableCell>
                                        <TableCell>
                                            {freeConnection.context==='FH000' ? (
                                                <Typography variant='body2' color='textSecondary'>{freeConnection?.relatedUser?.nickname}에게 사용</Typography>
                                            ) : (
                                                <Typography variant='body2' color='textPrimary'>{freeHistoryContextFilter(freeConnection.context)}</Typography>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Typography color='secondary' variant='body2'>{freeConnection?.remains}</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </EditLayout>
    )
})

export default FreeHistory

const useStyles = makeStyles((theme) => ({
    contentBox: {
        padding: 24,
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'space-between'
    },
    itemBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '45%'
    },
    titleBox: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    title: {
        fontWeight: 'bold'
    },
    desc: {

    },
    tableHeadCell: {
        paddingTop: 5,
        paddingBottom: 5
    },
    tableContainer: {
        width: '100%',
        marginTop: 10
    },
    date: {

    },
    content: {

    },
    remain: {

    }
}))