import ConnectionStateType from "../../models/api/enum/connectionState";

const connectionStateFilter = (connectionState: ConnectionStateType) => {
    switch(connectionState){
        case 'N':
            return '연결됨'
        case 'O':
            return '대화 진행 중'
        case 'X':
            return '연결 해제'
        default:
            return connectionState
    }
}

export default connectionStateFilter