import React from 'react'
import { Box, Typography, makeStyles, Button } from '@material-ui/core'
import { EventPoint, EventResponse } from '../../../../models/api/potato/event'
import fullPathFilter from '../../../../utils/filter/fullPathFilter'
import dateGmtFilter from '../../../../utils/filter/dateGmtFilter'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import BooleanType from '../../../../models/api/enum/boolean'

const regions = [
    { code: 'KR', name: '한국' },
    { code: 'SA', name: '사우디아라비아' },
    { code: 'KW', name: '쿠웨이트' },
    { code: 'US', name: '미국' },
    { code: 'TR', name: '터키' },
    { code: 'IL', name: '이스라엘' },
    { code: 'JP', name: '일본' },
    { code: 'DE', name: '독일' },
    { code: 'FR', name: '프랑스' },
    { code: 'GB', name: '영국' },
    { code: 'SE', name: '스웨덴' },
    { code: 'CA', name: '캐나다' },
    { code: 'AU', name: '호주' },
    { code: 'MY', name: '말레이시아' },
    { code: 'BR', name: '브라질(남미)' },
    { code: 'RU', name: '러시아' },
    { code: 'GLOBAL_H', name: '전체 고가 티어' },
    { code: 'ISLM_L', name: '이슬람 저가 티어' },
    { code: 'ISLM_M', name: '이슬람(저가)' },
    { code: 'ISLM_G', name: '이슬람(Global)' },
    { code: 'ISLM_H', name: '이슬람 고가 티어' }
]

const gemV2 = [3, 10, 30, 80, 1, 2, 7, 200];
const gemV4 = [150, 500, 1500, 4000, 25, 65, 350, 10000];
export const gemV5 = [150, 400, 1000, 2000, 4000, 8000, 1600, 550];
export const gemV5ProductsName = ['gem_sky', 'gem_aqua', 'gem_cyan', 'gem_blue', 'gem_navy', 'gem_denim', 'gem_cobalt', 'gem_teal (바텀시트 전용)']

interface Props {
    onClickToggle: (id: number, isActivate: BooleanType) => void
    onClickDelete: (id: number, name: string) => void
    onClickUpdate: (id: number, data: EventResponse) => void
    data: EventResponse
}

const ServiceEventListItem: React.FC<Props> = (props) => {

    const { data, onClickToggle, onClickDelete, onClickUpdate } = props

    const { id, eventName, imagePath, region, targetUserType, startDate, endDate, eventDetails, startNotificationDate, endNotificationDate, willSendStartNotification, willSendEndNotification, eventPoints, isActivate } = data

    const classes = useStyles()

    const checkSendNotification = (date: string, region: string) => {
        const currentDate = new Date();
        const targetDate = new Date(dateGmtFilter(date, region))
        return targetDate.getTime() <= currentDate.getTime()
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.numberBox}>
                <Typography color='textPrimary'>NO.{id} | {eventName}</Typography>
                <ToggleButtonGroup onChange={(e, value) => onClickToggle(id, value)} exclusive>
                    <ToggleButton value='Y' className={classes.toggleOn} style={isActivate==='Y' ? { color: '#ffffff', backgroundColor: '#449d44' } : {}}>ON</ToggleButton>
                    <ToggleButton value='N' className={classes.toggleOff} style={isActivate==='N' ? { color: '#ffffff', backgroundColor: '#c9302c' } : {}}>OFF</ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Box className={classes.contentBox}>
                <img src={fullPathFilter(imagePath)} className={classes.eventImage}/>
                <Box className={classes.contentItemBox}>
                    <Box className={classes.itemBox}>
                        <Typography color='textSecondary' variant='body2'>대상</Typography>
                        <Typography color='textPrimary' variant='body2'>국가: <b>{regions.find(localRegion => localRegion.code===region)?.name || ''}</b> / 구매 횟수: {targetUserType==='all' ? '상관 없음' : '1회 이상'}</Typography>
                    </Box>
                    <Box className={classes.itemBox}>
                        <Typography color='textSecondary' variant='body2'>기간</Typography>
                        <Typography color='textPrimary' variant='body2'>{dateGmtFilter(startDate, region)} ~ {dateGmtFilter(endDate, region)}</Typography>
                    </Box>
                    <Box className={classes.itemBox}>
                        <Typography color='textSecondary' variant='body2'>노티피케이션 안내말</Typography>
                        <Typography color='textPrimary' variant='body2'>{eventDetails.map(eventDetail => eventDetail.comment).join(' | ')}</Typography>
                    </Box>
                    <Box className={classes.itemBox}>
                        <Box style={{ display: 'flex', columnGap: 12 }}>
                            <Typography color='textSecondary' variant='body2'>시작 노티</Typography>
                            <Typography color='textPrimary' variant='body2'>{dateGmtFilter(startNotificationDate, region)} {willSendStartNotification==='Y' ? checkSendNotification(startNotificationDate, region) ? '전송됨' : '전송 예정' : '전송 안함'}</Typography>
                        </Box>
                        <Box style={{ display: 'flex', columnGap: 12 }}>
                            <Typography color='textSecondary' variant='body2'>종료 노티</Typography>
                            <Typography color='textPrimary' variant='body2'>{dateGmtFilter(endNotificationDate, region)} {willSendEndNotification==='Y' ? checkSendNotification(endNotificationDate, region) ? '전송됨' : '전송 예정' : '전송 안함'}</Typography>
                        </Box>
                    </Box>
                    <Box className={classes.itemBox} style={{ borderBottom: 'none' }}>
                        <Typography color='textSecondary' variant='body2'>상품 구성</Typography>
                        {eventPoints.length >= 1 && (
                            <>
                            <Typography color='textSecondary' variant='body2'>v2</Typography>
                            <Box className={classes.tableBox}>
                                <Box className={classes.tableHead}>
                                    {['상품 코드', 'iOS', '보너스', 'Android', '보너스'].map((text, index) => (
                                        <Typography className={classes.tableHeadCell} key={index} color='textPrimary' variant='body1'>{text}</Typography>
                                    ))}
                                </Box>
                                <Box className={classes.tableRow}>
                                    <Typography className={classes.tableCell} color='textSecondary' variant='body1'>glam_gem_1</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV2[0]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[0].iosBonus_1}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV2[0]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[0].androidBonus_1}</Typography>
                                </Box>
                                <Box className={classes.tableRow}>
                                    <Typography className={classes.tableCell} color='textSecondary' variant='body1'>glam_gem_2</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV2[1]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[0].iosBonus_2}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV2[1]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[0].androidBonus_2}</Typography>
                                </Box>
                                <Box className={classes.tableRow}>
                                    <Typography className={classes.tableCell} color='textSecondary' variant='body1'>glam_gem_3</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV2[2]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[0].iosBonus_3}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV2[2]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[0].androidBonus_3}</Typography>
                                </Box>
                                <Box className={classes.tableRow}>
                                    <Typography className={classes.tableCell} color='textSecondary' variant='body1'>glam_gem_4</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV2[3]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[0].iosBonus_4}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV2[3]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[0].androidBonus_4}</Typography>
                                </Box>
                                <Box className={classes.tableRow}>
                                    <Typography className={classes.tableCell} color='textSecondary' variant='body1'>glam_gem_5</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV2[4]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[0].iosBonus_5}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV2[4]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[0].androidBonus_5}</Typography>
                                </Box>
                                <Box className={classes.tableRow}>
                                    <Typography className={classes.tableCell} color='textSecondary' variant='body1'>glam_gem_6</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV2[5]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[0].iosBonus_6}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV2[5]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[0].androidBonus_6}</Typography>
                                </Box>
                                <Box className={classes.tableRow}>
                                    <Typography className={classes.tableCell} color='textSecondary' variant='body1'>glam_gem_7</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV2[6]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[0].iosBonus_7}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV2[6]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[0].androidBonus_7}</Typography>
                                </Box>
                                <Box className={classes.tableRow}>
                                    <Typography className={classes.tableCell} color='textSecondary' variant='body1'>glam_gem_8</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV2[7]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[0].iosBonus_8}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV2[7]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[0].androidBonus_8}</Typography>
                                </Box>
                            </Box>
                            </>
                        )}

                        {eventPoints.length >= 2 && (
                            <>
                            <Typography color='textSecondary' variant='body2'>v4</Typography>
                            <Box className={classes.tableBox}>
                                <Box className={classes.tableHead}>
                                    {['상품 코드', 'iOS', '보너스', 'Android', '보너스'].map((text, index) => (
                                        <Typography className={classes.tableHeadCell} key={index} color='textPrimary' variant='body1'>{text}</Typography>
                                    ))}
                                </Box>
                                <Box className={classes.tableRow}>
                                    <Typography className={classes.tableCell} color='textSecondary' variant='body1'>glam_gem_1</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV4[0]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[1].iosBonus_1}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV4[0]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[1].androidBonus_1}</Typography>
                                </Box>
                                <Box className={classes.tableRow}>
                                    <Typography className={classes.tableCell} color='textSecondary' variant='body1'>glam_gem_2</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV4[1]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[1].iosBonus_2}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV4[1]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[1].androidBonus_2}</Typography>
                                </Box>
                                <Box className={classes.tableRow}>
                                    <Typography className={classes.tableCell} color='textSecondary' variant='body1'>glam_gem_3</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV4[2]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[1].iosBonus_3}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV4[2]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[1].androidBonus_3}</Typography>
                                </Box>
                                <Box className={classes.tableRow}>
                                    <Typography className={classes.tableCell} color='textSecondary' variant='body1'>glam_gem_4</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV4[3]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[1].iosBonus_4}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV4[3]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[1].androidBonus_4}</Typography>
                                </Box>
                                <Box className={classes.tableRow}>
                                    <Typography className={classes.tableCell} color='textSecondary' variant='body1'>glam_gem_5</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV4[4]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[1].iosBonus_5}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV4[4]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[1].androidBonus_5}</Typography>
                                </Box>
                                <Box className={classes.tableRow}>
                                    <Typography className={classes.tableCell} color='textSecondary' variant='body1'>glam_gem_6</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV4[5]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[1].iosBonus_6}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV4[5]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[1].androidBonus_6}</Typography>
                                </Box>
                                <Box className={classes.tableRow}>
                                    <Typography className={classes.tableCell} color='textSecondary' variant='body1'>glam_gem_7</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV4[6]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[1].iosBonus_7}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV4[6]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[1].androidBonus_7}</Typography>
                                </Box>
                                <Box className={classes.tableRow}>
                                    <Typography className={classes.tableCell} color='textSecondary' variant='body1'>glam_gem_8</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV4[7]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[1].iosBonus_8}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV4[7]}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[1].androidBonus_8}</Typography>
                                </Box>
                            </Box>
                            </>
                        )}

                        {eventPoints.length >= 3 && (
                            <>
                            <Typography color='textSecondary' variant='body2'>v5</Typography>
                            <Box className={classes.tableBox}>
                                <Box className={classes.tableHead}>
                                    {['상품 코드', 'iOS', '보너스', 'Android', '보너스'].map((text, index) => (
                                        <Typography className={classes.tableHeadCell} key={index} color='textPrimary' variant='body1'>{text}</Typography>
                                    ))}
                                </Box>
                                {
                                    gemV5ProductsName.map((product, index) => {
                                        const iosBonusPoint = `iosBonus_${index + 1}` as keyof EventPoint
                                        const androidBonusPoint = `androidBonus_${index + 1}` as keyof EventPoint
                                        return (
                                            <Box className={classes.tableRow}>
                                                <Typography className={classes.tableCell} color='textSecondary' variant='body1'>{product}</Typography>
                                                <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV5[index]}</Typography>
                                                <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[2]?.[iosBonusPoint]}</Typography>
                                                <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{gemV5[index]}</Typography>
                                                <Typography className={classes.tableCell} color='textPrimary' variant='body1'>{eventPoints[2]?.[androidBonusPoint]}</Typography>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                            </>
                            )
                        }
                    </Box>
                </Box>
            </Box>
            <Box className={classes.buttonBox}>
                <Button variant='outlined' onClick={() => onClickUpdate(id, data)}>수정</Button>
                <Button className={classes.deleteButton} onClick={() => onClickDelete(id, eventName)}>삭제</Button>
            </Box>
        </Box>
    )
}

export default ServiceEventListItem

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 8,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        marginBottom: 24
    },
    numberBox: {
        backgroundColor: theme.palette.action.selected,
        padding: 16,
        boxSizing: 'border-box',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        display: 'flex',
        justifyContent: 'space-between'
    },
    contentBox: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: 24,
        boxSizing: 'border-box',
        alignItems: 'flex-start'
    },
    eventImage: {
        width: '20%',
        objectFit: 'contain'
    },
    contentItemBox: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 12,
        width: `calc(80% - 24px)`
    },
    itemBox: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 12,
        paddingBottom: 12,
    },
    tableBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        borderTop: `1px solid ${theme.palette.divider}`,
        borderLeft: `1px solid ${theme.palette.divider}`,
        boxSizing: 'border-box'
    },  
    tableRow: {
        display: 'flex'
    },
    tableCell: {
        width: '20%',
        borderRight: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        textAlign: 'center',
        padding: '8px 0'
    },
    tableHead: {
        backgroundColor: theme.palette.action.selected,
        display: 'flex'
    },  
    tableHeadCell: {
        width: '20%',
        boxSizing: 'border-box',
        borderRight: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: '8px 0',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    toggleOn: {
        "&:hover": {
            color: '#ffffff',
            backgroundColor: '#449d44'
        },
        padding: '0 10px'
    },
    toggleOff: {
        "&:hover": {
            color: '#ffffff',
            backgroundColor: '#c9302c'         
        },
        padding: '0 10px'
    },
    buttonBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        columnGap: 12,
        padding: 24,
        boxSizing: 'border-box',
        borderTop: `1px solid ${theme.palette.divider}`
    },
    deleteButton: {
        backgroundColor: '#d9534f',
        color: '#ffffff',
        "&:hover": {
            backgroundColor: '#d9534f'
        }
    }
}))