import { Box, Button, makeStyles, TextField, Typography } from '@material-ui/core'
import React, { forwardRef, useState } from 'react'

interface Props {
    close: () => void
    type: 'create' | 'update'
    title?: string
    description?: string
    onSubmit: (type: 'create' | 'update', key: string, description: string) => void
}

const FeatureFlagModal: React.FC<Props> = forwardRef(({
    close, type, title, description, onSubmit
}, ref) => {

    const classes = useStyles()

    const [ key, setKey ] = useState<string>(title || '')
    const [ newDescription, setNewDescription ] = useState<string>(description || '')

    return (
        <Box className={classes.container}>
            <Box className={classes.titleBox}>
                <Typography variant='h6' color='textPrimary' className={classes.title}>피쳐플래그 {type==='create' ? '추가' : '수정'}</Typography>
            </Box>
            <Box className={classes.contentBox}>
                <TextField 
                    placeholder='피쳐플래그 ID' 
                    fullWidth 
                    disabled={Boolean(title)}
                    value={key}
                    onChange={e => setKey(e.target.value)}
                />
                <TextField 
                    placeholder='설명' 
                    fullWidth
                    value={newDescription}
                    onChange={e => setNewDescription(e.target.value)}
                />
                <Typography color='textSecondary' variant='body2'>* 등록된 피쳐플래그 ID는 수정 및 삭제할 수 없습니다.</Typography>
            </Box>
            <Box className={classes.buttonBox}>
                <Button classes={{ disabled: classes.buttonDisabled }} className={classes.submitButton} disabled={!Boolean(key)} onClick={() => onSubmit(type, title ? title : key, newDescription)}>저장</Button>
            </Box>
        </Box>
    )
})

export default FeatureFlagModal

const useStyles = makeStyles((theme) => ({
    container: {
        borderRadius: 16,
        backgroundColor: theme.palette.background.paper,
        maxWidth: 600,
        width: '60vw'
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 24px',
        boxSizing: 'border-box',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    title: {
        fontWeight: 'bold',
    },
    contentBox: {
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 15
    },
    buttonBox: {
        padding: '15px 24px',
        borderTop: `1px solid ${theme.palette.divider}`,
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    buttonDisabled: {
        color: 'rgba(0, 0, 0, .26)',
        backgroundColor: 'rgba(0, 0, 0, .12)'
    },
    submitButton: {
        backgroundColor: '#4caf50',
        color: '#ffffff',
        "&:disabled": {
            backgroundColor: theme.palette.action.disabled
        }
    },
}))