import Admin from './Service/Admin';
import AppealList from './Appeal/AppealList';
import AppealReportWait from './Appeal/AppealReportWait';
import AppealReportDone from './Appeal/AppealReportDone';
import Induce from './Service/Induce';
import Flag from './Service/Flag';
import Exclusion from './Service/Exclusion';
import Util from './Service/Util';
import Application from './Service/Application';
import Ads from './Service/Ads';
import Banner from './Event/Banner';
import Receipt from './Service/Receipt';
import Service from './Event/Service';
import Target from './Event/Target';
import AppealCommentList from './Appeal/AppealCommentList';

const Routes = {

    //서비스 관리
    Service: {
        Admin: Admin,
        Induce: Induce,
        Exclusion: Exclusion,
        Util: Util,
        Flag: Flag,
        Application: Application,
        Ads: Ads,
        Receipt: Receipt,
    },
    
    //컨텐츠 관리
    Appeal: {
        AppealList: AppealList,
        AppealReportWait: AppealReportWait,
        AppealReportDone: AppealReportDone,
        AppealCommentList: AppealCommentList
    },

    //이벤트 관리
    Event: {
        Banner: Banner,
        Service: Service,
        Target: Target
    },
};

export default Routes