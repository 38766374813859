import {Button, makeStyles, TableCell, TableRow, Typography} from '@material-ui/core'
import React from 'react'
import {PhoneNumberListData} from "../../../models/api/buzz/sms-verification";

interface Props extends PhoneNumberListData {
  onClickInvalidate: (value: string) => void
}

const SmsVerificationBlockListItem: React.FC<Props> = ({
                                          phoneNumber,onClickInvalidate
                                        }) => {

  const classes = useStyles()

  return (
    <TableRow>
      <TableCell>
        <Typography variant='h6' color='textPrimary'>{
          phoneNumber
        }</Typography>
      </TableCell>
      <TableCell>
        <Button className={classes.submitButton} onClick={() => {
          onClickInvalidate(phoneNumber)
        }}>초기화</Button>
      </TableCell>
    </TableRow>
  )
}

export default SmsVerificationBlockListItem

const useStyles = makeStyles((theme) => ({
  nicknameBox: {
    display: 'flex',
    alignItems: 'center'
  },
  userImage: {
    width: 56,
    height: 56,
    borderRadius: 10,
    marginRight: 15,
    cursor: 'pointer'
  },
  refundButton: {
  },
  noneButton: {
    padding: '6px 16px'
  },
  submitButton: {
    backgroundColor: '#5cb85c',
    width: '100px',
    color: '#ffffff',
    "&:hover": {
      backgroundColor: '#5cb85c'
    }
  },
}))
