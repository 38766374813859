const educationReverseFilter = (education: string) => {
    switch(education){
        case '고등학교':
            return 'UE01'
        case '전문대':
            return 'UE02'
        case '대학교':
            return 'UE03'
        case '석사':
            return 'UE04'
        case '박사':
            return 'UE05'
        case '기타':
            return 'UE06'
        default:
            return education
    }
}

export default educationReverseFilter