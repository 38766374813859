const datingReverseFilter = (dating: string) => {
    switch (dating.replace(/ /gi, "")) {
        case '함께드라이브가기':
            return 'DS1';
        case '함께산책하기':
            return 'DS2';
        case '함께여행가기':
            return 'DS3';
        case '카페데이트':
            return 'DS4';
        case '함께운동하기':
            return 'DS5';
        case '꾸준히연락하기':
            return 'DS6';
        case '집데이트':
            return 'DS7';
        case '함께술마시기':
            return 'DS8';
        case '함께게임하기':
            return 'DS9';
        case '친구같은편한만남':
            return 'DS10';
        case '한번볼때진하게만나기':
            return 'DS11';
        case '함께스포츠관람':
            return 'DS12';
        case '함께전시회관람':
            return 'DS13';
        case '노래방데이트':
            return 'DS15';
        case '취미활동공유하기':
            return 'DS16';
        case '서로사진찍어주기':
            return 'DS17';
        case '영화관람':
            return 'DS18';
        case '함께맛집투어':
            return 'DS19';
        case '통화자주하기':
            return 'DS20';
        case '즉흥적인만남':
            return 'DS21';
        case '짧더라도자주만나기':
            return 'DS22';
        case '도시락나들이':
            return 'DS23';
        case '함께공연관람':
            return 'DS24';
        default:
            return dating;
    }
}

export default datingReverseFilter