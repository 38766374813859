import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, ListItem, makeStyles, TextField, Typography, List, OutlinedInput, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import buzzEndpoints from '../../../../api/buzz'
import { api } from '../../../../api/common'
import usePagination from '../../../../hooks/usePagination'
import { PaginationResponse } from '../../../../models/api/buzz'
import { MessageResponse } from '../../../../models/api/buzz/message'
import { UserAlertRequest, UserBlockRequest } from '../../../../models/api/buzz/user'
import ContextCodeType from '../../../../models/api/enum/contextCode'
import adminPermissionFilter from '../../../../utils/filter/adminPermissionFilter'
import contextCodeFilter from '../../../../utils/filter/contextCodeFilter'
import dateFilter from '../../../../utils/filter/dateFilter'
import kstFilter from '../../../../utils/filter/kstFilter'
import messageFilter from '../../../../utils/filter/messageFilter'
import EditLayout from './EditLayout'

const warningReason: ContextCodeType[] = [
    'AMR002',
    'AMR004',
    'AMR003',
    'AMR005',
    'AMR000',
    'AMR001'
]

const suspendReason: ContextCodeType[] = [
    'AMR009',
    'AMR002',
    'AMR004',
    'AMR003',
    'AMR005',
    'AMR000',
    'AMR001',
    'AMR006',
    'AMR007'
]

interface Props {
    close: () => void
    name?: string
    userId: number
    refetch: () => void
}

const CheckboxEdit: React.FC<Props> = forwardRef(({
    close, name, userId, refetch
}, ref) => {

    const classes = useStyles()

    const { mutate: userAlert } = useMutation(
        (data: UserAlertRequest) => api.post(`${buzzEndpoints.userAlert(userId)}`, data).then(res => res.data),
        {
            onSuccess: () => { 
                refetch()
                close()
            }
        }
    )
    const { mutate: userBlock } = useMutation(
        (data: UserBlockRequest) => api.post(`${buzzEndpoints.userBlock(userId)}`, data).then(res => res.data),
        {
            onSuccess: () => {
                refetch()
                close()
            }
        }
    )

    const { page, setPage, pageLength, setPageLength } = usePagination({
        initalPage: 1
    })

    const [ messages, setMessages ] = useState<string[]>([])
    const [ reason, setReason ] = useState<string>('')
    const [ shouldOutConnections, setShouldOutConnections ] = useState<boolean>(false)
    const [ shouldRefunds, setShouldRefunds ] = useState<boolean>(false)
    const [ reports, setReports ] = useState<MessageResponse[]>([])

    const { isLoading, isFetching, isRefetching } = useQuery<{ data: MessageResponse[], pagination: PaginationResponse }>(
        ['userMessages', userId, page],
        async () => api.get(`${buzzEndpoints.getUserMessage(userId, page)}`).then(res => res.data),
        {
            onSuccess: (data) => {
                setReports(data.data)
                setPageLength(data.pagination.totalPages)
            }
        }
    )

    const handleUpdate = () => {
        let messageData = messages
        if(reason){
            messageData = [...messageData, reason]
        }
        if(name==='경고'){
            userAlert({
                messages: messageData.filter(message => message)
            })
        } else if(name==='정지'){
            userBlock({
                messages: messageData.filter(message => message),
                shouldOutConnections: shouldOutConnections,
                shouldRefunds: shouldRefunds
            })
        }
    }

    const handleChange = (e: any) => {
        if(messages.includes(e.target.name)){
            setMessages(messages => messages.filter(message => message!==e.target.name))
        } else {
            setMessages(messages => [...messages, e.target.name])
        }
    }

    const handleChangeReason = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReason(e.target.value)
    }

    const isEmpty = useMemo(() => {
        if(!isLoading && !isFetching && !isRefetching && !reports.length){
            return true
        } else {
            return false
        }
    }, [reports, isLoading, isFetching, isRefetching])

    const displayData = useMemo(() => {
        return reports.map((report) => {
            const isBlockCancelAndNoReason = report.type === 'ME000' && report.messageDetails[0].context.trim() === '';
            const reason = {
                true: <span className={classes.noReasonText}>(없음)</span>,
                false: report.messageDetails.map(detail => contextCodeFilter(detail.context)).join(', ')
            }[isBlockCancelAndNoReason.toString()]

            return {
                id: report.id,
                type: messageFilter(report.type),
                reason,
                date: `${dateFilter(report.createdAt)} (${kstFilter(report.createdAt)})`,
                admin: report.admin?.nickname || 'system'
            }
        })
    }, [reports, classes.noReasonText])

    return (
        <EditLayout title={name==='정지' ? '이용 정지' : '경고'} onSubmit={handleUpdate} close={close} color='#008c00'>
            <Box className={classes.container}>
                <Box className={classes.contentBox}>
                    <Typography variant='body1' color='textPrimary'>최근 제재 내역</Typography>
                    {isEmpty && <Typography color='textPrimary' variant='subtitle1'>데이터가 없습니다.</Typography>}
                    <TableContainer className={classes.tableBox}>
                        <Table>
                            <TableBody>
                                {displayData.map(data => (
                                    <TableRow key={data.id}>
                                        <TableCell>
                                            <Typography variant='body2' color='textPrimary'>{data.type}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant='body2' color='textPrimary'>{data.reason}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant='body2' color='textPrimary'>{data.date}, {data.admin}</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {!isEmpty && (
                        <Pagination
                            page={page}
                            count={pageLength}
                            onChange={(_, value) => setPage(value)}
                            className={classes.paginationBox}
                        />
                    )}
                </Box>
                <Box className={classes.contentBox} style={{ paddingTop: 0 }}>
                    <Typography variant='body1' color='textPrimary'>처리 사유</Typography>
                    <FormGroup className={classes.labelBox} onChange={handleChange}>
                        {name==='정지' ? suspendReason.map(code => (
                            <FormControlLabel 
                                control={
                                    <Checkbox color='default' name={code}/>
                                } 
                                label={contextCodeFilter(code)==='악성 유저' ? '악성 유저 (선택 시 악성 디바이스ID 자동 등록)' : contextCodeFilter(code)}
                                className={classes.label}
                                key={code}
                            />
                        )) : name==='경고' ? warningReason.map(code => (
                            <FormControlLabel
                                control={
                                    <Checkbox color='default' name={code}/>
                                } 
                                label={contextCodeFilter(code)}
                                className={classes.label}
                                key={code}
                            />
                        )) : null}
                        <Box className={classes.labelTextFieldBox}>
                            <Checkbox color='default' value={false}/>
                            <TextField
                                placeholder='기타 사유'
                                onChange={handleChangeReason}
                                value={reason}
                                fullWidth
                            />
                        </Box>
                    </FormGroup>
                    {(name==='정지' && adminPermissionFilter('UserProfileBlockOption')) && (
                        <Box>
                            <Typography variant='body1' color='textPrimary'>옵션</Typography>
                            {messages.includes('AMR009') && (
                                <FormGroup className={classes.labelBox} onChange={(e) => setShouldRefunds(refunds => !refunds)}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox color='default' value={shouldRefunds}/>
                                        } 
                                        label={'전체 보상(악성 유저의 경우)'}
                                        className={classes.label}
                                    />
                                </FormGroup>
                            )}
                            <FormGroup className={classes.labelBox} onChange={(e) => setShouldOutConnections(outConnection => !outConnection)}>
                                <FormControlLabel
                                    control={
                                        <Checkbox color='default' value={shouldOutConnections}/>
                                    } 
                                    label={'연결 해제(복구 불가)'}
                                    className={classes.label}
                                />
                            </FormGroup>
                        </Box>
                    )}
                </Box>
            </Box>
        </EditLayout>
    )
})

export default CheckboxEdit

const useStyles = makeStyles((theme) => ({
    container: {
        maxHeight: '70vh',
        overflowY: 'scroll'
    },
    contentBox: {
        padding: 24,
        boxSizing: 'border-box',
    },
    labelBox: {
        margin: 10
    },
    label: {
        color: theme.palette.text.primary
    },
    labelTextFieldBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: -11
    },
    tableBox: {
        marginTop: 10,
        maxHeight: '20vh',
        overflowY: 'scroll'
    },
    paginationBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 24,
    },
    noReasonText: {
        color: 'rgba(0,0,0,.4)'
    }
}))