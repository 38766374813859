const dateFilter = (rawDate?: string) => {
    if (rawDate === undefined || rawDate === null) { return ''; }

    const st = rawDate.split(/[- :]/);
    // Apply each element to the Date function
    const sd = new Date(
        parseInt(st[0]),
        parseInt(st[1]) - 1,
        parseInt(st[2]),
        parseInt(st[3]),
        parseInt(st[4]),
        parseInt(st[5])
    );
    const sDate = new Date(sd);

    const srcDate = sDate.getTime() + 32400000;
    const destDate = new Date();
    let intervalSec:number = (destDate.getTime() - srcDate) / 1000;
    if (intervalSec <= 0) {
        intervalSec = 1;
    }

    const minFlag = 60;
    const hourFlag = 60 * 60;
    const dayFlag = 60 * 60 * 24;
    const monthFlag = 60 * 60 * 24 * 31;
    const yearFlag = 60 * 60 * 24 * 31 * 12;

    if (intervalSec < minFlag) {
        return Math.floor(intervalSec) + '초 전';
    } else if (intervalSec >= minFlag && intervalSec < hourFlag) {
        return Math.round(intervalSec / minFlag) + '분 전';
    } else if (intervalSec >= hourFlag && intervalSec < dayFlag) {
        return Math.round(intervalSec / hourFlag) + '시간 전';
    } else if (intervalSec >= dayFlag && intervalSec < monthFlag) {
        return Math.round(intervalSec / dayFlag) + '일 전';
    } else if (intervalSec >= monthFlag && intervalSec < yearFlag) {
        return Math.round(intervalSec / monthFlag) + '달 전';
    }
    return Math.round(intervalSec / yearFlag) + '년 전';
}

export default dateFilter