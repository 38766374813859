import { Box, makeStyles, Typography, Input } from '@material-ui/core'
import React, { forwardRef, useState } from 'react'
import { useMutation } from 'react-query'
import buzzEndpoints from '../../../../api/buzz'
import { api } from '../../../../api/common'
import potatoEndpoints from '../../../../api/potato'
import EditLayout from './EditLayout'

interface Props {
    name?: string
    userId: number
    close: () => void
    identification: string
    refetch: () => void
    username?: string
    pictureOrder?: number
    category?: string
}

const TextEdit: React.FC<Props> = forwardRef(({
    name, userId, close, identification, refetch, username, pictureOrder, category
}, ref) => {

    const classes = useStyles()

    const [blockCancelReason, setBlockCancelReason] = useState("");

    const onSuccess = () => {
        refetch()
    }

    const { mutate: initPassword } = useMutation(() => api.post(`${buzzEndpoints.initPassword(userId)}`), { onSuccess: onSuccess })
    const { mutate: userOut } = useMutation(() => api.post(`${buzzEndpoints.userOut(userId)}`), { onSuccess: onSuccess })
    const { mutate: userOutCancel } = useMutation(() => api.post(`${buzzEndpoints.userOutCancel(userId)}`), { onSuccess: onSuccess })
    const { mutate: userImmediateOut } = useMutation(() => api.post(`${buzzEndpoints.userImmediateOut(userId)}`), { onSuccess: onSuccess })
    const { mutate: callSelfAuthentication } = useMutation(() => api.post(`${potatoEndpoints.callSelfAuthentication(userId)}`), { onSuccess: onSuccess })
    const { mutate: joinFeedback } = useMutation(() => api.post(`${buzzEndpoints.joinFeedback()}`, {
        contact: 'by admin',
        context: '관리자 신청',
        identification: identification,
        userId: userId
    }), { onSuccess: onSuccess })
    const { mutate: blockCancel } = useMutation(() => api.post(`${buzzEndpoints.userBlockCancel(userId)}`, {reason: blockCancelReason}), { onSuccess: onSuccess })
    const { mutate: blockDevice } = useMutation(() => api.post(`${buzzEndpoints.deviceBlock()}`, {
        userId: userId,
        action: 'delete'
    }), { onSuccess: onSuccess })
    const { mutate: refund } = useMutation(() => api.post(`${buzzEndpoints.refund()}`, { userId: userId }), { onSuccess: onSuccess })

    const { mutate: deletePicture } = useMutation(
        () => api.post(`${buzzEndpoints.deletePicture()}`, {
            userId: userId,
            pictureOrder: pictureOrder!
        }),
        {
            onSuccess: onSuccess
        }
    )

    const { mutate: deletePhotoVerification } = useMutation(
        () => api.delete(`${potatoEndpoints.photoVerification(userId)}`, { data: { category: category } }),
        {
            onSuccess: onSuccess
        }
    )

    const handleSubmit = () => {
        close()
        switch(name){
            case '비밀번호 초기화':
                initPassword(); return;
            case '탈퇴 신청':
                userOut(); return;
            case '탈퇴 신청 취소':
                userOutCancel(); return;
            case '즉시 탈퇴':
                userImmediateOut(); return;
            case '관리자 사진 인증 요청':
                callSelfAuthentication(); return;
            case '디바이스 중복 문의 등록':
                joinFeedback(); return;
            case '이용 정지 해제':
                blockCancel(); return;
            case '악성 디바이스 ID 해제':
                blockDevice(); return
            case '전체 보상':
                refund(); return;
            case '사진 삭제':
                deletePicture(); return;
            case '사진 인증 취소':
                deletePhotoVerification(); return;
        }
    }

    return (
        <EditLayout title={name || ''} onSubmit={handleSubmit} close={close} color='#008c00'>
            <Box className={classes.contentBox}>
                <Typography color='textPrimary' variant='subtitle1'>
                    {name==='비밀번호 초기화' && '해당 계정의 비밀번호가 000000(숫자)로 설정됩니다.'}
                    {name==='탈퇴 신청' && '해당 계정 탈퇴가 신청됩니다.'}
                    {name==='탈퇴 신청 취소' && '해당 계정의 탈퇴 신청이 취소됩니다.'}
                    {name==='즉시 탈퇴' && '해당 계정이 즉시 탈퇴됩니다.(계정 복구 불가)'}
                    {name==='관리자 사진 인증 요청' && '사진 인증을 요청합니다.'}
                    {name==='디바이스 중복 문의 등록' && '디바이스 중복 문의를 등록합니다.'}
                    {name==='이용 정지 해제' && '이용 정지를 해제하시겠습니까? (해제해도 대화방은 다시 연결되지 않습니다.)'}
                    {name==='악성 디바이스 ID 해제' && '해당 악성 디바이스 ID를 해제하시겠습니까?'}
                    {name==='전체 보상' && (
                        <Box>
                            <Typography color='textPrimary' variant='subtitle1'>해당 유저에게 젬 및 무료 횟수를 사용한 모든 유저에게 보상하시겠습니까?</Typography>
                            <Typography color='textPrimary' variant='subtitle1'>(승인 즉시 보상이 진행되며 해당 유저 화면에 알림 노트가 등장합니다)</Typography>
                        </Box>
                    )}
                    {name==='호스트 등록' && (
                        <Box>
                            <Typography color='textPrimary' variant='subtitle1'>{username}님을</Typography>
                            <Typography color='textPrimary' variant='subtitle1'>호스트로 등록하시겠습니까?</Typography>
                        </Box>
                    )}
                    {name==='사진 삭제' && '해당 사진이 삭제됩니다.'}
                    {name==='사진 인증 취소' && '관리자/셀프 사진 인증 모두 취소 됩니다.'}
                </Typography>
            </Box>
            {name === "이용 정지 해제" && (
              <Box className={(classes.contentBox, classes.inputWrapper)}>
                <Input
                  className={classes.input}
                  placeholder="사유 입력"
                  value={blockCancelReason}
                  onChange={(e) => setBlockCancelReason(e.target.value)}
                  inputProps={{ maxLength: 50 }}
                />
                <div className={classes.textLengthIndicator}>
                    <span>{blockCancelReason.length}</span>
                    <span>/</span>
                    <span>50</span>
                </div>
              </Box>
            )}
        </EditLayout>
    )
})

export default TextEdit

const useStyles = makeStyles((theme) => ({
    contentBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 24,
        boxSizing: 'border-box'
    },
    inputWrapper: {
      padding: "0 24px 6.5px 24px",
    },
    input: {
      width: "100%",
      fontSize: "16px",
      lineHeight: "24px",
    },
    textLengthIndicator: {
        display: 'flex',
        justifyContent: 'flex-end',
        fontSize: '12px',
        lineHeight: '14.4px',
        color: 'rgba(0,0,0,.6)',
        gap: '0 1px',
        marginTop: '8px',
        marginBottom: '50px',
    }
}))