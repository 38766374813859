import ImageStateType from "../../models/api/enum/imageState"

const imageStateFilter = (state?: ImageStateType) => {
    switch(state){
        case 'SA01':
            return '인증 요청'
        case 'SA02': 
            return '인증 대기'
        case 'SA03': 
            return '요청 반려'
        case 'SA04': 
            return '인증 승인'
        case 'SA05': 
            return '인증 요청 해제'
        case 'SA06': 
            return '이용 정지'
        case 'SA07': 
            return '승인 취소'
        default:
            return 'x'
    }
}

export default imageStateFilter