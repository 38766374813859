import { makeStyles, Box, Typography } from '@material-ui/core'
import React from 'react'
import { Comment } from '../../../models/api/buzz/appeal'
import { replacePlaceholderImage } from '../../../utils/common'
import dateFilter from '../../../utils/filter/dateFilter'
import fullPathFilter from '../../../utils/filter/fullPathFilter'

interface Props extends Comment {
    onClickUser: (id: number) => void
    marginLeft?: number
    isChildren?: boolean
    selectedCommentId?: number
}

const AppealDetailCommentItem: React.FC<Props> = ({
    childrenProp, userId, onClickUser, user, body, createdAt, marginLeft = 0, isChildren = false, selectedCommentId, id, updatedAt, isDeleted, isBrowseHidden
}) => {

    const classes = useStyles()
    
    return (
        <Box className={isChildren ? '' : classes.commentContainer} style={selectedCommentId===id ? { border: `3px solid #f2f476` } : {}}>
            <Box className={classes.commentBox}>
                <img onClick={() => onClickUser(userId)} src={fullPathFilter(user?.pictures?.[0]?.pathSmall)} onError={replacePlaceholderImage} className={classes.commentUserImage} style={{ marginLeft: marginLeft || 0 }}/>
                <Box className={classes.commentInfoBox}>
                    <Typography variant='body2' color='textPrimary' style={{ fontWeight: 'bold' }}>{user?.nickname}</Typography>
                    <Typography variant='body2' color='textSecondary' className={classes.body}>{body}</Typography>
                    <Box style={{ display: 'flex' }}>
                        <Typography variant='body2' color='textPrimary'>{dateFilter(createdAt)} 생성{!(createdAt===updatedAt) && `, ${dateFilter(updatedAt)} 수정`}</Typography>
                        {isBrowseHidden && <Typography className={classes.hiddenText} variant='body2'>숨김</Typography>} 
                        {isDeleted && <Typography className={classes.deletedText} variant='body2'>삭제됨</Typography>}
                    </Box>
                </Box>
            </Box>
            {childrenProp && childrenProp.map(child => (
                <AppealDetailCommentItem {...child} onClickUser={onClickUser} marginLeft={marginLeft + 24} isChildren selectedCommentId={selectedCommentId}/>
            ))}
        </Box>
    )
}

export default AppealDetailCommentItem

const useStyles = makeStyles((theme) => ({
    commentContainer: {
        border: `1px solid ${theme.palette.divider}`,
    },
    commentBox: {
        display: 'flex',
        padding: 16,
        width: '100%',
        boxSizing: 'border-box',
    },
    commentUserImage: {
        width: 34,
        height: 34,
        margin: '8px 0',
        borderRadius: '50%',
        border: `4px solid green`,
        cursor: 'pointer'
    },
    commentInfoBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginLeft: 16,
        width: '100%'
    },
    body: {
        backgroundColor: theme.palette.action.selected,
        width: '100%'
    },
    modalContainer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        width: '60vw',
        maxWidth: 1200,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 16,
        backgroundColor: theme.palette.background.paper,
        maxHeight: '70vh'
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 24px',
        boxSizing: 'border-box',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    title: {
        fontWeight: 'bold'
    },
    contentBox: {
        display: 'flex',
        padding: 24,
        justifyContent: 'space-between',
    },
    contentItemBox: {
        padding: 12,
        boxSizing: 'border-box',
        width: '50%',
        maxHeight: `calc(70vh - 62px - 72px)`,
        overflowY: 'scroll',
    },
    userInfoBox: {
        marginBottom: 20
    },
    username: {
        fontWeight: 'bold'
    },
    userInfo: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 16,
        position: 'relative',
        marginBottom: 16
    },
    userImage: {
        borderRadius: '50%',
        border: `4px solid green`,
        width: 70,
        height: 70,
        position: 'absolute',
        bottom: 0,
        right: 0,
        cursor: 'pointer'
    },
    commentItemBox: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 10,
    },
    attachmentBox: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 20
    },
    attachmentImage: {
        width: '100%',
        objectFit: 'contain'
    },
    articleBox: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 10,
        marginBottom: 20
    },
    topic: {
        color: '#f44336',
        border: `1px solid #f44336`,
        borderRadius: 16,
        boxSizing: 'border-box',
        padding: '4px 12px',
        marginTop: 10,
        marginBottom: 10,
        width: 'fit-content'
    },
    hiddenText: {
        backgroundColor: '#af8876',
        color: '#ffffff',
        marginLeft: 5
    },
    deletedText: {
        backgroundColor: '#ef6643',
        color: '#ffffff',
        marginLeft: 5
    }
}))