import { Box, makeStyles, Modal, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import buzzEndpoints from '../../../api/buzz'
import { api } from '../../../api/common'
import useImageModal from '../../../hooks/useImageModal'
import usePagination from '../../../hooks/usePagination'
import { BuzzPaginationResponse } from '../../../models/api/buzz'
import { RecommendationResponse } from '../../../models/api/buzz/recommend'
import ImageModal from '../../common/Modal/ImageModal'
import Loading from '../Loading'
import RecommendItem from './RecommendItem'

interface Props {
    userId: number
    setUserId: (id: number) => void
}

const Recommend: React.FC<Props> = ({
    userId, setUserId
}) => {

    const classes = useStyles()

    const { page, setPage, pageLength, setPageLength } = usePagination({
        initalPage: 1
    })

    const { imagePath, imageModalOpened, handleImageModal } = useImageModal()

    const [ recommendations, setRecommendations ] = useState<RecommendationResponse[]>([])

    const { isLoading, isFetching, isRefetching } = useQuery<BuzzPaginationResponse<RecommendationResponse>>(
        ['userRecommendations', userId, page],
        async () => api.get(`${buzzEndpoints.recommendationDetail(userId, page)}`).then(res => res.data),
        {
            onSuccess: (data) => {
                setRecommendations(data.data)
                setPageLength(data.lastPage)
            }
        }
    )

    const handleChangeModalUser = (id: number) => {
        setUserId(id)
    }

    const isEmpty = useMemo(() => {
        if(!isLoading && !isFetching && !isRefetching && !recommendations.length){
            return true
        } else {
            return false
        }
    }, [isLoading, isFetching, isRefetching, recommendations])

    if(isLoading || isFetching || isRefetching){
        return <Loading/>
    }
    return (
        <Box className={classes.container}>
            {isEmpty ? (
                <Typography color='textPrimary' variant='h6'>데이터가 없습니다.</Typography>
            ) : (
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {recommendations.map(recommendation => (
                                <RecommendItem 
                                    key={recommendation.id} 
                                    {...recommendation}
                                    onClickImage={handleImageModal}
                                    onClickNickname={handleChangeModalUser}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {!isEmpty && ( 
                <Pagination
                    count={pageLength}
                    page={page}
                    onChange={(_, value) => setPage(value)}
                    className={classes.paginationBox}
                />
            )}
            <Modal open={imageModalOpened} onClose={() => handleImageModal()} className={classes.modalContainer}>
                <ImageModal close={handleImageModal} source={imagePath}/>
            </Modal>
        </Box>
    )
}

export default Recommend

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: 24
    },
    tableContainer: {
        height: '45vh',
        overflowY: 'scroll'
    },
    paginationBox: {
        padding: 24,
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    modalContainer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}))