import React, { useMemo } from 'react'
import { Box, CircularProgress, makeStyles, Snackbar, Typography } from '@material-ui/core'
import Sidebar from './Sidebar'
import { ChevronRight } from '@material-ui/icons'
import Loading from '../Loading'
import ErrorBoundary from '../ErrorBoundary'
import { Alert, ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { useRecoilValue } from 'recoil'
import { snackbarAtom } from '../../store/user'
import Staging from '../common/Environment/Staging'

interface Props {
    title?: string
    loading?: boolean
    level?: string
    filterItem?: {
        label: string
        value: string | number
        color?: string
    }[]
    filterSecondaryItem?: {
        label: string
        value: string | number
    }[]
    onFilterChange?: (e: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => void
    onFilterSecondaryChange?: (e: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => void
    filterValue?: (string | number) | (string | number)[]
    filterSecondaryValue?: string | number
    filterClass?: string
    filterSecondaryClass?: string
    isEmpty?: boolean
    subtitle?: string
    exclusive?: boolean
    description?: string
}

const Layout: React.FC<Props> = ({
    children, title, loading, level, filterItem, onFilterChange, filterValue, filterSecondaryItem, onFilterSecondaryChange, filterSecondaryValue, filterClass, filterSecondaryClass, isEmpty, subtitle, exclusive = true, description
}) => {

    const classes = useStyles()

    const snackbar = useRecoilValue(snackbarAtom)

    const titleBoxHeight = useMemo(() => {
        if(description){
            return 159;
        }
        if(subtitle){
            return 150;
        }
        return 120;
    }, [description, subtitle])

    return (
        <ErrorBoundary>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackbar.open}
                autoHideDuration={3000}
                key={'top' + 'right'}
                transitionDuration={{enter: 300, exit: 0}}
            >
                <Alert severity={snackbar.severity || 'info'} style={{ width: '100%' }}>{snackbar.message}</Alert>
            </Snackbar>
            {localStorage.getItem('env')==='staging' && (
                <Box className={classes.envBox}>
                    <Staging/>
                </Box>
            )}
            <Box className={classes.contentContainer} style={localStorage.getItem('env')==='staging' ? { marginTop: 40 } : {}}>
                <Box className={classes.titleBox} style={{ height: titleBoxHeight }}>
                    <Box>
                        <Typography variant='h5' color='textPrimary' className={classes.title}>{title}</Typography>
                        <Typography variant='subtitle1' color='textPrimary'>{subtitle}</Typography>
                    </Box>
                    <Box className={classes.subTitleBox}>
                        <Box className={classes.pathBox}>
                            <Typography variant='body1' color='textPrimary' className={classes.path}>{level}</Typography>
                            <ChevronRight color='action'/>
                            <Typography variant='body1' color='textPrimary' className={classes.path}>{title}</Typography>
                        </Box>
                        <Box className={classes.buttonGroup}>
                            {filterItem && (
                                <ToggleButtonGroup 
                                    onChange={onFilterChange} 
                                    value={filterValue} 
                                    className={classes.buttonGroupBox}
                                    exclusive={exclusive}
                                >
                                    {filterItem.map(item => (
                                        <ToggleButton 
                                            className={filterClass ? filterClass : classes.button}
                                            key={item.value} 
                                            value={item.value}
                                            style={(item.color && filterValue?.toString().includes(String(item.value))) ? { backgroundColor: item.color, color: '#ffffff' } : {}}
                                        >
                                            {item.label}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            )}
                            {filterSecondaryItem && (
                                <ToggleButtonGroup
                                    onChange={onFilterSecondaryChange}
                                    value={filterSecondaryValue}
                                    className={classes.buttonGroupBox}
                                    exclusive
                                >
                                    {filterSecondaryItem.map(item => (
                                        <ToggleButton
                                            className={filterSecondaryClass ? filterSecondaryClass : classes.button}
                                            key={item.value}
                                            value={item.value}
                                        >
                                            {item.label}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            )}
                        </Box>
                    </Box>
                    {
                        description && (
                            <Box className={classes.descriptionBox}>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    className={classes.description}
                                >
                                    {description}
                                </Typography>
                            </Box>
                        )
                    }
                </Box>
                {loading ? <Loading/> : isEmpty && (
                    <Box className={classes.emptyBox}>
                        <Typography variant='h6' color='textPrimary'>데이터가 없습니다.</Typography>
                    </Box>
                )}
                <Box className={classes.contentBox}>
                    {children}
                </Box>
            </Box>
        </ErrorBoundary>
    )
}

export default Layout

const useStyles = makeStyles((theme) => ({
    selected: {
        color: '#4caf50'
    },
    container: {
        width: '100%',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'row',
    },
    contentContainer: {
        padding: 24,
        width: `calc(100vw - 280px)`,
        boxSizing: 'border-box',
        marginLeft: 280
    },
    contentBox: {
        marginTop: 104,
        backgroundColor: theme.palette.background.default,
        overflowY: 'scroll'
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 10,
        position: 'fixed',
        top: 0,
        left: 280,
        padding: 24,
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.default,
        width: 'calc(100% - 280px)',
        zIndex: 12
    },
    title: {
        fontWeight: 'bold'
    },
    pathBox: {
        display: 'flex',
        alignItems: 'center'
    },
    path: {
        fontWeight: 'bold'
    },
    subTitleBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: 35
    },
    buttonGroup: {
        display: 'flex',
        alignItems: 'center',
        columnGap: 20
    },
    buttonGroupBox: {
        
    },
    button: {
        padding: '5px 10px',
    },
    emptyBox: {
        width: `calc(100vw - 328px)`,
        height: '90%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
    },
    empty: {
        zIndex: 10
    },
    envBox: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        width: '100%'
    },
    descriptionBox: {
        display: "block",
    },
    description: {
        fontSize: "14px",
        lineHeight: "24px",
        color: "rgba(0,0,0,.7)",
        marginTop: "-6px",
    },
}))