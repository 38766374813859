import { useEffect, useState } from "react"

interface Options<T> {
  validate: (value: T) => boolean
  onSubmit: (value: T) => void
  onError?: (error: Error) => void
}

function useValidValue<T>(defaultValue: T, options: Options<T>) {

  const [ isValid, setIsValid ] = useState<boolean>(false)
  const [ value, setValue ] = useState<T>(defaultValue)

  useEffect(() => {
    setIsValid(options.validate(value))
  }, [value, options])

  return {
    value,
    isValid,
    setValue,
    submitValue: () => {
      if (!isValid) {
        if (typeof options.onError === 'function') {
          options.onError(new Error("Invalid value"))
        }
      } else {
        options.onSubmit(value);
      }
    },
  }
}
export default useValidValue;