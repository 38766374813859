import { Box, FormControl, FormControlLabel, FormGroup, FormLabel, makeStyles, OutlinedInput, Radio, RadioGroup, Typography } from '@material-ui/core'
import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import buzzEndpoints from '../../../../api/buzz'
import { api } from '../../../../api/common'
import { Job, JobResponse } from '../../../../models/api/buzz/job'
import EducationType from '../../../../models/api/enum/education'
import educationFilter from '../../../../utils/filter/educationFilter'
import educationReverseFilter from '../../../../utils/filter/educationReverseFilter'
import EditLayout from './EditLayout'

interface Props {
    value?: string | number
    name?: string
    userId: number
    close: () => void
    refetch: () => void
    jobData: JobResponse
}

const educations: EducationType[] = ['UE01', 'UE02', 'UE03', 'UE04', 'UE05', 'UE06']

const SingleSelectEdit: React.FC<Props> = forwardRef(({
    value, name, userId, close, refetch, jobData
}, ref) => {

    const { mutate: updateProfile } = useMutation(
        (data: object) => api.post(`${buzzEndpoints.updateUserProfile(userId)}`, data).then(res => res.data),
        {
            onMutate: () => close(),
            onSuccess: () => refetch()
        }
    )

    const [ newValue, setNewValue ] = useState(value)
    const [ jobId, setJobId ] = useState('')
    const [ keyword, setKeyword ] = useState<string>('')
    const [ storedJobList, setStoredJobList ] = useState<Job[]>([])
    const [ filteredJobList, setFilteredJobList ] = useState<Job[]>([])
    const [ customJob, setCustomJob ] = useState<string>('')

    const handleKeyword = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value)
        setFilteredJobList(storedJobList.filter(job => job.name.includes(e.target.value)))
        setCustomJob('')
    }, [storedJobList])

    const handleChangeCustomJob = () => {
        setCustomJob(keyword)
    }

    const classes = useStyles()

    const handleUpdate = () => {
        if(name==='학력'){
            updateProfile({
                education: educationReverseFilter(newValue as string)
            })
        } else if(name==='직업'){
            updateProfile(Boolean(customJob) ? {
                jobCustomValue: customJob
            } : {
                jobId: Number(jobId)
            })
        }
    }

    useEffect(() => {
        if(jobData){
            jobData.jobCategory.forEach(job => {
                job?.jobs?.jobs?.forEach(job => {
                    setStoredJobList(list => [...list, job])
                })
            })
        }
    }, [jobData])

    return (
        <EditLayout title={`${name} 설정`} onSubmit={handleUpdate} close={close} color='#008c00' submitText='설정'>
            {name==='직업' && (
                <Box className={classes.searchInputBox}>
                    <OutlinedInput
                        placeholder='직업 검색'
                        fullWidth
                        className={classes.searchInput}
                        value={keyword}
                        onChange={handleKeyword}
                    />
                </Box>
            )}
            <Box className={name==='직업' ? classes.container : classes.educationContainer}>
                {name==='직업' ? (
                    <Box className={classes.formBox}>
                        {(!filteredJobList.length && keyword) && <Typography color='textSecondary' variant='body1'>찾는 직업이 없나요?</Typography>}
                        {!keyword ? jobData?.jobCategory.map(category => (
                            <FormGroup onChange={(e: any) => setJobId(e.target.name)} key={category.id}>
                                <Typography className={classes.categoryTitle} variant='h6' color='textPrimary'>{category.name}</Typography>
                                <RadioGroup value={newValue}>
                                    {category.jobs?.jobs?.map(job => (
                                        <FormControlLabel key={job.id} value={job.id} className={classes.label} label={job.name} name={String(job.id)} control={<Radio checked={jobId===String(job.id)}/>}/>
                                    ))}
                                </RadioGroup>
                            </FormGroup>
                        )) : (
                            <FormGroup onChange={(e: any) => setJobId(e.target.name)}>
                                {filteredJobList.length ? filteredJobList.map(job => (
                                    <FormControlLabel key={job.id} value={job.id} className={classes.label} label={job.name} name={String(job.id)} control={<Radio checked={jobId===String(job.id)}/>}/>
                                )) : (
                                    <FormControlLabel className={classes.label} value={keyword} name={keyword} label={keyword} control={<Radio onChange={handleChangeCustomJob} checked={customJob===keyword}/>}/>
                                )}
                            </FormGroup>
                        )}
                    </Box>
                ) : (
                    <Box className={classes.formBox}>
                        <FormControl>
                            <RadioGroup value={newValue} onChange={e => setNewValue(e.target.value)}>
                                {educations.map(education => (
                                    <FormControlLabel key={education} value={educationFilter(education)} className={classes.label} label={educationFilter(education)} control={<Radio/>}/>
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                )}
            </Box>
        </EditLayout>
    )
})

export default SingleSelectEdit

const useStyles = makeStyles((theme) => ({
    container: {
        height: '50vh',
        overflowY: 'scroll',
        padding: 24,
        boxSizing: 'border-box'
    },
    educationContainer: {
        overflowY: 'scroll',
        padding: 24,
        boxSizing: 'border-box'
    },
    formBox: {
        display: 'flex',
        flexDirection: 'column'
    },
    label: {
        color: theme.palette.text.primary
    },
    categoryTitle: {
        padding: '10px 0',
    },
    searchInputBox: {
        padding: 24,
        boxSizing: 'border-box',
        paddingBottom: 0  
    },
    searchInput: {
    }
}))