import { Switch, TableCell, TableRow, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { AdminResponse } from '../../../../models/api/buzz'
import { AdminEmployType } from '../../../../models/api/buzz/admin'
import adminLevelFilter from '../../../../utils/filter/adminLevelFilter'

interface Props extends AdminResponse {
    onClickActivate: (id: number, isActivate: boolean) => void
    onClickNickname: (id: number, identification: string, employType: AdminEmployType, adminLevelId: number, email: string, nickname: string) => void
}

const AdminListItem: React.FC<Props> = ({
    adminLevelId, id, identification, email, employType, createdAt, updatedAt, isDeactivate, onClickActivate, nickname, onClickNickname
}) => {

    const [ activate, setActivate ] = useState<boolean>(!isDeactivate)

    const handleActivate = (isActivate: boolean) => {
        setActivate(!isActivate)
        onClickActivate(id, !isActivate)
    }

    return (
        <TableRow>
            <TableCell>
                <Typography color='textPrimary'>{id}</Typography>
            </TableCell>
            <TableCell style={{ cursor: 'pointer' }} onClick={() => onClickNickname(id, identification, employType, adminLevelId, email, nickname)}>
                <Typography color='secondary'>{identification}</Typography>
            </TableCell>
            <TableCell>
                <Typography color='secondary'>{email}</Typography>
            </TableCell>
            <TableCell>
                <Typography color='textPrimary'>{adminLevelId}. {adminLevelFilter(adminLevelId)}</Typography>
            </TableCell>
            <TableCell>
                <Typography color='textPrimary'>{employType==='internal' ? '내부 직원' : '외부 직원'}</Typography>
            </TableCell>
            <TableCell>
                <Typography color='textPrimary'>{createdAt}</Typography>
            </TableCell>
            <TableCell>
                <Typography color='textPrimary'>{updatedAt}</Typography>
            </TableCell>
            <TableCell>
                <Switch color={activate ? 'secondary' : 'default'} onClick={() => handleActivate(activate)} checked={activate}/>
            </TableCell>
        </TableRow>
    )
}

export default AdminListItem