import React, { useEffect, lazy, Suspense } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Routes from './pages'
import PATHS from './constants/paths'
import Sidebar from './components/Layout/Sidebar'
import { styled } from '@mui/material/styles'
import SmsVerificationBlock from "./pages/User/SmsVerificationBlock";

const Me = lazy(() => import('./pages/Me'))
const Home = lazy(() => import('./pages/Home'))
const Login = lazy(() => import('./pages/Login'))

const JoinReview = lazy(() => import('./pages/Join/JoinReview'))
const JoinReviewHidden = lazy(() => import('./pages/Join/JoinReviewHidden'))
const JoinProgress = lazy(() => import('./pages/Join/JoinProgress'))
const JoinRating = lazy(() => import('./pages/Join/JoinRating'))
const JoinFeedback = lazy(() => import('./pages/Join/JoinFeedback'))

const UserList = lazy(() => import('./pages/User/UserList'))
const UserPictureWait = lazy(() => import('./pages/User/PictureWait'))
const UserPictureDone = lazy(() => import('./pages/User/PictureDone'))
const UserReportWait = lazy(() => import('./pages/User/ReportWait'))
const UserReportDone = lazy(() => import('./pages/User/ReportDone'))
const UserSpecialLike = lazy(() => import('./pages/User/SpecialLike'))
const UserLike = lazy(() => import('./pages/User/Like'))
const UserRecommend = lazy(() => import('./pages/User/Recommend'))
const UserBlock = lazy(() => import('./pages/User/Block'))
const UserSelfAuthRequest = lazy(() => import('./pages/User/SelfAuthRequest'))
const UserSelfAuthWait = lazy(() => import('./pages/User/SelfAuthWait'))
const UserSelfAuthDone = lazy(() => import('./pages/User/SelfAuthDone'))
const UserSelfAuthUserWait = lazy(() => import('./pages/User/SelfAuthUserWait'))
const UserSelfAuthUserDone = lazy(() => import('./pages/User/SelfAuthUserDone'))

const Router = () => {

    useEffect(() => {
        if(!localStorage.getItem('accessToken') && window.location.pathname!==PATHS.LOGIN){
            window.location.href = PATHS.LOGIN
        }
    }, [])

    return (
        <BrowserRouter>
                <Switch>
                    <Container>
                        <Sidebar/>
                        <Suspense fallback={<Loading>Loading...</Loading>}>
                        <Route exact path={PATHS.ME} component={Me}/>

                        <Route exact path={PATHS.HOME} component={Home}/>
                        <Route exact path={PATHS.LOGIN} component={Login}/>

                        {/*가입 관리*/}
                        <Route exact path={PATHS.JOIN + PATHS.REVIEW} component={JoinReview}/>
                        <Route exact path={PATHS.JOIN + PATHS.REVIEW + PATHS.HIDDEN} component={JoinReviewHidden}/>
                        <Route exact path={PATHS.JOIN + PATHS.PROGRESS} component={JoinProgress}/>
                        <Route exact path={PATHS.JOIN + PATHS.RATING} component={JoinRating}/>
                        <Route exact path={PATHS.JOIN + PATHS.FEEDBACK} component={JoinFeedback}/>

                        {/*회원 관리*/}
                        <Route exact path={PATHS.USER + PATHS.LIST} component={UserList}/>
                        <Route exact path={PATHS.USER + PATHS.PICTURE + PATHS.WAIT} component={UserPictureWait}/>
                        <Route exact path={PATHS.USER + PATHS.PICTURE + PATHS.DONE} component={UserPictureDone}/>
                        <Route exact path={PATHS.USER + PATHS.REPORT + PATHS.WAIT} component={UserReportWait}/>
                        <Route exact path={PATHS.USER + PATHS.REPORT + PATHS.DONE} component={UserReportDone}/>
                        <Route exact path={PATHS.USER + PATHS.SPECIAL_LIKE} component={UserSpecialLike}/>
                        <Route exact path={PATHS.USER + PATHS.LIKE} component={UserLike}/>
                        <Route exact path={PATHS.USER + PATHS.RECOMMEND} component={UserRecommend}/>
                        <Route exact path={PATHS.USER + PATHS.BLOCK} component={UserBlock}/>
                        <Route exact path={PATHS.USER + PATHS.SELF_AUTHENTICATION + PATHS.REQUEST} component={UserSelfAuthRequest}/>
                        <Route exact path={PATHS.USER + PATHS.SELF_AUTHENTICATION + PATHS.WAIT} component={UserSelfAuthWait}/>
                        <Route exact path={PATHS.USER + PATHS.SELF_AUTHENTICATION + PATHS.DONE} component={UserSelfAuthDone}/>
                        <Route exact path={PATHS.USER + PATHS.SELF_AUTHENTICATION + PATHS.USER + PATHS.WAIT} component={UserSelfAuthUserWait}/>
                        <Route exact path={PATHS.USER + PATHS.SELF_AUTHENTICATION + PATHS.USER + PATHS.DONE} component={UserSelfAuthUserDone}/>
                        <Route exact path={PATHS.USER + PATHS.SMS_VERIFICATION + PATHS.BLOCK} component={SmsVerificationBlock}/>

                        {/*컨텐츠 관리*/}
                        <Route exact path={PATHS.APPEAL + PATHS.LIST} component={Routes.Appeal.AppealList}/>
                        <Route exact path={PATHS.APPEAL + PATHS.REPORT + PATHS.WAIT} component={Routes.Appeal.AppealReportWait}/>
                        <Route exact path={PATHS.APPEAL + PATHS.REPORT + PATHS.DONE} component={Routes.Appeal.AppealReportDone}/>
                        <Route exact path={PATHS.APPEAL + PATHS.COMMENT + PATHS.LIST} component={Routes.Appeal.AppealCommentList}/>

                        {/*서비스 관리*/}
                        <Route exact path={PATHS.SERVICE + PATHS.ADMIN} component={Routes.Service.Admin}/>
                        <Route exact path={PATHS.SERVICE + PATHS.INDUCE} component={Routes.Service.Induce}/>
                        <Route exact path={PATHS.SERVICE + PATHS.FLAG} component={Routes.Service.Flag}/>
                        <Route exact path={PATHS.SERVICE + PATHS.EXCLUSION} component={Routes.Service.Exclusion}/>
                        <Route exact path={PATHS.SERVICE + PATHS.UTIL} component={Routes.Service.Util}/>
                        <Route exact path={PATHS.SERVICE + PATHS.APPLICATION} component={Routes.Service.Application}/>
                        <Route exact path={PATHS.SERVICE + PATHS.ADS} component={Routes.Service.Ads}/>
                        <Route exact path={PATHS.SERVICE + PATHS.RECEIPT} component={Routes.Service.Receipt}/>

                        {/* 이벤트 관리 */}
                        <Route exact path={PATHS.EVENT + PATHS.BANNER} component={Routes.Event.Banner}/>
                        <Route exact path={PATHS.EVENT + PATHS.SERVICE} component={Routes.Event.Service}/>
                        <Route exact path={PATHS.EVENT + PATHS.TARGET} component={Routes.Event.Target}/>
                        </Suspense>
                    </Container>
                </Switch>
        </BrowserRouter>
    )
}

export default Router

const Container = styled('div')(({ theme }) => ({
    width: '100%',
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'row',
}))

const Loading = styled('h2')(({ theme }) => ({
    marginLeft: 300,
    marginTop: 20,
    color: theme.palette.text.primary
}))
