import { Box, Button, FormControl, InputLabel, makeStyles, MenuItem, Select, Typography, FormControlLabel, Checkbox, useTheme, TextField as V4TextField } from '@material-ui/core'
import { AttachFile } from '@material-ui/icons'
import React, { forwardRef, useState, useEffect } from 'react'
import { EventResponse } from '../../../../models/api/potato/event'
import { DateTimePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { TextField } from '@mui/material'
import dateGmtFilter from '../../../../utils/filter/dateGmtFilter'
import fullPathFilter from '../../../../utils/filter/fullPathFilter'
import { ChromePicker } from 'react-color'
import { convertDataToSnake } from '../../../../utils/api'
import { gemV5, gemV5ProductsName } from '../../Service/ServiceEventListItem'

const regions = [
    { code: 'KR', name: '한국' },
    { code: 'SA', name: '사우디아라비아' },
    { code: 'KW', name: '쿠웨이트' },
    { code: 'US', name: '미국' },
    { code: 'TR', name: '터키' },
    { code: 'IL', name: '이스라엘' },
    { code: 'JP', name: '일본' },
    { code: 'DE', name: '독일' },
    { code: 'FR', name: '프랑스' },
    { code: 'GB', name: '영국' },
    { code: 'SE', name: '스웨덴' },
    { code: 'CA', name: '캐나다' },
    { code: 'AU', name: '호주' },
    { code: 'MY', name: '말레이시아' },
    { code: 'BR', name: '브라질(남미)' },
    { code: 'RU', name: '러시아' },
    //{ code: 'GLOBAL_H', name: '전체 고가 티어' },
    { code: 'ISLM_L', name: '이슬람(저가)' },
    //{ code: 'ISLM_M', name: '이슬람 기본 티어' },
    { code: 'ISLM_G', name: '이슬람(Global)' },
    //{ code: 'ISLM_H', name: '이슬람 고가 티어' }
]

const targetUserTypes = [
    { value: 'all', label: '상관 없음' },
    { value: 'purchased', label: '1회 이상' }
]

const initalEventPoints = [
    {
        version: 2,
        ios_bonus_1: 0,
        ios_bonus_2: 0,
        ios_bonus_3: 0,
        ios_bonus_4: 0,
        ios_bonus_5: 0,
        ios_bonus_6: 0,
        ios_bonus_7: 0,
        ios_bonus_8: 0,
        android_bonus_1: 0,
        android_bonus_2: 0,
        android_bonus_3: 0,
        android_bonus_4: 0,
        android_bonus_5: 0,
        android_bonus_6: 0,
        android_bonus_7: 0,
        android_bonus_8: 0,
    },
    {
        version: 4,
        ios_bonus_1: 0,
        ios_bonus_2: 0,
        ios_bonus_3: 0,
        ios_bonus_4: 0,
        ios_bonus_5: 0,
        ios_bonus_6: 0,
        ios_bonus_7: 0,
        ios_bonus_8: 0,
        android_bonus_1: 0,
        android_bonus_2: 0,
        android_bonus_3: 0,
        android_bonus_4: 0,
        android_bonus_5: 0,
        android_bonus_6: 0,
        android_bonus_7: 0,
        android_bonus_8: 0,
    },
    {
        version: 5,
        ios_bonus_1: 0,
        ios_bonus_2: 0,
        ios_bonus_3: 0,
        ios_bonus_4: 0,
        ios_bonus_5: 0,
        ios_bonus_6: 0,
        ios_bonus_7: 0,
        ios_bonus_8: 0,
        android_bonus_1: 0,
        android_bonus_2: 0,
        android_bonus_3: 0,
        android_bonus_4: 0,
        android_bonus_5: 0,
        android_bonus_6: 0,
        android_bonus_7: 0,
        android_bonus_8: 0,
    }
]

const gemV2 = [3, 10, 30, 80, 1, 2, 7, 200]
const gemV4 = [150, 500, 1500, 4000, 25, 65, 350, 10000]

const now = new Date()
const year = now.getFullYear()
const month = now.getMonth()
const day = now.getDate()

const defaultCurrentDate = new Date(year, month, day, 0, 0, 0)
const defaultNextDate = new Date(year, month, day + 1, 0, 0, 0)

interface Props {
    type: 'create' | 'update'
    onSubmitCreate: (data: FormData) => void
    onSubmitUpdate: (data: FormData) => void
    close: () => void
    eventData?: EventResponse | null
}

const ServiceModal: React.FC<Props> = forwardRef(({
    type, onSubmitCreate, onSubmitUpdate, close, eventData: prevEventData
}, ref) => {

    const classes = useStyles()

    const theme = useTheme()

    const [ eventData, setEventData ] = useState({
        action: 'all',
        eventName: prevEventData?.eventName || '',
        buttonColor: prevEventData?.buttonColor || '333333',
        region: prevEventData?.region || 'KR',
        targetUserType: prevEventData?.targetUserType || 'all',
        startDate: dateGmtFilter(prevEventData?.startDate, prevEventData?.region, false) || defaultCurrentDate,
        endDate: dateGmtFilter(prevEventData?.endDate, prevEventData?.region, false) || defaultNextDate,
        willSendStartNotification: prevEventData?.willSendStartNotification || 'Y',
        willSendEndNotification: prevEventData?.willSendEndNotification || 'Y',
        startNotificationDate: dateGmtFilter(prevEventData?.startNotificationDate, prevEventData?.region, false) || defaultCurrentDate,
        endNotificationDate: dateGmtFilter(prevEventData?.endNotificationDate, prevEventData?.region, false) || defaultNextDate,
        startNotificationComment: prevEventData?.eventDetails[0].startNotificationComment || '',
        endNotificationComment: prevEventData?.eventDetails[0].endNotificationComment || '',
        comment: prevEventData?.eventDetails?.[0].comment || '',
        image: prevEventData?.imagePath || null as any,
        imagePreview: ''
    })
    const [ eventPoints, setEventPoints ] = useState<any[]>(initalEventPoints)
    const [ showColorPicker, setShowColorPicker ] = useState<boolean>(false)

    const { action, eventName, buttonColor, region, targetUserType, startDate, endDate, willSendEndNotification, willSendStartNotification, startNotificationComment, endNotificationComment, startNotificationDate, endNotificationDate, comment, image, imagePreview } = eventData

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setEventData(eventData => ({
            ...eventData,
            [name]: value
        }))
    }
    const handlePointChange = (version: number, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target
        setEventPoints(eventPoints => {
            const versions = eventPoints.map((event) => event.version)
            if(version === 5 && versions.indexOf(5) <= 0){
                eventPoints.push(initalEventPoints[2]);
            }
            return eventPoints.map(event => event.version!==version ? event : {
                ...event,
                [name]: Number(value) || 0
            })
        })
    }
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.files?.[0]){
            let reader = new FileReader()
            const file = e.target.files[0]
            reader.readAsDataURL(file)
            reader.onload = () => {
                setEventData(eventData => ({...eventData, image: file, imagePreview: reader.result as string}))
            }
        }
    }

    const handleSubmit = () => {
        let formData = new FormData()
        formData.append('action', action)
        formData.append('event_name', eventName)
        formData.append('button_color', buttonColor)
        formData.append('region', region)
        formData.append('target_user_type', targetUserType)
        formData.append('start_date', dateGmtFilter(startDate, region, true))
        formData.append('end_date', dateGmtFilter(endDate, region, true))
        formData.append('will_send_start_notification', willSendStartNotification)
        formData.append('will_send_end_notification', willSendEndNotification)
        formData.append('start_notification_date', dateGmtFilter(startNotificationDate, region, true))
        formData.append('end_notification_date', dateGmtFilter(endNotificationDate, region, true))
        formData.append('start_notification_comment', startNotificationComment)
        formData.append('end_notification_comment', endNotificationComment)
        formData.append('comment', comment)
        formData.append('image', image)
        formData.append('event_points', JSON.stringify(eventPoints))
        
        if(type==='create'){
            onSubmitCreate(formData)
        } else {
            onSubmitUpdate(formData)
        }
    }

    useEffect(() => {
        if(type==='update'){
            setEventPoints(prevEventData?.eventPoints.map(eventPoint => convertDataToSnake(eventPoint)) || initalEventPoints)
        }
    }, [prevEventData?.eventPoints, type])
    
    return (
        <Box className={classes.container}>
            <Box className={classes.titleBox}>
                <Typography variant='h6' color='textPrimary' className={classes.title}>{type==='create' ? '특별 이벤트 생성' : `NO.${prevEventData?.id} 특별 이벤트 수정`}</Typography>
            </Box>
            <Box className={classes.contentBox}>
                <Box className={classes.itemBox}>
                    <Typography color='textPrimary' variant='subtitle1' className={classes.itemTitle}>이벤트 정보</Typography>
                    <Box display='flex' flexDirection='row' justifyContent='space-between'>
                        <Box className={classes.imageBox}>
                            <Box className={classes.imageEmpty}>
                                {(imagePreview || prevEventData?.imagePath) ? <img src={imagePreview || fullPathFilter(prevEventData?.imagePath)} className={classes.imagePreview}/> : '이미지를 등록해 주세요.'}
                            </Box>
                            <Button fullWidth className={classes.imageButton} startIcon={<AttachFile/>} component='label'>
                                이미지 선택
                                <input type='file' hidden onChange={handleImageChange}/>
                            </Button>
                        </Box>
                        <Box className={classes.inputBox}>
                            <V4TextField 
                                variant='standard' 
                                fullWidth 
                                label='이벤트명' 
                                name='eventName'
                                value={eventName}
                                onChange={handleChange}
                            />
                            <FormControl fullWidth>
                                <InputLabel>국가</InputLabel>
                                <Select value={region} onChange={e => setEventData(eventData => ({...eventData, region: e.target.value as string}))}>
                                    {regions.map(region => (
                                        <MenuItem key={region.code} value={region.code}>{region.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Box display='flex' flexDirection='row' justifyContent='space-between' gridColumnGap={20}>
                                    <DateTimePicker
                                        ampm={false}
                                        value={startDate}
                                        onChange={date => setEventData(eventData => ({...eventData, startDate: date || new Date()}))}
                                        renderInput={params => <TextField {...params} className={classes.textField} variant='standard' label='이벤트 시작일시' fullWidth/>}
                                        inputFormat='yyyy-MM-dd HH:mm:ss'
                                        mask={"____-__-__"}
                                    />
                                    <DateTimePicker
                                        ampm={false}
                                        value={endDate}
                                        onChange={date => setEventData(eventData => ({...eventData, endDate: date || new Date()}))}
                                        renderInput={params => <TextField {...params} className={classes.textField} variant='standard' label='이벤트 종료일시' fullWidth/>}
                                        inputFormat='yyyy-MM-dd HH:mm:ss'
                                        mask={"____-__-__"}
                                    />
                                </Box>
                                <Typography color='textSecondary' variant='body2'>* 선택한 국가의 UTC 기준으로 자동 계산되므로, 의도한 이벤트 시간을 정확하게 입력하세요.</Typography>
                            </LocalizationProvider>
                            <FormControl fullWidth>
                                <InputLabel>구매 횟수</InputLabel>
                                <Select value={targetUserType} onChange={e => setEventData(eventData => ({...eventData, targetUserType: e.target.value as string}))}>
                                    {targetUserTypes.map(type => (
                                        <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Box flex={1} position='relative'>
                                <Typography color='textSecondary' variant='body2'>젬 표시 색상</Typography>
                                <Box display='flex' flexDirection='row' width='100%' onClick={() => setShowColorPicker(true)}>
                                    <Box width={30} height={30} borderRadius={4} marginRight='10px' style={{ backgroundColor: `#${buttonColor}` }}/>
                                    <V4TextField value={`#${buttonColor}`} name='buttonColor' variant='standard'/>
                                </Box>
                                {showColorPicker && (
                                    <>
                                        <Box position='fixed' width='100vw' height='100vh' top={0} left={0} right={0} bottom={0} onClick={() => setShowColorPicker(false)}/>
                                        <Box position='absolute' zIndex={11}>
                                            <ChromePicker
                                                onChange={color => setEventData(eventData => ({...eventData, buttonColor: color.hex.replace('#', '')}))}
                                                color={`#${buttonColor}`}
                                            />
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.itemBox}>
                    <Typography color='textPrimary' variant='subtitle1' className={classes.itemTitle}>노티피케이션</Typography>
                    <Box display='flex' gridColumnGap={20} justifyContent='space-between'>
                        <FormControlLabel
                            label={<Typography variant='body1' color='textPrimary'>시작 노티 사용</Typography>}
                            control={<Checkbox color='primary' checked={willSendStartNotification==='Y'}/>}
                            className={classes.formControlLabel}
                            onChange={(_, checked) => setEventData(eventData => ({...eventData, willSendStartNotification: checked ? 'Y' : 'N'}))}
                        />
                        <FormControlLabel
                            label={<Typography variant='body1' color='textPrimary'>종료 노티 사용</Typography>}
                            color={theme.palette.text.primary}
                            control={<Checkbox color='primary' checked={willSendEndNotification==='Y'}/>}
                            className={classes.formControlLabel}
                            onChange={(_, checked) => setEventData(eventData => ({...eventData, willSendEndNotification: checked ? 'Y' : 'N'}))}
                        />
                    </Box>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' gridColumnGap={20}>
                            <DateTimePicker
                                ampm={false}
                                value={startNotificationDate}
                                onChange={date => setEventData(eventData => ({...eventData, startNotificationDate: date || new Date()}))}
                                renderInput={params => <TextField {...params} className={classes.textField} variant='standard' label='이벤트 시작 노티 시간' fullWidth/>}
                                disabled={willSendStartNotification==='N'}
                                mask={"____-__-__"}
                                inputFormat='yyyy-MM-dd HH:mm:ss'
                            />
                            <DateTimePicker
                                ampm={false}
                                value={endNotificationDate}
                                onChange={date => setEventData(eventData => ({...eventData, endNotificationDate: date || new Date()}))}
                                renderInput={params => <TextField {...params} className={classes.textField} variant='standard' label='이벤트 종료 노티 시간' fullWidth/>}
                                disabled={willSendEndNotification==='N'}
                                mask={"____-__-__"}
                                inputFormat='yyyy-MM-dd HH:mm:ss'
                            />
                        </Box>
                    </LocalizationProvider>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' gridColumnGap={20}>
                        <V4TextField
                            label='시작 메시지'
                            fullWidth
                            variant='standard'
                            disabled={willSendStartNotification==='N'}
                            name='startNotificationComment'
                            value={startNotificationComment}
                            onChange={handleChange}
                        />
                        <V4TextField
                            label='종료 메시지'
                            fullWidth
                            variant='standard'
                            disabled={willSendEndNotification==='N'}
                            name='endNotificationComment'
                            value={endNotificationComment}
                            onChange={handleChange}
                        />
                    </Box>
                    <V4TextField
                        fullWidth
                        label='노티피케이션 안내말'
                        variant='standard'
                        name='comment'
                        value={comment}
                        onChange={handleChange}
                    />
                </Box>
                <Box className={classes.itemBox}>
                    <Typography color='textPrimary' variant='subtitle1' className={classes.itemTitle}>상품 구성</Typography>

                    { eventPoints.length && (
                    <>
                        <Typography color='textSecondary' variant='subtitle1' className={classes.itemTitle}>젬 버전 2</Typography>
                        <Box display='flex' flexDirection='column' className={classes.tableContainer}>
                            <Box display='flex' className={classes.tableHead}>
                                {['상품 코드', 'iOS', '보너스', 'Android', '보너스'].map((text, index) => (
                                    <Typography className={classes.tableCell} style={{ fontWeight: 'bold' }} color='textPrimary' variant='body2' key={index}>{text}</Typography>
                                ))}
                            </Box>
                            {[...Array(8)].map((_, index) => (
                                <Box key={index} display='flex'>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body2'>glam_gem_{index + 1}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body2' style={{ fontWeight: 'bold' }}>{gemV2[index]}</Typography>
                                    <V4TextField 
                                        className={classes.gemInput} 
                                        InputProps={{ style: { height: 40 } }}
                                        value={eventPoints[0][`ios_bonus_${index + 1}`]}
                                        name={`ios_bonus_${index + 1}`}
                                        onChange={e => handlePointChange(2, e)}
                                        variant='outlined'
                                    />
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body2' style={{ fontWeight: 'bold' }}>{gemV2[index]}</Typography>
                                    <V4TextField 
                                        className={classes.gemInput} 
                                        InputProps={{ style: { height: 40 } }}
                                        value={eventPoints[0][`android_bonus_${index + 1}`]}
                                        name={`android_bonus_${index + 1}`}
                                        onChange={e => handlePointChange(2, e)}
                                        variant='outlined'
                                    />
                                </Box>
                            ))}
                        </Box>
                    </>
                    )}

                    { eventPoints?.[1] && (
                    <>
                        <Typography color='textSecondary' variant='subtitle1' className={classes.itemTitle}>젬 버전 4</Typography>
                        <Box display='flex' flexDirection='column' className={classes.tableContainer}>
                            <Box display='flex' className={classes.tableHead}>
                                {['상품 코드', 'iOS', '보너스', 'Android', '보너스'].map((text, index) => (
                                    <Typography className={classes.tableCell} style={{ fontWeight: 'bold' }} color='textPrimary' variant='body2' key={index}>{text}</Typography>
                                ))}
                            </Box>
                            {[...Array(8)].map((_, index) => (
                                <Box key={index} display='flex'>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body2'>glam_gem_{index + 1}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body2' style={{ fontWeight: 'bold' }}>{gemV4[index]}</Typography>
                                    <V4TextField 
                                        className={classes.gemInput} 
                                        InputProps={{ style: { height: 40 } }}
                                        value={eventPoints[1][`ios_bonus_${index + 1}`]}
                                        name={`ios_bonus_${index + 1}`}
                                        onChange={e => handlePointChange(4, e)}    
                                        variant='outlined'
                                    />
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body2' style={{ fontWeight: 'bold' }}>{gemV4[index]}</Typography>
                                    <V4TextField 
                                        className={classes.gemInput} 
                                        InputProps={{ style: { height: 40 } }}
                                        value={eventPoints[1][`android_bonus_${index + 1}`]}
                                        name={`android_bonus_${index + 1}`}
                                        onChange={e => handlePointChange(4, e)}   
                                        variant='outlined'     
                                    />
                                </Box>
                            ))}
                        </Box>
                    </>
                    )}

                    { eventPoints?.[2] && (
                    <>
                        <Typography color='textSecondary' variant='subtitle1' className={classes.itemTitle}>젬 버전 5</Typography>
                        <Box display='flex' flexDirection='column' className={classes.tableContainer}>
                            <Box display='flex' className={classes.tableHead}>
                                {['상품 코드', 'iOS', '보너스', 'Android', '보너스'].map((text, index) => (
                                    <Typography className={classes.tableCell} style={{ fontWeight: 'bold' }} color='textPrimary' variant='body2' key={index}>{text}</Typography>
                                ))}
                            </Box>
                            {gemV5ProductsName.map((product, index) => (
                                <Box key={index} display='flex'>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body2'>{product}</Typography>
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body2' style={{ fontWeight: 'bold' }}>{gemV5[index]}</Typography>
                                    <V4TextField 
                                        className={classes.gemInput} 
                                        InputProps={{ style: { height: 40 } }}
                                        value={eventPoints[2]?.[`ios_bonus_${index + 1}`]}
                                        name={`ios_bonus_${index + 1}`}
                                        onChange={e => handlePointChange(5, e)}    
                                        variant='outlined'
                                    />
                                    <Typography className={classes.tableCell} color='textPrimary' variant='body2' style={{ fontWeight: 'bold' }}>{gemV5[index]}</Typography>
                                    <V4TextField 
                                        className={classes.gemInput} 
                                        InputProps={{ style: { height: 40 } }}
                                        value={eventPoints[2]?.[`android_bonus_${index + 1}`]}
                                        name={`android_bonus_${index + 1}`}
                                        onChange={e => handlePointChange(5, e)}   
                                        variant='outlined'     
                                    />
                                </Box>
                            ))}
                        </Box>
                    </>
                    )}
                </Box>
            </Box>
            <Box className={classes.buttonBox}>
                <Button variant='contained' onClick={close}>취소</Button>
                <Button className={classes.button} onClick={handleSubmit}>{type==='create' ? '생성' : '수정'}</Button>
            </Box>
        </Box>
    )
})

export default ServiceModal

const useStyles = makeStyles((theme) => ({
    container: {
        width: '60vw',
        maxWidth: 800,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 16,
        backgroundColor: theme.palette.background.paper,
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 24px',
        boxSizing: 'border-box',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    title: {
        fontWeight: 'bold'
    },
    contentBox: {
        padding: 24,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '70vh',
        overflowY: 'scroll'
    },
    buttonBox: {
        display: 'flex',
        padding: 24,
        justifyContent: 'flex-end',
        boxSizing: 'border-box',
        columnGap: 10
    },
    itemBox: {
        marginBottom: 24,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 12
    },
    itemTitle: {
        fontWeight: 'bold'
    },
    button: {
        color: '#ffffff',
        backgroundColor: '#1976d2',
        "&:hover": {
            backgroundColor: '#1976d2'
        }
    },
    imageBox: {
        width: 200
    },
    imageButton: {
        backgroundColor: theme.palette.action.selected
    },
    imageEmpty: {
        boxSizing: 'border-box',
        border: `1px solid ${theme.palette.divider}`,
        width: 200,
        height: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.text.primary
    },
    inputBox: {
        width: `calc(100% - 220px)`,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 12
    },
    formControlLabel: {
        width: '100%'
    },
    tableContainer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        borderLeft: `1px solid ${theme.palette.divider}`,
    },
    tableHead: {
        backgroundColor: theme.palette.action.selected
    },
    tableCell: {
        borderRight: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        width: '20%',
        boxSizing: 'border-box',
        height: 47,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    gemInput: {
        width: '20%',
        padding: '2px 15px!important',
        borderRight: `1px solid ${theme.palette.divider}!important`,
        borderBottom: `1px solid ${theme.palette.divider}!important`,
        boxSizing: 'border-box'
    },
    imagePreview: {
        objectFit: 'contain',
        width: 200,
        height: 200
    },
    textField: {
    }
}))