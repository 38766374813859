import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'

const Dev = () => {

    const classes = useStyles()

    return (
        <Box className={classes.container}>
            <Typography className={classes.env} variant='h6'>Dev</Typography>
        </Box>
    )
}

export default Dev

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: '#4caf50',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
    },
    env: {
        color: '#ffffff',
        fontWeight: 'bold'
    }
}))