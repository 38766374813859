import StoreHistoryType from '../../models/api/enum/storeHistory';

const NEW_ACCOUNT_PRODUCTS = ['AP021', 'AP022', 'AP023']

const storeHistoryFilter = (storeHistory: string, increase: number) => {
    if (storeHistory === 'FP000') {
        return '가입';
    } else if (storeHistory === 'FP001') {
        return '출석';
    } else if (storeHistory === 'FP002') {
        return '프로필 평가';
    } else if (storeHistory === 'FP003') {
        return '추천';
    } else if (storeHistory === 'FP004') {
        return '호감 수락';
    } else if (storeHistory === 'FP005') {
        return '추천 보상';
    } else if (storeHistory === 'FP006') {
        return '리뷰 남기기';
    } else if (storeHistory === 'FP007') {
        return '전화번호 인증';
    } else if (storeHistory === 'FP008') {
        return '복귀 축하';
    } else if (storeHistory === 'FP009') {
        return '페이스북 좋아요';
    } else if (storeHistory === 'FP010') {
        return '실시간 평가 리워드';
    } else if (storeHistory === 'FP011') {
        return 'Youtube 구독 리워드';
    } else if (storeHistory === 'FP012') {
        return '공유하기 리워드';
    } else if (storeHistory === 'FP013') {
        return '사진 인증';
    } else if (storeHistory === 'SP000') {
        return '젬 구매';
    } else if (storeHistory === 'SP001') {
        return '젬 구매';
    } else if (storeHistory === 'SP002') {
        return '젬 구매';
    } else if (storeHistory === 'SP003') {
        return '젬 구매';
    } else if (storeHistory === 'SP004') {
        return '젬 구매';
    } else if (storeHistory === 'SP005') {
        return '젬 구매';
    } else if (storeHistory === 'SP006') {
        return '젬 구매';
    } else if (storeHistory === 'SP007') {
        return '젬 구매';
    } else if (storeHistory === 'SP010') {
        return '젬 구매';
    } else if (storeHistory === 'SP011') {
        return '젬 구매';
    } else if (storeHistory === 'SP012') {
        return '젬 구매';
    } else if (storeHistory === 'SP013') {
        return '젬 구매';
    } else if (storeHistory === 'SP014') {
        return '젬 구매';
    } else if (storeHistory === 'SP015') {
        return '30젬 구매';
    } else if (storeHistory === 'SP016') {
        return '70젬 구매';
    } else if (storeHistory === 'SP017') {
        return '150젬 구매';
    } else if (storeHistory === 'SP018') {
        return '400젬 구매';
    } else if (storeHistory === 'SP019') {
        return '850젬 구매';
    } else if (storeHistory === 'SP020') {
        return '35젬 구매';
    } else if (storeHistory === 'SP021') {
        return '80젬 구매';
    } else if (storeHistory === 'SP022') {
        return '150젬 구매';
    } else if (storeHistory === 'SP023') {
        return '400젬 구매';
    } else if (storeHistory === 'SP024') {
        return '850젬 구매';
    } else if (storeHistory === 'SP015E') {
        return '30젬 구매(이벤트)';
    } else if (storeHistory === 'SP016E') {
        return '70젬 구매(이벤트)';
    } else if (storeHistory === 'SP017E') {
        return '150젬 구매(이벤트)';
    } else if (storeHistory === 'SP018E') {
        return '400젬 구매(이벤트)';
    } else if (storeHistory === 'SP019E') {
        return '850젬 구매(이벤트)';
    } else if (storeHistory === 'SP020E') {
        return '35젬 구매(이벤트)';
    } else if (storeHistory === 'SP021E') {
        return '80젬 구매(이벤트)';
    } else if (storeHistory === 'SP022E') {
        return '150젬 구매(이벤트)';
    } else if (storeHistory === 'SP023E') {
        return '400젬 구매(이벤트)';
    } else if (storeHistory === 'SP024E') {
        return '850젬 구매(이벤트)';
    } else if (storeHistory === 'SP025') {
        return '5젬 구매';
    } else if (storeHistory === 'SP026') {
        return '15젬 구매';
    } else if (storeHistory === 'SP027') {
        return '40젬 구매';
    } else if (storeHistory === 'SP028') {
        return '100젬 구매';
    } else if (storeHistory === 'SP029') {
        return '5젬 구매';
    } else if (storeHistory === 'SP030') {
        return '15젬 구매';
    } else if (storeHistory === 'SP031') {
        return '40젬 구매';
    } else if (storeHistory === 'SP032') {
        return '100젬 구매';
    } else if (storeHistory === 'SP025E') {
        return '5젬 구매(이벤트)';
    } else if (storeHistory === 'SP026E') {
        return '15젬 구매(이벤트)';
    } else if (storeHistory === 'SP027E') {
        return '40젬 구매(이벤트)';
    } else if (storeHistory === 'SP028E') {
        return '100젬 구매(이벤트)';
    } else if (storeHistory === 'SP029E') {
        return '5젬 구매(이벤트)';
    } else if (storeHistory === 'SP030E') {
        return '15젬 구매(이벤트)';
    } else if (storeHistory === 'SP031E') {
        return '40젬 구매(이벤트)';
    } else if (storeHistory === 'SP032E') {
        return '100젬 구매(이벤트)';
    } else if (storeHistory === 'f_like_today') {
        return '좋아요 전송';
    } else if (storeHistory === 'f_like_old') {
        return '좋아요 전송';
    } else if (storeHistory === 'f_chat') {
        return '대화 하기';
    } else if (storeHistory === 'f_chatR') {
        return '대화 보상';
    } else if (storeHistory === 'f_unlock_high') {
        return '프로필 보기(리스트)';
    } else if (storeHistory === 'f_retry') {
        return '매력 평가 받기';
    } else if (storeHistory === 'f_more_card') {
        return '추가 소개';
    } else if (storeHistory === 'f_special_like') {
        return '크러쉬 전송';
    } else if (storeHistory === 'm_like_today') {
        return '좋아요 전송';
    } else if (storeHistory === 'm_like_old') {
        return '좋아요 전송';
    } else if (storeHistory === 'm_chat') {
        return '대화 하기';
    } else if (storeHistory === 'm_chatR') {
        return '대화 보상';
    } else if (storeHistory === 'm_unlock_high') {
        return '프로필 보기(리스트)';
    } else if (storeHistory === 'm_retry') {
        return '매력 평가 받기';
    } else if (storeHistory === 'm_more_card') {
        return '추가 소개';
    } else if (storeHistory === 'm_special_like') {
        return '크러쉬 전송';
    } else if (storeHistory === 'AP000') {
        return '젬 구매(무통장 결제)';
    } else if (storeHistory === 'AP001') {
        return '젬 구매(무통장 결제)';
    } else if (storeHistory === 'AP002') {
        return '젬 구매(무통장 결제)';
    } else if (storeHistory === 'AP003') {
        return '젬 구매(무통장 결제)';
    } else if (storeHistory === 'AP004') {
        return '젬 구매(무통장 결제)';
    } else if (storeHistory === 'AP005') {
        return '젬 구매(무통장 결제)';
    } else if (storeHistory === 'AP006') {
        return '젬 구매(무통장 결제)';
    } else if (storeHistory === 'AP007') {
        return '젬 구매(무통장 결제)';
    } else if (storeHistory === 'AP008') {
        return '젬 구매(무통장 결제)';
    } else if (storeHistory === 'AP009') {
        return '젬 구매(무통장 결제)';
    } else if (storeHistory === 'AP010') {
        return '젬 구매(무통장 결제)';
    } else if (storeHistory === 'AP011') {
        return '젬 구매(무통장 결제)';
    } else if (storeHistory === 'AP012') {
        return '젬 구매(무통장 결제)';
    } else if (storeHistory === 'AP013') {
        return '젬 구매(무통장 결제)';
    } else if (storeHistory === 'AP014') {
        return '젬 구매(무통장 결제)';
    } else if (storeHistory === 'AP015') {
        return '젬 구매(무통장 결제)';
    } else if (storeHistory === 'AP016') {
        return '젬 구매(무통장 결제)';
    } else if (storeHistory === 'AP017') {
        return '젬 구매(무통장 결제)';
    } else if (storeHistory === 'AP018') {
        return '젬 구매(무통장 결제)';
    } else if (storeHistory === 'AP019') {
        return '젬 구매(무통장 결제)';
    } else if (storeHistory === 'AP020') {
        return '젬 구매(무통장 결제)';
    } else if (NEW_ACCOUNT_PRODUCTS.includes(storeHistory)) {
        return '젬 구매(무통장 결제)';
    } else if (storeHistory === 'AP000R') {
        return '젬 구매 취소(무통장 결제)';
    } else if (storeHistory === 'AP001R') {
        return '젬 구매 취소(무통장 결제)';
    } else if (storeHistory === 'AP002R') {
        return '젬 구매 취소(무통장 결제)';
    } else if (storeHistory === 'AP003R') {
        return '젬 구매 취소(무통장 결제)';
    } else if (storeHistory === 'AP004R') {
        return '젬 구매 취소(무통장 결제)';
    } else if (storeHistory === 'AP005R') {
        return '젬 구매 취소(무통장 결제)';
    } else if (storeHistory === 'AP006R') {
        return '젬 구매 취소(무통장 결제)';
    } else if (storeHistory === 'AP007R') {
        return '젬 구매 취소(무통장 결제)';
    } else if (storeHistory === 'AP008R') {
        return '젬 구매 취소(무통장 결제)';
    } else if (storeHistory === 'AP009R') {
        return '젬 구매 취소(무통장 결제)';
    } else if (storeHistory === 'AP010R') {
        return '젬 구매 취소(무통장 결제)';
    } else if (storeHistory === 'AP011R') {
        return '젬 구매 취소(무통장 결제)';
    } else if (storeHistory === 'AP012R') {
        return '젬 구매 취소(무통장 결제)';
    } else if (storeHistory === 'AP013R') {
        return '젬 구매 취소(무통장 결제)';
    } else if (storeHistory === 'AP014R') {
        return '젬 구매 취소(무통장 결제)';
    } else if (storeHistory === 'AP015R') {
        return '젬 구매 취소(무통장 결제)';
    } else if (storeHistory === 'female_diamond') {
        return '다이아몬드 상위 티어의 여성';
    } else if (storeHistory === 'female_volume') {
        return '볼륨감 있는 그녀';
    } else if (storeHistory === 'female_slim') {
        return '슬림한 체형의 그녀';
    } else if (storeHistory === 'female_improvisation') {
        return '즉흥적인 만남을 좋아하는 그녀';
    } else if (storeHistory === 'female_drinking') {
        return '술을 즐길 줄 아는 그녀';
    } else if (storeHistory === 'female_cat') {
        return '고양이상의 그녀';
    } else if (storeHistory === 'female_dog') {
        return '강아지상의 그녀';
    } else if (storeHistory === 'female_high') {
        return '166cm 이상 키 큰 여성';
    } else if (storeHistory === 'female_low') {
        return '160cm 이하 아담한 여성';
    } else if (storeHistory === 'female_lovely') {
        return '애교 넘치는 그녀';
    } else if (storeHistory === 'female_b_type') {
        return '털털한 B형 여자';
    } else if (storeHistory === 'male_diamond') {
        return '다이아몬드 상위 티어의 남성';
    } else if (storeHistory === 'male_high') {
        return '180cm 이상 훤칠한 그대';
    } else if (storeHistory === 'male_living_alone') {
        return '자취하는 남자';
    } else if (storeHistory === 'male_petLover') {
        return '애완동물을 좋아하는 남자';
    } else if (storeHistory === 'male_ass') {
        return '엉덩이가 화난 그대';
    } else if (storeHistory === 'male_thick_eyebrows') {
        return '눈썹이 짙은 남성';
    } else if (storeHistory === 'male_b_type') {
        return '털털한 B형 남자';
    } else if (storeHistory === 'male_a_type') {
        return '다정한 A형 남자';
    } else if (storeHistory === 'male_cooking') {
        return '요리 잘하는 남자';
    } else if (storeHistory === 'male_fashion') {
        return '패션 센스 넘치는 그대';
    } else if (storeHistory === 'male_ripped') {
        return '몸 좋은 근육질의 남성';
    } else if (storeHistory === 'female_religion_christian') {
        return '종교가 기독교인';
    } else if (storeHistory === 'female_religion_catholic') {
        return '종교가 천주교인';
    } else if (storeHistory === 'female_religion_buddhism') {
        return '종교가 불교인';
    } else if (storeHistory === 'female_job_public') {
        return '공무원으로 일하는';
    } else if (storeHistory === 'female_job_design') {
        return '디자이너로 일하는';
    } else if (storeHistory === 'female_job_ceo') {
        return '사업가 ・ CEO로 일하는';
    } else if (storeHistory === 'female_cooking') {
        return '요리 솜씨가 좋은';
    } else if (storeHistory === 'female_fashion') {
        return '패션 센스 있는';
    } else if (storeHistory === 'female_petLover') {
        return '반려 동물을 좋아하는';
    } else if (storeHistory === 'female_gaming') {
        return '게임 좋아하는';
    } else if (storeHistory === 'female_sporting') {
        return '운동 좋아하는';
    } else if (storeHistory === 'female_sports_watching') {
        return '스포츠 관람을 즐기는';
    } else if (storeHistory === 'female_living_alone') {
        return '자취하는';
    } else if (storeHistory === 'female_divorced') {
        return '돌싱';
    } else if (storeHistory === 'female_living_pet') {
        return '반려 동물과 함께 사는';
    } else if (storeHistory === 'female_ideal_often_communication') {
        return '자주 연락하는걸 좋아하는';
    } else if (storeHistory === 'male_religion_christian') {
        return '종교가 기독교인';
    } else if (storeHistory === 'male_religion_catholic') {
        return '종교가 천주교인';
    } else if (storeHistory === 'male_religion_buddhism') {
        return '종교가 불교인';
    } else if (storeHistory === 'male_job_medical') {
        return '의사 ・ 약사로 일하는';
    } else if (storeHistory === 'male_job_legal') {
        return '법조계에서 일하는';
    } else if (storeHistory === 'male_job_it') {
        return 'IT업계에서 일하는';
    } else if (storeHistory === 'male_job_public') {
        return '공무원으로 일하는';
    } else if (storeHistory === 'male_job_design') {
        return '디자이너로 일하는';
    } else if (storeHistory === 'male_job_finance') {
        return '금융 업계에서 일하는';
    } else if (storeHistory === 'male_job_ceo') {
        return '사업가 ・ CEO로 일하는';
    } else if (storeHistory === 'male_job_food') {
        return '요리사 ・ 파티시에로 일하는';
    } else if (storeHistory === 'male_prestige_school') {
        return '명문대 출신';
    } else if (storeHistory === 'male_rich') {
        return '경제력 있는';
    } else if (storeHistory === 'male_lovely') {
        return '애교 넘치는';
    } else if (storeHistory === 'male_drinking') {
        return '술을 즐기는';
    } else if (storeHistory === 'male_gaming') {
        return '게임 좋아하는';
    } else if (storeHistory === 'male_sporting') {
        return '운동 좋아하는';
    } else if (storeHistory === 'male_improvisation') {
        return '즉흥적인 만남 선호';
    } else if (storeHistory === 'male_sports_watching') {
        return '스포츠 관람을 즐기는';
    } else if (storeHistory === 'male_divorced') {
        return '돌싱';
    } else if (storeHistory === 'male_living_pet') {
        return '반려 동물과 함께 사는';
    } else if (storeHistory === 'male_ideal_often_communication') {
        return '자주 연락하는걸 좋아하는';
    } else if (storeHistory === 'f_unlock') {
        return '프로필 보기(커뮤니티)';
    } else if (storeHistory === 'm_unlock') {
        return '프로필 보기(커뮤니티)';
    } else if (storeHistory === 'm_change_nickname') {
        return '닉네임 변경';
    } else if (storeHistory === 'f_change_nickname') {
        return '닉네임 변경';
    } else if (storeHistory === 'RENEWAL') {
        return '젬 리뉴얼';
    } else if (storeHistory === 'CT001') {
        return '허위 유저 보상';
    } else if (storeHistory === 'CT002') {
        return '구매 보너스';
    } else if (storeHistory === 'SP033') {
        return '150젬 구매(Android)';
    } else if (storeHistory === 'SP034') {
        return '500젬 구매(Android)';
    } else if (storeHistory === 'SP035') {
        return '1500젬 구매(Android)';
    } else if (storeHistory === 'SP036') {
        return '4000젬 구매(Android)';
    } else if (storeHistory === 'SP037') {
        return '150젬 구매(IOS)';
    } else if (storeHistory === 'SP038') {
        return '500젬 구매(IOS)';
    } else if (storeHistory === 'SP039') {
        return '1500젬 구매(IOS)';
    } else if (storeHistory === 'SP040') {
        return '4000젬 구매(IOS)';
    } else if (storeHistory === 'SP041') {
        return '25젬 구매(Android)';
    } else if (storeHistory === 'SP042') {
        return '65젬 구매(Android)';
    } else if (storeHistory === 'SP043') {
        return '350젬 구매(Android)';
    } else if (storeHistory === 'SP044') {
        return '10000젬 구매(Android)';
    } else if (storeHistory === 'SP045') {
        return '25젬 구매(IOS)';
    } else if (storeHistory === 'SP046') {
        return '65젬 구매(IOS)';
    } else if (storeHistory === 'SP047') {
        return '350젬 구매(IOS)';
    } else if (storeHistory === 'SP048') {
        return '10000젬 구매(IOS)';
    } else if (storeHistory === 'SP049') {
        return '150젬 구매(Android)';
    } else if (storeHistory === 'SP050') {
        return '400젬 구매(Android)';
    } else if (storeHistory === 'SP051') {
        return '1000젬 구매(Android)';
    } else if (storeHistory === 'SP052') {
        return '2000젬 구매(Android)';
    } else if (storeHistory === 'SP053') {
        return '4000젬 구매(Android)';
    } else if (storeHistory === 'SP054') {
        return '150젬 구매(IOS)';
    } else if (storeHistory === 'SP055') {
        return '400젬 구매(IOS)';
    } else if (storeHistory === 'SP056') {
        return '1000젬 구매(IOS)';
    } else if (storeHistory === 'SP057') {
        return '2000젬 구매(IOS)';
    } else if (storeHistory === 'SP058') {
        return '4000젬 구매(IOS)';
    } else if (storeHistory === 'SP059') {
        return '150젬 구매(WEB)';
    } else if (storeHistory === 'SP060') {
        return '400젬 구매(WEB)';
    } else if (storeHistory === 'SP061') {
        return '1000젬 구매(WEB)';
    } else if (storeHistory === 'SP062') {
        return '2000젬 구매(WEB)';
    } else if (storeHistory === 'SP063') {
        return '4000젬 구매(WEB)';
    } else if (storeHistory === 'SP070') {
        return '8000젬 구매(WEB)';
    } else if (storeHistory === 'SP071') {
        return '1600젬 구매(WEB)';
    } else if (storeHistory === 'SP064') {
        return '8000젬 구매(IOS)';
    } else if (storeHistory === 'SP065') {
        return '1600젬 구매(IOS)';
    } else if (storeHistory === 'SP066') {
        return '550젬 구매(IOS)';
    } else if (storeHistory === 'SP067') {
        return '8000젬 구매(Android)';
    } else if (storeHistory === 'SP068') {
        return '1600젬 구매(Android)';
    } else if (storeHistory === 'SP069') {
        return '550젬 구매(Android)';
    } else if (storeHistory === 'SP033E') {
        return '3젬 구매(이벤트)';
    } else if (storeHistory === 'SP034E') {
        return '10젬 구매(이벤트)';
    } else if (storeHistory === 'SP035E') {
        return '30젬 구매(이벤트)';
    } else if (storeHistory === 'SP036E') {
        return '80젬 구매(이벤트)';
    } else if (storeHistory === 'SP037E') {
        return '3젬 구매(이벤트)';
    } else if (storeHistory === 'SP038E') {
        return '10젬 구매(이벤트)';
    } else if (storeHistory === 'SP039E') {
        return '30젬 구매(이벤트)';
    } else if (storeHistory === 'SP040E') {
        return '80젬 구매(이벤트)';
    } else if (storeHistory === 'SP041E') {
        return '25젬 구매(이벤트, Android)';
    } else if (storeHistory === 'SP042E') {
        return '65젬 구매(이벤트, Android)';
    } else if (storeHistory === 'SP043E') {
        return '350젬 구매(이벤트, Android)';
    } else if (storeHistory === 'SP044E') {
        return '10000젬 구매(이벤트, Android)';
    } else if (storeHistory === 'SP045E') {
        return '25젬 구매(이벤트, IOS)';
    } else if (storeHistory === 'SP046E') {
        return '65젬 구매(이벤트, IOS)';
    } else if (storeHistory === 'SP047E') {
        return '350젬 구매(이벤트, IOS)';
    } else if (storeHistory === 'SP048E') {
        return '10000젬 구매(이벤트, IOS)';
    } else if (storeHistory === 'SP059E') {
        return '150젬 구매(이벤트, WEB)';
    } else if (storeHistory === 'SP060E') {
        return '400젬 구매(이벤트, WEB)';
    } else if (storeHistory === 'SP061E') {
        return '1000젬 구매(이벤트, WEB)';
    } else if (storeHistory === 'SP062E') {
        return '2000젬 구매(이벤트, WEB)';
    } else if (storeHistory === 'SP063E') {
        return '4000젬 구매(이벤트, WEB)';
        // SPW 웰컴
    } else if (storeHistory === 'SPW01') {
        return '5젬 구매(웰컴 이벤트)';
    } else if (storeHistory === 'SPW02') {
        return '15젬 구매(웰컴 이벤트)';
    } else if (storeHistory === 'SPW03') {
        return '40젬 구매(웰컴 이벤트)';
    } else if (storeHistory === 'SPW04') {
        return '100젬 구매(웰컴 이벤트)';
    } else if (storeHistory === 'SPW05') {
        return '3젬 구매(웰컴 이벤트)';
    } else if (storeHistory === 'SPW06') {
        return '10젬 구매(웰컴 이벤트)';
    } else if (storeHistory === 'SPW07') {
        return '30젬 구매(웰컴 이벤트)';
    } else if (storeHistory === 'SPW08') {
        return '80젬 구매(웰컴 이벤트)';
    } else if (storeHistory === 'SPW09') {
        return '150젬 구매(첫 결제 이벤트)';
    } else if (storeHistory === 'SPW10') {
        return '1000젬 구매(첫 결제 이벤트)';
    } else if (storeHistory === 'SPP01') {
        return '5젬 구매(첫 결제 이벤트)';
    } else if (storeHistory === 'SPP02') {
        return '15젬 구매(첫 결제 이벤트)';
    } else if (storeHistory === 'SPP03') {
        return '40젬 구매(첫 결제 이벤트)';
    } else if (storeHistory === 'SPP04') {
        return '100젬 구매(첫 결제 이벤트)';
    } else if (storeHistory === 'SPP05') {
        return '3젬 구매(첫 결제 이벤트)';
    } else if (storeHistory === 'SPP06') {
        return '10젬 구매(첫 결제 이벤트)';
    } else if (storeHistory === 'SPP07') {
        return '30젬 구매(첫 결제 이벤트)';
    } else if (storeHistory === 'SPP08') {
        return '80젬 구매(첫 결제 이벤트)';
    } else if (storeHistory === 'SPP09') {
        return '150젬 구매(첫 결제 이벤트)';
    } else if (storeHistory === 'SPP10') {
        return '1000젬 구매(첫 결제 이벤트)';
    } else if (storeHistory === 'm_video_call_gender_filter') {
        return '라이브 성별';
    } else if (storeHistory === 'f_video_call_gender_filter') {
        return '라이브 성별';
    } else if (storeHistory === 'm_add_intro_unlock') {
        return '추가 추천 잠금해제';
    } else if (storeHistory === 'f_add_intro_unlock') {
        return '추가 추천 잠금해제';
    } else if (storeHistory === 'm_relevance_unlock') {
        return '유사한 상대 추천';
    } else if (storeHistory === 'f_relevance_unlock') {
        return '유사한 상대 추천';
    } else if (storeHistory === 'm_profile_view_unlock') {
        return '내 프로필 조회 잠금해제';
    } else if (storeHistory === 'f_profile_view_unlock') {
        return '내 프로필 조회 잠금해제';
    } else if (storeHistory === 'm_profile_boost') {
        return '스포트라이트';
    } else if (storeHistory === 'f_profile_boost') {
        return '스포트라이트';
    } else {
        return storeHistory
    }
}

const generateText = (store: StoreHistoryType, increase: number) => {
    const length = store.length;
    const lastSt = store.charAt(length - 1);
    let source = '';

    // 환불일 경우
    if (lastSt === 'R') {
        const stringStore = store as string
        source = storeHistoryFilter(stringStore.substring(0, length - 1), increase);
        source += ' (환불)';
        return source;
    }

    source = storeHistoryFilter(store, increase);
    return source;
};

export default generateText
