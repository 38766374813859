import { Box, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import buzzEndpoints from '../../../../api/buzz'
import { api } from '../../../../api/common'
import usePagination from '../../../../hooks/usePagination'
import { PaginationResponse } from '../../../../models/api/buzz'
import { MessageResponse } from '../../../../models/api/buzz/message'
import contextCodeFilter from '../../../../utils/filter/contextCodeFilter'
import kstFilter from '../../../../utils/filter/kstFilter'
import messageFilter from '../../../../utils/filter/messageFilter'
import EditLayout from './EditLayout'

const MessageItem: React.FC<MessageResponse> = ({
    createdAt, messageDetails, type, admin
}) => {
    return (
        <TableRow>
            <TableCell style={{ width: 54 }}>{messageFilter(type)}</TableCell>
            <TableCell>{messageDetails.map(detail => contextCodeFilter(detail.context)).join(', ')}</TableCell>
            <TableCell>{kstFilter(createdAt)}</TableCell>
            <TableCell>{admin.nickname}</TableCell>
        </TableRow>
    )
}

interface Props {
    userId: number
    close: () => void
}

const SuspendHistory: React.FC<Props> = forwardRef(({
    userId, close
}, ref) => {

    const classes = useStyles()

    const [ messages, setMessages ] = useState<MessageResponse[]>([])

    const { page, setPage, pageLength, setPageLength } = usePagination({
        initalPage: 1
    })

    const { isLoading, isFetching, isRefetching } = useQuery<{ data: MessageResponse[], pagination: PaginationResponse }>(
        ['userSuspends', page],
        async () => api.get(`${buzzEndpoints.getUserMessage(userId, page)}`).then(res => res.data),
        {
            onSuccess: (data) => {
                setMessages(data.data)
                setPageLength(data.pagination.totalPages)
            }
        }
    )

    const isEmpty = useMemo(() => {
        if(!isLoading && !isFetching && !isRefetching && !messages.length){
            return true
        } else {
            return false
        }
    }, [messages, isLoading, isFetching, isRefetching])

    return (
        <EditLayout title='제재 내역' onSubmit={close} close={close}>
            <Box className={classes.container}>
                <Box className={classes.contentBox}>
                    <Typography variant='h6' color='textPrimary'>제재 조치 내역</Typography>
                    {isEmpty && <Typography color='textPrimary' variant='subtitle1'>데이터가 없습니다.</Typography>}
                    {!isEmpty && (
                        <TableContainer className={classes.tableBox}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>경고 타입</TableCell>
                                        <TableCell>상세 내용</TableCell>
                                        <TableCell>시간</TableCell>
                                        <TableCell>관리자</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {messages.map(message => (
                                        <MessageItem key={message.id} {...message}/>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                    {!isEmpty && (
                        <Pagination
                            page={page}
                            count={pageLength}
                            onChange={(_, value) => setPage(value)}
                            className={classes.paginationBox}
                        />
                    )}
                </Box>
            </Box>
        </EditLayout>
    )
})

export default SuspendHistory

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    contentBox: {
        padding: 24,
        boxSizing: 'border-box'
    },
    tableBox: {
        marginTop: 10,
        height: '40vh',
        overflowY: 'scroll'
    },
    paginationBox: {
        height: 60,
        display: 'flex',
        justifyContent: 'flex-end'
    }
}))