import { Box, Button, ButtonBaseProps, makeStyles, Modal, useTheme, ButtonBaseActions } from '@material-ui/core'
import React, { createRef, forwardRef, ReactElement, useCallback, useEffect, useRef, useState } from 'react'

interface Props {
    onSubmit: () => void
    open: boolean
    onClose: () => void
    text?: string
    cancel?: boolean
}

const TextModal: React.FC<Props> = forwardRef(({
    children, onClose, onSubmit, open, text, cancel = true
}, ref) => {

    const classes = useStyles()
    
    const theme = useTheme()

    const handleActionRef = useCallback((ref: ButtonBaseActions) => {
        if(ref){
            ref.focusVisible()
        }
    }, [])

    return (
        <Modal className={classes.modalContainer} onClose={onClose} open={open}>
            <Box className={classes.container}>
                <Box className={classes.contentBox}>
                    {children}
                </Box>
                <Box className={classes.buttonBox}>
                    {cancel && <Button className={classes.cancelButton} onClick={onClose}>취소</Button>}
                    <Button action={handleActionRef} className={classes.submitButton} style={!cancel ? { backgroundColor: theme.palette.action.selected, color: theme.palette.text.primary } : {}} onClick={onSubmit}>{text || '확인'}</Button>
                </Box>
            </Box>
        </Modal>
    )
})

export default TextModal

const useStyles = makeStyles((theme) => ({
    modalContainer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        width: '30vw',
        maxWidth: 800,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 16
    },
    buttonBox: {
        padding: '15px 24px',
        boxSizing: 'border-box',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        borderTop: `1px solid ${theme.palette.divider}`
    },
    cancelButton: {
        fontWeight: 'bold'
    },
    submitButton: {
        fontWeight: 'bold',
        backgroundColor: '#008c00',
        color: '#ffffff',
        "&:hover": {
            backgroundColor: '#008c00'
        }
    },
    contentBox: {
        padding: 24,
        boxSizing: 'border-box'
    }
}))