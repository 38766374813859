import { Box, Button, makeStyles, TableRow, Typography, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import buzzEndpoints from '../../../../api/buzz'
import { api } from '../../../../api/common'
import { env } from '../../../../config'
import { ChatResponse, Connection } from '../../../../models/api/buzz/connection'
import { replacePlaceholderImage } from '../../../../utils/common'
import ageFilter from '../../../../utils/filter/ageFilter'
import connectionStateFilter from '../../../../utils/filter/connectionStateFilter'
import dateFilter from '../../../../utils/filter/dateFilter'
import educationFilter from '../../../../utils/filter/educationFilter'
import fullPathFilter from '../../../../utils/filter/fullPathFilter'
import genderFilter from '../../../../utils/filter/genderFilter'
import kstFilter from '../../../../utils/filter/kstFilter'
import levelFilter from '../../../../utils/filter/levelFilter'

interface Props extends Connection {
    userId: number
    onClickNickname: (id: number) => void
    onClickImage: (source: string) => void
    serviceBlockType: any
    onClickConnectionRefund: (id: number, nickname: string, isFreeOpen: number) => void
    showChat?: boolean
}

const ConnectionItem: React.FC<Props> = ({
    userReceiver, chatServerUrl, id, userSender, state, createdAt, openUser, userId, isFreeOpen, isRefund, onClickNickname, onClickImage, serviceBlockType, onClickConnectionRefund, showChat = false
}) => {

    const [ showChats, setShowChats ] = useState<boolean>(showChat)
    const [ chats, setChats ] = useState<ChatResponse[]>([])
    const [ isFetched, setIsFetched ] = useState<boolean>(false)

    const { refetch } = useQuery<ChatResponse[]>(
        ['connectionChats', chatServerUrl],
        () => api.post(`${buzzEndpoints.getChats()}`, { chatUrl: chatServerUrl }).then(res => res.data),
        {
            enabled: false,
            onSuccess: (data) => {
                setChats(data.filter(chat => chat.body))
                setIsFetched(true)
            },
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false
        }
    )

    const classes = useStyles()

    const theme = useTheme()

    const handleShowChat = () => {
        setShowChats(show => !show)
    }

    useEffect(() => {
        if(showChats){
            refetch()
        } else {
            setChats([])
            setIsFetched(false)
        }
    }, [showChats])

    return (
        <Box className={classes.container} style={{ cursor: 'pointer' }} onClick={handleShowChat}>
            <Typography variant='h6' className={typeof(userReceiver)==='number' ? classes.send : classes.receive}>{typeof(userReceiver)==='number' ? '보낸 대화' : '받은 대화'}</Typography>
            <Typography variant='body1' color='textPrimary'>CONNECTION_ID: {id}</Typography>
            <Typography variant='body1' color='textPrimary'>CHAT_SERVER_URL: {chatServerUrl}</Typography>
            <Box className={classes.contentBox}>
                {typeof(userReceiver)==='number' ? (
                    <Box className={classes.userContent}>
                        <img className={classes.userImage} src={fullPathFilter(userSender.pictures?.[0]?.pathSmall)} onClick={(e) => { e.stopPropagation(); onClickImage(userSender.pictures.length ? userSender.pictures?.[0]?.path : '') }} onError={replacePlaceholderImage}/>
                        <Box className={classes.userContentBox}>
                            <Typography variant='body1' color='secondary' style={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); onClickNickname(userSender.id) }}>{userSender.nickname}</Typography>
                            <Typography variant='body1' color='textPrimary'>{userSender.state} {userSender.locality} | {ageFilter(userSender.birthday)} | {genderFilter(userSender.gender)} | {userSender.height}cm | {levelFilter(userSender.level)}</Typography>
                            <Typography variant='body1' color='textPrimary'>{userSender.company} | {userSender.job} | {userSender.school} | {educationFilter(userSender.education)}</Typography>
                        </Box>
                    </Box>
                ) : (
                    <Box className={classes.userContent}>
                        <img className={classes.userImage} src={fullPathFilter(userReceiver.pictures?.[0]?.pathSmall)} onClick={(e) => { e.stopPropagation(); onClickImage(userReceiver.pictures.length ? userReceiver.pictures?.[0]?.path : '') }} onError={replacePlaceholderImage}/>
                        <Box className={classes.userContentBox}>
                            <Typography variant='body1' color='secondary' style={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); onClickNickname(userReceiver.id) }}>{userReceiver.nickname}</Typography>
                            <Typography variant='body1' color='textPrimary'>{userReceiver.state} {userReceiver.locality} | {ageFilter(userReceiver.birthday)} | {genderFilter(userReceiver.gender)} | {userReceiver.height}cm | {levelFilter(userReceiver.level)}</Typography>
                            <Typography variant='body1' color='textPrimary'>{userReceiver.company} | {userReceiver.job} | {userReceiver.school} | {educationFilter(userReceiver.education)}</Typography>
                        </Box>
                    </Box>
                )}
                <Typography variant='h6' style={{ color: state==='N' ? '#999999' : state==='O' ? '#008C00' : '#DC8100', cursor: 'pointer' }} className={classes.connectionState}>{connectionStateFilter(state)}</Typography>
                <Box className={classes.connectionBox}>
                    <Typography variant='body1'>{dateFilter(createdAt)} 연결 ({kstFilter(createdAt)})</Typography>
                    {typeof(userReceiver)==='number' ? (
                        (userId===openUser || userSender.id===openUser) && (
                            <Typography variant='body1' color='textPrimary'><b>{userId===openUser ? '본인이' : '상대가'}</b> 대화 오픈 | <b>{isFreeOpen ? '무료 횟수' : '젬'}</b> 사용</Typography>
                        )
                    ) : (
                        (userId===openUser || userReceiver.id===openUser) && (
                            <Typography variant='body1' color='textPrimary'><b>{userId===openUser ? '본인이' : '상대가'}</b> 대화 오픈 | <b>{isFreeOpen ? '무료 횟수' : '젬'}</b> 사용</Typography>
                        )
                    )}
                    <Typography variant='body1' style={{ fontWeight: 'bold' }}>{isRefund==='Y' && '대화 보상 받음'}</Typography>
                </Box>
                {typeof(userReceiver)==='number' ? (
                    <Box className={classes.buttonBox}>
                        {(isRefund==='N' && userId===openUser && serviceBlockType!==5) && <Button className={classes.button} onClick={(e) => { e.stopPropagation(); onClickConnectionRefund(id, userSender.nickname, isFreeOpen) }}>보상하기</Button>}
                        {(isRefund==='Y' && userId===openUser) && <Button disabled>보상받음</Button>}
                    </Box>
                ) : (
                    <Box className={classes.buttonBox}>
                        {(isRefund==='N' && userId===openUser && serviceBlockType!==5) && <Button className={classes.button} onClick={(e) => { e.stopPropagation(); onClickConnectionRefund(id, userReceiver.nickname, isFreeOpen) }}>보상하기</Button>}
                        {(isRefund==='Y' && userId===openUser) && <Button disabled>보상받음</Button>}
                    </Box>
                )}
            </Box>
            {showChats && (
                <Box className={classes.chatBox}>
                    {chats.length ? (
                        chats.map(chat => {
                            return (
                                <Box key={chat.id} style={{ width: '100%', display: 'flex', justifyContent: Number(chat.id)===userId ? 'flex-end' : 'flex-start' }}>
                                    <Box style={{ display: 'flex', width: '50%', padding: 12, borderRadius: 12, boxSizing: 'border-box', backgroundColor: Number(chat.id)===userId ? '#FFD8D8' : theme.palette.action.selected }}>
                                        <Typography color='textPrimary' variant='body2' style={{ color: Number(chat.id)===userId ? '#000000' : theme.palette.text.primary }}>{chat.body}</Typography>
                                        <Typography color='textPrimary' variant='body2' style={{ color: Number(chat.id)===userId ? '#000000' : theme.palette.text.primary }}>{kstFilter(chat.timestamp)}</Typography>
                                    </Box>
                                </Box>
                            )
                        })
                    ) : isFetched ? (
                        <Typography color='textPrimary' variant='body1'>메시지가 없습니다.</Typography>
                    ) : null}
                </Box>
            )}
        </Box>
    )
}

export default ConnectionItem

const useStyles = makeStyles((theme) => ({
    chatBox: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        rowGap: 12,
        minHeight: 30,
        maxHeight: 600,
        scrollBehavior: "auto",
        overflowY: "scroll"
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 8,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        marginBottom: 24,
        padding: 24,
        boxSizing: 'border-box'
    },
    send: {
        color: '#3c763d'
    },
    receive: {
        color: '#337ab7'
    },
    contentBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    userContent: {
        display: 'flex',
        flexDirection: 'row',
        margin: '20px 0',
        width: '40%'
    },
    userContentBox: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 5,
        marginLeft: 10,
    },
    userImage: {
        maxWidth: 96,
        width: 70,
        objectFit: 'cover',
        cursor: 'pointer'
    },
    connectionState: {
        margin: '20px 0',
    },
    connectionBox: {
        margin: '20px 0',
        width: '30%'
    },
    buttonBox: {
        width: '10%',
        display: 'flex',
        alignItems: 'center'
    },
    button: {
        backgroundColor: theme.palette.action.selected
    }
}))
