import { Box, makeStyles, Modal, Typography } from '@material-ui/core'
import React, { forwardRef, useState } from 'react'
import { useQuery } from 'react-query'
import buzzEndpoints from '../../../../api/buzz'
import { api } from '../../../../api/common'
import { AppealDetailResponse, AppealResponse } from '../../../../models/api/buzz/appeal'
import { replacePlaceholderImage } from '../../../../utils/common'
import appealDateFilter from '../../../../utils/filter/appealDateFilter'
import appealKstFilter from '../../../../utils/filter/appealKstFilter'
import dateFilter from '../../../../utils/filter/dateFilter'
import fullPathFilter from '../../../../utils/filter/fullPathFilter'
import kstFilter from '../../../../utils/filter/kstFilter'
import UserModal from '../../../UserModal'
import AppealDetailCommentItem from '../../AppealDetailCommentItem'

interface Props {
    id: number
    selectedCommentId?: number
}

const AppealDetailModal: React.FC<Props> = forwardRef(({
    id, selectedCommentId
}, ref) => {

    const classes = useStyles()

    const [ userModalOpened, setUserModalOpened ] = useState<boolean>(false)
    const [ userId, setUserId ] = useState<number>(0)
    const [ appeal, setAppeal ] = useState<AppealDetailResponse>()

    const {  } = useQuery<{ data: AppealDetailResponse }>(
        ['appealDetail', id],
        () => api.get(`${buzzEndpoints.getAppealDetail(id)}`).then(res => res.data),
        {
            onSuccess: (data) => setAppeal(data.data)
        }
    )

    const handleUserModal = (id?: number) => {
        setUserId(id || 0)
        setUserModalOpened(open => !open)
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.titleBox}>
                <Typography variant='h6' color='textPrimary' className={classes.title}>게시글 상세 {id}</Typography>
            </Box>
            <Box className={classes.contentBox}>
                <Box className={classes.contentItemBox}>
                    <Box className={classes.userInfoBox}>
                        <Box>
                            <Typography variant='subtitle1' color='textPrimary'>유저# {appeal?.userId}</Typography>
                            <Typography variant='subtitle1' color='textPrimary' className={classes.username}>{appeal?.user?.nickname}</Typography>
                        </Box>
                        <Box className={classes.userInfo}>
                            <Box>
                                <Typography color='textSecondary' variant='body1'>{appeal?.user?.region} | {appeal?.user?.age}</Typography>
                                <Typography color='textSecondary' variant='body1'>{appeal?.user?.address}</Typography>
                                <Typography color='textSecondary' variant='body1'>{appeal?.user?.job}</Typography>
                            </Box>
                            <img onClick={() => handleUserModal(appeal?.userId)} src={fullPathFilter(appeal?.user?.pictures[0]?.pathSmall)} onError={replacePlaceholderImage} className={classes.userImage}/>
                        </Box>
                        <Typography variant='body1' color='textSecondary'>{dateFilter(appeal?.user?.createdAt || '')} 가입</Typography>
                    </Box>
                    <Box className={classes.attachmentBox}>
                        {appeal?.attachments?.map(attachment => (
                            <img key={attachment.id} src={fullPathFilter(attachment.pathSmall)} onError={replacePlaceholderImage} className={classes.attachmentImage}/>
                        ))}
                    </Box>
                    {appeal?.article && (
                        <Box className={classes.articleBox}>
                            <Typography variant='body2' color='textPrimary' style={{ fontWeight: 'bold' }}>게시글 내용</Typography>
                            <Typography variant='body2' color='textPrimary'>{appeal?.article.body}</Typography>
                        </Box>
                    )}
                    <Typography variant='body2' className={classes.topic}>어필</Typography>
                    <Typography variant='body2' color='textPrimary'>{dateFilter(appeal?.createdAt)} 작성 ({appealKstFilter(appeal?.createdAt)})</Typography>
                </Box>
                <Box className={classes.contentItemBox}>
                    <Box className={classes.commentItemBox}>
                        {appeal?.comments.map(comment => (
                            <AppealDetailCommentItem
                                key={comment.id}
                                {...comment}
                                onClickUser={handleUserModal}
                                selectedCommentId={selectedCommentId}
                            />
                        ))}
                    </Box>
                </Box>
            </Box>
            <Modal className={classes.modalContainer} open={userModalOpened} onClose={() => handleUserModal()}>
                <UserModal userId={userId}/>
            </Modal>
        </Box>
    )
})

export default AppealDetailModal

const useStyles = makeStyles((theme) => ({
    commentBox: {
        display: 'flex',
        padding: 16,
        border: `1px solid ${theme.palette.divider}`,
        width: '100%',
        boxSizing: 'border-box',
    },
    commentUserImage: {
        width: 34,
        height: 34,
        margin: '8px 0',
        borderRadius: '50%',
        border: `4px solid green`,
        cursor: 'pointer'
    },
    commentInfoBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginLeft: 16
    },
    modalContainer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        width: '60vw',
        maxWidth: 1200,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 16,
        backgroundColor: theme.palette.background.paper,
        maxHeight: '70vh'
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 24px',
        boxSizing: 'border-box',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    title: {
        fontWeight: 'bold'
    },
    contentBox: {
        display: 'flex',
        padding: 24,
        justifyContent: 'space-between',
    },
    contentItemBox: {
        padding: 12,
        boxSizing: 'border-box',
        width: '50%',
        maxHeight: `calc(70vh - 62px - 72px)`,
        overflowY: 'scroll',
    },
    userInfoBox: {
        marginBottom: 20
    },
    username: {
        fontWeight: 'bold'
    },
    userInfo: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 16,
        position: 'relative',
        marginBottom: 16
    },
    userImage: {
        borderRadius: '50%',
        border: `4px solid green`,
        width: 70,
        height: 70,
        position: 'absolute',
        bottom: 0,
        right: 0,
        cursor: 'pointer'
    },
    commentItemBox: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 10,
    },
    attachmentBox: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 20
    },
    attachmentImage: {
        width: '100%',
        objectFit: 'contain'
    },
    articleBox: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 10,
        marginBottom: 20
    },
    topic: {
        color: '#f44336',
        border: `1px solid #f44336`,
        borderRadius: 16,
        boxSizing: 'border-box',
        padding: '4px 12px',
        marginTop: 10,
        marginBottom: 10,
        width: 'fit-content'
    },
}))