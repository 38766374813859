import ReportCodeType from "../../models/api/enum/reportCode";

const reportCodeFilter = (code: ReportCodeType) => {
    switch(code){
        case 'CR00': 
            return '부적절한 사진';
        case 'CR01': 
            return '허위 프로필';
        case 'CR02': 
            return '다른 이를 사칭';
        case 'CR03': 
            return '욕설 및 비방';
        case 'CR04': 
            return '허위 콘텐츠';
        case 'CR05': 
            return '스팸';
        case 'CR06': 
            return '시스템 신고 부적절한 단어';
        case 'CR07': 
            return '시스템 신고 다수의 신고';
        case 'CR08': 
            return '광고 및 도배';
        case 'CR09': 
            return '부적절한 내용';
        case 'CR10': 
            return '광고 및 스팸';
        case 'CR11': 
            return '사기 및 도박';
        case 'CR12': 
            return '음란물 유포';
        case 'CR13': 
            return '집단 풍기 문란';
        case 'CR14': 
            return '저작권 침해';
        case 'CR15': 
            return '개인 신상정보 노출';
        case 'CR16': 
            return '불쾌한 언행 (욕설, 비난, 모욕, 성희롱, 차별)';
        case 'CR17': 
            return '불쾌한 노출 (상반신, 하반신 과도한 노출)';
        case 'CR18': 
            return '허위 프로필, 스팸 (다른 이를 사칭, 사진 도용, 상업적인 성 서비스)';
        case 'CR19': 
            return '부적절한 프로필 정보 (잘못 된 프로필 정보, SNS 계정 등 연락처 기재)';
        case 'CR20': 
            return '특별한 이유 없음 (상대가 마음에 들지 않음)';
        case 'CR21': 
            return '허위 성별';
        case 'CR22':
            return '불법 촬영물'
        case 'CR23':
            return '허위 영상물'
        case 'CR24':
            return '아동 및 청소년 성착취물'
        default: 
            return code;
    }
}

export default reportCodeFilter