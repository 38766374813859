const dateGmtFilter = (date?: string | Date, region?: string, isSubtract?: boolean) => {
    if(date && region){
        const timeValue = new Date(date)

        let hour

        switch (region) {
            // 사우디 아라비아
            case 'SA': hour = 3; break;
                // 쿠웨이트
            case 'KW': hour = 3; break;
                // 미국
            case 'US': hour = -5; break;
                // 터키
            case 'TR': hour = 2; break;
                // 이스라엘
            case 'IL': hour = 3; break;
                // 저가 티어 국가
            case 'GLOBAL_L': hour = 2; break;
                // 고가 티어 국가
            case 'GLOBAL_H': hour = 3; break;
                // 이슬람(저가)
            case 'ISLM_L': hour = 2; break;
                // 이슬람 기본 티어
            case 'ISLM_M': hour = 4; break;
                // 이슬람(Global)
            case 'ISLM_G': hour = 4; break;
                // 이슬람 고가 티어
            case 'ISLM_H': hour = 3; break;
                // 한국
            default: hour = 9;
        }

        const duration = 60 * 60 * 1000 * hour
        const dateTime = isSubtract ? new Date(timeValue.getTime() - duration) : new Date(timeValue.getTime() + duration)
        
        const year = dateTime.getFullYear()
        let month: string | number = dateTime.getMonth() + 1
        if(month<10) month = `0${month}`
        let currentDate: string | number = dateTime.getDate()
        if(currentDate<10) currentDate = `0${currentDate}`
        let hours: string | number = dateTime.getHours()
        if(hours<10) hours = `0${hours}`
        let minutes: string | number = dateTime.getMinutes()
        if(minutes<10) minutes = `0${minutes}`
        let seconds: string | number = dateTime.getSeconds()
        if(seconds<10) seconds = `0${seconds}`
        return `${year}-${month}-${currentDate} ${hours}:${minutes}:${seconds}`
    } else {
        return ''
    }
}

export default dateGmtFilter