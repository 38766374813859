import ReligionType from "../../models/api/enum/religion";

const religionFilter = (religion: ReligionType) => {
    switch(religion){
        case 'UR00':
            return '무교';
        case 'UR01':
            return '기독교';
        case 'UR02':
            return '불교';
        case 'UR03':
            return '천주교';
        case 'UR04':
            return '원불교';
        case 'UR05':
            return '이슬람교';
        case 'UR06':
            return '힌두교';
        case 'UR07':
            return '유대교';
        case 'UR08':
            return '시크교';
        case 'UR09':
            return '성공회';
        case 'UR10':
            return '동방 정교회';
        case 'UR11':
            return '민속 신앙';
        case 'UR99':
            return '기타';
        default:
            return '기타';
    }
}

export default religionFilter