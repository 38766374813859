import React, { forwardRef, useMemo, useState } from 'react'
import { useQueries, useQuery } from 'react-query'
import buzzEndpoints from '../../../../api/buzz'
import { api } from '../../../../api/common'
import potatoEndpoints from '../../../../api/potato'
import { MessageResponse } from '../../../../models/api/buzz/message'
import { UserReportResponse } from '../../../../models/api/potato/report'
import { Box, Button, IconButton, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import dateFilter from '../../../../utils/filter/dateFilter'
import kstFilter from '../../../../utils/filter/kstFilter'
import { TotalCountResponse } from '../../../../models/api/buzz/report'
import { PotatoPaginationMeta, PotatoResponseType } from '../../../../models/api/potato'
import { PaginationResponse } from '../../../../models/api/buzz'
import contextCodeFilter from '../../../../utils/filter/contextCodeFilter'
import usePagination from '../../../../hooks/usePagination'
import { ApiPromiseReturnType } from '../../../../models/api/common'
import messageFilter from '../../../../utils/filter/messageFilter'
import reportCodeFilter from '../../../../utils/filter/reportCodeFilter'
import { getParams } from '../../../../utils/api'
import { Pagination } from '@material-ui/lab'

interface Props {
    id: number
    close: () => void
    reportType?: string
}

const PunishmentHistoryModal: React.FC<Props> = forwardRef(({
    id, close, reportType
}, ref) => {

    const classes = useStyles()

    const { page: hPage, setPage: setHPage, pageLength: hPageLength, setPageLength: setHPageLength } = usePagination({
        initalPage: 1
    })
    const { page: rPage, setPage: setRPage, pageLength: rPageLength, setPageLength: setRPageLength } = usePagination({
        initalPage: 1
    })

    const [ histories, setHistories ] = useState<MessageResponse[]>([])
    const [ reports, setReports ] = useState<UserReportResponse[]>([])

    const [ history, report, { data: totalCount } ] = useQueries<[ApiPromiseReturnType<{ data: MessageResponse[], pagination: PaginationResponse }>, ApiPromiseReturnType<{ data: UserReportResponse[], pagination: PaginationResponse }>, ApiPromiseReturnType<{ data: TotalCountResponse }>]>([
        {
            queryKey: ['userMessage', hPage],
            queryFn: async () => api.get(`${buzzEndpoints.getUserMessage(id, hPage)}`).then(res => res.data),
            onSuccess: (data) => {
                setHistories(data.data)
                setHPageLength(data.pagination.totalPages)
            }
        },
        {
            queryKey: ['userReports', rPage],
            queryFn: async () => api.get(`${buzzEndpoints.getVideoCallReports(id)}${getParams({ report_type: reportType, page: rPage })}`).then(res => res.data),
            onSuccess: (data) => {
                setReports(data.data)
                setRPageLength(data.pagination.totalPages)
            }
        },
        {
            queryKey: ['totalCount'],
            queryFn: async () => api.get(`${buzzEndpoints.getTotalCount(id)}`).then(res => res.data)
        }
    ])

    const isHistoryEmpty = useMemo(() => {
        const { isLoading, isFetching, isRefetching } = history
        if(!isLoading && !isFetching && !isRefetching && !histories.length){
            return true
        } else {
            return false
        }
    }, [history.isLoading, history.isFetching, history.isRefetching, histories])

    const isReportEmpty = useMemo(() => {
        const { isLoading, isFetching, isRefetching } = report
        if(!isLoading && !isFetching && !isRefetching && !reports.length){
            return true
        } else {
            return false
        }
    }, [report.isLoading, report.isFetching, report.isRefetching, reports])

    const displayData = useMemo(() => {
        return histories.map((history) => {
            const isBlockCancelAndNoReason = history.type === 'ME000' && history.messageDetails[0].context.trim() === '';
            const reason = {
                true: <span className={classes.noReasonText}>(없음)</span>,
                false: history.messageDetails.map(detail => contextCodeFilter(detail.context)).join(', ')
            }[isBlockCancelAndNoReason.toString()]

            return {
                id: history.id,
                type: messageFilter(history.type),
                reason,
                date: `${dateFilter(history.createdAt)} (${kstFilter(history.createdAt)})`,
                admin: history.admin?.nickname
            }
        })
    }, [histories, classes.noReasonText])

    return (
        <Box className={classes.container}>
            <Box className={classes.titleBox}>
                <Typography className={classes.title} variant='h6' color='textPrimary'>신고/제재 내역</Typography>
                <IconButton onClick={close}>
                    <Close/>
                </IconButton>
            </Box>
            <Box className={classes.sanctionInfoBox}>
                <Typography variant='body2' className={classes.countButton} style={{ borderColor: '#6200ea', color: '#6200ea' }}>[라이브] 카메라 OFF {totalCount?.data.camera}건</Typography>
                <Typography variant='body2' className={classes.countButton} style={{ borderColor: '#ff6d00', color: '#ff6d00' }}>경고 {totalCount?.data.alert}건</Typography>
                <Typography variant='body2' className={classes.countButton} style={{ borderColor: '#d50000', color: '#d50000' }}>이용정지 {totalCount?.data.serviceBlock}건</Typography>
            </Box>
            <Box className={classes.tableBox}>
                <Typography className={classes.subTitle} color='textPrimary' variant='subtitle1'>제재 조치 내역</Typography>
                <Typography className={classes.desc} color='textPrimary' variant='body2'>관리자에 의해 받은 내역</Typography>
                {isHistoryEmpty && <Typography variant='subtitle1' color='textPrimary'>데이터가 없습니다.</Typography>}
                {!isHistoryEmpty && (
                    <TableContainer className={classes.tableContainer} component={Paper}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {['경고 타입', '상세 내용', '시간', '관리자'].map(text => (
                                        <TableCell key={text}>{text}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {displayData.map(message => (
                                    <TableRow key={message.id}>
                                        <TableCell>{message.type}</TableCell>
                                        <TableCell className={classes.punishmentReason}>{message.reason}</TableCell>
                                        <TableCell className={classes.date}>{message.date}</TableCell>
                                        <TableCell className={classes.nickname}>{message.admin}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                {!isHistoryEmpty && (
                    <Pagination
                        page={hPage}
                        count={hPageLength}
                        onChange={(_, value) => setHPage(value)}
                        className={classes.paginationBox}
                    />
                )}
            </Box>
            <Box className={classes.tableBox}>
                <Typography className={classes.subTitle} color='textPrimary' variant='subtitle1'>신고 내역</Typography>
                <Typography className={classes.desc} color='textPrimary' variant='body2'>유저에게 신고 받은 내역</Typography>
                {isReportEmpty && <Typography variant='subtitle1' color='textPrimary'>데이터가 없습니다.</Typography>}
                {!isReportEmpty && (
                    <TableContainer className={classes.tableContainer} component={Paper}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {['사유', '시간', '신고자'].map(text => (
                                        <TableCell key={text}>{text}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reports.map(report => (
                                    <TableRow key={report.id}>
                                        <TableCell>{reportCodeFilter(report.context)}</TableCell>
                                        <TableCell className={classes.date}>{dateFilter(report.createdAt)} ({kstFilter(report.createdAt)})</TableCell>
                                        <TableCell className={classes.nickname}>{report?.userReported?.nickname}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                {!isReportEmpty && (
                    <Pagination
                        page={rPage}
                        count={rPageLength}
                        onChange={(_, value) => setRPage(value)}
                        className={classes.paginationBox}
                    />
                )}
            </Box>
            <Box className={classes.buttonBox}>
                <Button className={classes.submitButton} onClick={close}>확인</Button>
            </Box>
        </Box>
    )
})

export default PunishmentHistoryModal

const useStyles = makeStyles((theme) => ({
    container: {
        width: '60vw',
        maxWidth: 800,
        borderRadius: 16,
        backgroundColor: theme.palette.background.paper
    },
    paginationBox: {
        padding: 24,
        display: 'flex',
        boxSizing: 'border-box',
        justifyContent: 'flex-end',
        paddingBottom: 0
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 24px',
        boxSizing: 'border-box',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    countButton: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 16,
        padding: '0 12px',
        height: 32,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#ffffff'
    },
    title: {
        fontWeight: 'bold'
    },
    buttonBox: {
        padding: '15px 24px',
        borderTop: `1px solid ${theme.palette.divider}`,
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    submitButton: {
        fontWeight: 'bold',
    },
    tableBox: {
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 24px',
        boxSizing: 'border-box',
    },
    tableContainer: {
        overflowY: 'scroll',
        maxHeight: '25vh'
    },
    subTitle: {
        fontWeight: 'bold',
    },
    desc: {
        marginBottom: 10
    },
    sanctionInfoBox: {
        display: 'flex',
        alignItems: 'center',
        columnGap: 20,
        padding: '12px 24px'
    },
    punishmentReason: {
        width: '40%'
    },
    reportReason: {
        width: '60%'
    },
    date: {
        width: 200
    },
    nickname: {
        width: 70
    },
    noReasonText: {
        color: 'rgba(0,0,0,.4)'
    }
}))