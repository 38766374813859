import ReactDom from 'react-dom'
import App from './App'
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { SENTRY_DSN, env } from './config'
import './index.css'
import { RecoilRoot } from 'recoil'
import { AppContainer } from 'react-hot-loader'
import { ReactElement } from 'react'

Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: env.ENV_NAME
})

const render = () => {
    ReactDom.render(
            <RecoilRoot>
                <App/>
            </RecoilRoot>,
        document.getElementById('root')
    )
}

render()