import { Box, Button, makeStyles, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import buzzEndpoints from '../../../api/buzz'
import { api } from '../../../api/common'
import BannerListItem from '../../../components/Event/Banner/BannerListItem'
import BannerModal from '../../../components/Event/Modal/BannerModal'
import Layout from '../../../components/Layout'
import { BannerResponse } from '../../../models/api/buzz/banner'

const Banner = () => {

    const classes = useStyles()

    const [ banners, setBanners ] = useState<BannerResponse[]>([])
    const [ bannerModalOpened, setBannerModalOpened ] = useState<boolean>(false)
    const [ bannerData, setBannerData ] = useState<BannerResponse>()
    const [ bannerModalType, setBannerModalType ] = useState<'create' | 'update'>('create')

    const { isLoading, refetch } = useQuery<{ data: BannerResponse[] }>(
        ['eventBanners'],
        () => api.get(`${buzzEndpoints.getBanners()}`).then(res => res.data),
        {
            onSuccess: (data) => {
                setBanners(data.data)
            }
        }
    )

    const handleBanner = (type?: 'create' | 'update', value?: BannerResponse) => {
        setBannerData(value)
        setBannerModalOpened(open => !open)
        setBannerModalType(type || 'create')
    }

    return (
        <Layout title='이벤트 배너 관리' level='이벤트 관리' subtitle='특별 이벤트 및 그외 배너들 관리'>
            <Box className={classes.buttonBox}>
                <Button className={classes.button} onClick={() => handleBanner('create')}>등록</Button>
            </Box>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {['아이디', '상태', '국가(언어)', '제목', '배너이미지', '웹페이지'].map(text => (
                                <TableCell className={classes.tableHeadCell} key={text}>{text}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {banners.map(banner => (
                            <BannerListItem
                                {...banner}
                                onClick={handleBanner}
                                key={banner.id}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal className={classes.modalContainer} open={bannerModalOpened} onClose={() => handleBanner()}>
                <BannerModal
                    {...bannerData}
                    type={bannerModalType}
                    close={handleBanner}
                    refetch={refetch}
                />
            </Modal>
        </Layout>
    )
}

export default Banner

const useStyles = makeStyles((theme) => ({
    buttonBox: {
        marginTop: 24,
        backgroundColor: theme.palette.background.default,
        paddingTop: 24,
        position: 'fixed',
        top: 100,
        width: 'calc(100% - 328px)',
        zIndex: 12
    },
    tableContainer: {
        borderRadius: 16,
        marginTop: 120,
        boxSizing: 'border-box',
        gap: 16,
        overflowY: 'scroll',
    },
    modalContainer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        backgroundColor: theme.palette.action.selected
    },
    tableHeadCell: {
        background: theme.palette.background.paper
    },
}))