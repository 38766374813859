import React from 'react'
import Placeholder from '../assets/placeholder.png'

export const getActiveColor = (active: '블락' | '탈퇴' | '휴면' | '활성' | '탈퇴 대기 중' | '소개 비활성') => {
    switch(active){
        case '블락':
            return '#D92E00'
        case '탈퇴':
        case '탈퇴 대기 중':
            return '#E10098'
        case '활성':
        case '소개 비활성':
            return '#008C00'
        case '휴면':
            return '#DC8100'
    }
}

export const getCurrentDateTime = () => {
    const today = new Date()
    const year = today.getFullYear()
    const month = today.getMonth() + 1
    const date = today.getDate()
    const hour = today.getHours()
    const minute = today.getMinutes()
    const second = today.getSeconds()

    return `${year}-${month}-${date} ${hour}:${minute}:${second}`
}

export const replacePlaceholderImage = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = Placeholder
}