import { Box, makeStyles, TextField, Typography } from '@material-ui/core'
import { AxiosResponse } from 'axios'
import React, { forwardRef } from 'react'
import { useMutation } from 'react-query'
import buzzEndpoints from '../../../../api/buzz'
import { api } from '../../../../api/common'
import useValidValue from '../../../../hooks/useValidValue'
import EditLayout from './EditLayout'

interface Props {
    userId: number
    close: () => void
    onEdit: (value: string) => void
    value: string
}

const BirthdayEdit: React.FC<Props> = forwardRef(({
    userId, close, value: defaultValue, onEdit
}, ref) => {
    const classes = useStyles()

    const { mutate } = useMutation<AxiosResponse<any>, unknown, { birthday: string }>(
        (params) => api.post(`${buzzEndpoints.updateUserProfile(userId)}`, params), {
            onSuccess: () => onEdit(value),
            onError: (error) => alert(JSON.stringify(error ?? {})),
        })

    const { value, isValid, setValue, submitValue } = useValidValue(defaultValue, {
        validate: (value: string) => /^(\d{4})-(\d{2})-(\d{2})$/.test(value),
        onSubmit: (value) => { mutate({ birthday: value }) },
    });

    return (
        <EditLayout title='생년월일 설정' onSubmit={submitValue} close={close}>
            <Box className={classes.contentBox}>
                <Box className={classes.inputBox}>
                    <TextField
                        placeholder='0000-00-00'
                        name='birthday'
                        value={value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
                        fullWidth
                    />
                </Box>
                <Box className={classes.validateBox}>
                    <Typography
                        color='textPrimary'
                        style={!isValid ? { color: '#d92e00' } : { color: '#008c00' }}
                    >
                        {isValid ? '등록 가능한 생년월일입니다' : '생년월일을 올바로 설정해주세요'}
                    </Typography>
                </Box>
            </Box>
        </EditLayout>
    )
})

BirthdayEdit.displayName = "BirthdayEdit";
export default BirthdayEdit

const useStyles = makeStyles((theme) => ({
    contentBox: {
        display: 'flex',
        flexDirection: 'column',
        padding: 24,
        boxSizing: 'border-box'
    },
    inputBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 10
    },
    validateBox: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end'
    }
}))