import { Box, Button, makeStyles, Paper, Typography } from '@material-ui/core'
import React, { useRef, useEffect, useState } from 'react'
import { AdminActionType, AppealResponse } from '../../../../models/api/buzz/appeal'
import { replacePlaceholderImage } from '../../../../utils/common'
import ageFilter from '../../../../utils/filter/ageFilter'
import dateFilter from '../../../../utils/filter/dateFilter'
import fullPathFilter from '../../../../utils/filter/fullPathFilter'
import kstFilter from '../../../../utils/filter/kstFilter'

interface Props extends AppealResponse {
    onClickUser: (id: number) => void
    onClickAction: (id: number, state: AdminActionType, lastAdminAction: AdminActionType) => void
    onClickDetail: (id: number) => void
}

const AppealListItem: React.FC<Props> = ({
    id, commentsCount, articles, createdAt, attachments, user, userId, onClickUser, lastAdminAction, onClickAction, onClickDetail, topics
}) => {

    const classes = useStyles()

    return (
        <Paper className={classes.container}>
            <Box className={classes.header}>
                <Typography variant='body2' color='textPrimary' className={classes.appealId}>게시글# {id}</Typography>
                <Typography variant='body2' className={classes.topic}>어필</Typography>
            </Box>
            <Button className={classes.detailButton} onClick={() => onClickDetail(id)}>게시글 상세 보기 (댓글 {commentsCount}개)</Button>
            <Box className={classes.articleBox} onClick={() => onClickDetail(id)}>
                {articles.map(article => (
                    <Box key={article.id} className={classes.article}>
                        {Boolean(topics.length) && <Typography variant='body2' color='textPrimary' style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{topics[0].name}</Typography>}
                        <Typography variant='body1' color='textPrimary' className={classes.articleBody}>{article.body}</Typography>
                    </Box>
                ))}
            </Box>
            <Box className={classes.attachmentBox}>
                {attachments?.map(attachment => (
                    <Box key={attachment.id} className={classes.attachment} onClick={() => onClickDetail(id)}>
                        <img src={fullPathFilter(attachment.pathSmall)} className={classes.attachmentImage} onError={replacePlaceholderImage}/>
                    </Box>
                ))}
            </Box>
            <Typography variant='body2' color='textPrimary' className={classes.createdAt}>{dateFilter(createdAt)} 작성 ({createdAt})</Typography>
            <Box className={classes.userInfoBox}>
                <Box>
                    <Typography variant='subtitle1' color='textPrimary'>유저# {userId}</Typography>
                    <Typography variant='subtitle1' color='textPrimary' className={classes.username}>{user?.nickname}</Typography>
                </Box>
                <Box className={classes.userInfo}>
                    <Box>
                        <Typography color='textSecondary' variant='body1'>{user?.region} | {user?.age}</Typography>
                        <Typography color='textSecondary' variant='body1'>{user?.address}</Typography>
                        <Typography color='textSecondary' variant='body1'>{user?.job}</Typography>
                    </Box>
                    <img onClick={() => onClickUser(userId)} src={fullPathFilter(user?.pictures[0]?.pathSmall)} onError={replacePlaceholderImage} className={classes.userImage}/>
                </Box>
                <Typography variant='body1' color='textSecondary'>{dateFilter(user?.createdAt)} 가입</Typography>
            </Box>
            <Box className={classes.buttonBox}>
                <Button onClick={() => onClickAction(id, 'Default', lastAdminAction)} className={classes.button} style={lastAdminAction==='Default' ? { backgroundColor: '#1976d2', color: '#ffffff' } : {}}>기본</Button>
                <Button onClick={() => onClickAction(id, 'Approval', lastAdminAction)} className={classes.button} style={lastAdminAction==='Approval' ? { backgroundColor: '#4caf50', color: '#ffffff' } : {}}>인증</Button>
                <Button onClick={() => onClickAction(id, 'Hide', lastAdminAction)} className={classes.button} style={lastAdminAction==='Hide' ? { backgroundColor: '#fb8c00', color: '#ffffff' } : {}}>숨김 & 제재</Button>
                <Button onClick={() => onClickAction(id, 'Delete', lastAdminAction)} className={classes.button} style={lastAdminAction==='Delete' ? { backgroundColor: '#ff5252', color: '#ffffff' } : {}}>삭제</Button>
            </Box>
        </Paper>
    )
}

export default AppealListItem

const useStyles = makeStyles((theme) => ({
    container: {
        width: `calc((100% - 80px) / 6)`,
        minWidth: 340,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 12,
        boxSizing: 'border-box',
        padding: 16,
        position: 'relative',  
        marginBottom: 16
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    appealId: {
        fontWeight: 'bold'
    },
    topic: {
        color: '#f44336',
        border: `1px solid #f44336`,
        borderRadius: 16,
        boxSizing: 'border-box',
        padding: '4px 12px'
    },
    detailButton: {
        borderRadius: 6,
        border: `1px solid ${theme.palette.divider}`,
        width: '100%',
        marginTop: 8
    },
    articleBox: {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 8,
        width: '100%'
    },
    article: {
        width: '100%',
        backgroundColor: theme.palette.action.selected,
        borderRadius: 4,
        boxSizing: 'border-box',
        padding: 10
    },
    articleBody: {

    },
    createdAt: {
        marginTop: 8
    },
    attachmentBox: {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 8,
        width: '100%'
    },
    attachment: {
        width: '100%'
    },
    attachmentImage: {
        width: '100%',
        objectFit: 'contain',
        cursor: 'pointer'
    },
    userInfoBox: {
        marginTop: 16,
        paddingBottom: 60
    },
    username: {
        fontWeight: 'bold'
    },
    userInfo: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 16,
        position: 'relative',
        marginBottom: 16
    },
    userImage: {
        borderRadius: '50%',
        border: `4px solid green`,
        width: 70,
        height: 70,
        position: 'absolute',
        bottom: 0,
        right: 0,
        cursor: 'pointer'
    },
    buttonBox: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: 8,
        position: 'absolute',
        bottom: 16
    },
    button: {
        backgroundColor: theme.palette.action.selected,
    }
}))