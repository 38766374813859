import MessageType from "../../models/api/enum/message";

const messageFilter = (message: MessageType) => {
    if (message === 'ME010') {
        return '정지';
    } else if (message === 'ME020') {
        return '사진 반려';
    } else if (message === 'ME030') {
        return '경고';
    } else if (message === 'ME040') {
        return '소개 비활성화';
    } else if (message === 'ME050') {
        return '사진 거절';
    } else if (message === 'ME060') {
        return '공지 메시지';
    } else if (message === 'ME070') {
        return '이메일 확인 불가';
    } else if (message === 'ME080') {
        return '사진 부분 승인';
    } else if (message === 'ME090') {
        return '소개 실패';
    } else if (message === 'ME100') {
        return '스킵 탈퇴';
    } else if (message === 'ME110') {
        return '무통장 입금 거절';
    } else if (message === 'ME120') {
        return '전화번호 뺏김';
    } else if (message === 'ME130') {
        return '휴면 전환';
    } else if (message === 'ME140') {
        return '시즌4 리젝';
    } else if (message === 'ME150') {
        return '관리자 사진 인증 반려';
    } else if (message === 'ME160') {
        return '셀프 사진 인증 반려';
    } else if (message === "ME000") {
      return "이용 정지 해제";
    }
    return message;
}

export default messageFilter