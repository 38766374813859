import SmokingType from "../../models/api/enum/smoking"

const smokingFilter = (smoking: SmokingType) => {
    switch(smoking){
        case 'SM00':
            return '자주'
        case 'SM01':
            return '가끔'
        case 'SM02' || 'SM03':
            return '안 함'
        default:
            return smoking
    }
}

export default smokingFilter