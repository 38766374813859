import { makeStyles, Paper, Typography, Box } from '@material-ui/core'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { api } from '../../../api/common'
import potatoEndpoints from '../../../api/potato'
import TextModal from '../../../components/common/Modal/TextModal'
import TargetEventListItem from '../../../components/Event/Target/TargetEventListItem'
import Layout from '../../../components/Layout'
import BooleanType from '../../../models/api/enum/boolean'
import { PotatoResponseType } from '../../../models/api/potato'
import { TargetEventResponse } from '../../../models/api/potato/event'
import Welcome from '../../../assets/event_welcome.jpg'
import Purchase from '../../../assets/event_purchase.jpg'
import { env } from '../../../config'

export interface UpdateData {
    category?: string,
    event_points?: {[key: string]: number | string}[],
    id?: number,
    is_activate?: string,
}

const Target = () => {

    const classes = useStyles()

    const [ events, setEvents ] = useState<TargetEventResponse[]>([])
    const [ toggleModalOpened, setToggleModalOpened ] = useState<boolean>(false)
    const [ eventId, setEventId ] = useState<number>(0)
    const [ toggleEventData, setToggleEventData ] = useState<{ action: string, isActivate: BooleanType }>({
        action: '',
        isActivate: 'N'
    })
    const [ updateData, setUpdateData ] = useState<UpdateData | null>(null)
    const [ updateModalOpened, setUpdateModalOpened ] = useState<boolean>(false)

    const { isLoading, refetch } = useQuery<PotatoResponseType<TargetEventResponse[]>>(
        ['targetEvents'],
        async () => api.get(`${potatoEndpoints.getTargetEvents()}`).then(res => res.data),
        {
            onSuccess: (data) => {
                setEvents(data.data)
            }
        }
    )

    const { mutate: updateTargetEvent } = useMutation(
        (data: UpdateData) => api.put(`${potatoEndpoints.updateTargetEvent()}`, data).then(res => res.data)
    )
    const { mutate: toggleTargetEvent } = useMutation(
        (data: UpdateData) => api.put(`${potatoEndpoints.updateTargetEvent()}`, data, { headers: { 'Content-Type': 'Application/json; multipart/form-data; boundary=__X_PAW_BOUNDARY__; charset=UTF-8' } }).then(res => res.data),
        {
            onSuccess: () => {
                setEvents(events => events.map(event => event.id===eventId ? ({...event, isActivate: toggleEventData.isActivate}) : event))
                refetch()
            }
        }
    )

    const handleToggleModal = (id?: number, data?: UpdateData, isActivate?: BooleanType) => {
        setEventId(data?.id || 0)
        setUpdateData({...data, is_activate: isActivate || 'N'})
        setToggleEventData(p => {
            const clone = {...p};
            clone.isActivate = isActivate || 'N';
            return clone;
        });
        setToggleModalOpened(open => !open)
    }
    const handleToggle = () => {
        if(updateData){
            toggleTargetEvent(updateData)
        }
        setToggleModalOpened(false)
    }
    const handleUpdateModal = (data?: object) => {
        setUpdateData(data || {})
        setUpdateModalOpened(open => !open)
    }
    const handleUpdate = () => {
        if(updateData){
            updateTargetEvent(updateData)
        }
        setUpdateModalOpened(false)
        refetch()
    }

    return (
        <Layout title='타겟 이벤트 관리' level='이벤트 관리' subtitle='특별한 케이스(가입시, 첫 구매시등) 에서 발생 될 이벤트'>
            <Box className={classes.container}>
                {events.map((event, index) => (
                    <TargetEventListItem
                        key={event.id}
                        onClickToggle={handleToggleModal}
                        onClickUpdate={handleUpdateModal}
                        image={index===0 ? Welcome : Purchase}
                        index={index}
                        data={event}
                    />
                ))}
            </Box>
            <TextModal
                open={toggleModalOpened}
                onClose={handleToggleModal}
                children={<Typography color='textPrimary'>이벤트를 {toggleEventData.isActivate==='Y' ? 'on' : 'off'} 하시겠습니까?</Typography>}
                onSubmit={handleToggle}
                text='승인'
            />
            <TextModal
                open={updateModalOpened}
                onClose={handleUpdateModal}
                children={<Typography color='textPrimary'>이벤트가 수정됩니다</Typography>}
                onSubmit={handleUpdate}
                text='승인'
            />
        </Layout>
    )
}

export default Target

const useStyles = makeStyles((theme) => ({
    buttonBox: {
        marginTop: 24
    },
    container: {
        overflowY: 'scroll',
        boxSizing: 'border-box',
        borderRadius: 16,
        marginTop: 50
    },
    tableContainer: {
        borderRadius: 16,
        marginTop: 24,
        boxSizing: 'border-box',
        gap: 16,
        overflowY: 'scroll',
    },
    modalContainer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        backgroundColor: theme.palette.action.selected
    }
}))