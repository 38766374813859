const kstFilter = (date?: string) => {
    if(date){
        const timeValue = new Date(date)
        const dateTime = new Date(timeValue.getTime() + 32400000)
        
        const year = dateTime.getFullYear()
        let month: string | number = dateTime.getMonth() + 1
        if(month<10) month = `0${month}`
        let currentDate: string | number = dateTime.getDate()
        if(currentDate<10) currentDate = `0${currentDate}`
        let hours: string | number = dateTime.getHours()
        if(hours<10) hours = `0${hours}`
        let minutes: string | number = dateTime.getMinutes()
        if(minutes<10) minutes = `0${minutes}`
        let seconds: string | number = dateTime.getSeconds()
        if(seconds<10) seconds = `0${seconds}`
        return `${year}-${month}-${currentDate} ${hours}:${minutes}:${seconds}`
    } else {
        return ''
    }
}

export default kstFilter