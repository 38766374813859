const attractionReverseFilter = (attraction: string) => {
    switch (attraction.replace(/ /gi, "")) {
        case '요리를잘해요':
            return 'AG1';
        case '패션감각':
            return 'AG2';
        case '유머감각':
            return 'AG3';
        case '다리가예뻐요':
            return 'AG4';
        case '경제력':
            return 'AG5';
        case '다정해요':
            return 'AG6';
        case '허세없어요':
            return 'AG7';
        case '재주가많아요':
            return 'AG8';
        case '엉덩이가예뻐요':
            return 'AG9';
        case '대화를잘해요':
            return 'AG10';
        case '피부가좋아요':
            return 'AG11';
        case '손이예뻐요':
            return 'AG12';
        case '장난기가많아요':
            return 'AG13';
        case '웃는게예뻐요':
            return 'AG14';
        case '목소리가좋아요':
            return 'AG15';
        case '보조개':
            return 'AG17';
        case '이야기를잘들어줘요':
            return 'AG18';
        case '노래를잘해요':
            return 'AG19';
        case '비율이좋아요':
            return 'AG20';
        case '큰눈':
            return 'AG22';
        case '긍정적마인드':
            return 'AG23';
        case '솔직해요':
            return 'AG24';
        case '예의가발라요':
            return 'AG25';
        case '표현을잘해요':
            return 'AG26';
        case '배려심이깊어요':
            return 'AG27';
        case '한사람만봐요':
            return 'AG28';
        case '웃음이많아요':
            return 'AG29';
        case '뭐든잘먹어요':
            return 'AG30';
        case '동안이에요':
            return 'AG31';
        case '운동감각':
            return 'AG32';
        case '섹시한두뇌':
            return 'AG34';
        case '시사에밝아요':
            return 'AG35';
        case '섹시한타투':
            return 'AG36';
        case '체력이좋음':
            return 'AG37';
        case '뛰어난커리어':
            return 'AG40';
        case '워커홀릭':
            return 'AG41';
        case '혼자잘놀아요':
            return 'AG42';
        case '게임을잘해요':
            return 'AG43';
        case '섬세해요':
            return 'AG44';
        case '넓은어깨':
            return 'AM1';
        case '골반미녀':
            return 'AF1';
        case '쌍커풀없는눈':
            return 'AG16';
        case '애교가많아요':
            return 'AG21';
        case '오똑한콧날':
            return 'AG33';
        case '짙은눈썹':
            return 'AG38';
        case '털털해요':
            return 'AG39';
        default:
            return attraction;
    }
}

export default attractionReverseFilter