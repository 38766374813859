import BooleanType from "../../models/api/enum/boolean"

const activeFilter = (isOut: BooleanType, isOutPending: BooleanType, isBlock: BooleanType, isDeactivate: BooleanType, isIntroduceDisable?: BooleanType) => {
    if(isOut==='Y'){
        if(isOutPending==='Y'){
            return '탈퇴 대기 중'
        } else {
            return '탈퇴'
        }
    } else {
        if(isBlock==='Y'){
            return '블락'
        } else {
            if(isDeactivate==='Y'){
                return '휴면'
            } else {
                if (isIntroduceDisable === "Y") {
                    return '소개 비활성'
                }
                return '활성'
            }
        }
    }
}

export default activeFilter