const lifeStyleReverseFilter = (lifeStyle: string) => {
    switch (lifeStyle.replace(/ /gi, "")) {
        case '1인가구':
            return 'LS1';
        case '룸메이트와함께지내요':
            return 'LS2';
        case '반려동물과함께지내요':
            return 'LS3';
        case '기숙사∙숙소에서지내요':
            return 'LS4';
        case '가족과함께살아요':
            return 'LS5';
        case '아침형인간':
            return 'LS7';
        case '새벽형인간/올빼미형':
            return 'LS8';
        case '규칙적인생활':
            return 'LS9';
        case '집에있는걸좋아해요':
            return 'LS10';
        case '외출을좋아해요':
            return 'LS11';
        case '자녀가있어요':
            return 'LS12';
        case '돌싱이에요':
            return 'LS13';
        case '주말에일해요':
            return 'LS14';
        case '평일에일해요':
            return 'LS15';
        case '규칙적인근무시간':
            return 'LS16';
        case '유연한근무시간':
            return 'LS17';
        case '야간근무':
            return 'LS18';
        case '교대근무':
            return 'LS19';
        case '진보적인정치성향':
            return 'LS20';
        case '보수적인정치성향':
            return 'LS21';
        case '중도적인정치성향':
            return 'LS22';
        default:
            return lifeStyle;
    }
}

export default lifeStyleReverseFilter