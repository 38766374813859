const useAuthToken = () => {
    const getToken = () => {
        return localStorage.getItem('accessToken')
    }
    const removeToken = () => {
        localStorage.removeItem('accessToken')
    }
    const setToken = (token: string) => {
        localStorage.setItem('accessToken', token)
    }
    return { getToken, removeToken, setToken }
}

export default useAuthToken