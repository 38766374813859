import { SocialNameEng } from "../../models/api/buzz/user"

const buzzEndpointPrefix = '/management'

const buzzEndpoints = {
    login: () => buzzEndpointPrefix + '/auth/login',
    me: () => buzzEndpointPrefix,
    users: () => buzzEndpointPrefix + `/users`,//?page=${page}${key ? `&t=${type}&k=${key}` : ''}`,
    addEmail: (id: number) => buzzEndpointPrefix + `/users/${id}/authorization/add_email`,
    userDetail: (id: number) => buzzEndpointPrefix + `/users/${id}`,
    userFacebook: (id: number) => buzzEndpointPrefix + `/facebook/${id}`,
    introductionDetail: (id: number) => buzzEndpointPrefix + `/introduction/${id}`,
    storeHistoryDetail: (id: number, page: number) => buzzEndpointPrefix + `/store/get_history/${id}?page=${page}`,
    recommendationDetail: (id: number, page: number) => buzzEndpointPrefix + `/recommandation/${id}?page=${page}`,
    updateUserNearbyStatus: (id: number) => buzzEndpointPrefix + `/users/${id}/nearby_status`,
    updateUserMessage: (id: number) => buzzEndpointPrefix + `/users/${id}/change_message`,
    updateUserProfile: (id: number) => buzzEndpointPrefix + `/users/${id}/edit`,
    getJobs: () => buzzEndpointPrefix + `/job`,
    getConnection: () => buzzEndpointPrefix + `/connection`,
    showConnection: () => buzzEndpointPrefix + `/connection/show`,
    sendNotification: () => buzzEndpointPrefix + `/notification/message/instance`,
    initPassword: (id: number) => buzzEndpointPrefix + `/users/${id}/init_password`,
    userOut: (id: number) => buzzEndpointPrefix + `/users/${id}/out`,
    userOutCancel: (id: number) => buzzEndpointPrefix + `/users/${id}/out_cancel`,
    userImmediateOut: (id: number) => buzzEndpointPrefix + `/users/${id}/immediate_out`,
    givePoint: () => buzzEndpointPrefix + `/store/give_point`,
    takePoint: () => buzzEndpointPrefix + `/store/take_point`,
    freeLike: () => buzzEndpointPrefix + `/free_like`,
    freeConnection: () => buzzEndpointPrefix + `/free_connection`,
    userRelationship: (from: number, to: string) => buzzEndpointPrefix + `/users/relation?from=${from}&to=${to}`,
    joinFeedback: () => buzzEndpointPrefix + `/join_feedback`,
    joinRatingManipulation: (id: number) => buzzEndpointPrefix + `/join/rating/manipulation/${id}`,
    getUserMessage: (id: number, page: number) => buzzEndpointPrefix + `/users/${id}/message?page=${page}`,
    userAlert: (id: number) => buzzEndpointPrefix + `/users/${id}/alert`,
    userBlock: (id: number) => buzzEndpointPrefix + `/users/${id}/block`,
    userBlockCancel: (id: number) => buzzEndpointPrefix + `/users/${id}/block_cancel`,
    userChangeNickname: (id: number) => buzzEndpointPrefix + `/users/${id}/change_nickname`,
    smsVerificationBlocks: () => buzzEndpointPrefix + `/users/blocks/sms`,
    purchaseGemByApp: () => buzzEndpointPrefix + `/store/give_point_by_app`,
    purchaseGemByAccount: () => buzzEndpointPrefix + `/store/give_point_by_account`,
    getTotalCount: (id: number) => buzzEndpointPrefix + `/sanction/${id}/total-count`,
    getVideoCallReports: (id: number) => buzzEndpointPrefix + `/video-call-reports/${id}`,
    validationEmail: () => buzzEndpointPrefix + `/validation/email`,
    userChangeEmail: (id: number) => buzzEndpointPrefix + `/users/${id}/authorization/change_email`,
    checkSmsDuplicated: () => buzzEndpointPrefix + `/sms/check_duplicate`,
    addUserSms: () => buzzEndpointPrefix + `/sms/add`,
    changeGender: (id: number) => buzzEndpointPrefix + `/users/${id}/change_gender`,
    deviceBlock: () => buzzEndpointPrefix + `/device_block`,
    refund: () => buzzEndpointPrefix + `/malice/refund`,
    freeLikeHistory: (id: number) => buzzEndpointPrefix + `/free_like_history/${id}`,
    freeConnectionHistory: (id: number) => buzzEndpointPrefix + `/free_connection_history/${id}`,
    refundConnection: () => buzzEndpointPrefix + `/connection/refund`,
    getChats: () => buzzEndpointPrefix + `/connection/messages`,

    getAdmin: () => buzzEndpointPrefix + `/admin`,
    updateAdminStatus: (id: number) => buzzEndpointPrefix + `/admin/${id}/deactivate`,
    createAdmin: () => buzzEndpointPrefix + `/auth/register`,

    getChangeProfileImageRequests: () => buzzEndpointPrefix + `/picture`,
    getChangeProfileImageRequestsDone: () => buzzEndpointPrefix + `/picture/history`,

    getUserFeedback: () => buzzEndpointPrefix + `/feedback`,
    pictureRollback: () => buzzEndpointPrefix + `/picture/history/rollback`,
    pictureReview: () => buzzEndpointPrefix + `/picture/review`,
    completeFeedback: (id: number) => buzzEndpointPrefix + `/feedback/${id}/handle`,
    getUserAdminPunishment: (id: number) => buzzEndpointPrefix + `/users/${id}/message`,
    getLikeMessages: () => buzzEndpointPrefix + `/like_messages`,
    getLikes: () => buzzEndpointPrefix + `/likes`,
    deletePicture: () => buzzEndpointPrefix + `/picture/delete`,

    getRecommends: () => buzzEndpointPrefix + `/recommandation`,

    getBlockedUsers: () => buzzEndpointPrefix + `/service_blocks`,
    getSuspiciousUsers: () => buzzEndpointPrefix + `/suspicious_users`,
    clearSuspiciousUser: () => buzzEndpointPrefix + `/suspicious_users`,

    getAdminList: () => buzzEndpointPrefix + `/admin/list`,
    getJoinReviews: () => buzzEndpointPrefix + `/join/review`,
    getJoinReviewsHidden: () => buzzEndpointPrefix + `/join/review/hidden`,
    getJoinProgress: () => buzzEndpointPrefix + `/join/progress`,
    getJoinRating: () => buzzEndpointPrefix + `/join/rating`,
    getJoinFeedback: () => buzzEndpointPrefix + `/join_feedback`,


    sendRejectJoinCustomReason: (id: number) => buzzEndpointPrefix + `/users/${id}/message`,
    rejectJoinReview: (id: number) => buzzEndpointPrefix + `/join/review/reject/${id}`,
    confirmJoinReview: (id: number) => buzzEndpointPrefix + `/join/review/confirm/${id}`,
    hiddenJoinReview: (id: number) => buzzEndpointPrefix + `/join/review/hidden/${id}`,
    recoverReviewUser: (id: number) => buzzEndpointPrefix + `/join/review/recover/${id}`,
    handleJoinFeedback: (id: number) => buzzEndpointPrefix + `/join_feedback/${id}/handle`,
    initJoinFeedback: (id: number) => buzzEndpointPrefix + `/join_feedback/${id}/init`,
    getPictureCount: () => buzzEndpointPrefix + `/picture/state`,

    toggleCamera: (id: number) => buzzEndpointPrefix + `/sanction/camera-off/${id}`,
    skipVideoCallReport: (id: number) => buzzEndpointPrefix + `/video-call-reports/${id}/skip`,
    skipVideoCallReportAllByUser: () => buzzEndpointPrefix + `/video-call-reports/skip/all_by_user`,
    skipVideoCallReportAllByUserReported: () => buzzEndpointPrefix + `/video-call-reports/skip/all_by_user_reported`,
    skipReportAllByUserReported: () => buzzEndpointPrefix + `/reports/skip/all_by_user_reported`,
    getVideoCallReportsAll: () => buzzEndpointPrefix + `/video-call-reports`,
    getUserVideoCallReportsAll: () => buzzEndpointPrefix + `/video-call-reports/user/history`,

    changeAdminPassword: () => buzzEndpointPrefix + `/admin/password/change`,
    getReportOtherByUser: (targetUserId: number, userId: number) => buzzEndpointPrefix + `/video-call-reports/${targetUserId}/other_by_user/${userId}`,
    getReportOtherByUserReported: (targetUserId: number, userId: number) => buzzEndpointPrefix + `/video-call-reports/${targetUserId}/other_by_user_reported/${userId}`,

    getReports: () => buzzEndpointPrefix + `/reports`,
    getReportsHistory: () => buzzEndpointPrefix + `/reports/user/history`,

    validateEmail: () => buzzEndpointPrefix + `/validation/email`,
    removeSocial: (id: number, socialNameEng: SocialNameEng) => buzzEndpointPrefix + `/users/${id}/authorization/remove_social/${socialNameEng}`,
    removeSocialWithEmail: (id: number, socialNameEng: SocialNameEng) => buzzEndpointPrefix + `/users/${id}/authorization/remove_social/${socialNameEng}/add_email`,
    deleteSms: () => buzzEndpointPrefix + `/sms/delete`,

    //appeals
    getAppeals: () => buzzEndpointPrefix + `/appeals`,
    updateAppealState: (id: number, state: string) => buzzEndpointPrefix + `/appeals/${id}/${state}`,
    getAppealDetail: (id: number) => buzzEndpointPrefix + `/appeals/${id}`,
    getAppealReports: () => buzzEndpointPrefix + `/v4/appeal_reports`,
    updateReportState: (id: number, state: string) => buzzEndpointPrefix + `/appeal_reports/${id}/${state}`,
    getComments: () => buzzEndpointPrefix + `/comments`,
    updateCommentState: (id: number, state: string) => buzzEndpointPrefix + `/comments/${id}/${state}`,

    //service
    getInduceUpdate: () => buzzEndpointPrefix + `/induce_update`,
    getParameter: () => buzzEndpointPrefix + `/parameter`,
    updateParameter: () => buzzEndpointPrefix + `/parameter/edit`,
    updateUserLocality: (id: number) => buzzEndpointPrefix + `/users/${id}/locality`,
    getAds: () => buzzEndpointPrefix + `/ads`,
    updateAds: () => buzzEndpointPrefix + `/ads/edit`,
    searchReceipt: () => buzzEndpointPrefix + `/store/search_receipt`,

    //event
    getBanners: () => buzzEndpointPrefix + `/event/banner`,
    updateBanner: (id: number) => buzzEndpointPrefix + `/event/banner/${id}/edit`,
    createBanner: () => buzzEndpointPrefix + `/event/banner`,

    // 차단 관계 동기화
    syncBlockedRelation: () => buzzEndpointPrefix + `/user_block_each_other`,
}

export default buzzEndpoints
