import { Box, Button, makeStyles, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout'
import { AdminResponse } from '../../../models/api/buzz'
import AdminListItem from '../../../components/Service/Admin/AdminListItem'
import { PersonAdd } from '@material-ui/icons'
import SearchInput from '../../../components/SearchInput'
import CreateAdminModal from '../../../components/Service/Admin/Modal/CreateAdminModal'
import AdminDetailModal from '../../../components/Service/Admin/Modal/AdminDetailModal'
import { AdminEmployType, AdminRequestParams } from '../../../models/api/buzz/admin'
import { useQuery, useMutation } from 'react-query'
import { api } from '../../../api/common'
import buzzEndpoints from '../../../api/buzz'
import { getParams } from '../../../utils/api'

const item = [
    { label: '이메일', value: 'email' },
    { label: '아이디', value: 'identification' },
    { label: 'No.', value: 'id' }
]

const Admin = () => {

    const [ admins, setAdmins ] = useState<AdminResponse[]>([])
    const [ keyword, setKeyword ] = useState<string>('')
    const [ searchType, setSearchType ] = useState<string>('')
    const [ createAdminModalOpened, setCreateAdminModalOpened ] = useState<boolean>(false)
    const [ adminDetailModalOpened, setAdminDetailModalOpened ] = useState<boolean>(false)
    const [ adminInfo, setAdminInfo ] = useState<Partial<AdminResponse>>({

    })
    const [ adminLevelId, setAdminLevelId ] = useState<number | string>('level')

    const handleSelectedLevelChange = (e: React.ChangeEvent<{ name?: string, value: unknown }>) => {
        setAdminLevelId(e.target.value as (number | string))
    }

    const classes = useStyles()

    const { data: adminData, isLoading, refetch, isFetching, isRefetching } = useQuery<{ data: AdminResponse[] }>(
        ['admins'],
        async () => {
            let params: AdminRequestParams = {}
            if(keyword){
                params.search_type = searchType || 'email'
                params.search_value = keyword
            }
            return api.get(`${buzzEndpoints.getAdmin()}${getParams(params)}`).then(res => res.data)
        },
        {
            onSuccess: (data) => {
                const sortedAdmins = [...data.data];
                sortedAdmins.sort((a, b) => a.isDeactivate - b.isDeactivate); // isDeactivate 내림차순으로 정렬

                setAdmins(sortedAdmins);
            }
        }
    )

    const { mutate: updateAdminStatus } = useMutation(
        (data: { id: number, isActivate: boolean }) => api.post(`${buzzEndpoints.updateAdminStatus(data.id)}`, {
            isDeactivate: !data.isActivate
        })
    )

    const handleCreateAdminModal = () => {
        setCreateAdminModalOpened(open => !open)
    }

    const handleShowDetail = (id?: number, identification?: string, employType?: AdminEmployType, adminLevelId?: number, email?: string, nickname?: string) => {
        setAdminInfo({
            id: id,
            identification: identification,
            email: email,
            employType: employType,
            nickname: nickname,
            adminLevelId: adminLevelId
        })
        setAdminDetailModalOpened(open => !open)
    }

    const handleSearch = async () => {
        refetch()
    }

    const handleActivate = async (id: number, isActivate: boolean) => {
        updateAdminStatus({
            id: id,
            isActivate: isActivate
        })
    }

    const handleKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value)
    }

    const handleSelectType = (e: React.ChangeEvent<{ name?: string | undefined, value: unknown }>) => {
        setSearchType(e.target.value as string)
    }

    useEffect(() => {
        if(typeof(adminLevelId)==='number'){
            setAdmins(adminData?.data?.filter(admin => admin.adminLevelId===adminLevelId) || [])
        } else {
            setAdmins(adminData?.data || [])
        }
    }, [adminLevelId])

    return (
        <Layout title='관리자 설정' level='서비스 관리' loading={isLoading}>
            <Box className={classes.searchBoxContainer}>
                <Box className={classes.header}>
                    <Select value={adminLevelId} onChange={handleSelectedLevelChange}>
                        <MenuItem value={'level'}>관리자 레벨 구분</MenuItem>
                        <MenuItem value={0}>0: 시스템 매니저</MenuItem>
                        <MenuItem value={1}>1: 매니저</MenuItem>
                        <MenuItem value={2}>2: 리더</MenuItem>
                        <MenuItem value={3}>3: CR팀</MenuItem>
                        <MenuItem value={4}>4: 직원</MenuItem>
                        <MenuItem value={5}>5: 운영요원</MenuItem>
                    </Select>
                    <Button className={classes.addButton} onClick={handleCreateAdminModal} startIcon={<PersonAdd/>}>관리자 계정 생성</Button>
                </Box>
                <SearchInput
                    keyword={keyword}
                    onChangeKeyword={handleKeyword}
                    onChangeSelect={handleSelectType}
                    onClickSearch={handleSearch}
                    selectItem={item}
                />
            </Box>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {['No.', '아이디', '이메일', '권한 레벨', '직원 타입', '생성 일시', '수정 일시', '활성 여부'].map(text => (
                                <TableCell key={text} className={classes.tableHeadCell}>{text}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {admins.map(admin => (
                            <AdminListItem
                                {...admin}
                                key={admin.id}
                                onClickActivate={handleActivate}
                                onClickNickname={handleShowDetail}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal open={createAdminModalOpened} onClose={handleCreateAdminModal} className={classes.modalContainer}>
                <CreateAdminModal close={handleCreateAdminModal}/>
            </Modal>
            <Modal open={adminDetailModalOpened} onClose={() => handleShowDetail()} className={classes.modalContainer}>
                <AdminDetailModal admin={adminInfo}/>
            </Modal>
        </Layout>
    )
}

export default Admin

const useStyles = makeStyles((theme) => ({
    searchBoxContainer: {
        backgroundColor: theme.palette.background.default,
        paddingTop: 24,
        position: 'fixed',
        top: 100,
        width: 'calc(100% - 328px)',
        zIndex: 12
    },
    tableContainer: {
        borderRadius: 16,
        marginTop: 170,
    },
    tableHeadCell: {
        backgroundColor: theme.palette.background.paper
    },
    modalContainer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '24px 0'
    },
    addButton: {
        backgroundColor: theme.palette.action.selected
    }
}))
