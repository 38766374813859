import { Box, IconButton, makeStyles, TextField, Typography } from '@material-ui/core'
import { Done } from '@material-ui/icons'
import React, { forwardRef, useState } from 'react'
import { useMutation } from 'react-query'
import buzzEndpoints from '../../../../api/buzz'
import { api } from '../../../../api/common'
import BooleanType from '../../../../models/api/enum/boolean'
import EditLayout from './EditLayout'
import { getPhoneNumber } from './GetPhoneNumber'

interface Props {
    userId: number
    close: () => void
    refetch: () => void
    value: string
}

const PhoneEdit: React.FC<Props> = forwardRef(({
    userId, close, refetch, value
}, ref) => {


    const [ isPhoneValidated, setIsPhoneValidated ] = useState<boolean>(false)
    const [ phoneNumber, setPhoneNumber ] = useState<string>(value?.replace('+82', '0') || '') 
    const [ isDuplicated, setIsDuplicated ] = useState<boolean>(false)



   
    const { mutate: checkDuplicated } = useMutation(
        () => {
            const phone = getPhoneNumber(phoneNumber)
            return api.post(`${buzzEndpoints.checkSmsDuplicated()}`, {phoneNumber: phone, userId }).then(res => res.data)
        },
        { 
            onSuccess: (data: { foundPhoneNumber: BooleanType }) => {
                if(data?.foundPhoneNumber==='N'){
                    setIsPhoneValidated(true)
                    setIsDuplicated(false)
                } else {
                    setIsPhoneValidated(true)
                    setIsDuplicated(true)
                }
            }
        }
    )
    const { mutate: updateUserPhone } = useMutation(
        () => {
           const phone = getPhoneNumber(phoneNumber)
           return api.post(`${buzzEndpoints.addUserSms()}`, {phoneNumber: phone, userId })
        },
        {
            onSuccess: () => {
                close()
                refetch()
            }
        }
    )

    const classes = useStyles()

    const handleUpdate = () => {
        if(phoneNumber && isPhoneValidated){
            updateUserPhone()
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let regex = /^[0-9]*$/
        if(regex.test(e.target.value)){
            if(isDuplicated || isPhoneValidated){
                setIsDuplicated(false)
                setIsPhoneValidated(false)
            }
            setPhoneNumber(e.target.value)
        }
    }

    return (
        <EditLayout title='전화번호 설정' disabled={(!isPhoneValidated || isDuplicated)} subtitle='대한민국(+82) 전화번호만 설정 가능합니다.' onSubmit={handleUpdate} close={close}>
            <Box className={classes.contentBox}>
                <Box className={classes.inputBox}>
                    <TextField 
                        placeholder='01012345678 형식'
                        name='phoneNumber'
                        value={phoneNumber}
                        onChange={onChange}
                        fullWidth
                    />
                    <IconButton onClick={() => checkDuplicated()} disabled={phoneNumber===''}>
                        <Done color={phoneNumber ? 'action' : 'disabled'}/>
                    </IconButton>
                </Box>
                <Box className={classes.validateBox}>
                    <Typography color='textPrimary' style={isPhoneValidated ? isDuplicated ? { color: '#d92e00' } : { color: '#008c00' } : {}}>{isPhoneValidated ? (isDuplicated ? '이미 존재하는 전화번호입니다' : '등록 가능한 전화번호입니다') : '전화번호를 설정해주세요'}</Typography>
                </Box>
            </Box>
        </EditLayout>
    )
})

export default PhoneEdit

const useStyles = makeStyles(() => ({
    contentBox: {
        display: 'flex',
        flexDirection: 'column',
        padding: 24,
        boxSizing: 'border-box'
    },
    inputBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 10
    },
    validateBox: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end'
    }
}))