const idealReverseFilter = (ideal: string) => {
    switch (ideal.replace(/ /gi, "")) {
        case '연락자주하는사람':
            return 'ID1';
        case '다정한사람':
            return 'ID2';
        case '웃는모습이예쁜사람':
            return 'ID3';
        case '운동을좋아하는사람':
            return 'ID4';
        case '표현을잘하는사람':
            return 'ID5';
        case '적극적인사람':
            return 'ID6';
        case '목소리가좋은사람':
            return 'ID7';
        case '패션감각이좋은사람':
            return 'ID8';
        case '노래잘하는사람':
            return 'ID9';
        case '잘먹는사람':
            return 'ID10';
        case '피부가좋은사람':
            return 'ID11';
        case '대화가잘통하는사람':
            return 'ID12';
        case '본인일에충실한사람':
            return 'ID13';
        case '솔직한사람':
            return 'ID14';
        case '섹시한사람':
            return 'ID15';
        case '말을예쁘게하는사람':
            return 'ID16';
        case '배려심깊은사람':
            return 'ID17';
        case '나만좋아해주는사람':
            return 'ID18';
        case '착한사람':
            return 'ID19';
        case '예의바른사람':
            return 'ID20';
        case '허세없는사람':
            return 'ID21';
        case '배울점이많은사람':
            return 'ID22';
        case '유머러스한사람':
            return 'ID23';
        case '대화가많은사람':
            return 'ID24';
        case '함께많은시간보낼수있는사람':
            return 'ID25';
        case '귀여운사람':
            return 'ID26';
        case '털털한사람':
            return 'ID27';
        case '애교많은사람':
            return 'ID28';
        default:
            return ideal;
    }
}

export default idealReverseFilter