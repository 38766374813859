import { Box, Button, IconButton, makeStyles, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { forwardRef } from 'react'
import { env } from '../../../../config'
import fullPathFilter from '../../../../utils/filter/fullPathFilter'
import ImagePlaceholder from '../../../../assets/placeholder.png'
import { replacePlaceholderImage } from '../../../../utils/common'

interface Props {
    source: string
    close: () => void
}

const ImageModal: React.FC<Props> = forwardRef(({
    source, close
}, ref) => {

    const classes = useStyles()

    const searchGoogleImage = () => {
        window.open('https://lens.google.com/uploadbyurl?url=' + fullPathFilter(source))
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.titleBox}>
                <IconButton onClick={close}>
                    <Close/>
                </IconButton>
            </Box>
            <Box className={classes.imageBox}>
                <img src={source ? fullPathFilter(source) : ImagePlaceholder} className={classes.image} onError={replacePlaceholderImage}/>
            </Box>
            <Box>
                <Button className={classes.button} fullWidth onClick={searchGoogleImage}>
                    <Typography variant='subtitle1' color='textPrimary'>구글 이미지 검색</Typography>
                </Button>
            </Box>
        </Box>
    )
})

export default ImageModal

const useStyles = makeStyles((theme) => ({
    container: {
        borderRadius: 16,
        backgroundColor: theme.palette.background.paper,
        maxWidth: 1200,
        minWidth: 800,
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: 16,
        boxSizing: 'border-box',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    imageBox: {
        height: '600px',
        width: '600px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        justifyContent: 'center',
        padding: '24px 0',
        boxSizing: 'border-box'
    },
    image: {
        height: '100%',
        width: 'auto',
        objectFit: 'cover'
    },
    button: {
        backgroundColor: theme.palette.action.selected,
        padding: 16
    }
}))
