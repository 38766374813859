import React from 'react'
import { Box, makeStyles, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@material-ui/core'
import { IntroductionResponse } from '../../../../models/api/buzz/introduction'
import introductionFilter from '../../../../utils/filter/introductionFilter'
import dateFilter from '../../../../utils/filter/dateFilter'
import ageFilter from '../../../../utils/filter/ageFilter'
import genderFilter from '../../../../utils/filter/genderFilter'
import bodyTypeFilter from '../../../../utils/filter/bodyTypeFilter'
import authorizationFilter from '../../../../utils/filter/authorizationFilter'
import deviceFilter from '../../../../utils/filter/deviceFilter'
import { getActiveColor, replacePlaceholderImage } from '../../../../utils/common'
import activeFilter from '../../../../utils/filter/activeFilter'
import fullPathFilter from '../../../../utils/filter/fullPathFilter'
import Placeholder from '../../../../assets/placeholder.png'
import kstFilter from '../../../../utils/filter/kstFilter'

interface Props extends IntroductionResponse {
    onClickNickname: (id: number) => void
    onClickImage: (source: string) => void
}

const IntroductionItem: React.FC<Props> = ({
    introductionUser, introductionType, isOpen, isDelete, createdAt, onClickNickname, onClickImage
}) => {
    return (
        <TableRow>
            <TableCell style={{ maxWidth: 96, width: 70, cursor: 'pointer' }} onClick={() => onClickImage(introductionUser.pictures.length ? introductionUser.pictures[0].path : '')}>
                <img style={{ maxWidth: '100%', height: 'auto' }} src={introductionUser.pictures.length ? fullPathFilter(introductionUser.pictures[0].pathSmall) : Placeholder} onError={replacePlaceholderImage}/>
            </TableCell>
            <TableCell>{introductionUser.id}</TableCell>
            <TableCell onClick={() => onClickNickname(introductionUser.id)}>
                <Typography style={{ cursor: 'pointer' }} variant='body2' color='secondary'>{introductionUser.nickname}</Typography>
            </TableCell>
            <TableCell>{introductionFilter(introductionType)}</TableCell>
            <TableCell>{isOpen==='Y' ? '열람' : '미열람'}{isDelete==='Y' && ' | 삭제됨'}</TableCell>
            <TableCell>
                <Typography variant='body2' color='textPrimary'>{dateFilter(createdAt)}</Typography>
                <Typography variant='body2' color='textPrimary'>{kstFilter(createdAt)}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant='body2' color='textPrimary'>{ageFilter(introductionUser.birthday)}세 | {genderFilter(introductionUser.gender)} | {introductionUser.region} | {introductionUser.state} | {introductionUser.locality}</Typography>
                <Typography variant='body2' color='textPrimary'>{`${introductionUser.height || 0}cm | ${bodyTypeFilter(introductionUser.bodyType) || '보통'}`}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant='body2' color='textPrimary'>{introductionUser.devices.length ? introductionUser.devices.map(device => deviceFilter(device.type)).join(', ') : 'unregist'}</Typography>
                <Typography variant='body2' color='textPrimary'>{introductionUser.authorizations.map(authorization => authorizationFilter(authorization.type)).join(', ')}</Typography>
            </TableCell>
            <TableCell style={{ color: getActiveColor(activeFilter(introductionUser.isOut, introductionUser.isOutPending, introductionUser.isBlock, introductionUser.isDeactivate)) }}>{activeFilter(introductionUser.isOut, introductionUser.isOutPending, introductionUser.isBlock, introductionUser.isDeactivate)}</TableCell>
            <TableCell>
                <Typography variant='body2'>{dateFilter(introductionUser.lastActivity)}</Typography>
                <Typography variant='body2'>{kstFilter(introductionUser.lastActivity)}</Typography>
            </TableCell>
        </TableRow>
    )
}

export default IntroductionItem