import { Box, makeStyles, Modal, Paper, Table, TableBody, TableContainer, TablePagination, Typography } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import React, { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import buzzEndpoints from '../../../api/buzz'
import { api } from '../../../api/common'
import usePagination from '../../../hooks/usePagination'
import { ConnectionResponse, Connection as ConnectionType, ShowConnectionResponse } from '../../../models/api/buzz/connection'
import { getParams } from '../../../utils/api'
import ImageModal from '../../common/Modal/ImageModal'
import Loading from '../Loading'
import ConnectionItem from './ConnectionItem'
import CheckRefundModal from './Modal/CheckRefundModal'

interface Props {
    userId: number
    setUserId: (id: number) => void
    connectionUserId?: number
}

const Connection: React.FC<Props> = ({
    userId, setUserId, connectionUserId
}) => {

    const { page, setPage, pageLength, setPageLength } = usePagination({
        initalPage: 1
    })

    const [ connections, setConnection ] = useState<ConnectionType[]>([])
    const [ imageModalOpened, setImageModalOpened ] = useState<boolean>(false)
    const [ selectedImageSource, setSelectedImageSource ] = useState<string>('')
    const [ serviceBlockType, setServiceBlockType ] = useState<any>()
    const [ checkModalOpened, setCheckModalOpened ] = useState<boolean>(false)
    const [ modalInfo, setModalInfo ] = useState<{ nickname: string, isFreeOpen: number }>({
        nickname: '',
        isFreeOpen: 1
    })
    const [ id, setId ] = useState<number>(0)
    const [ openPoint, setOpenPoint ] = useState<number>(0)

    const { isLoading, isFetching, isRefetching, refetch: getConnections } = useQuery<ConnectionResponse>(
        ['userConnections', page, userId],
        async () => api.post(`${buzzEndpoints.getConnection()}${getParams({ page: page })}`, { userId: userId }).then(res => res.data),
        {
            enabled: false,
            onSuccess: (data) => {
                setConnection(data.data)
                setPageLength(data.pagination.totalPages)
                setServiceBlockType(data.serviceBlock?.blockType)
                setOpenPoint(data.openPoint)
            }
        }
    )
    useEffect(() => {
        getConnections();
    }, [page, getConnections])

    const { refetch: getShowConnection } = useQuery<ShowConnectionResponse>(
        ['userConnectionShow', connectionUserId, userId],
        async () => api.post(`${buzzEndpoints.showConnection()}`, { userId: userId, userReportedId: connectionUserId }).then(res => res.data),
        {
            enabled: false,
            onSuccess: (data) => {
                if(data.data){
                    setConnection([data.data])
                    setServiceBlockType(data.serviceBlock?.blockType)
                    setOpenPoint(data.openPoint)
                }
            }
        }
    )

    const { mutate: refundConnection } = useMutation(
        (data) => api.post(`${buzzEndpoints.refundConnection()}`, { connectionId: id }),
        {
            onSuccess: () => {
                setConnection(connections => connections.map(connection => {
                    if(connection.id===id){
                        return {...connection, isRefund: 'Y'}
                    } else {
                        return connection
                    }
                }))
            }
        }
    )

    const classes = useStyles()

    const handleClickNickname = (id: number) => {
        setUserId(id)
    }

    const handleImageModal = (source?: string) => {
        if(imageModalOpened){
            setSelectedImageSource('')
            setImageModalOpened(false)
        } else {
            setSelectedImageSource(source || '')
            setImageModalOpened(true)
        }
    }

    const handleCheckRefundModal = () => {
        setCheckModalOpened(open => !open)
    }

    const onClickConnectionRefund = (id: number, nickname: string, isFreeOpen: number) => {
        setModalInfo({
            nickname: nickname,
            isFreeOpen: isFreeOpen
        })
        setId(id)
        handleCheckRefundModal()
    }

    const handleRefund = () => {
        handleCheckRefundModal()
        refundConnection()
    }

    const isEmpty = useMemo(() => {
        if(!isLoading && !isFetching && !isRefetching && !connections.length){
            return true
        } else {
            return false
        }
    }, [isLoading, isFetching, isRefetching, connections])

    useEffect(() => {
        if(connectionUserId){
            getShowConnection()
        } else {
            getConnections()
        }
    }, [connectionUserId])

    if(isLoading || isFetching || isRefetching){
        return <Loading/>
    }

    return (
        <Box className={classes.container}>
            {isEmpty ? (
                <Typography color='textPrimary' variant='h6'>데이터가 없습니다.</Typography>
            ) : (
                <Paper className={classes.tableContainer}>
                    {connectionUserId ? (
                        connections.length ? (
                            <ConnectionItem
                                serviceBlockType={serviceBlockType}
                                {...connections[0]}
                                userId={userId}
                                onClickNickname={handleClickNickname}
                                onClickImage={handleImageModal}
                                onClickConnectionRefund={onClickConnectionRefund}
                                showChat
                            />
                        ) : (
                            <Typography color='textPrimary' variant='h6'>데이터가 없습니다.</Typography>
                        )
                    ) : (
                        connections.map(connection => (
                            <ConnectionItem
                                key={connection.id}
                                serviceBlockType={serviceBlockType}
                                {...connection}
                                userId={userId}
                                onClickNickname={handleClickNickname}
                                onClickImage={handleImageModal}
                                onClickConnectionRefund={onClickConnectionRefund}
                            />
                        ))
                    )}
                </Paper>
            )}
            {!isEmpty && (
                <Pagination
                    page={page}
                    count={pageLength}
                    onChange={(_, value) => setPage(value)}
                    className={classes.paginationBox}
                />
            )}
            <Modal className={classes.modalContainer} open={imageModalOpened} onClose={() => handleImageModal()}>
                <ImageModal source={selectedImageSource} close={handleImageModal}/>
            </Modal>
            <Modal className={classes.modalContainer} open={checkModalOpened} onClose={handleCheckRefundModal}>
                <CheckRefundModal openPoint={openPoint} close={handleCheckRefundModal} mutate={handleRefund} {...modalInfo}/>
            </Modal>
        </Box>
    )
}

export default Connection

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: 24,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableContainer: {
        height: '60vh',
        overflowY: 'scroll',
    },
    paginationBox: {
        padding: 24,
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    modalContainer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}))