import { Box, makeStyles, Typography } from "@material-ui/core"
import { useEffect, useRef, useState } from "react";
import { convertSecondsToTime } from "./Edit/util";

interface RemainTimerProps {
  remainSeconds: number;
}

const RemainTimer: React.FC<RemainTimerProps> = ({ remainSeconds }) => {
  const styles = useStyles();

  const [remainTime, setRemainTimer] = useState(remainSeconds);

  const intervalRef = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    if (remainSeconds){
      intervalRef.current = setInterval(() => {
        setRemainTimer(v => v - 1);
      }, 1000);

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current)
        }
      }
    }
  }, [remainSeconds])

  useEffect(() => {
    if (remainTime <= 0) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [remainTime])

  return (
    <>
      <Box style={{ display: 'flex' }} className={styles.charge}>
        <Typography variant='body2'>충전까지&nbsp;</Typography>
        <Typography
          variant='body2'
          color={remainSeconds === -2 ? 'textSecondary' : 'secondary'}
        >
          {remainSeconds === -2
            ? '미사용'
            : remainSeconds === -1
              ? '00:00:00'
              : convertSecondsToTime(remainTime)
          }
        </Typography>
      </Box>
    </>
  );
}

export default RemainTimer;

const useStyles = makeStyles((theme) => ({
  charge: {
    width: 200,
    boxSizing: 'border-box',
    padding: 12,
  },
}));
