import DatingType from '../../models/api/enum/dating'

const datingFilter = (dating: DatingType) => {
    switch(dating){
        case 'DS1':
            return '함께 드라이브가기';
        case 'DS2':
            return '함께 산책하기';
        case 'DS3':
            return '함께 여행 가기';
        case 'DS4':
            return '카페 데이트';
        case 'DS5':
            return '함께 운동하기';
        case 'DS6':
            return '꾸준히 연락하기';
        case 'DS7':
            return '집 데이트';
        case 'DS8':
            return '함께 술 마시기';
        case 'DS9':
            return '함께 게임하기';
        case 'DS10':
            return '친구 같은 편한 만남';
        case 'DS11':
            return '한번 볼 때 진하게 만나기';
        case 'DS12':
            return '함께 스포츠 관람';
        case 'DS13':
            return '함께 전시회 관람';
        case 'DS15':
            return '노래방 데이트';
        case 'DS16':
            return '취미활동 공유하기';
        case 'DS17':
            return '서로 사진 찍어주기';
        case 'DS18':
            return '영화 관람';
        case 'DS19':
            return '함께 맛집 투어';
        case 'DS20':
            return '통화 자주하기';
        case 'DS21':
            return '즉흥적인 만남';
        case 'DS22':
            return '짧더라도 자주 만나기';
        case 'DS23':
            return '도시락 나들이';
        case 'DS24':
            return '함께 공연 관람';
        default:
            return dating
    }
}

export default datingFilter