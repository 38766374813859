import {FaceVerificationStatus} from "../../models/api/buzz/user";

const selfVerificationStatusFilter = (state?: FaceVerificationStatus) => {
  switch(state){
    case 'requested':
      return '인증 신청'
    case 'not_found':
      return '인증되지 않음'
    case 'in_review':
      return '리뷰중'
    case 'confirm':
      return '인증 승인'
    case 'rejected':
      return '인증 반려'
    case 'skip':
      return '인증 취소'
    case 'exclude':
      return '인증 대상 아님'
    case 'soft_requested':
      return 'Soft 인증 받음'
    case 'force_requested':
      return 'Force 인증 받음'
    default:
      return 'x'
  }
}

export default selfVerificationStatusFilter
