import RelationType from "../../models/api/enum/relation";

const relationFilter = (relation: RelationType) => {
    switch(relation){
        case 'MySelf':
            return '본인아이디입력';
        case 'UserConnected':
            return '연결됨';
        case 'ChatOpen':
            return '대화방열림';
        case 'UserDisconnected':
            return '대화방닫힘';
        case 'SendLike':
            return '호감전송';
        case 'CheckLike':
            return '호감확인';
        case 'SendReject':
            return '호감거절';
        case 'CheckReceiveLike':
            return '받은호감확인';
        case 'ReceiveLike':
            return '호감받음';
        case 'NotRelated':
            return '관계없음';
        case 'GaveHighScore':
            return '높게평가받음';
        case 'BothGaveHighScore':
            return '둘다높게평가';
        default:
            return relation;
    }
}

export default relationFilter