const adminLevelFilter = (level: number) => {
    switch(level){
        case 0:
            return '시스템 매니저'
        case 1:
            return '매니저'
        case 2:
            return '리더'
        case 3:
            return 'CR팀'
        case 4:
            return '직원'
        case 5:
            return '운영요원'
        default:
            return level
    }
}

export default adminLevelFilter