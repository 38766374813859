import DeviceType from "../../models/api/enum/device"

const deviceFilter = (device: DeviceType) => {
    switch(device){
        case 'A':
            return 'Android-GCM'
        case 'I':
            return 'IOS-APNS'
    }
}

export default deviceFilter