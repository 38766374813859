const ageFilter = (birthday: string) => {
    const today = new Date()
    const birthdate = new Date(birthday)
    const age = today.getFullYear() - birthdate.getFullYear()
    const m = today.getMonth() - birthdate.getMonth()
    if(m < 0 || (m===0 && today.getDate() < birthdate.getDate())){
        return age
    }
    return age + 1
}

export default ageFilter