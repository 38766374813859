export  const convertSecondsToTime = (seconds: any) => {
    let days: any = Math.floor(seconds / (3600 * 24));
    seconds -= days * 3600 * 24;
    days = (days > 0) ? days + '일 ' : '';
    // 시
    let hrs: any = Math.floor(seconds / 3600);
    seconds -= hrs * 3600;
    hrs = (hrs < 10) ? '0' + hrs : hrs;
    // 분
    let mnts: any = Math.floor(seconds / 60);
    seconds -= mnts * 60;
    mnts = (mnts < 10) ? '0' + mnts : mnts;
    // 초
    seconds = (seconds < 10) ? '0' + seconds : seconds;
    return days + hrs + ':' + mnts + ':' + seconds;
}