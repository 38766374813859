const levelFilter = (level: number) => {
    if(0 < level && level <= 6){
        return '다이아몬드'
    } else if(6 < level && level <= 11){
        return '골드'
    } else if(11 < level && level <= 16){
        return '실버'
    } else if(16 < level && level <= 25){
        return '브론즈'
    } else {
        return '가입 대기 중'
    }
}

export default levelFilter