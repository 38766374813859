import { Box, makeStyles, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router'
import buzzEndpoints from '../../../api/buzz'
import { api } from '../../../api/common'
import { env } from '../../../config'
import { IntroductionResponse } from '../../../models/api/buzz/introduction'
import { getActiveColor } from '../../../utils/common'
import activeFilter from '../../../utils/filter/activeFilter'
import ageFilter from '../../../utils/filter/ageFilter'
import authorizationFilter from '../../../utils/filter/authorizationFilter'
import bodyTypeFilter from '../../../utils/filter/bodyTypeFilter'
import dateFilter from '../../../utils/filter/dateFilter'
import deviceFilter from '../../../utils/filter/deviceFilter'
import fullPathFilter from '../../../utils/filter/fullPathFilter'
import genderFilter from '../../../utils/filter/genderFilter'
import introductionFilter from '../../../utils/filter/introductionFilter'
import ImageModal from '../../common/Modal/ImageModal'
import Loading from '../Loading'
import IntroductionItem from './IntroductionItem'

interface Props {
    userId: number
    setUserId: (id: number) => void
}

const Introduction: React.FC<Props> = ({
    userId, setUserId
}) => {

    const [ introductions, setIntroductions ] = useState<IntroductionResponse[]>([])
    const [ imageModalOpened, setImageModalOpened ] = useState<boolean>(false)
    const [ selectedImageSource, setSelectedImageSource ] = useState<string>('')

    const classes = useStyles()

    const theme = useTheme()

    const { isLoading, isFetching, isRefetching } = useQuery<IntroductionResponse[]>(
        ['userIntroductions', userId],
        async () => api.get(`${buzzEndpoints.introductionDetail(userId)}`).then(res => res.data),
        {
            onSuccess: (data) => {
                setIntroductions(data)
            }
        }
    )

    const handleImageModal = (source?: string) => {
        if(imageModalOpened){
            setSelectedImageSource('')
            setImageModalOpened(false)
        } else {
            setSelectedImageSource(source || '')
            setImageModalOpened(true)
        }
    }

    const handleChangeModalUser = (id: number) => {
        setUserId(id)
    }

    const isEmpty = useMemo(() => {
        if(!isLoading && !isFetching && !isRefetching && !introductions.length){
            return true
        } else {
            return false
        }
    }, [isLoading, isFetching, isRefetching, introductions])

    if(isLoading || isFetching || isRefetching){
        return <Loading/>
    }
    return (
        <Box className={classes.container}>
            {isEmpty ? (
                <Typography color='textPrimary' variant='h6'>데이터가 없습니다.</Typography>
            ) : (
                <TableContainer component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {['대표사진', 'ID', '닉네임', '소개 종류', '소개 정보', '소개 일시', '기본정보', '기타정보', '상태', '최근 접속일'].map(text => (
                                    <TableCell key={text} style={{ backgroundColor: theme.palette.background.paper }}>{text}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {introductions.map(introduction => (
                                <IntroductionItem key={introduction.id} {...introduction} onClickNickname={handleChangeModalUser} onClickImage={handleImageModal}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <Modal className={classes.modalContainer} open={imageModalOpened} onClose={() => handleImageModal()}>
                <ImageModal source={selectedImageSource} close={handleImageModal}/>
            </Modal>
        </Box>
    )
}

export default Introduction

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxHeight: '60vh',
        overflowY: 'scroll',
        paddingTop: 24
    },
    modalContainer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}))