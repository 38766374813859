import AuthorizationType from "../../models/api/enum/authorization"

const authorizationFilter = (authorization: AuthorizationType) => {
    switch(authorization){
        case 'A00':
            return '애플'
        case 'D00':
            return '디바이스'
        case 'E00':
            return '이메일'
        case 'F00':
            return '페이스북'
        case 'G00':
            return '구글'
        default:
            return ''
    }
}

export default authorizationFilter