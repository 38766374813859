import { Box, List, ListItem, makeStyles, Typography, TextField, Button } from '@material-ui/core'
import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import buzzEndpoints from '../../../../../api/buzz'
import { api } from '../../../../../api/common'
import { AuthorizationResponse } from '../../../../../models/api/common'
import AuthorizationType from '../../../../../models/api/enum/authorization'
import authorizationFilter from '../../../../../utils/filter/authorizationFilter'
import EditLayout from '../EditLayout'

interface Props {
    close: () => void
    authorizations: AuthorizationResponse[]
    selectedAuthType: AuthorizationType
    userId: number
    refetch: () => void
}

const RemoveSocialAuth: React.FC<Props> = forwardRef(({
    close, authorizations, selectedAuthType, userId, refetch
}, ref) => {

    const classes = useStyles()

    const [ hasAuth, setHasAuth ] = useState<boolean>(false)
    const [ email, setEmail ] = useState<string | undefined>('')
    const [ emailValidated, setEmailValidated ] = useState<boolean | undefined>(undefined)
    const [ otherAuthAccounts, setOtherAuthAccounts ] = useState<string[]>([])
    const [ identification, setIdentification ] = useState<string>('')

    const allAuthTypes = ['D00', 'E00', selectedAuthType]

    const socialNameEng = useMemo(() => {
        if(selectedAuthType==='G00'){
            return 'google'
        } else if(selectedAuthType==='F00'){
            return 'facebook'
        } else if(selectedAuthType==='A00'){
            return 'apple'
        } else {
            return 'google'
        }
    }, [selectedAuthType])

    const { mutate: validateEmail } = useMutation(
        () => api.post(`${buzzEndpoints.validateEmail()}`, { identification: identification }),
        {
            onSuccess: () => setEmailValidated(true),
            onError: () => setEmailValidated(false)
        }
    )
    const { mutate: removeSocial } = useMutation(
        () => api.post(`${buzzEndpoints.removeSocial(userId, socialNameEng)}`),
        {
            onSuccess: () => {
                close()
                refetch()
            }
        }
    )
    const { mutate: removeSocialWithEmail } = useMutation(
        () => api.post(`${buzzEndpoints.removeSocialWithEmail(userId, socialNameEng)}`),
        {
            onSuccess: () => {
                close()
                refetch()
            }
        }
    )
    const { mutate: createEmail } = useMutation(
        () => api.post(`${buzzEndpoints.addEmail(userId)}`, { identification: identification }),
        {
            onSuccess: () => {
                setHasAuth(true)
            }
        }
    )

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIdentification(e.target.value)
    }

    const handleAddEmail = () => {
        createEmail()
    }

    const removeSocialAuth = () => {
        if(hasAuth){
            removeSocial()
        } else {
            removeSocialWithEmail()
        }
    }

    useEffect(() => {
        authorizations.forEach(authorization => {
            if(authorization.type==='E00' || allAuthTypes.includes(authorization.type)){
                if(authorization.type!==selectedAuthType && authorization.type!=='D00'){
                    setHasAuth(true)
                }
            }
            setOtherAuthAccounts(accounts => [...accounts, authorization.type===selectedAuthType ? authorizationFilter(authorization.type) : ''])
        })
    }, [])

    return (
        <EditLayout title={`${authorizationFilter(selectedAuthType)} 해제`} subtitle={hasAuth ? '잔존하는 인증수단 있습니다' : '이메일 계정 추가가 필요합니다'} onSubmit={removeSocialAuth} disabled={!hasAuth} submitText='연동 해제' color='#d9534f' close={close}>
            <Box className={classes.contentBox}>
                <Typography color='textPrimary' variant='subtitle1' style={{ fontWeight: 'bold' }}>잔존 계정</Typography>
                <Box>
                    {hasAuth && <Typography color='textPrimary' variant='body2'>이메일 {email}</Typography>}
                    {!hasAuth && (
                        <Box className={classes.emailValidateBox}>
                            <Typography color='textPrimary' variant='body2'>이메일</Typography>
                            <Box className={classes.emailInputBox}>
                                <TextField
                                    placeholder='example@cupist.com'
                                    value={identification}
                                    onChange={handleChange}
                                    fullWidth
                                    error={emailValidated===undefined ? false : !emailValidated}
                                    helperText={emailValidated===undefined ? '이메일 계정을 설정해주세요' : emailValidated ? '등록 가능한 이메일 계정입니다' : '이미 존재하는 이메일 계정입니다'}
                                />
                                <Button className={classes.button} onClick={() => validateEmail()} disabled={(identification.length <= 0)}>확인</Button>
                            </Box>
                            <Box width='100%' style={{ float: 'right' }}>
                                <Button className={classes.button} onClick={handleAddEmail} disabled={!emailValidated}>이메일 추가</Button>
                            </Box>
                        </Box>
                    )}
                    {!hasAuth && <Typography color='textPrimary' variant='body2'>비밀번호가 000000으로 설정됩니다.</Typography>}
                    {email && otherAuthAccounts.map(account => account ? (
                        <Box>
                            <Typography color='textPrimary' variant='body2'>{account} 인증됨</Typography>
                        </Box>
                    ) : null)}
                </Box>
            </Box>
        </EditLayout>
    )
})

export default RemoveSocialAuth

const useStyles = makeStyles((theme) => ({
    contentBox: {
        padding: 24,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
    },
    emailValidateBox: {

    },
    emailInputBox: {
        display: 'flex',
        flexDirection: 'row'
    },
    button: {
        backgroundColor: theme.palette.action.selected,
        alignSelf: 'flex-start'
    }
}))