import { Box, makeStyles } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import React, { forwardRef, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import buzzEndpoints from '../../../../api/buzz'
import { api } from '../../../../api/common'
import GenderType from '../../../../models/api/enum/gender'
import EditLayout from './EditLayout'

interface Props {
    userId: number
    close: () => void
    refetch: () => void
    value: GenderType
}

const GenderEdit: React.FC<Props> = forwardRef(({
    userId, close, refetch, value
}, ref) => {

    const [ gender, setGender ] = useState<GenderType>(value)

    const classes = useStyles()

    const { mutate: changeGender } = useMutation(
        (data: { gender: GenderType }) => api.post(`${buzzEndpoints.changeGender(userId)}`, data).then(res => res.data),
        {
            onSuccess: () => {
                refetch()
                close()
            }
        }
    )

    const handleUpdate = () => {
        changeGender({
            gender: gender
        })
    }

    const handleGender = () => {
        setGender(gender => gender==='M' ? 'F' : 'M')
    }

    return (
        <EditLayout title='성별 수정' onSubmit={handleUpdate} close={close}>
            <Box className={classes.contentBox}>
                <ToggleButtonGroup value={gender} className={classes.buttonGroup} onChange={handleGender}>
                    <ToggleButton className={classes.button} value={'M'}>남</ToggleButton>
                    <ToggleButton className={classes.button} value={'F'}>여</ToggleButton>
                </ToggleButtonGroup>
            </Box>
        </EditLayout>
    )
})

export default GenderEdit

const useStyles = makeStyles((theme) => ({
    contentBox: {
        padding: 24,
        boxSizing: 'border-box',
        width: '100%'
    },
    buttonGroup: {
        width: '100%'
    },
    button: {
        width: '50%'
    }
}))