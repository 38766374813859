import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import React, { forwardRef } from 'react'

interface Props {
    close: () => void
    mutate: () => void
    nickname: string
    isFreeOpen: number
    openPoint: number
}

const CheckRefundModal: React.FC<Props> = forwardRef(({
    close, mutate, nickname, isFreeOpen, openPoint
}, ref) => {

    const classes = useStyles()

    return (
        <Box className={classes.container}>
            <Box className={classes.titleBox}>
                <Typography className={classes.title} variant='h6' color='textPrimary'>대화방 보상</Typography>
            </Box>
            <Box className={classes.contentBox}>
                <Typography color='textPrimary'>{nickname}님과의 대화방이 보상됩니다.{isFreeOpen ? '(무료 1회)' : `(${openPoint}젬)`}</Typography>
            </Box>
            <Box className={classes.buttonBox}>
                <Button className={classes.cancelButton} onClick={close}>취소</Button>
                <Button className={classes.submitButton} onClick={mutate}>확인</Button>
            </Box>
        </Box>
    )
})

export default CheckRefundModal

const useStyles = makeStyles((theme) => ({
    container: {
        width: '30vw',
        maxWidth: 800,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 16
    },
    buttonBox: {
        padding: '15px 24px',
        boxSizing: 'border-box',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        borderTop: `1px solid ${theme.palette.divider}`
    },
    cancelButton: {
        fontWeight: 'bold'
    },
    submitButton: {
        fontWeight: 'bold',
        backgroundColor: '#008c00',
        color: '#ffffff',
        "&:hover": {
            backgroundColor: '#008c00'
        }
    },
    contentBox: {
        padding: 24,
        boxSizing: 'border-box'
    },
    class: {
        
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 24px',
        boxSizing: 'border-box',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    title: {
        fontWeight: 'bold'
    },
}))