import React, { forwardRef, useEffect, useState } from 'react'
import EditLayout from './EditLayout'
import { makeStyles, Box, Typography, TextField, OutlinedInput, Button } from '@material-ui/core'
import buzzEndpoints from '../../../../api/buzz'
import relationFilter from '../../../../utils/filter/relationFilter'
import RelationType from '../../../../models/api/enum/relation'
import { useQuery } from 'react-query'
import { api } from '../../../../api/common'

interface Props {
    userId: number
    close: () => void
}

const Relationship: React.FC<Props> = forwardRef(({
    userId, close
}, ref) => {

    const classes = useStyles()

    const [ searchId, setSearchId ] = useState<string>('')

    const { data, refetch } = useQuery<{ relationship: RelationType, block: string }>(
        ['relationship', userId],
        () => api.get(`${buzzEndpoints.userRelationship(userId, searchId)}`).then(res => res.data),
        {
            enabled: false
        }
    )

    const handleSearch = () => {
        refetch()
    }

    const handleSubmit = () => {
        close()
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key==='Enter'){
            refetch()
        }
    }

    return (
        <EditLayout title='관계 조회' onSubmit={handleSubmit} submitText='취소' close={close}>
            <Box className={classes.container}>
                <Box className={classes.topBox}>
                    <Typography variant='body1' color='textPrimary'>유저 아이디</Typography>
                    <Box className={classes.inputBox}>
                        <TextField
                            placeholder='아이디'
                            value={searchId}
                            onChange={e => setSearchId(e.target.value)}
                            onKeyPress={handleKeyPress}
                            className={classes.input}
                            helperText={!Boolean(searchId) && '상대 유저 아이디를 입력해주세요'}
                        />
                        <Button onClick={handleSearch}>조회</Button>
                    </Box>
                </Box>
                <Box className={classes.contentBox}>
                    <Typography variant='body1' color='textPrimary'>관계</Typography>
                    <Box>
                        <Typography variant='body1' color='textPrimary'>{relationFilter(data?.relationship!) || ''}</Typography>
                        <Typography variant='body1' color='textPrimary'>{data?.block==='BothBlock' ? '서로 차단' : data?.block==='Block' ? '차단함' : data?.block==='Blocked' ? '차단 당함' : ''}</Typography>
                    </Box>
                </Box>
            </Box>
        </EditLayout>
    )
})

export default Relationship

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 24,
        width: '100%',
        boxSizing: 'border-box'
    },
    topBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    contentBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 24
    },
    inputBox: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative'
    },
    input: {
        padding: 5,
        marginRight: 10,
    }
}))