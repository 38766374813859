import { Box, Button, makeStyles, Paper, Switch, Typography, useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import buzzEndpoints from '../../../api/buzz'
import { api } from '../../../api/common'
import Layout from '../../../components/Layout'
import { ParameterResponse, SplashImageResponse } from '../../../models/api/buzz/parameter'
import BooleanType from '../../../models/api/enum/boolean'
import fullPathFilter from '../../../utils/filter/fullPathFilter'

const Application = () => {

    const classes = useStyles()

    const theme = useTheme()

    const [ isHideAppeal, setIsHideAppeal ] = useState<boolean>(false)
    const [ isHideIStoreReview, setIsHideIStoreReview ] = useState<boolean>(false)
    const [ isHideRating, setIsHideRating ] = useState<boolean>(false)
    const [ splashImage, setSplashImage ] = useState<ParameterResponse>({
        key: 'splash_image',
        value: '1',
        id: 0,
        createdAt: '',
        updatedAt: ''
    })
    const [ selectedSplashId, setSelectedSplashId ] = useState<number>(1)

    const { isLoading } = useQuery<ParameterResponse[]>(
        [],
        () => api.get(`${buzzEndpoints.getParameter()}`).then(res => res.data),
        {
            onSuccess: (data) => {
                setIsHideAppeal(data.find(flag => flag.key==='hide_appeal')?.value==='Y' ? true : false)
                setIsHideIStoreReview(data.find(flag => flag.key==='hide_i_store_review')?.value==='Y' ? true : false)
                setIsHideRating(data.find(flag => flag.key==='hide_rating')?.value==='Y' ? true : false)
                setSplashImage(data.find(flag => flag.key==='splash_image')!)
                setSelectedSplashId(Number(data.find(flag => flag.key==='splash_image')?.value))
            }
        }
    )

    const { mutate: updateFlag } = useMutation(
        (data: { key: string, value: BooleanType | string }) => api.post(`${buzzEndpoints.updateParameter()}`, data).then(res => res.data),
    )

    const handleChange = (type: string) => {
        switch(type){
            case 'hide_appeal':
                setIsHideAppeal(hide => !hide)
                updateFlag({
                    key: type,
                    value: isHideAppeal ? 'N' : 'Y'
                })
                break;
            case 'hide_i_store_review':
                setIsHideIStoreReview(hide => !hide)
                updateFlag({
                    key: type,
                    value: isHideIStoreReview ? 'N' : 'Y'
                })
                break;
            case 'hide_rating':
                setIsHideRating(hide => !hide)
                updateFlag({
                    key: type,
                    value: isHideRating ? 'N' : 'Y'
                })
                break;
            default:
                break;
        }
    }

    const updateSplash = () => {
        updateFlag({
            key: 'splash_image',
            value: String(selectedSplashId)
        }, {
            onSuccess: () => {
                setSplashImage(splashImage => ({...splashImage, value: String(selectedSplashId)}))
            }
        })
    }

    return (
        <Layout title='앱 설정' level='서비스 관리'>
            <Box className={classes.container}>
                <Paper className={classes.tableContainer}>
                    <Box className={classes.itemBox}>
                        <Typography variant='body1' color='textPrimary' className={classes.title}>커뮤니티 숨기기</Typography>
                        <Switch
                            color={isHideAppeal ? 'primary' : 'default'}
                            checked={isHideAppeal}
                            onChange={() => handleChange('hide_appeal')}
                        />
                    </Box>
                    <Box className={classes.itemBox}>
                        <Typography variant='body1' color='textPrimary' className={classes.title}>iOS 스토어 리뷰 숨기기</Typography>
                        <Switch
                            color={isHideIStoreReview ? 'primary' : 'default'}
                            checked={isHideIStoreReview}
                            onChange={() => handleChange('hide_i_store_review')}
                        />
                    </Box>
                    <Box className={classes.itemBox}>
                        <Typography variant='body1' color='textPrimary' className={classes.title}>평가 관련 정보 숨기기</Typography>
                        <Switch
                            color={isHideRating ? 'primary' : 'default'}
                            checked={isHideRating}
                            onChange={() => handleChange('hide_rating')}
                        />
                    </Box>
                    <Box className={classes.splashBox}>
                        <Typography variant='body1' color='textPrimary' className={classes.title}>스플래시 화면</Typography>
                        <Box className={classes.splashButtonBox}>
                            <Box className={classes.splashImageBox}>
                                {splashImage?.splashImageList?.map(splash => (
                                    <Box className={classes.imageLabelBox} key={splash.id}> 
                                        <img onClick={() => setSelectedSplashId(splash.id)} src={fullPathFilter(splash?.imagePath)} className={classes.splash} style={splash.id===selectedSplashId ? { boxShadow: '0 8px 9px -5px rgba(0,0,0,.2),0 15px 22px 2px rgba(0,0,0,.14),0 6px 28px 5px rgba(0,0,0,.12)' } : {}}/>
                                        <Box style={{ display: 'flex' }}>
                                            {splash.id===selectedSplashId ? (
                                                <Typography color='textPrimary'><b>{splash?.title}</b></Typography>
                                            ) : (
                                                <Typography color='textPrimary'>{splash?.title}</Typography>
                                            )}
                                            {splash.id===Number(splashImage.value) && <Typography><span style={{ color: theme.palette.secondary.main }}>(현재)</span></Typography>}
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                            <Box>
                                <Button onClick={updateSplash} className={classes.submitButton}>변경하기</Button>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Layout>
    )
}

export default Application

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    tableContainer: {
        borderRadius: 16,
        marginTop: 24,
        width: 800
    },
    itemBox: {
        padding: 24,
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    title: {
        fontWeight: 'bold'
    },
    splashBox: {
        padding: 24,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    splashImageBox: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: 50,
        
    },
    imageLabelBox: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 10,
        alignItems: 'center'
    },
    splashButtonBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
    },
    splash: {
        height: 320,
        objectFit: 'cover',
        cursor: 'pointer'
    },
    submitButton: {
        backgroundColor: '#4caf50',
        color: '#ffffff',
        "&:hover": {
            backgroundColor: '#4caf50',
        },
        marginTop: 10
    },
}))