import { Box, makeStyles, Menu, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import React, { forwardRef, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import buzzEndpoints from '../../../../api/buzz'
import { api } from '../../../../api/common'
import useInputs from '../../../../hooks/useInputs'
import EditLayout from './EditLayout'

interface Props {
    userId: number
    close: () => void
    gemRedesignVersion: number
}

const BuyGem: React.FC<Props> = forwardRef(({
    userId, close, gemRedesignVersion
}, ref) => {

    const isRedesignVersion = gemRedesignVersion % 2 === 0

    const classes = useStyles()

    const [ purchaseType, setPurchaseType ] = useState<'account'>('account')
    const [ purchaseItem, setPurchaseItem ] = useState({
        receiptType: '',
        productType: '',
        receiptCode: '',
        additionalPoint: 0,
        type: '',
        desc: ''
    })
    const [ additionalPoint, setAdditionalPoint ] = useState<string>('')
    const [ inAppData, setInAppData ] = useState<any[]>([])
    const [ accountData, setAccountData ] = useState<any[]>([])

    const { mutate: purchaseGemByAccount } = useMutation(
        (data: object) => api.post(`${buzzEndpoints.purchaseGemByAccount()}`, data).then(res => res.data),
        {
            onSuccess: () => close()
        }
    )

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAdditionalPoint(e.target.value)
    }

    const handleChangeType = (e: React.ChangeEvent<{ name?: string | undefined, value: unknown }>) => {
        setPurchaseType(e.target.value as 'account')
    }

    const handleChangeItem = (e: React.ChangeEvent<{ name?: string | undefined, value: unknown }>) => {

        setPurchaseItem(accountData[e.target.value as number] as any)
        setAdditionalPoint(accountData[e.target.value as number].additionalPoint)
    }

    const handleUpdate = () => {
        const data = {
            userId: userId,
            productType: purchaseItem.productType,
            additionalPoint: Number(additionalPoint)
        };
        purchaseGemByAccount(data)
    }


    useEffect(() => {
        if(gemRedesignVersion === 5){
            setInAppData(replaceDesc(gemRedesignVersion, v5InAppData, parseInAppType))
            setAccountData(replaceDesc(gemRedesignVersion,  v5AccountData, parseAccountType))
        }else{
            setInAppData(replaceDesc(gemRedesignVersion, isRedesignVersion ? v2InAppData : v1InAppData, parseInAppType))
            setAccountData(replaceDesc(gemRedesignVersion,  v5AccountData, parseAccountType))
        }

    }, [gemRedesignVersion, isRedesignVersion])

    return (
        <EditLayout title='젬 구매' onSubmit={handleUpdate} close={close} submitText='지급' color='#008c00'>
            <Box className={classes.contentBox}>
                <Select value={purchaseType} onChange={handleChangeType} fullWidth>
                    <MenuItem value='account'>무통장 구매</MenuItem>
                </Select>
                <Select className={classes.productSelectBox} fullWidth onChange={handleChangeItem}>
                    {accountData.map((data, index) => (
                        <MenuItem value={index} key={index}>{data.desc}</MenuItem>
                    ))}
                </Select>
                <TextField
                    className={classes.additionalPointInput}
                    fullWidth
                    value={additionalPoint}
                    placeholder='추가 지급 젬'
                    onChange={handleChange}
                />
            </Box>
        </EditLayout>
    )
})

export default BuyGem

const replaceDesc = function (gemRedesignVersion: number, array: any[], cb: any) {
    let filteredArray = [...array];

    if (gemRedesignVersion < 5) {
        filteredArray = filteredArray.filter((item) => !V5AccountNewProducts.includes(item.productType))
    }

    // 1,2 버전은 이전과 같음. 50배 적용 대상 x
    if (gemRedesignVersion <= 2) {
        return filteredArray;
    }

    return filteredArray.map(cb);
};

const useStyles = makeStyles((theme) => ({
    contentBox: {
        padding: 24,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 10
    },
    inputBox: {
        display: 'flex',
        flexDirection: 'row'
    },
    productSelectBox: {

    },
    additionalPointInput: {

    },
    codeBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    codeInput: {
        width: '23%'
    }
}))

const productType = {
    SP025: 5,
    SP026: 15,
    SP027: 40,
    SP028: 100,
    SP029: 5,
    SP030: 15,
    SP031: 40,
    SP032: 100,
    SP033: 3,
    SP034: 10,
    SP035: 30,
    SP036: 80,
    SP037: 3,
    SP038: 10,
    SP039: 30,
    SP040: 80,
    //v5
    SP049:3,
    SP050:8,
    SP051:20,
    SP052:40,
    SP053:80,
    SP054:3,
    SP055:8,
    SP056:20,
    SP057:40,
    SP058:80,
    // 이하 무통장
    AP008: 15,
    AP009: 40,
    AP010: 100,
    AP011: 300,
    AP012: 10,
    AP013: 30,
    AP014: 80,
    AP015: 240,
    //v5
    AP016: 3,
    AP017: 8,
    AP018: 20,
    AP019: 40,
    AP020: 80,
    /**
     * V5 new products
     */
    AP021: 160,
    AP022: 32,
    AP023: 11
};

const eventName = {
    SPW01: '웰컴 이벤트',
    SPW03: '웰컴 이벤트',
    SPP01: '첫 결제 이벤트',
    SPP03: '첫 결제 이벤트',
    SPW05: '웰컴 이벤트',
    SPW07: '웰컴 이벤트',
    SPP05: '첫 결제 이벤트',
    SPP07: '첫 결제 이벤트',
    // v5
    SPP09: '첫 결제 이벤트',
    SPP10: '첫 결제 이벤트',
    SPW09: '웰컴 이벤트',
    SPW10: '웰컴 이벤트',


};

const eventBonus = {
    SPW01: 2,
    SPW03: 12,
    SPP01: 5,
    SPP03: 12,
    SPW05: 1,
    SPW07: 9,
    SPP05: 1,
    SPP07: 9,
    //v5
    SPP09: 1,
    SPW10: 6,
    SPW09: 1,
    SPP10: 6,

};

//무통장 입금
const accountDataPrice = {
    AP008: 19000,
    AP009: 47000,
    AP010: 109000,
    AP011: 300000,
    AP012: 17000,
    AP013: 45000,
    AP014: 109000,
    AP015: 300000,
    //v5
    AP016: 7500,
    AP017: 17000,
    AP018: 39000,
    AP019: 69000,
    AP020: 119000,
    /**
     * V5 new products
     */
    AP021: 199000,
    AP022: 59000,
    AP023: 22000
};

const multiple = 50
const unit = '젬'

const parseInAppType = (obj: {
    eventProductType: keyof typeof eventName
    receiptType: string
    productType: keyof typeof productType
    additionalPoint: any
    desc: any
}) => {
    // 장비 타입
    let desc = '';
    if (obj.receiptType === 'I') {
        desc = 'iOS';
    } else {
        desc = 'Android';
    }

    // 이벤트 타입
    if (obj.eventProductType) {
        desc += ` ${eventName[obj.eventProductType]}`;
    }

    // 젬 재조정 여부
    desc += ` ${productType[obj.productType] * multiple}`;

    // 이벤트 타입
    if (obj.eventProductType) {
        desc += `+${eventBonus[obj.eventProductType] * multiple}`;
    }

    obj.additionalPoint = obj.additionalPoint;

    // 단위
    desc += unit;
    obj.desc = desc;
    return obj;
};

// 무통장 구매
const parseAccountType = (obj: {
    eventProductType: keyof typeof eventName
    productType: keyof typeof accountDataPrice
    additionalPoint: any
    desc: any
}) => {
    let desc = '';
    desc += `${(productType[obj.productType] * multiple).toLocaleString()}`;
    desc += unit;
    desc += `(${(accountDataPrice[obj.productType]).toLocaleString()}원)`;
    if (obj.productType === BOTTOMSHEET_ONLY_PRODUCT) {
        desc += ' - 바텀시트 전용'
    }
    obj.additionalPoint = obj.additionalPoint * 1;
    obj.desc = desc;
    return obj;
};

// 2022.12.08 기준 @qomi
// mutiple 적용   : desc
// mutiple 적용 x : additionalPoint

const v1InAppData = [
    {
        receiptType: 'A',
        productType: 'SP025',
        receiptCode: '',
        additionalPoint: 50,
        desc: 'Android 5젬',
        type: 'inapp'
    },
    {
        receiptType: 'A',
        productType: 'SP026',
        receiptCode: '',
        additionalPoint: 100,
        desc: 'Android 15젬',
        type: 'inapp'
    },
    {
        receiptType: 'A',
        productType: 'SP027',
        receiptCode: '',
        additionalPoint: 200,
        desc: 'Android 40젬',
        type: 'inapp'
    },
    {
        receiptType: 'A',
        productType: 'SP028',
        receiptCode: '',
        additionalPoint: 500,
        desc: 'Android 100젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP029',
        receiptCode: '',
        additionalPoint: 50,
        desc: 'IOS 5젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP030',
        receiptCode: '',
        additionalPoint: 100,
        desc: 'IOS 15젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP031',
        receiptCode: '',
        additionalPoint: 200,
        desc: 'IOS 40젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP032',
        receiptCode: '',
        additionalPoint: 500,
        desc: 'IOS 100젬',
        type: 'inapp'
    },
    {
        receiptType: 'A',
        productType: 'SP025',
        eventProductType: 'SPW01',
        eventBonus: 'android_bonus_1',
        receiptCode: '',
        additionalPoint: 50,
        desc: 'android 웰컴 이벤트 5+2젬',
        type: 'inapp'
    },
    {
        receiptType: 'A',
        productType: 'SP027',
        eventProductType: 'SPW03',
        eventBonus: 'android_bonus_3',
        receiptCode: '',
        additionalPoint: 300,
        desc: 'android 웰컴 이벤트 40+12젬',
        type: 'inapp'
    },
    {
        receiptType: 'A',
        productType: 'SP025',
        eventProductType: 'SPP01',
        eventBonus: 'android_bonus_1',
        receiptCode: '',
        additionalPoint: 50,
        desc: 'android 첫 결제 이벤트 5+2젬',
        type: 'inapp'
    },
    {
        receiptType: 'A',
        productType: 'SP027',
        eventProductType: 'SPP03',
        eventBonus: 'android_bonus_3',
        receiptCode: '',
        additionalPoint: 300,
        desc: 'android 첫 결제 이벤트 40+12젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP029',
        eventProductType: 'SPW01',
        eventBonus: 'ios_bonus_1',
        receiptCode: '',
        additionalPoint: 50,
        desc: 'ios 웰컴 이벤트 5+2젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP031',
        eventProductType: 'SPW03',
        eventBonus: 'ios_bonus_3',
        receiptCode: '',
        additionalPoint: 300,
        desc: 'ios 웰컴 이벤트 40+12젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP029',
        eventProductType: 'SPP01',
        eventBonus: 'ios_bonus_1',
        receiptCode: '',
        additionalPoint: 50,
        desc: 'ios 첫 결제 이벤트 5+2젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP031',
        eventProductType: 'SPP03',
        eventBonus: 'ios_bonus_3',
        receiptCode: '',
        additionalPoint: 300,
        desc: 'ios 첫 결제 이벤트 40+12젬',
        type: 'inapp'
    }
];
const v2InAppData = [
    {
        receiptType: 'A',
        productType: 'SP033',
        receiptCode: '',
        additionalPoint: 50,
        desc: 'Android 3젬',
        type: 'inapp'
    },
    {
        receiptType: 'A',
        productType: 'SP034',
        receiptCode: '',
        additionalPoint: 100,
        desc: 'Android 10젬',
        type: 'inapp'
    },
    {
        receiptType: 'A',
        productType: 'SP035',
        receiptCode: '',
        additionalPoint: 200,
        desc: 'Android 30젬',
        type: 'inapp'
    },
    {
        receiptType: 'A',
        productType: 'SP036',
        receiptCode: '',
        additionalPoint: 500,
        desc: 'Android 80젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP037',
        receiptCode: '',
        additionalPoint: 50,
        desc: 'IOS 3젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP038',
        receiptCode: '',
        additionalPoint: 100,
        desc: 'IOS 10젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP039',
        receiptCode: '',
        additionalPoint: 200,
        desc: 'IOS 30젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP040',
        receiptCode: '',
        additionalPoint: 500,
        desc: 'IOS 80젬',
        type: 'inapp'
    },
    {
        receiptType: 'A',
        productType: 'SP033',
        eventProductType: 'SPW05',
        eventBonus: 'android_bonus_1',
        receiptCode: '',
        additionalPoint: 100, // 웰컴이벤트 50젬 + 구매보너스 50젬
        desc: 'android 웰컴 이벤트 3+1젬',
        type: 'inapp'
    },
    {
        receiptType: 'A',
        productType: 'SP035',
        eventProductType: 'SPW07',
        eventBonus: 'android_bonus_3',
        receiptCode: '',
        additionalPoint: 650, // 웰컴이벤트 450젬 + 구매보너스 200젬
        desc: 'android 웰컴 이벤트 30+9젬',
        type: 'inapp'
    },
    {
        receiptType: 'A',
        productType: 'SP033',
        eventProductType: 'SPP05',
        eventBonus: 'android_bonus_1',
        receiptCode: '',
        additionalPoint: 100, // 웰컴이벤트 50젬 + 구매보너스 50젬
        desc: 'android 첫 결제 이벤트 3+1젬',
        type: 'inapp'
    },
    {
        receiptType: 'A',
        productType: 'SP035',
        eventProductType: 'SPP07',
        eventBonus: 'android_bonus_3',
        receiptCode: '',
        additionalPoint: 650, // 웰컴이벤트 450젬 + 구매보너스 200젬
        desc: 'android 첫 결제 이벤트 30+9젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP037',
        eventProductType: 'SPW05',
        eventBonus: 'ios_bonus_1',
        receiptCode: '',
        additionalPoint: 100, // 웰컴이벤트 50젬 + 구매보너스 50젬
        desc: 'ios 웰컴 이벤트 3+1젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP039',
        eventProductType: 'SPW07',
        eventBonus: 'ios_bonus_3',
        receiptCode: '',
        additionalPoint: 650, // 웰컴이벤트 450젬 + 구매보너스 200젬
        desc: 'ios 웰컴 이벤트 30+9젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP037',
        eventProductType: 'SPP05',
        eventBonus: 'ios_bonus_1',
        receiptCode: '',
        additionalPoint: 100, // 웰컴이벤트 50젬 + 구매보너스 50젬
        desc: 'ios 첫 결제 이벤트 3+1젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP039',
        eventProductType: 'SPP07',
        eventBonus: 'ios_bonus_3',
        receiptCode: '',
        additionalPoint: 650, // 웰컴이벤트 450젬 + 구매보너스 200젬
        desc: 'ios 첫 결제 이벤트 30+9젬',
        type: 'inapp'
    }
];
const v5InAppData = [
    {
        receiptType: 'A',
        productType: 'SP049',
        receiptCode: '',
        additionalPoint: 50,
        desc: 'Android 3젬',
        type: 'inapp'
    },
    {
        receiptType: 'A',
        productType: 'SP050',
        receiptCode: '',
        additionalPoint: 80,
        desc: 'Android 8젬',
        type: 'inapp'
    },
    {
        receiptType: 'A',
        productType: 'SP051',
        receiptCode: '',
        additionalPoint: 150,
        desc: 'Android 20젬',
        type: 'inapp'
    },
    {
        receiptType: 'A',
        productType: 'SP052',
        receiptCode: '',
        additionalPoint: 250,
        desc: 'Android 40젬',
        type: 'inapp'
    },
    {
        receiptType: 'A',
        productType: 'SP053',
        receiptCode: '',
        additionalPoint: 500,
        desc: 'Android 80젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP054',
        receiptCode: '',
        additionalPoint: 50,
        desc: 'IOS 3젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP055',
        receiptCode: '',
        additionalPoint: 80,
        desc: 'IOS 8젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP056',
        receiptCode: '',
        additionalPoint: 150,
        desc: 'IOS 20젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP057',
        receiptCode: '',
        additionalPoint: 250,
        desc: 'IOS 40젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP058',
        receiptCode: '',
        additionalPoint: 500,
        desc: 'IOS 80젬',
        type: 'inapp'
    },
   //이벤트 타입  추가
    {
        receiptType: 'A',
        productType: 'SP049',
        eventProductType: 'SPW09',
        eventBonus: 'gem_sky_welcome',
        receiptCode: '',
        additionalPoint: 100, // 웰컴이벤트 50젬 + 구매보너스 50젬
        desc: 'android 웰컴 이벤트 3+1젬',
        type: 'inapp'
    },
    {
        receiptType: 'A',
        productType: 'SP051',
        eventProductType: 'SPW10',
        eventBonus: 'gem_cyan_welcome',
        receiptCode: '',
        additionalPoint: 450, // 웰컴이벤트 300젬 + 구매보너스 150젬
        desc: 'android 웰컴 이벤트 20+6젬',
        type: 'inapp'
    },
    {
        receiptType: 'A',
        productType: 'SP049',
        eventProductType: 'SPP09',
        eventBonus: 'gem_sky_first',
        receiptCode: '',
        additionalPoint: 100, // 웰컴이벤트 50젬 + 구매보너스 50젬
        desc: 'android 첫 결제 이벤트 3+1젬',
        type: 'inapp'
    },
    {
        receiptType: 'A',
        productType: 'SP051',
        eventProductType: 'SPP10',
        eventBonus: 'gem_cyan_first',
        receiptCode: '',
        additionalPoint: 450, // 웰컴이벤트 300젬 + 구매보너스 150젬
        desc: 'android 첫 결제 이벤트 20+6젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP054',
        eventProductType: 'SPW09',
        eventBonus: 'gem_sky_welcome',
        receiptCode: '',
        additionalPoint: 100, // 웰컴이벤트 50젬 + 구매보너스 50젬
        desc: 'ios 웰컴 이벤트 3+1젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP056',
        eventProductType: 'SPW10',
        eventBonus: 'gem_cyan_welcome',
        receiptCode: '',
        additionalPoint: 450, // 웰컴이벤트 300젬 + 구매보너스 150젬
        desc: 'ios 웰컴 이벤트 20+6젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP054',
        eventProductType: 'SPP09',
        eventBonus: 'gem_sky_first',
        receiptCode: '',
        additionalPoint: 100, // 웰컴이벤트 50젬 + 구매보너스 50젬
        desc: 'ios 첫 결제 이벤트 3+1젬',
        type: 'inapp'
    },
    {
        receiptType: 'I',
        productType: 'SP056',
        eventProductType: 'SPP10',
        eventBonus: 'gem_cyan_first',
        receiptCode: '',
        additionalPoint: 450, // 웰컴이벤트 300젬 + 구매보너스 150젬
        desc: 'ios 첫 결제 이벤트 20+6젬',
        type: 'inapp'
    }
];

//무통장 입금


const v5AccountData = [
    {
        productType: 'AP016',
        additionalPoint: 15,
        desc: '3젬(7,500원)',
        type: 'account'
    }, {
        productType: 'AP017',
        additionalPoint: 40,
        desc: '8젬(17,000원)',
        type: 'account'
    }, {
        productType: 'AP018',
        additionalPoint: 100,
        desc: '20젬(39,000원)',
        type: 'account'
    },
    {
        productType: 'AP022',
        additionalPoint: 800,
        desc: '32젬(59,000원)',
        type: 'account'
    },
    {
        productType: 'AP019',
        additionalPoint: 200,
        desc: '40젬(69,000원)',
        type: 'account'
    },
    {
        productType: 'AP020',
        additionalPoint: 400,
        desc: '80젬(119,000원)',
        type: 'account'
    },
    {
        productType: 'AP021',
        additionalPoint: 800,
        desc: '160젬(199,000원)',
        type: 'account'
    },
    {
        productType: 'AP023',
        additionalPoint: 55,
        desc: '11젬(22,000원)',
        type: 'account'
    }
];

const V5AccountNewProducts = ['AP021', 'AP022', 'AP023']

const BOTTOMSHEET_ONLY_PRODUCT = 'AP023'