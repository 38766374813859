const freeHistoryContextFilter = (code: string) => {
    if (code === 'FH001') {
        return '자동 충전';
    } else if (code === 'FH002') {
        return '대화방 보상';
    } else if (code === 'FH003') {
        return '복귀 축하 좋아요';
    } else if (code === 'FH010') {
        return '관리자 지급';
    } else if (code === 'FH011') {
        return '관리자 회수';
    } else if (code === 'FH012') {
        return '허위 계정 보상';
    } else if (code === 'FH013') {
        return '관리자 대화방 보상';
    }

    return code;
}

export default freeHistoryContextFilter