import React, { useState } from 'react'
import { makeStyles, Avatar, ListItem, Button, Switch, PaletteType, IconButton } from '@material-ui/core'
import { Link, useHistory, withRouter, useLocation } from 'react-router-dom'
import PATHS from '../../constants/paths'
import { env } from '../../config'
import { ExitToApp } from '@material-ui/icons'
import useAuthToken from '../../hooks/useAuthToken'
import adminPermissionFilter from '../../utils/filter/adminPermissionFilter'
import Dev from '../common/Environment/Dev'
import Production from '../common/Environment/Production'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'

const Item: React.FC<{ to: string, label: string, pathname: string }> = ({
    to, label, pathname
}) => {

    const classes = useStyles()

    return (
        <ListItem className={pathname===(to) ? classes.categoryItemSelected : classes.categoryItem} component={Link} to={to}>
            <Button className={classes.categoryItemButton}>{label}</Button>
        </ListItem>
    )
}

const Sidebar = () => {

    const classes = useStyles()

    const history = useHistory()

    const location = useLocation()

    const { removeToken } = useAuthToken()

    const [ theme, setTheme ] = useState<PaletteType>(localStorage.getItem('theme') as PaletteType)
    const [ serverType, setServerType ] = useState<string>(localStorage.getItem('env') || 'dev')

    const onChangeTheme = () => {
        setTheme(theme => theme==='dark' ? 'light' : 'dark')
        localStorage.setItem('theme', theme==='dark' ? 'light' : 'dark')
        window.location.reload()
    }

    const handleLogout = () => {
        removeToken()
        history.push(PATHS.LOGIN)
    }

    if(location.pathname===PATHS.LOGIN || location.pathname===PATHS.HOME){
        return <Box></Box>
    }

    return (
        <Container>
            <EnvBox>
                {env.ENV_NAME==='dev' ? localStorage.getItem('env')==='staging' ? (
                    <></>
                ) : (
                    <Dev/>
                ) : (
                    <Production/>
                )}
            </EnvBox>
            <UserInfoBox onClick={() => history.push(PATHS.ME)}>
                <Avatar/>
                <Typography variant='body1' color='textPrimary'>{localStorage.getItem('nickname')}</Typography>
            </UserInfoBox>
            <Switch onChange={onChangeTheme} checked={theme==='dark'} color='default'/>
            {items.map(item => (
                <Box display='flex' flexDirection='column' key={item.category}>
                    {item.items.some(item => adminPermissionFilter(item.permission)) && <Typography variant='body1' color='textPrimary' padding='15px 0' fontWeight='bold'>{item.category}</Typography>}
                    {item.items.map(item => {
                        if(adminPermissionFilter(item.permission)){
                            return <Item key={item.label} label={item.label!} to={item.to!} pathname={location.pathname}/>
                        } else {
                            return null
                        }
                    })}
                </Box>
            ))}
            <Box width='100%' display='flex' justifyContent='flex-start' marginBottom='24px'>
                <IconButton onClick={handleLogout}>
                    <ExitToApp/>
                </IconButton>
            </Box>
        </Container>
    )
}

export default withRouter(Sidebar)

const Container = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRight: `1px solid ${theme.palette.divider}`,
    width: 280,
    boxSizing: 'border-box',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px',
    rowGap: 24,
    position: 'fixed',
    left: 0,
    top: 0,
    height: `100vh`,
    overflowY: 'scroll',
    paddingTop: 40,
}))

const EnvBox = styled('div')(({ theme }) => ({
    height: 40,
    width: 280,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 19
}))

const UserInfoBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    boxSizing: 'border-box',
    columnGap: 10,
    borderRadius: 16,
    backgroundColor: theme.palette.background.default,
    marginTop: 24,
    cursor: 'pointer'
}))

const useStyles = makeStyles((theme) => ({
    subHeader: {
        padding: '15px 0',
        fontWeight: 'bold'
    },
    categoryItem: {
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&:hover': {
            backgroundColor: theme.palette.action.disabled
        },
        borderRadius: 16
    },
    categoryItemSelected: {
        backgroundColor: theme.palette.action.disabled,
        borderRadius: 16
    },
    categoryItemButton: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
}))

const items = [
    {
        category: '가입 관리',
        items: [
            {
                label: '가입 승인 대기',
                to: PATHS.JOIN + PATHS.REVIEW,
                permission: 'JoinReviewPending'
            },
            {
                label: '가입 스킵 현황',
                to: PATHS.JOIN + PATHS.REVIEW + PATHS.HIDDEN,
                permission: 'JoinSkip'
            },
            {
                label: '가입 현황',
                to: PATHS.JOIN + PATHS.PROGRESS,
                permission: 'JoinState'
            },
            {
                label: '실시간 평가 현황',
                to: PATHS.JOIN + PATHS.RATING,
                permission: 'JoinRatingState'
            },
            {
                label: '계정 문의',
                to: PATHS.JOIN + PATHS.FEEDBACK,
                permission: 'JoinDeviceFeedback'
            }
        ]
    },
    {
        category: '회원 관리',
        items: [
            {
                label: '전체 회원',
                to: PATHS.USER + PATHS.LIST,
                permission: 'UserAll'
            },
            {
                label: '프로필 사진 변경 요청 대기',
                to: PATHS.USER + PATHS.PICTURE + PATHS.WAIT,
                permission: 'UserPictureConfirm'
            },
            {
                label: '프로필 사진 변경 요청 처리',
                to: PATHS.USER + PATHS.PICTURE + PATHS.DONE,
                permission: 'UserPictureHistory'
            },
            {
                label: '유저 신고 대기',
                to: PATHS.USER + PATHS.REPORT + PATHS.WAIT,
                permission: 'UserReports'
            },
            {
                label: '유저 신고 처리',
                to: PATHS.USER + PATHS.REPORT + PATHS.DONE,
                permission: 'UserReportHistory'
            },
            {
                label: '좋아요 내역',
                to: PATHS.USER + PATHS.LIKE,
                permission: 'UserSpecialLikeHistory'
            },
            {
                label: '크러쉬 내역',
                to: PATHS.USER + PATHS.SPECIAL_LIKE,
                permission: 'UserSpecialLikeHistory'
            },
            /*{
                label: '비활성 유저 현황',
                to: '/'
            },
            {
                label: '탈퇴 내역',
                to: '/'
            },
            */
            {
                label: '친구 초대 내역',
                to: PATHS.USER + PATHS.RECOMMEND,
                permission: 'UserRecommend'
            },
            {
                label: '이용정지 리스트',
                to: PATHS.USER + PATHS.BLOCK,
                permission: 'ServiceBlockList'
            },
            {
                label: '관리자 사진 인증 요청',
                to: PATHS.USER + PATHS.SELF_AUTHENTICATION + PATHS.REQUEST,
                permission: 'GetSelfAuthenticationList'
            },
            {
                label: '관리자 사진 인증 대기',
                to: PATHS.USER + PATHS.SELF_AUTHENTICATION + PATHS.WAIT,
                permission: 'GetSelfAuthenticationList'
            },
            {
                label: '관리자 사진 인증 결과',
                to: PATHS.USER + PATHS.SELF_AUTHENTICATION + PATHS.DONE,
                permission: 'GetSelfAuthenticationList'
            },
            {
                label: '셀프 사진 인증 대기',
                to: PATHS.USER + PATHS.SELF_AUTHENTICATION + PATHS.USER + PATHS.WAIT,
                permission: 'GetSelfAuthenticationList'
            },
            {
                label: '셀프 사진 인증 결과',
                to: PATHS.USER + PATHS.SELF_AUTHENTICATION + PATHS.USER + PATHS.DONE,
                permission: 'GetSelfAuthenticationList'
            },
            {
                label: '문자 인증 차단 리스트',
                to: PATHS.USER + PATHS.SMS_VERIFICATION + PATHS.BLOCK,
                permission: 'ServiceTargetEvent'
            }
        ]
    },
    {
        category: '컨텐츠 관리',
        items: [
            /*{
                label: '소셜 활성화 여부 내역',
                to: '/'
            },
            */
            {
                label: '피드 전체 리스트',
                to: PATHS.APPEAL + PATHS.LIST,
                permission: 'Appeal'
            },
            {
                label: '피드 대기 리스트',
                to: PATHS.APPEAL + PATHS.REPORT + PATHS.WAIT,
                permission: 'AppealReport'
            },
            {
                label: '피드 처리 리스트',
                to: PATHS.APPEAL + PATHS.REPORT + PATHS.DONE,
                permission: 'AppealReportHistory'
            },
            {
                label: '피드 댓글 리스트',
                to: PATHS.APPEAL + PATHS.COMMENT + PATHS.LIST,
                permission: 'Appeal'
            }
        ]
    },
    {
        category: '서비스 관리',
        items: [
            /*{
                label: '영수증 조회',
                to: '/'
            }*/
            {
                label: '차단관계 동기화',
                to: PATHS.SERVICE + PATHS.EXCLUSION,
                permission: 'RestoreExclusionRelationship'
            },
            {
                label: '앱 설정',
                to: PATHS.SERVICE + PATHS.APPLICATION,
                permission: 'ServiceApplicationSettings'
            },
            {
                label: '영수증 조회',
                to: PATHS.SERVICE + PATHS.RECEIPT,
                permission: 'ServiceSearchReceipt'
            },
            {
                label: '관리자 설정',
                to: PATHS.SERVICE + PATHS.ADMIN,
                permission: 'AdminOperatorExternal'
            },
            {
                label: '업데이트 유도',
                to: PATHS.SERVICE + PATHS.INDUCE,
                permission: 'ServiceInduceUpdate'
            },
            {
                label: '광고 설정',
                to: PATHS.SERVICE + PATHS.ADS,
                permission: 'ServiceAdSetting'
            },
            {
                label: '피쳐플래그',
                to: PATHS.SERVICE + PATHS.FLAG,
                permission: 'ServiceApplicationSettings'
            },
            {
                label: '서비스 유틸',
                to: PATHS.SERVICE + PATHS.UTIL,
                permission: 'ServiceApplicationSettings'
            },

        ]
    },
    {
        category: '이벤트 관리',
        items: [
            {
                label: '이벤트 배너 관리',
                to: PATHS.EVENT + PATHS.BANNER,
                permission: 'ServiceEventBanner'
            },
            {
                label: '특별 이벤트 관리',
                to: PATHS.EVENT + PATHS.SERVICE,
                permission: 'ServiceEvent'
            },
            {
                label: '타겟 이벤트 관리',
                to: PATHS.EVENT + PATHS.TARGET,
                permission: 'ServiceTargetEvent'
            }
        ]
    }
]
