import { Box, Button, IconButton, makeStyles, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React from 'react'

interface Props {
    title: string
    onSubmit: () => void
    multiple?: boolean
    onAddItem?: () => void
    submitText?: string
    close?: () => void
    color?: string
    subtitle?: string
    disabled?: boolean
}

const EditLayout: React.FC<Props> = ({
    children, title, onSubmit, multiple, onAddItem, submitText, close, color, subtitle, disabled = false
}) => {

    const classes = useStyles()

    return (
        <Box className={classes.container}>
            <Box className={classes.titleBox}>
                <Box>
                    <Typography variant='h6' color='textPrimary' className={classes.title}>{title}</Typography>
                    <Typography variant='subtitle2' color='textPrimary'>{subtitle}</Typography>
                </Box>
                <IconButton onClick={close}>
                    <Close/>
                </IconButton>
            </Box>
            {children}
            <Box className={classes.buttonBox}>
                {multiple && <Button className={classes.submitButton} onClick={() => onAddItem && onAddItem()}>추가</Button>}
                <Button className={classes.submitButton} onClick={onSubmit} disabled={disabled} style={color ? { backgroundColor: color, color: '#ffffff' } : {}}>{submitText || '확인'}</Button>
            </Box>
        </Box>
    )
}

export default EditLayout

const useStyles = makeStyles((theme) => ({
    container: {
        borderRadius: 16,
        backgroundColor: theme.palette.background.paper,
        maxWidth: 1000,
        width: '60vw'
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 24px',
        boxSizing: 'border-box',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    title: {
        fontWeight: 'bold',
    },
    buttonBox: {
        padding: '15px 24px',
        borderTop: `1px solid ${theme.palette.divider}`,
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    submitButton: {
        fontWeight: 'bold',
        "&:disabled": {
            backgroundColor: `${theme.palette.action.disabled}!important`,
            color: `${theme.palette.text.disabled}!important`
        }
    },
}))