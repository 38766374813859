import { makeStyles, Box, Typography, TextField } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import React, { forwardRef, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import buzzEndpoints from '../../../../api/buzz'
import { api } from '../../../../api/common'
import { gemVersions } from '../../../../constants/globalConstants'
import { FreeRequest, PointRequest } from '../../../../models/api/buzz/user'
import EditLayout from './EditLayout'

interface Props {
    name?: string
    close: () => void
    userId: number
    refetch: () => void
    gemRedesignVersion?: number
}

const GemEdit: React.FC<Props> = forwardRef(({
    name, userId, close, refetch, gemRedesignVersion
}, ref) => {

    const classes = useStyles()

    const [ toggle, setToggle ] = useState<'give' | 'take'>('give')
    const [ context, setContext ] = useState<string>('')
    const [ value, setValue ] = useState<number>(0)

    const onSuccess = () => {
        close()
        refetch()
    }

    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let regex = /^[0-9]*$/
        if(regex.test(e.target.value)){
            setValue(Number(e.target.value))
        }
    }

    const { mutate: freeLike } = useMutation(
        (data: FreeRequest) => api.post(`${buzzEndpoints.freeLike()}`, data).then(res => res.data),
        { onSuccess: () => onSuccess() }
    )
    const { mutate: freeConnection } = useMutation(
        (data: FreeRequest) => api.post(`${buzzEndpoints.freeConnection()}`, data).then(res => res.data),
        { onSuccess: () => onSuccess() }
    )
    const { mutate: givePoint } = useMutation(
        (data: PointRequest) => api.post(`${buzzEndpoints.givePoint()}`, data).then(res => res.data),
        { onSuccess: () => onSuccess() }
    )
    const { mutate: takePoint } = useMutation(
        (data: PointRequest) => api.post(`${buzzEndpoints.takePoint()}`, data).then(res => res.data),
        { onSuccess: () => onSuccess() }
    )

    const handleSubmit = () => {
        if(value>0){
            switch(name){
                case '젬 지급·회수':
                    toggle==='give' ? givePoint({ point: value, targetId: userId, storeTitle: context }) : takePoint({ point: value, targetId: userId, storeTitle: context }); return;
                case '좋아요 지급·회수':
                    freeLike({ action: toggle, freeCount: value, userId: userId }); return;
                case '대화 열기 지급·회수':
                    freeConnection({ action: toggle, freeCount: value, userId: userId }); return;
            }
        } else {
            
        }
    }

    return (
        <EditLayout title={name!} onSubmit={handleSubmit} close={close} color={'#008c00'}>
            <Box className={classes.container}>
                {(name==='젬 지급·회수') && (
                    <Box className={classes.gemBox}>
                        <Box className={classes.topBox}>
                            <Typography variant='body1' color='textPrimary'>이용 내역 표기</Typography>
                            <Typography variant='body1' color='textPrimary'>{gemVersions[gemRedesignVersion ? gemRedesignVersion - 1 : 0]}</Typography>
                        </Box>
                        <TextField
                            placeholder='표시할 내용 입력...'
                            fullWidth
                            value={context}
                            onChange={e => setContext(e.target.value)}
                        />
                    </Box>
                )}
                <Box className={classes.contentBox}>
                    <ToggleButtonGroup value={toggle} className={classes.toggleBox}>
                        <ToggleButton onClick={() => setToggle('give')} value='give' className={classes.toggleButtonGive} style={toggle==='give' ? { backgroundColor: '#449d44', color: '#ffffff' } : {}}>지급</ToggleButton>
                        <ToggleButton onClick={() => setToggle('take')} value='take' className={classes.toggleButtonTake} style={toggle==='take' ? { backgroundColor: '#c9302c', color: '#ffffff' } : {}}>회수</ToggleButton>
                    </ToggleButtonGroup>
                    <TextField
                        variant='outlined'
                        placeholder={'젬'}
                        className={classes.input}
                        inputProps={{
                            style: {
                                padding: '10px 15px'
                            }
                        }}
                        value={value}
                        onChange={handleValueChange}
                    />
                </Box>
            </Box>
        </EditLayout>
    )
})

export default GemEdit

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 24,
        width: '100%',
        boxSizing: 'border-box'
    },
    gemBox: {
        display: 'flex',
        flexDirection: 'column'
    },
    topBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 15
    },
    contentBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 40,
        marginTop: 24
    },
    toggleBox: {
        marginRight: 24,
        height: '100%'
    },
    toggleButtonGive: {
        "&:hover": {
            backgroundColor: '#449d44',
            color: '#ffffff'
        }
    },
    toggleButtonTake: {
        "&:hover": {
            backgroundColor: '#c9302c',
            color: '#ffffff'
        }
    },
    input: {
        height: '100%',
    }
}))