import { Box, Button, makeStyles, MenuItem, Modal, Select, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import useInputs from '../../../../../hooks/useInputs'
import { AdminEmployType } from '../../../../../models/api/buzz/admin'
import CryptoJS from 'crypto-js'
import { AdminResponse } from '../../../../../models/api/buzz'
import { useMutation } from 'react-query'
import { api } from '../../../../../api/common'
import potatoEndpoints from '../../../../../api/potato'
import { AdminPasswordResetRequest } from '../../../../../models/api/potato/auth'
import TextModal from '../../../../common/Modal/TextModal'

const getRandomPassword = () => {
    let str = ''
    for (let i = 0; i < 6; i++) {
        str += Math.floor(Math.random() * 10)
    }
    return str
}

interface Props {
    admin: Partial<AdminResponse>
}

const CreateAdminModal: React.FC<Props> = ({
    admin
}, ref) => {

    const classes = useStyles()

    const [ newPassword, setNewPassword ] = useState<string>('')
    const [ resetLoginCountsModalOpened, setResetLoginCountsModalOpened ] = useState<boolean>(false)
    const [ resetPasswordModalOpened, setResetPasswordModalOpened ] = useState<boolean>(false)
    
    const handleResetPasswordModal = () => {
        setResetPasswordModalOpened(open => !open)
    }
    
    const handleResetLoginCountsModal = () => {
        setResetLoginCountsModalOpened(open => !open)
    }    

    const { mutate: resetLoginCounts } = useMutation(
        () => api.delete(`${potatoEndpoints.resetLoginCount(admin.id!)}`),
        {
            onSuccess: handleResetLoginCountsModal
        }
    )
    const { mutateAsync: resetPassword } = useMutation(
        (data: AdminPasswordResetRequest) => api.put(`${potatoEndpoints.changePassword()}`, data),
        {
            onSuccess: handleResetPasswordModal
        }
    )

    const handleResetPassword = async () => {
        const password = getRandomPassword()
        await resetPassword({
            id: admin.id!,
            password: CryptoJS.enc.Hex.stringify(CryptoJS.SHA1(password))
        })
        setNewPassword(password)
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.titleBox}>
                <Typography className={classes.title} variant='h6' color='textPrimary'>관리자 계정 수정</Typography>
            </Box>
            <Box className={classes.contentBox}>
                <TextField placeholder='ID' value={admin.identification} disabled name='identification'/>
                <TextField placeholder='Nickname' value={admin.nickname} disabled name='nickname'/>
                <TextField placeholder='Email' value={admin.email} disabled name='email'/>
                <Select disabled defaultValue={admin.adminLevelId}>
                    <MenuItem value={3}>3: CR팀</MenuItem>
                    <MenuItem value={4}>4: 직원</MenuItem>
                    <MenuItem value={5}>5: 운영요원</MenuItem>
                </Select>
                <Select disabled defaultValue={admin.employType}>
                    <MenuItem value='internal'>내부 직원</MenuItem>
                    <MenuItem value='external'>외부 직원</MenuItem>
                </Select>
                <Button className={classes.button} onClick={() => resetLoginCounts()}>로그인 시도 횟수 초기화</Button>
                <Button className={classes.button} onClick={handleResetPassword}>비밀번호 초기화</Button>
            </Box>
            <Box className={classes.buttonBox}>
            </Box>
            <TextModal
                open={resetLoginCountsModalOpened}
                onSubmit={handleResetLoginCountsModal}
                onClose={handleResetLoginCountsModal}
                cancel={false}
                children={<Typography color='textPrimary'>{admin.nickname}의 로그인 시도 횟수가 초기화되었습니다.</Typography>}
            />
            <TextModal
                open={resetPasswordModalOpened}
                onSubmit={handleResetPasswordModal}
                onClose={handleResetPasswordModal}
                cancel={false}
                children={<Typography color='textPrimary'>{admin.nickname}의 비밀번호가 {newPassword}로 초기화되었습니다.</Typography>}
            />
        </Box>
    )
}

export default CreateAdminModal

const useStyles = makeStyles((theme) => ({
    modalContainer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        width: '60vw',
        maxWidth: 800,
        borderRadius: 16,
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.paper
    },
    titleBox: {
        padding: '15px 24px',
        width: '100%',
        boxSizing: 'border-box',
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    title: {
        fontWeight: 'bold'
    },
    buttonBox: {
        padding: '15px 24px',
        boxSizing: 'border-box',
        borderTop: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    button: {
        backgroundColor: theme.palette.action.selected,
        alignSelf: 'flex-start'
    },  
    cancelButton: {

    },
    submitButton: {

    },
    contentBox: {
        padding: 24,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 24
    },
}))