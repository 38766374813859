import { Box, Button, FormControl, FormGroup, InputLabel, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { AttachFile } from '@material-ui/icons'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import React, { forwardRef, useState } from 'react'
import { useMutation } from 'react-query'
import buzzEndpoints from '../../../../api/buzz'
import { api } from '../../../../api/common'
import { env } from '../../../../config'
import { BannerResponse } from '../../../../models/api/buzz/banner'
import BooleanType from '../../../../models/api/enum/boolean'
import fullPathFilter from '../../../../utils/filter/fullPathFilter'

interface Props extends Partial<BannerResponse> {
    type: 'create' | 'update'
    close: () => void
    refetch: () => void
}

const BannerModal: React.FC<Props> = forwardRef(({
    type, close, isProgress: isProgressPrev, lang: langPrev, title: titlePrev, bannerImage: bannerImagePrev, webpage: webpagePrev, contentsImage: contentsImagePrev, id, refetch
}, ref) => {

    const classes = useStyles()

    const [ bannerData, setBannerData ] = useState({
        isProgress: isProgressPrev || 'N',
        lang: langPrev || 'ko',
        title: titlePrev || '',
        bannerImagePreview: bannerImagePrev || '',
        contentsImagePreview: contentsImagePrev || '',
        bannerImage: null as any,
        contentsImage: null as any,
        webpage: webpagePrev || ''
    })
    const [ webpageError, setWebpageError ] = useState<boolean>(false)

    const { isProgress, lang, title, bannerImagePreview, contentsImagePreview, bannerImage, contentsImage, webpage } = bannerData

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setBannerData(data => ({
            ...data,
            [name]: value
        }))
        if(name==='webpage'){
            if(value.includes('https://') || value.includes('http://')){
                setWebpageError(false)
            } else {
                setWebpageError(true)
            }
        }   
    }

    const handleMutate = () => {
        if(!webpage && (!contentsImage && !contentsImagePreview)){
            alert('index.html 파일 혹은 Url 주소는 필수여야 합니다.');
        } else if(webpage && (contentsImage || contentsImagePreview)){
            alert('웹페이지와 url 주소는 동시에 보낼 수 없습니다');
        } else if(!bannerImage && !bannerImagePreview){
            alert('배너이미지는 필수입니다');
        } else if(webpageError){

        } else {
            let myHeaders = new Headers()
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`)
            let formData = new FormData()
            formData.append('id', String(id!))
            formData.append('is_progress', isProgress)
            formData.append('lang', lang)
            formData.append('banner_image', bannerImage || bannerImagePreview)
            formData.append('webpage', contentsImage || contentsImagePreview)
            formData.append('title', title)
            formData.append('webpage_url', webpage)
            let requestOptions: any = {
                method: 'POST',
                headers: myHeaders,
                body: formData,
                redirect: 'follow'
            };
            if(type==='create'){
                fetch(env.BASE_API_URL + buzzEndpoints.createBanner(), requestOptions)
                .then(response => response.text())
                .then(result => {
                    close()
                    refetch()
                })
                .catch(error => console.log('error', error));
            } else {
                fetch(env.BASE_API_URL + buzzEndpoints.updateBanner(id!), requestOptions)
                .then(response => response.text())
                .then(result => {
                    close()
                    refetch()
                })
                .catch(error => console.log('error', error));
            }
        }
    }

    const handleBannerImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.files?.[0]){
            const file = e.target.files[0]
            if(file.name==='banner.png'){
                let reader = new FileReader()
                setBannerData(data => ({...data, bannerImage: file}))
                reader.readAsDataURL(file)
                reader.onload = () => {
                    setBannerData(data => ({...data, bannerImagePreview: reader.result as string}))
                }
            } else {
                alert('배너 이미지 파일 이름은 banner.png 여야 합니다.')
            }
        }
    }

    const handleContentsImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.files?.[0]){
            const file = e.target.files[0]
            let reader = new FileReader()
            setBannerData(data => ({...data, contentsImage: file}))
            reader.readAsDataURL(file)
            reader.onload = () => {
                setBannerData(data => ({...data, contentsImagePreview: reader.result as string}))
            }
        }
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.titleBox}>
                <Typography variant='h6' color='textPrimary' className={classes.title}>단일 항목 조회</Typography>
            </Box>
            <Box className={classes.contentBox}>
                <Box className={classes.itemBox}>
                    <Typography variant='subtitle1' color='textPrimary'>상태</Typography>
                    <ToggleButtonGroup exclusive value={isProgress} onChange={(e, value) => setBannerData(data => ({...data, isProgress: value}))}>
                        <ToggleButton value='Y'>진행</ToggleButton>
                        <ToggleButton value='N'>중지</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <Box className={classes.itemBox}>
                    <Typography variant='subtitle1' color='textPrimary'>국가</Typography>
                    <FormControl>
                        <InputLabel>국가명</InputLabel>
                        <Select value={lang} onChange={e => setBannerData(data => ({...data, lang: e.target.value as string}))}>
                            <MenuItem value='ko'>한국</MenuItem>
                            <MenuItem value='en'>미국</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box className={classes.itemBox}>
                    <Typography variant='subtitle1' color='textPrimary'>제목</Typography>
                    <TextField 
                        fullWidth
                        value={title}
                        name='title'
                        onChange={handleChange}    
                    />
                </Box>
                <Box className={classes.itemBox}>
                    <Typography variant='subtitle1' color='textPrimary'>배너이미지</Typography>
                    <img src={bannerImagePreview || fullPathFilter(bannerImagePrev)} className={classes.bannerImage}/>
                    <Button component='label' startIcon={<AttachFile/>} className={classes.button}>
                        banner.png파일만 가능합니다
                        <input
                            hidden
                            type='file'
                            onChange={handleBannerImage}
                        />
                    </Button>
                </Box>
                <Box className={classes.itemBox}>
                    <Typography variant='subtitle1' color='textPrimary'>*웹페이지</Typography>
                    <img src={contentsImagePreview || fullPathFilter(contentsImagePrev)} className={classes.bannerImage}/>
                    <Button component='label' startIcon={<AttachFile/>} className={classes.button}>
                        웹페이지 업로드시 index.html은 필수입니다
                        <input
                            hidden
                            type='file'
                            onChange={handleContentsImage}
                        />
                    </Button>
                </Box>
                <Box className={classes.itemBox}>
                    <TextField
                        label='URL 주소 입력(http 포함, Full url을 입력해주세요)'
                        value={webpage}
                        name='webpage'
                        onChange={handleChange}
                        error={webpageError}
                        helperText={webpageError ? '웹페이지 주소에는 http://, 또는 https:// 가 붙어야 합니다.' : ''}
                    />
                </Box>
            </Box>
            <Box className={classes.buttonBox}>
                <Button onClick={handleMutate}>{type==='create' ? '등록' : '수정'}</Button>
                <Button onClick={close}>취소</Button>
            </Box>
        </Box>
    )
})

export default BannerModal

const useStyles = makeStyles((theme) => ({
    container: {
        width: '60vw',
        maxWidth: 800,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 16,
        backgroundColor: theme.palette.background.paper,
        maxHeight: '90vh',
        overflowY: 'scroll'
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 24px',
        boxSizing: 'border-box',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    title: {
        fontWeight: 'bold'
    },
    contentBox: {
        padding: 24,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 15
    },
    buttonBox: {
        display: 'flex',
        padding: 24,
        justifyContent: 'flex-end',
        boxSizing: 'border-box',
        columnGap: 10
    },
    itemBox: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 10
    },
    button: {
        textTransform: 'none',
        backgroundColor: theme.palette.action.selected
    },
    bannerImage: {
        width: '100%',
        objectFit: 'cover'
    }
}))