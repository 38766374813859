import GenderType from "../../models/api/enum/gender"

const genderFilter = (gender: GenderType) => {
    switch(gender){
        case 'M':
            return '남'
        case 'F':
            return '여'
    }
}

export default genderFilter