import { Box, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useTheme } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import buzzEndpoints from '../../../api/buzz'
import { BuzzPaginationResponse } from '../../../models/api/buzz'
import { StoreHistoryResponse } from '../../../models/api/buzz/storeHistory'
import Loading from '../Loading'
import storeHistoryFilter from '../../../utils/filter/storeHistoryFilter'
import { Pagination } from '@material-ui/lab'
import usePagination from '../../../hooks/usePagination'
import { useQuery } from 'react-query'
import { api } from '../../../api/common'
import kstFilter from '../../../utils/filter/kstFilter'

const HistoryItem: React.FC<StoreHistoryResponse> = ({
    createdAt, context, increase, decrease, remainPoints
}) => {
    return (
        <TableRow>
            <TableCell>{kstFilter(createdAt)}</TableCell>
            <TableCell>{storeHistoryFilter(context, increase).includes('GLAM_BONUS_') ? `Braze를 통한 결제 보너스 ${increase}젬` : storeHistoryFilter(context, increase)}</TableCell>
            <TableCell style={{ color: increase ? '#2DC100' : '#E11A1A' }}>{increase ? `+${increase}` : `-${decrease}`}</TableCell>
            <TableCell>{remainPoints.remains}</TableCell>
        </TableRow>
    )
}

interface Props {
    userId: number
}

const History: React.FC<Props> = ({
    userId
}) => {

    const { page, setPage, pageLength, setPageLength } = usePagination({
        initalPage: 1
    })
    
    const [ histories, setHistories ] = useState<StoreHistoryResponse[]>([])

    const { isLoading, isFetching, isRefetching } = useQuery<BuzzPaginationResponse<StoreHistoryResponse>>(
        ['storeHistories', userId, page],
        async () => api.get(`${buzzEndpoints.storeHistoryDetail(userId, page)}`).then(res => res.data),
        {
            onSuccess: (data) => {
                setHistories(data.data)
                setPageLength(data.lastPage)
            }
        }
    )

    const classes = useStyles()

    const isEmpty = useMemo(() => {
        if(!isLoading && !isFetching && !isRefetching && !histories.length){
            return true
        } else {
            return false
        }
    }, [isLoading, isFetching, isRefetching, histories])

    if(isLoading || isFetching || isRefetching){
        return <Loading/>
    }
    return (
        <Box className={classes.container}>
            {isEmpty ? (
                <Typography color='textPrimary' variant='h6'>데이터가 없습니다.</Typography>
            ) : (
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {['일시', '경로', '내역', '보유 젬'].map(text => (
                                    <TableCell key={text} className={classes.tableHeadCell}>{text}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {histories.map(history => (
                                <HistoryItem key={history.id} {...history}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {!isEmpty && (
                <Pagination
                    count={pageLength}
                    page={page}
                    onChange={(_, value) => setPage(value)}
                    className={classes.paginationBox}
                />
            )}
        </Box>
    )
}

export default History

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: 24
    },
    tableContainer: {
        height: '45vh',
        overflowY: 'scroll'
    },
    paginationBox: {
        padding: 24,
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    tableHeadCell: {
        backgroundColor: theme.palette.background.paper
    }
}))