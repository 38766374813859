
export const getPhoneNumber = ((phoneValue: string) => {
    if(/^1/.test(phoneValue)){
        return `+82${phoneValue}`
    } else if(/^0/.test(phoneValue)){
        return `+82${phoneValue.substring(1)}`
    }  else if(/^821/.test(phoneValue)){
        return `+${phoneValue}`
    } else if(/^820/.test(phoneValue)){
        return `+82${phoneValue.substring(3)}`
    }else{
        return phoneValue
    }
})