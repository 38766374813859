import { Box, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { forwardRef, useEffect } from 'react'
import { useMutation } from 'react-query'
import buzzEndpoints from '../../../../api/buzz'
import { api } from '../../../../api/common'
import useInputs from '../../../../hooks/useInputs'
import EditLayout from './EditLayout'

interface Props {
    name?: string
    value?: string | number
    close: () => void
    refetch: () => void
    userId: number
}

const InputEdit: React.FC<Props> = forwardRef(({
    name, value, close, refetch, userId
}, ref) => {

    const classes = useStyles()

    const [ { newValue }, onChange ] = useInputs({
        newValue: value || ''
    })

    const onSuccess = () => {
        close()
        refetch()
    }

    const { mutate: updateUserProfile } = useMutation(
        () => api.post(`${buzzEndpoints.updateUserProfile(userId)}`, name==='직장' ? { company: newValue } : name==='학교' ? { school: newValue } : {}),
        {
            onSuccess: onSuccess
        }
    )
    const { mutate: sendNotification } = useMutation(
        () => api.post(`${buzzEndpoints.sendNotification()}`, { targetId: userId, message: newValue }),
        {
            onSuccess: () => close()
        }
    )
    const { mutate: changeNickname } = useMutation(
        () => api.post(`${buzzEndpoints.userChangeNickname(userId)}`, { nickname: newValue }),
        {
            onSuccess: onSuccess
        }
    )

    const handleUpdate = () => {
        switch(name){
            case '직장':
                updateUserProfile(); return;
            case '학교':
                updateUserProfile(); return;
            case '알림 전송':
                sendNotification(); return;
            case '닉네임':
                changeNickname(); return;
            case '호스트 해지':
                return;
        }
    }

    useEffect(() => {
        if(name==='닉네임'){
            if(value==='닉네임 없음'){
                onChange({
                    target: {
                        name: 'newValue',
                        value: ''
                    }
                })
            }
        }
    }, [])

    return (
        <EditLayout title={`${name} 설정`} onSubmit={handleUpdate} close={close} submitText={name==='알림 전송' ? '전송' : '설정'} color='#008c00'>
            <Box className={classes.contentBox}>
                <TextField
                    className={classes.contentInput}
                    value={newValue}
                    placeholder={name}
                    onChange={onChange}
                    name='newValue'
                    fullWidth
                />
            </Box>
        </EditLayout>
    )
})

export default InputEdit

const useStyles = makeStyles((theme) => ({
    contentBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 24,
        boxSizing: 'border-box'
    },
    contentInput: {

    }
}))