import { Box, Button, makeStyles, TextareaAutosize, Typography } from '@material-ui/core'
import React, { forwardRef, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import buzzEndpoints from '../../../../api/buzz'
import { api } from '../../../../api/common'
import useInputs from '../../../../hooks/useInputs'
import EditLayout from './EditLayout'

interface Props {
    name?: string
    value?: string | number
    userId: number
    close: () => void
    refetch: () => void
}

const TextareaEdit: React.FC<Props> = forwardRef(({
    name, value, userId, close, refetch
}, ref) => {

    const classes = useStyles()

    const [ newValue, setNewValue ] = useState<string>(value as string || '')

    const { mutate: updateMessage } = useMutation(
        () => api.post(`${buzzEndpoints.updateUserMessage(userId)}`, { message: newValue }),
        {
            onSuccess: () => {
                close()
                refetch()
            }
        }
    )
    const { mutate: updateStatus } = useMutation(
        () => api.post(`${buzzEndpoints.updateUserNearbyStatus(userId)}`, { activityStatus: newValue }),
        {
            onSuccess: () => {
                close()
                refetch()
            }
        }
    )

    const handleUpdate = () => {
        if(name==='동네친구'){
            updateStatus()
        } else if(name==='소개') {
            updateMessage()
        }
    }

    return (
        <EditLayout title={`${name} 설정`} onSubmit={handleUpdate} close={close}>
            <Box className={classes.contentBox}>
                <TextareaAutosize
                    value={newValue}
                    onChange={(e) => setNewValue(e.target.value)}
                    name='newValue'
                    className={classes.textarea}
                    maxRows={8}
                    minRows={8}
                    maxLength={name==='소개' ? 400 : 60}
                />
                <Typography color='textPrimary' variant='subtitle1' className={classes.maxLength}>{newValue.toLocaleString().length}/{name==='소개' ? '400' : '60'}</Typography>
            </Box>
        </EditLayout>
    )
})

export default TextareaEdit

const useStyles = makeStyles((theme) => ({
    contentBox: {
        padding: 24,
        width: '100%',
        boxSizing: 'border-box',
        position: 'relative'
    },
    textarea: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        resize: 'none',
        padding: 12,
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'inherit',
        fontSize: 16,
        color: theme.palette.text.primary
    },
    maxLength: {
        position: 'absolute',
        bottom: 36,
        right: 36
    },
    buttonBox: {
        padding: 24,
        borderTop: `1px solid ${theme.palette.divider}`,
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    submitButton: {
        fontWeight: 'bold',
    },
}))