import { IconButton, makeStyles, Box, Typography, Button, Avatar } from '@material-ui/core'
import { Visibility } from '@material-ui/icons'
import React from 'react'
import { AdminActionType, AppealCommentResponse } from '../../../../models/api/buzz/appeal'
import appealKstFilter from '../../../../utils/filter/appealKstFilter'
import dateFilter from '../../../../utils/filter/dateFilter'
import fullPathFilter from '../../../../utils/filter/fullPathFilter'
import kstFilter from '../../../../utils/filter/kstFilter'

interface Props extends AppealCommentResponse {
    onClickAction: (id: number, action: AdminActionType, lastAction: AdminActionType) => void
    onClick: (id: number, commentId: number) => void
    onClickUser: (id: number) => void
    onClickMark: (id: number) => void
    isMarked: boolean
}

const AppealCommentListItem: React.FC<Props> = ({
    body, createdAt, user, parentId, id, isBrowseHidden, onClickAction, lastAdminAction, onClick, appealId, onClickUser, onClickMark, isMarked
}) => {

    const classes = useStyles()

    return (
        <Box>
            {isMarked && <Box className={classes.markedLine} id='mark'/>}
            <Box display='flex' gridColumnGap={24} className={classes.container} alignItems='center'>
                <IconButton onClick={() => onClickMark(id)}>
                    <Visibility color={isMarked ? 'action' : 'disabled'}/>
                </IconButton>
                <Box className={classes.contentBox} display='flex' justifyContent='space-between' onClick={() => onClick(appealId, id)}>
                    <Typography variant='body2' color='textSecondary'>{body}</Typography>
                    <Box className={classes.commentInfoBox} display='flex' gridColumnGap={12}>
                        <Typography color='textSecondary' variant='body2' className={classes.bold}>{appealKstFilter(createdAt)}</Typography>
                        <Typography color='textSecondary' variant='body2' className={classes.bold} onClick={e => { e.stopPropagation(); onClickUser(user?.id) }}>{user?.nickname}</Typography>
                        <Avatar src={fullPathFilter(user?.pictures?.[0]?.pathSmall)} className={classes.userImage} onClick={e => { e.stopPropagation(); onClickUser(user?.id) }}/>
                        <Typography color='textSecondary' variant='body2' className={classes.bold}>{parentId ? '대댓글' : '댓글'}# {id}</Typography>
                        {isBrowseHidden && <Typography color='textSecondary' variant='body2'>(숨겨짐)</Typography>}
                        <Box display='flex' gridColumnGap={8} className={classes.buttonBox}>
                            <Button onClick={e => { e.stopPropagation(); onClickAction(id, 'Default', lastAdminAction) }} className={classes.button} style={lastAdminAction==='Default' ? { backgroundColor: '#1976d2', color: '#ffffff' } : {}}>기본</Button>
                            <Button onClick={e => { e.stopPropagation(); onClickAction(id, 'Approval', lastAdminAction) }} className={classes.button} style={lastAdminAction==='Approval' ? { backgroundColor: '#4caf50', color: '#ffffff' } : {}}>인증</Button>
                            <Button onClick={e => { e.stopPropagation(); onClickAction(id, 'Hide', lastAdminAction) }} className={classes.button} style={lastAdminAction==='Hide' ? { backgroundColor: '#fb8c00', color: '#ffffff' } : {}}>숨김</Button>
                            <Button onClick={e => { e.stopPropagation(); onClickAction(id, 'Delete', lastAdminAction) }} className={classes.button} style={lastAdminAction==='Delete' ? { backgroundColor: '#ff5252', color: '#ffffff' } : {}}>삭제</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default AppealCommentListItem

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginBottom: 12
    },
    contentBox: {
        padding: '10px 14px',
        boxSizing: 'border-box',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
        width: '100%',
        cursor: 'pointer'
    },
    commentInfoBox: {

    },
    bold: {
        fontWeight: 'bold'
    },
    buttonBox: {

    },
    button: {
        padding: 0,
        backgroundColor: theme.palette.action.selected,
        minWidth: 50,
        fontSize: '0.75rem'
    },
    userImage: {
        width: 18,
        height: 18,
        cursor: 'pointer'
    },
    markedLine: {
        backgroundColor: 'red',
        height: 4,
        width: '100%',
        marginBottom: 10
    }
}))