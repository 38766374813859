import { Box, Button, makeStyles, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout'
import { AdminResponse } from '../../../models/api/buzz'
import AdminListItem from '../../../components/Service/Admin/AdminListItem'
import { PersonAdd } from '@material-ui/icons'
import SearchInput from '../../../components/SearchInput'
import CreateAdminModal from '../../../components/Service/Admin/Modal/CreateAdminModal'
import AdminDetailModal from '../../../components/Service/Admin/Modal/AdminDetailModal'
import { AdminEmployType, AdminRequestParams } from '../../../models/api/buzz/admin'
import { useQuery, useMutation } from 'react-query'
import { api } from '../../../api/common'
import buzzEndpoints from '../../../api/buzz'
import { getParams } from '../../../utils/api'
import { ParameterResponse } from '../../../models/api/buzz/parameter'
import FlagListItem from '../../../components/Service/Flag/FlagListItem'
import BooleanType from '../../../models/api/enum/boolean'
import FeatureFlagModal from '../../../components/Service/Modal/FeatureFlagModal'

const item = [
    { label: '이메일', value: 'email' },
    { label: '아이디', value: 'identification' },
    { label: 'No.', value: 'id' }
]

const Flag = () => {

    const classes = useStyles()

    const [ flags, setFlags ] = useState<ParameterResponse[]>([])
    const [ flagModalOpened, setFlagModalOpened ] = useState<boolean>(false)
    const [ flagInfo, setFlagInfo ] = useState<{
        type: 'create' | 'update',
        title: string
        description?: string
        value: BooleanType
    }>({
        type: 'create',
        title: '',
        description: '',
        value: 'N'
    })

    const { isLoading } = useQuery<ParameterResponse[]>(
        [],
        () => api.get(`${buzzEndpoints.getParameter()}`).then(res => res.data),
        {
            onSuccess: (data) => {
                setFlags(data)
            }
        }
    )

    const { mutate: updateFlag } = useMutation(
        (data: { key: string, value: BooleanType, description?: string }) => api.post(`${buzzEndpoints.updateParameter()}`, data).then(res => res.data),
    )
    const { mutate: createFlag } = useMutation(
        (data: { key: string, description?: string }) => api.post(`${buzzEndpoints.getParameter()}`, {...data, value: 'N'}).then(res => res.data)
    )

    const handleFlagModal = (type?: 'create' | 'update', title?: string, description?: string, value?: BooleanType) => {
        setFlagInfo({ 
            type: type || 'create', 
            title: title || '', 
            description: description,
            value: value || 'N'
        })
        setFlagModalOpened(open => !open)
    }
    const handleActivate = (key: string, value: BooleanType) => {
        updateFlag({
            key: key, 
            value: value==='N' ? 'Y' : 'N'
        }, {
            onSuccess: () => setFlags(flags => flags.map(flag => flag.key===key ? {...flag, value: flag.value==='N' ? 'Y' : 'N'} : flag))
        })
    }
    const handleSubmit = (type: 'create' | 'update', key: string, description: string, value?: BooleanType) => {
        if(type==='create'){
            createFlag({
                key: key,
                description: description
            }, {
                onSuccess: () => {
                    handleFlagModal()
                    setFlags(flags => [{ key: key, description: description, value: 'N', createdAt: '', updatedAt: '', id: 0 }, ...flags])
                }
            })
        } else {
            updateFlag({
                key: key,
                description: description || ' ',
                value: value || 'N'
            }, {
                onSuccess: () => {
                    handleFlagModal()
                    setFlags(flags => flags.map(flag => flag.key===key ? {...flag, description: description} : flag))
                }
            })
        }
    }

    return (
        <Layout title='피쳐플래그' level='서비스 관리' loading={isLoading}>
            <Box className={classes.container}>
                <Box className={classes.header}>
                    <Button className={classes.addButton} onClick={() => handleFlagModal('create', '', '')}>추가</Button>
                </Box>
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {['피쳐플래그 ID', '설명', '활성 여부'].map(text => (
                                    <TableCell key={text} className={classes.tableHeadCell}>{text}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {flags.map(flag => (
                                <FlagListItem
                                    {...flag}
                                    title={flag.key}
                                    key={flag.key}
                                    handleActivate={handleActivate}
                                    onClickTitle={handleFlagModal}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Modal className={classes.modalContainer} open={flagModalOpened} onClose={() => handleFlagModal()}>
                <FeatureFlagModal
                    close={handleFlagModal}
                    {...flagInfo}
                    onSubmit={handleSubmit}
                />
            </Modal>
        </Layout>
    )
}

export default Flag

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: `calc(100% - 60px)`
    },
    tableContainer: {
        height: `100%`,
        borderRadius: 16,
        marginTop: 24,
        maxWidth: 800
    },
    tableHeadCell: {
        backgroundColor: theme.palette.background.paper
    },
    modalContainer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '24px 0',
        width: '100%',
        maxWidth: 800
    },
    addButton: {
        backgroundColor: theme.palette.action.selected
    }
}))