import React from 'react'
import { Box, Typography } from '@material-ui/core'

class ErrorBoundary extends React.Component {

    state = {
        hasError: false
    }

    static getDerivedStateFromError(){
        return {
            hasError: true
        }
    }

    render(){
        if(this.state.hasError){
            return <Box marginLeft={280}>error occured</Box>
        }
        return this.props.children
    }
}

export default ErrorBoundary