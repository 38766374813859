import 'date-fns'
import { Box, Button, makeStyles, Modal, Paper, TextField, Typography, Popover, useTheme } from '@material-ui/core'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import buzzEndpoints from '../../../api/buzz'
import { UserDetailResponse } from '../../../models/api/buzz'
import activeFilter from '../../../utils/filter/activeFilter'
import attractionFilter from '../../../utils/filter/attractionFilter'
import authorizationFilter from '../../../utils/filter/authorizationFilter'
import bodyTypeFilter from '../../../utils/filter/bodyTypeFilter'
import characterFilter from '../../../utils/filter/charaterFilter'
import dateFilter from '../../../utils/filter/dateFilter'
import datingFilter from '../../../utils/filter/datingFilter'
import drinkingFilter from '../../../utils/filter/drinkingFilter'
import educationFilter from '../../../utils/filter/educationFilter'
import genderFilter from '../../../utils/filter/genderFilter'
import idealFilter from '../../../utils/filter/idealFilter'
import imageStateFilter from '../../../utils/filter/imageStateFilter'
import interestFilter from '../../../utils/filter/interestFilter'
import levelFilter from '../../../utils/filter/levelFilter'
import lifeStyleFilter from '../../../utils/filter/lifeStyleFilter'
import smokingFilter from '../../../utils/filter/smokingFilter'
import Loading from '../Loading'
import TextareaEdit from './Edit/TextareaEdit'
import MultiSelectEdit from './Edit/MultiSelectEdit'
import SingleSelectEdit from './Edit/SingleSelectEdit'
import InputEdit from './Edit/InputEdit'
import TextEdit from './Edit/TextEdit'
import GemEdit from './Edit/GemEdit'
import Relationship from './Edit/Relationship'
import useInputs from '../../../hooks/useInputs'
import CheckboxEdit from './Edit/CheckboxEdit'
import BuyGem from './Edit/BuyGem'
import ReportHistory from './Edit/ReportHistory'
import EmailEdit from './Edit/EmailEdit'
import PhoneEdit from './Edit/PhoneEdit'
import GenderEdit from './Edit/GenderEdit'
import SuspendHistory from './Edit/SuspendHistory'
import adminPermissionFilter from '../../../utils/filter/adminPermissionFilter'
import { FacebookResponse } from '../../../models/api/buzz/user'
import religionFilter from '../../../utils/filter/religionFilter'
import FreeHistory from './Edit/FreeHistory'
import fullPathFilter from '../../../utils/filter/fullPathFilter'
import ImageModal from '../../common/Modal/ImageModal'
import { gemVersions } from '../../../constants/globalConstants'
import AuthorizationType from '../../../models/api/enum/authorization'
import useImageModal from '../../../hooks/useImageModal'
import RemoveSocialAuth from './Edit/RemoveSocialAuth'
import { useMutation, useQueries, useQuery } from 'react-query'
import { ApiPromiseReturnType, PictureResponse } from '../../../models/api/common'
import { api } from '../../../api/common'
import useSnackbar from '../../../hooks/useSnackbar'
import PunishmentHistoryModal from '../../common/Modal/PunishmentHistoryModal'
import FacebookImage from './Edit/FacebookImage'
import RemovePhoneNumber from './Edit/RemovePhoneNumber'
import kstFilter from '../../../utils/filter/kstFilter'
import { replacePlaceholderImage } from '../../../utils/common'
import { JobResponse } from '../../../models/api/buzz/job'
import BirthdayEdit from './Edit/BirthdayEdit'
import RemainTimer from './RemainTimer'
import potatoEndpoints from '../../../api/potato'
import selfVerificationStatusFilter from "../../../utils/filter/selfVerificationStatusFilter";

interface TableItemdata {
    name?: string
    value?: string | number
    fullWidth?: boolean
    onClick?: (name?: string, value?: string | number) => void
    label?: string
}

const TableItem: React.FC<TableItemdata> = ({
    name, value, fullWidth, onClick, label
}) => {

    const classes = useStyles()

    return (
        <Box className={fullWidth ? classes.tableItemFullWidth : classes.tableItem}>
            <Typography variant='body2' color='textPrimary' className={classes.tableName}>{name}</Typography>
            <Typography variant='body2' color='secondary' className={classes.tableValue}>
                {label || value}
                {onClick && <Button className={classes.setting} onClick={() => onClick(name, value)}>설정</Button>}
            </Typography>
        </Box>
    )
}

interface Props {
    userId: number
}

const Profile: React.FC<Props> = ({
    userId
}) => {

    const classes = useStyles()

    const theme = useTheme()

    const { data: facebookData, refetch: getFacebook } = useQuery<FacebookResponse>(
        ['userFacebook'],
        async () => api.get(`${buzzEndpoints.userFacebook(userId)}`).then(res => res.data),
        {
            onSuccess: (data) => {
                data.albums?.data?.forEach(album => {
                    if(album.photos){
                        album?.photos?.data?.forEach(photo => {
                            if(!facebookImages.includes(photo?.images?.[0]?.source)){
                                setFacebookImages(images => [...images, photo?.images?.[0]?.source])
                            }
                        })
                    }
                })
            },
            enabled: false
        }
    )

    const _isSelfAuthenticated = (state?: string) => {
        return state==='SA04'
    }

    const [ { data, refetch: getUser }, {} ] = useQueries<[ApiPromiseReturnType<UserDetailResponse>, ApiPromiseReturnType<JobResponse>]>([
        {
            queryKey: ['users', userId],
            queryFn: async () => api.get(`${buzzEndpoints.userDetail(userId)}`).then(res => res.data),
            onSuccess: (data) => {
                data?.authorizations?.forEach(authorization => {
                    if(authorization.type==='F00'){
                        getFacebook()
                    }
                })
                const user_id = data.id;
                const adminPhoto = data?.selfVerifications?.adminPhoto;
                const userPhoto = data?.selfVerifications?.userPhoto;
                const admin_auth_state = adminPhoto && adminPhoto.state;
                const user_auth_state = userPhoto && userPhoto.state;
                const self_authenticated = _isSelfAuthenticated(admin_auth_state) || _isSelfAuthenticated(user_auth_state);
                const category = _isSelfAuthenticated(user_auth_state) ? 'user_photo' : 'admin_photo';
                setCategory(category)
                setBirthday(data?.birthday)
            },
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false
        },
        {
            queryKey: ['jobs'],
            queryFn: async () => api.get(`${buzzEndpoints.getJobs()}`).then(res => res.data),
            onSuccess: (data) => {
                setJobs(data)
            }
        },
    ])

    const { mutate: manipulateMutation } = useMutation(
        (params: { additionalAmount: number, targetScore: number }) => api.post(`${buzzEndpoints.joinRatingManipulation(data?.ratings?.id!)}`, params).then(res => res.data)
    )

    const [ freeHistoryModalOpened, setFreeHistoryModalOpened ] = useState<boolean>(false)
    const [ editModalOpened, setEditModalOpened ] = useState<boolean>(false)
    const [ modalInfo, setModalInfo ] = useState<{ name: string, value?: string | number }>({
        name: '',
        value: ''
    })
    const [ deletePictureOrder, setDeletePictureOrder ] = useState<number>(0)
    const [ removeSocialModalOpened, setRemoveSocialModalOpened ] = useState<boolean>(false)
    const [ authorizationType, setAuthorizationType ] = useState<AuthorizationType>()
    const [ facebookImages, setFacebookImages ] = useState<string[]>([])
    const [ reportAndPunishmentModalOpened, setReportAndPunishmentModalOpened ] = useState<boolean>(false)
    const [ facebookAlbumOpened, setFacebookAlbumOpened ] = useState<boolean>(false)
    const [ removePhoneNumberOpened, setRemovePhoneNumberOpened ] = useState<boolean>(false)
    const [ gemPopoverOpened, setGemPopoverOpened ] = useState<boolean>(false)
    const [ jobs, setJobs ] = useState<JobResponse>({
        jobCategory: []
    })
    const [ category, setCategory ] = useState<string>('')
    const [ birthday, setBirthday ] = useState<string>('')

    const [ { additionalAmount, targetScore }, onChange ] = useInputs({
        additionalAmount: undefined,
        targetScore: undefined
    })

    const anchorEl = useRef<HTMLButtonElement>()
    const gemAnchorEl = useRef<any>()
    const gemPaperRef = useRef<HTMLDivElement>()


    const { imageModalOpened, imagePath, handleImageModal } = useImageModal()

    const handleFacebookAlbum = () => {
        setFacebookAlbumOpened(open => !open)
    }

    const handleGemPopover = () => {
        setGemPopoverOpened(open => !open)
    }

    const handleEditModal = (name?: string, value?: string | number) => {
        if(editModalOpened){
            setModalInfo({ name: '', value: '' })
            setEditModalOpened(false)
        } else {
            if(name){
                setModalInfo({ name: name, value: value })
            } else {
            }
            setEditModalOpened(true)
        }
    }

    const handleReportAndPunishment = () => {
        setReportAndPunishmentModalOpened(open => !open)
    }

    const handleFreeHistory = () => {
        setFreeHistoryModalOpened(open => !open)
    }

    const handleManipulate = () => {
        manipulateMutation({
            additionalAmount: Number(additionalAmount),
            targetScore: Number(targetScore)
        })
    }

    const handleDeleteUserImage = async (name: string, order: number) => {
        setDeletePictureOrder(order)
        handleEditModal(name)
    }

    const handleRemoveSocialModal = (type?: AuthorizationType) => {
        setAuthorizationType(type || undefined)
        setRemoveSocialModalOpened(open => !open)
    }

    const handleRemovePhoneNumber = () => {
        setRemovePhoneNumberOpened(open => !open)
    }

    const refetch = async () => {
        await getUser()
    }

    const { mutate: markAsApprovedAuthentication } = useMutation(
      () =>
        api.post(`${potatoEndpoints.reviewSelfAuth(userId)}`, {
          state: "marked_approve",
        }),
      {
        onSuccess: () => {
          refetch();
        },
      }
    );

    const { openSnackbar } = useSnackbar()

    const copyToClipboard = (value: any) => {
        openSnackbar({
            message: '클립보드에 복사되었습니다',
            severity: 'success'
        })
        const tempEl = document.createElement('input')
        tempEl.value = value
        document.body.appendChild(tempEl)
        tempEl.select()
        document.execCommand('copy')
        document.body.removeChild(tempEl)
    }

    const disableDeletePicture = (picture: PictureResponse) => {
        let confirmCount = 0;
        data?.picturePendings.forEach(picture => {
            picture.isConfirm==='Y' && confirmCount++
        })
        if (confirmCount > 2) {
            if (picture.isConfirm==='Y') {
                return false;
            }
        }
        return true;
    }

    useEffect(() => {
        getUser()
    }, [userId])

    const isAdminVerification = useMemo(() => {
        if(data?.selfVerifications?.adminPhoto?.state==='SA04' || data?.selfVerifications?.userPhoto?.state==='SA04'){
            return true
        } else {
            return false
        }
    }, [data?.selfVerifications])

    if(data){
        return (
            <Box className={classes.container}>
                <Box className={classes.contentBox}>
                    <Paper className={classes.infoBox}>
                        <Box className={classes.infoTitleBox} style={{ flexDirection: 'column' }}>
                            <Typography className={classes.infoTitle}>기본 정보</Typography>
                            {facebookData && (
                                <Box className={classes.facebookBox}>
                                    <Box className={classes.facebookInfo}>
                                        <Typography color='textPrimary' variant='body2'>페이스북 계정 정보</Typography>
                                        <Typography color='secondary' variant='body2' style={{ cursor: 'pointer' }} component={'a'} href={facebookData?.link} target='_blank'>타임라인으로 이동</Typography>
                                        <Typography color='textPrimary' variant='body2'>이름: {facebookData?.name}</Typography>
                                        <Typography color='textPrimary' variant='body2'>이메일: {facebookData?.email}</Typography>
                                        <Typography color='textPrimary' variant='body2'>성별: {facebookData?.gender}</Typography>
                                        <Typography color='textPrimary' variant='body2'>친구 수: {facebookData?.friends?.summary?.totalCount}</Typography>
                                    </Box>
                                    <Box className={classes.facebookImageBox}>
                                        <Box className={classes.mainImageBox}>
                                            <Typography variant='body2' color='textPrimary'>대표 사진</Typography>
                                            <img src={facebookData?.picture.data.url} onClick={() => handleImageModal(facebookData?.picture?.data?.url)} className={classes.mainImage}/>
                                        </Box>
                                        <Box className={classes.albumImageBox}>
                                            <Box>
                                                <Typography variant='body2' color='textPrimary'>앨범 사진&nbsp;</Typography>
                                                <Button onClick={handleFacebookAlbum} innerRef={anchorEl} disableElevation>
                                                    <Typography variant='body2' color='secondary'>더 보기</Typography>
                                                </Button>
                                            </Box>
                                            <Popover
                                                anchorEl={anchorEl.current}
                                                open={facebookAlbumOpened}
                                                onClose={handleFacebookAlbum}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                            >
                                                <FacebookImage sources={facebookImages}/>
                                            </Popover>
                                            <Box>
                                                {facebookImages.length > 0 && <img src={facebookImages[0]} onClick={() => handleImageModal(facebookImages[0])} className={classes.albumImage}/>}
                                                {facebookImages.length > 1 && <img src={facebookImages[1]} onClick={() => handleImageModal(facebookImages[1])} className={classes.albumImage}/>}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                        <Box className={classes.tableRow}>
                            <Box className={classes.tableItem}>
                                <Typography variant='body2' color='textPrimary' className={classes.tableName}>ID</Typography>
                                <Typography variant='body2' color='secondary' className={classes.tableValue} style={{ cursor: 'pointer' }} onClick={() => copyToClipboard(data.id)}>{data?.id}</Typography>
                            </Box>
                            <Box className={classes.divider}/>
                            <TableItem name='앱 버전' value={data?.internalInformation?.appVersion}/>
                        </Box>
                        <Box className={classes.tableRow}>
                            <Box className={classes.tableItem}>
                                <Typography variant='body2' color='textPrimary' className={classes.tableName}>닉네임</Typography>
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant='body2' color='secondary' className={classes.tableValue} style={{ cursor: 'pointer' }} onClick={() => copyToClipboard(data?.nickname)}>{data?.nickname || '닉네임 없음'}</Typography>
                                    {adminPermissionFilter('UserProfileChangeNickname') && <Button className={classes.setting} onClick={() => handleEditModal('닉네임', data?.nickname)}>설정</Button>}
                                </Box>
                            </Box>
                            <Box className={classes.divider}/>
                            <Box className={classes.tableItem}>
                                <Typography variant='body2' color='textPrimary' className={classes.tableName}>인증</Typography>
                                <Typography variant='body2' color='secondary' className={classes.tableValue}>
                                    {data?.authorizations.length && data.authorizations.map(authorization => authorizationFilter(authorization.type)).join(' | ')}
                                    {(data?.authorizations.length && adminPermissionFilter('UserProfileChangeFacebook')) && data.authorizations.filter(authorization => ['G00', 'F00', 'A00'].includes(authorization.type)).map(authorization => (
                                        <Button key={authorization.id} className={classes.disableSocialButton} onClick={() => handleRemoveSocialModal(authorization.type)}>{authorizationFilter(authorization.type)} 해제</Button>
                                    ))}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='가입방식' value={data?.authorizations.length ? data.authorizations.filter(authorization => authorization.type!=='D00').map(authorization => authorizationFilter(authorization.type)).join(' | ') : ''}/>
                            <Box className={classes.divider}/>
                            <Box className={classes.tableItem}>
                                <Typography variant='body2' color='textPrimary' className={classes.tableName}>이메일</Typography>
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant='body2' color='secondary' className={classes.tableValue} style={{ cursor: 'pointer' }} onClick={() => copyToClipboard(data?.authorizations?.filter(authorization => authorization.type==='E00').map(authorization => authorization.identification).join(' | '))}>{data?.authorizations.length ? data.authorizations.filter(authorization => authorization.type==='E00').map(authorization => authorization.identification).join(' | ') : ''}</Typography>
                                    {adminPermissionFilter('UserProfileChangeEmail') && <Button className={classes.setting} onClick={() => handleEditModal('이메일', data?.authorizations?.filter(authorization => authorization.type==='E00').map(authorization => authorization.identification).join(' | '))}>{Boolean(data?.authorizations?.filter(authorization => authorization.type==='E00').map(authorization => authorization.identification).join(' | ')) ? '설정' : '이메일 연동'}</Button>}
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.tableRow}>
                            {adminPermissionFilter('UserProfileChangePhoneNumber') && (
                                <Box className={classes.tableItem}>
                                    <Typography variant='body2' color='textPrimary' className={classes.tableName}>폰 번호</Typography>
                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant='body2' color='secondary' className={classes.tableValue} style={{ cursor: 'pointer' }} onClick={() => copyToClipboard(data?.phoneAuthentication?.phoneNumber)}>{data?.phoneAuthentication?.phoneNumber || '등록되지 않음'}</Typography>
                                        {adminPermissionFilter('UserProfileChangePhoneNumber') && <Button className={classes.setting} onClick={() => handleEditModal('폰 번호', data?.phoneAuthentication?.phoneNumber || '')}>설정</Button>}
                                        {(adminPermissionFilter('UserProfileChangePhoneNumber') && data?.phoneAuthentication?.phoneNumber) && <Button className={classes.removeButton} onClick={handleRemovePhoneNumber}>삭제</Button>}
                                    </Box>
                                </Box>
                            )}
                            <Box className={classes.divider}/>
                            <TableItem name='추천인' value={'...'}/>
                        </Box>
                        <Box className={classes.tableRow}>
                            <Box className={classes.tableItem}>
                                <Typography variant='body2' color='textPrimary' className={classes.tableName}>생일</Typography>
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant='body2' color='secondary' className={classes.tableValue}>{birthday}</Typography>
                                    {adminPermissionFilter('UserProfileChangeBasicInfo') && <Button className={classes.setting} onClick={() => handleEditModal('생일', birthday ?? '')}>설정</Button>}
                                </Box>
                            </Box>
                            <Box className={classes.divider}/>
                            <TableItem name='성별' value={genderFilter(data?.gender)} onClick={handleEditModal}/>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='성격' value={data?.characters?.map(character => characterFilter(character)).join(', ')}/>
                            <Box className={classes.divider}/>
                            <TableItem name='키' value={data?.height} label={data?.height ? `${data.height}cm` : ''}/>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='체형' value={bodyTypeFilter(data?.bodyType)}/>
                            <Box className={classes.divider}/>
                            <TableItem name='혈액형' value={data?.bloodType}/>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='위치' value={`${data?.region} | ${data?.locality} | ${data?.location}`}/>
                            <Box className={classes.divider}/>
                            <TableItem name='종교' value={religionFilter(data?.religion)}/>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='직업' value={data?.job} onClick={adminPermissionFilter('UserProfileChangeBasicInfo') ? handleEditModal : undefined}/>
                            <Box className={classes.divider}/>
                            <TableItem name='학력' value={educationFilter(data?.education)} onClick={adminPermissionFilter('UserProfileChangeBasicInfo') ? handleEditModal : undefined}/>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='직장' value={data?.company} onClick={adminPermissionFilter('UserProfileChangeBasicInfo') ? handleEditModal : undefined}/>
                            <Box className={classes.divider}/>
                            <TableItem name='학교' value={data?.school} onClick={adminPermissionFilter('UserProfileChangeBasicInfo') ? handleEditModal : undefined}/>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='흡연' value={smokingFilter(data?.smoking)}/>
                            <Box className={classes.divider}/>
                            <TableItem name='음주' value={drinkingFilter(data?.drinking)}/>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='계정 상태' value={activeFilter(data?.isOut, data?.isOutPending, data?.isBlock, data?.isDeactivate)}/>
                            <Box className={classes.divider}/>
                            {adminPermissionFilter('UserProfileChangeBasicInfo') && <TableItem name='실존 국가' value={data?.currentRegion}/>}
                        </Box>
                        {adminPermissionFilter('UserProfileDeletePicture') && (
                            <Box className={classes.userImageBox}>
                                {data?.picturePendings.map(picture => (
                                    <Box className={classes.imageBox} key={picture.id}>
                                        <Box className={classes.imagePendingBox}>
                                            <img src={fullPathFilter(picture.pathSmall)} className={classes.userImage} style={{ opacity: picture.isConfirm==='Y' ? 1 : .3 }} onClick={() => handleImageModal(picture.path)} onError={replacePlaceholderImage}/>
                                            {picture.isConfirm!=='Y' && <Typography color='textPrimary' variant='body2' style={{ position: 'absolute' }}>변경 대기 중</Typography>}
                                        </Box>
                                        {adminPermissionFilter('UserProfileDeletePicture') && <Button disabled={disableDeletePicture(picture)} onClick={() => handleDeleteUserImage('사진 삭제', picture.order)} className={classes.userImageDeleteButton}>삭제</Button>}
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </Paper>
                    <Paper className={classes.infoBox}>
                        <Box className={classes.infoTitleBox}>
                            <Typography className={classes.infoTitle}>매력 정보</Typography>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='랭크' value={levelFilter(data?.level)}/>
                            <Box className={classes.divider}/>
                            <TableItem name='매력지수' value={data?.aveScore}/>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='평가 받은 인원수' value={(data?.ratings?.amount || 0) + (data?.ratings?.addtionalUserAmount || 0)}/>
                            <Box className={classes.divider}/>
                            <TableItem name='재평가 수' value={data?.ratings?.attemptCount}/>
                        </Box>
                        {adminPermissionFilter('UserProfileManipulationRatingScore') && (
                            <Box className={classes.tableRow}>
                                <TextField
                                    variant='standard'
                                    placeholder='추가 인원(선택)'
                                    inputProps={{
                                        style: {
                                            fontSize: 14,
                                            padding: 12,
                                            border: 'none',
                                            outline: 'none',
                                        }
                                    }}
                                    style={{ margin: '0 10px' }}
                                    value={additionalAmount}
                                    name='additionalAmount'
                                    onChange={onChange}
                                    type='number'
                                />
                                <TextField
                                    variant='standard'
                                    placeholder='목표 점수'
                                    inputProps={{
                                        style: {
                                            fontSize: 14,
                                            padding: 12,
                                            border: 'none',
                                            outline: 'none',
                                        }
                                    }}
                                    style={{ margin: '0 10px' }}
                                    value={targetScore}
                                    name='targetScore'
                                    onChange={onChange}
                                    type='number'
                                />
                                <Button color='secondary' onClick={handleManipulate}>적용</Button>
                            </Box>
                        )}
                    </Paper>
                    <Paper className={classes.infoBox}>
                        <Box className={classes.infoTitleBox}>
                            <Typography className={classes.infoTitle}>제재 내역</Typography>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='그룹' fullWidth/>
                        </Box>
                    </Paper>
                </Box>
                <Box className={classes.contentBox}>
                    <Paper className={classes.infoBox}>
                        <Box className={classes.infoTitleBox}>
                            <Typography className={classes.infoTitle}>프로필 정보</Typography>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='동네친구' fullWidth value={data.nearbyStatus} onClick={adminPermissionFilter('UserProfileChangeBasicInfo') ? handleEditModal : undefined}/>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='소개' fullWidth value={data.message} onClick={adminPermissionFilter('UserProfileChangeBasicInfo') ? handleEditModal : undefined}/>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='특징' fullWidth value={data.attractions?.map(attraction => attractionFilter(attraction.context)).join(', ')} onClick={adminPermissionFilter('UserProfileChangeBasicInfo') ? handleEditModal : undefined}/>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='관심사' fullWidth value={data.interests?.map(interest => interestFilter(interest.context)).join(', ')} onClick={adminPermissionFilter('UserProfileChangeBasicInfo') ? handleEditModal : undefined}/>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='이상형' fullWidth value={data.ideals?.map(ideal => idealFilter(ideal.context)).join(', ')} onClick={adminPermissionFilter('UserProfileChangeBasicInfo') ? handleEditModal : undefined}/>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='데이트 스타일' fullWidth value={data.datings?.map(dating => datingFilter(dating.context)).join(', ')} onClick={adminPermissionFilter('UserProfileChangeBasicInfo') ? handleEditModal : undefined}/>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='라이프 스타일' fullWidth value={data.lifeStyles?.map(lifeStyle => lifeStyleFilter(lifeStyle.context)).join(', ')} onClick={adminPermissionFilter('UserProfileChangeBasicInfo') ? handleEditModal : undefined}/>
                        </Box>
                    </Paper>
                    <Paper className={classes.infoBox}>
                        <Box className={classes.infoTitleBox}>
                            <Typography className={classes.infoTitle}>매칭 정보</Typography>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='보낸/받은 호감수' value={`${data.matchInfo?.likeCount || 0} | ${data.matchInfo?.receiveLikeCount || 0}`}/>
                            <Box className={classes.divider}/>
                            <TableItem name='연결 수' value={data.matchInfo?.connectionCount}/>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='대화방 오픈 수' value={data.matchInfo?.openConnectionCount}/>
                            <Box className={classes.divider}/>
                            <TableItem name='현재 진행 중인 대화' value={0}/>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='블라인드 콜' value={'0회 (평점 0)'}/>
                            <Box className={classes.divider}/>
                            <TableItem/>
                        </Box>
                    </Paper>
                    <Paper className={classes.infoBox}>
                        <Box className={classes.infoTitleBox}>
                            <Typography className={classes.infoTitle}>이용 정보</Typography>
                            <Typography style={{ cursor: 'pointer' }} color='textPrimary' variant='body2' onClick={handleFreeHistory}>전체 보기</Typography>
                        </Box>
                        <Box className={classes.tableRow}>
                            <Typography variant='body2' className={classes.free}>무료 좋아요</Typography>
                            <Typography variant='body2' className={classes.itemCell}>잔여 <span style={{ color: theme.palette.secondary.main }}>{data.freeLikeInfo.freeCount + data.freeLikeInfo.compensationCount}</span></Typography>
                            <Typography variant='body2' className={classes.itemCell}>사용 <span style={{ color: theme.palette.secondary.main }}>{data.freeLikeInfo.useCount}</span></Typography>
                            <Typography variant='body2' className={classes.itemCell}>보상 <span style={{ color: theme.palette.secondary.main }}>{data.freeLikeInfo.totalCompensationCount}</span></Typography>
                            <RemainTimer remainSeconds={data.freeLikeInfo.remainSeconds} />
                        </Box>
                        <Box className={classes.tableRow}>
                            <Typography variant='body2' className={classes.free}>무료 대화</Typography>
                            <Typography variant='body2' className={classes.itemCell}>잔여 <span style={{ color: theme.palette.secondary.main }}>{data.freeConnectionInfo.freeCount + data.freeConnectionInfo.compensationCount}</span></Typography>
                            <Typography variant='body2' className={classes.itemCell}>사용 <span style={{ color: theme.palette.secondary.main }}>{data.freeConnectionInfo.useCount}</span></Typography>
                            <Typography variant='body2' className={classes.itemCell}>보상 <span style={{ color: theme.palette.secondary.main }}>{data.freeConnectionInfo.totalCompensationCount}</span></Typography>
                            <RemainTimer remainSeconds={data.freeConnectionInfo.remainSeconds} />
                        </Box>
                    </Paper>
                    <Paper className={classes.infoBox}>
                        <Box className={classes.infoTitleBox}>
                            <Typography className={classes.infoTitle}>결제 정보</Typography>
                        </Box>
                        <Box className={classes.paymentTableRow}>
                            <Box className={classes.paymentTableCategoryBox} style={{ height: 88 }}>
                                <Typography variant='body2' color='textPrimary'>젬</Typography>
                            </Box>
                            <Box className={classes.paymentTableGemBox}>
                                <TableItem name='현재 보유한 젬' value={data.storeInfo?.remainPoints?.remains || 0} fullWidth/>
                                <TableItem name='누적 획득한 젬' value={data.storeInfo?.getPoints || 0} fullWidth/>
                            </Box>
                            <Box className={classes.paymentTableGemBox}>
                                <TableItem name='누적 사용한 젬' value={data.storeInfo?.usePoints || 0} fullWidth/>
                                <TableItem name='누적 구매한 젬' value={data.storeInfo?.purchasePoints || 0} fullWidth/>
                            </Box>
                        </Box>
                    </Paper>
                    <Paper className={classes.infoBox}>
                        <Box className={classes.infoTitleBox}>
                            <Typography className={classes.infoTitle}>추가 정보</Typography>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='플랫폼' value={data.internalInformation?.deviceType==='I' ? 'IOS' : 'Android'}/>
                            <Box className={classes.divider}/>
                            <TableItem name='기종' value={data.internalInformation?.deviceName}/>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='가입일시' value={`${dateFilter(data.createdAt)} | ${kstFilter(data.createdAt)}`}/>
                            <Box className={classes.divider}/>
                            <TableItem name='마지막 접속' value={`${dateFilter(data.lastActivityAt)} | ${kstFilter(data.lastActivityAt)}`}/>
                        </Box>
                        <Box className={classes.tableRow}>
                            <Box className={classes.tableItem}>
                                <Typography variant='body2' color='secondary' className={classes.tableName}>BM 버전</Typography>
                                <Box>
                                    <Typography variant='body2' color='secondary' className={classes.tableValue} innerRef={gemAnchorEl}>
                                        {gemVersions[data.gemRedesignVersion - 1]}
                                    </Typography>
                                </Box>
                                <Popover
                                    open={gemPopoverOpened}
                                    onClose={handleGemPopover}
                                    anchorEl={gemAnchorEl.current}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Box className={classes.gemVersionBox}>
                                        {gemVersions.map(version => (
                                            <Typography key={version} className={classes.gemVersion}>{version}</Typography>
                                        ))}
                                    </Box>
                                </Popover>
                            </Box>
                            <Box className={classes.divider}/>
                            <TableItem name='관리자 사진 인증' value={imageStateFilter(data.selfVerifications?.adminPhoto?.state)}/>
                        </Box>
                        <Box className={classes.tableRow}>
                            <TableItem name='페이스 인증 상태' value={selfVerificationStatusFilter(data.selfVerifications?.photo?.state)}/>
                            <Box className={classes.divider}/>
                            <TableItem name='셀프 사진 인증' value={imageStateFilter(data.selfVerifications?.userPhoto?.state)}/>
                        </Box>
                        <Box className={classes.seperator}/>
                        <Box className={classes.buttonBox}>
                            {(adminPermissionFilter('UserProfileSendNotification') || adminPermissionFilter('UserProfileSendGem')) && (
                                <Box className={classes.buttonStickyBox}>
                                    {adminPermissionFilter('UserProfileSendNotification') && (
                                        <Button disabled={data.isOut==='Y'} className={classes.button} onClick={() => handleEditModal('알림 전송')}>알림 전송</Button>
                                    )}
                                    {adminPermissionFilter('UserProfileSendGem') && (
                                        <Button disabled={data.isOut==='Y'} className={classes.button} onClick={() => handleEditModal('젬 지급·회수')}>젬 지급·회수</Button>
                                    )}
                                    {adminPermissionFilter('UserProfileSendFreeLike') && (
                                        <Button disabled={data.isOut==='Y'} className={classes.button} onClick={() => handleEditModal('좋아요 지급·회수')}>좋아요 지급·회수</Button>
                                    )}
                                    {adminPermissionFilter('UserProfileSendFreeConnection') && (
                                        <Button disabled={data.isOut==='Y'} className={classes.button} onClick={() => handleEditModal('대화 열기 지급·회수')}>대화 열기 지급·회수</Button>
                                    )}
                                    {adminPermissionFilter('UserProfileSendGem') && (
                                        <Button disabled={data.isOut==='Y'} className={classes.button} onClick={() => handleEditModal('젬 구매')}>젬 구매</Button>
                                    )}
                                    {adminPermissionFilter('UserProfileShowRelation') && (
                                        <Button className={classes.button} onClick={() => handleEditModal('관계 조회')}>관계 조회</Button>
                                    )}
                                    {adminPermissionFilter('UserProfileAddDeviceJoinFeedback') && (
                                        <Button className={classes.button} onClick={() => handleEditModal('디바이스 중복 문의 등록')}>디바이스 중복 문의 등록</Button>
                                    )}
                                    {adminPermissionFilter('UserProfileShowRelation') && (
                                        <Button className={classes.button} onClick={handleReportAndPunishment}>신고/제재 내역 보기</Button>
                                    )}
                                </Box>
                            )}
                            <Box className={classes.seperator}/>
                            {(adminPermissionFilter('UserProfileAlert') || adminPermissionFilter('UserProfileBlock') || adminPermissionFilter('UserProfileOutImmediately') || adminPermissionFilter('UserProfileResetPassword') || adminPermissionFilter('UserProfileOutRequest')) && (
                                <>
                                  <Box className={classes.buttonStickyBox}>
                                      {adminPermissionFilter('UserProfileAlert') && (
                                          <Button disabled={data.isOut==='Y'} className={classes.button} onClick={() => handleEditModal('경고')}>경고</Button>
                                      )}
                                      {(adminPermissionFilter('UserProfileBlock') && data.isBlock==='Y') && (
                                          <Button disabled={data.isOut==='Y'} style={{ backgroundColor: '#008c00', color: '#ffffff' }} className={classes.button} onClick={() => handleEditModal('이용 정지 해제')}>이용 정지 해제</Button>
                                      )}
                                      {(adminPermissionFilter('UserProfileBlock') && data.isBlock==='N') && (
                                          <Button style={{ backgroundColor: '#d9534f', color: '#ffffff' }} className={classes.button} onClick={() => handleEditModal('정지')}>정지</Button>
                                      )}
                                      {(adminPermissionFilter('UserProfileBlock') && data.isUserDeviceBlocked) && (
                                          <Button className={classes.button} onClick={() => handleEditModal('악성 디바이스 ID 해제')} style={{ color: '#ffffff', backgroundColor: '#dc8100' }}>악성 디바이스 ID 해제</Button>
                                      )}
                                      {(adminPermissionFilter('UserProfileBlockOption') && data.serviceBlock?.blockType===5) && (
                                          <Button disabled={data.serviceBlock.isRefund===1} className={classes.button} onClick={() => handleEditModal('전체 보상')}>전체 보상</Button>
                                      )}
                                      {adminPermissionFilter('UserProfileResetPassword') && (
                                          <Button  disabled={data.isOut==='Y'} className={classes.button} onClick={() => handleEditModal('비밀번호 초기화')}>비밀번호 초기화</Button>
                                      )}
                                      {(adminPermissionFilter('UserProfileOutRequest') && data.isOutPending==='N') && (
                                          <Button disabled={data.isOut==='Y'} className={classes.button} onClick={() => handleEditModal('탈퇴 신청')}>탈퇴 신청</Button>
                                      )}
                                      {(adminPermissionFilter('UserProfileOutRequest') && data.isOutPending==='Y') && (
                                          <Button className={classes.button} onClick={() => handleEditModal('탈퇴 신청 취소')} style={{ color: '#ffffff', backgroundColor: '#dc8100' }}>탈퇴 신청 취소</Button>
                                      )}
                                      {adminPermissionFilter('UserProfileOutImmediately') && (
                                          <Button className={classes.button} onClick={() => handleEditModal('즉시 탈퇴')}>즉시 탈퇴</Button>
                                      )}
                                      {adminPermissionFilter('RequestSelfAuthentication') && (
                                          <Button disabled={!isAdminVerification} className={classes.button} onClick={() => handleEditModal('사진 인증 취소')}>사진 인증 취소</Button>
                                      )}
                                  </Box>
                                  <Box className={classes.seperator} />
                                  <Box className={classes.buttonStickyBox}>
                                    {adminPermissionFilter("RequestSelfAuthentication") && (
                                      <Button
                                        disabled={isAdminVerification}
                                        className={classes.button}
                                        onClick={() => handleEditModal("관리자 사진 인증 요청")}
                                      >
                                        관리자 사진 인증 요청
                                      </Button>
                                    )}

                                    {adminPermissionFilter("RequestSelfAuthentication") && (
                                      <Button
                                        className={classes.button}
                                        onClick={() => markAsApprovedAuthentication()}
                                      >
                                        관리자 사진 인증됨
                                      </Button>
                                    )}
                                  </Box>
                                </>
                            )}
                        </Box>
                    </Paper>
                </Box>
                <Modal onClose={() => handleImageModal()} className={classes.editModalContainer} open={imageModalOpened}>
                    <ImageModal source={imagePath} close={handleImageModal}/>
                </Modal>
                <Modal open={editModalOpened} onClose={() => handleEditModal()} className={classes.editModalContainer}>
                    {['동네친구', '소개', '호스트 소개'].includes(modalInfo.name) ? (
                        <TextareaEdit
                            close={handleEditModal}
                            refetch={getUser}
                            {...modalInfo}
                            userId={userId}
                        />
                    ) : ['특징', '관심사', '이상형', '데이트 스타일', '라이프 스타일'].includes(modalInfo.name) ? (
                        <MultiSelectEdit
                            close={handleEditModal}
                            refetch={getUser}
                            {...modalInfo}
                            userId={userId}
                        />
                    ) : ['직업', '학력'].includes(modalInfo.name) ? (
                        <SingleSelectEdit
                            close={handleEditModal}
                            refetch={getUser}
                            {...modalInfo}
                            userId={userId}
                            jobData={jobs}
                        />
                    ) : ['직장', '학교', '알림 전송', '닉네임', '호스트 해지'].includes(modalInfo.name) ? (
                        <InputEdit
                            close={handleEditModal}
                            refetch={getUser}
                            {...modalInfo}
                            userId={userId}
                        />
                    ) : ['비밀번호 초기화', '사진 인증 취소','탈퇴 신청', '즉시 탈퇴', '관리자 사진 인증 요청', '탈퇴 신청 취소', '디바이스 중복 문의 등록', '이용 정지 해제', '악성 디바이스 ID 해제', '전체 보상', '호스트 등록', '사진 삭제'].includes(modalInfo.name) ? (
                        <TextEdit
                            refetch={getUser}
                            close={handleEditModal}
                            {...modalInfo}
                            userId={userId}
                            identification={data.joinDevice.identification}
                            username={data.nickname}
                            pictureOrder={deletePictureOrder}
                            category={category}
                        />
                    ) : ['젬 지급·회수', '좋아요 지급·회수', '대화 열기 지급·회수'].includes(modalInfo.name) ? (
                        <GemEdit
                            close={handleEditModal}
                            userId={userId}
                            {...modalInfo}
                            refetch={refetch}
                            gemRedesignVersion={data.gemRedesignVersion}
                        />
                    ) : ['관계 조회'].includes(modalInfo.name) ? (
                        <Relationship
                            close={handleEditModal}
                            userId={userId}
                        />
                    ) : ['정지', '경고'].includes(modalInfo.name) ? (
                        <CheckboxEdit
                            close={handleEditModal}
                            userId={userId}
                            refetch={getUser}
                            {...modalInfo}
                        />
                    ) : ['젬 구매'].includes(modalInfo.name) ? (
                        <BuyGem
                            close={handleEditModal}
                            userId={userId}
                            gemRedesignVersion={data.gemRedesignVersion}
                        />
                    ) : ['신고 내역 보기'].includes(modalInfo.name) ? (
                        <ReportHistory
                            close={handleEditModal}
                            userId={userId}
                        />
                    ) : ['이메일'].includes(modalInfo.name) ? (
                        <EmailEdit
                            userId={userId}
                            close={handleEditModal}
                            refetch={getUser}
                            value={String(modalInfo.value) || ''}
                        />
                    ) : ['폰 번호'].includes(modalInfo.name) ? (
                        <PhoneEdit
                            userId={userId}
                            close={handleEditModal}
                            refetch={getUser}
                            value={String(modalInfo.value) || ''}
                        />
                    ) : ['성별'].includes(modalInfo.name) ? (
                        <GenderEdit
                            userId={userId}
                            close={handleEditModal}
                            refetch={getUser}
                            value={data.gender}
                        />
                    ) : ['제재 내역 보기'].includes(modalInfo.name) ? (
                        <SuspendHistory
                            close={handleEditModal}
                            userId={userId}
                        />
                    ) : ['생일'].includes(modalInfo.name) ? (
                        <BirthdayEdit
                            close={handleEditModal}
                            userId={userId}
                            value={modalInfo.value as string}
                            onEdit={(birthday) => {
                                setBirthday(birthday);
                                handleEditModal();
                            }}
                        />
                    ) : <div/>}
                </Modal>
                <Modal open={freeHistoryModalOpened} onClose={handleFreeHistory} className={classes.editModalContainer}>
                    <FreeHistory userData={data} userId={userId} close={handleFreeHistory} />
                </Modal>
                <Modal open={reportAndPunishmentModalOpened} onClose={handleReportAndPunishment} className={classes.editModalContainer}>
                    <PunishmentHistoryModal id={userId} close={handleReportAndPunishment}/>
                </Modal>
                <Modal open={removeSocialModalOpened} onClose={() => handleRemoveSocialModal()} className={classes.editModalContainer}>
                    <RemoveSocialAuth userId={userId} refetch={refetch} close={handleRemoveSocialModal} selectedAuthType={authorizationType!} authorizations={data.authorizations}/>
                </Modal>
                <Modal open={removePhoneNumberOpened} onClose={handleRemovePhoneNumber} className={classes.editModalContainer}>
                    <RemovePhoneNumber userId={userId} refetch={refetch} close={handleRemovePhoneNumber} phoneNumber={data?.phoneAuthentication?.phoneNumber || ''}/>
                </Modal>
            </Box>
        )
    }
    return <Loading/>
}

export default Profile

const useStyles = makeStyles((theme) => ({
    buttonStickyBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: 12,
        gap: 12
    },
    editModalContainer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxHeight: '60vh',
        overflowY: 'scroll'
    },
    contentBox: {
        width: `calc(50% - 12px)`,
    },
    infoBox: {
        border: `1px solid ${theme.palette.divider}`,
        width: '100%',
        boxSizing: 'border-box',
        marginTop: 24
    },
    infoTitleBox: {
        backgroundColor: theme.palette.action.hover,
        width: '100%',
        padding: 12,
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'space-between'
    },
    infoTitle: {
        fontWeight: 'bold'
    },
    tableRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        boxSizing: 'border-box',
        borderTop: `1px solid ${theme.palette.divider}`,
        minHeight: 44
    },
    tableItem: {
        width: `calc(50% - .5px)`,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 12,
        overflow: 'scroll',
    },
    tableItemFullWidth: {
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 12,
        overflow: 'hidden',
    },
    removeButton: {
        backgroundColor: '#d9534f',
        color: '#ffffff',
        marginLeft: 10,
        "&:hover": {
            backgroundColor: '#d9534f'
        }
    },
    divider: {
        height: '100%',
        backgroundColor: `${theme.palette.divider}`,
        width: 1
    },
    tableName: {
        marginRight: 10,
        whiteSpace: 'nowrap'
    },
    tableValue: {
        whiteSpace: 'pre-wrap',
        display: 'flex',
        alignItems: 'center'
    },
    buttonBox: {
    },
    button: {
        backgroundColor: theme.palette.action.selected,
        "&:hover": {
            backgroundColor: 'inherit'
        }
    },
    seperator: {
        width: '100%',
        height: 1,
        backgroundColor: theme.palette.divider
    },
    free: {
        backgroundColor: theme.palette.action.hover,
        height: 44,
        width: 120,
        padding: 12,
        boxSizing: 'border-box',
        borderRight: `1px solid ${theme.palette.divider}`
    },
    itemCell: {
        width: `calc((100% - 320px) / 3)`,
        borderRight: `1px solid ${theme.palette.divider}`,
        boxSizing: 'border-box',
        padding: 12
    },
    facebookBox: {
        width: '100%',
        display: 'flex',
    },
    facebookInfo: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column'
    },
    facebookImageBox: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '50%'
    },
    mainImageBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '45%'
    },
    albumImageBox: {
        width: '45%',
        display: 'flex',
        flexDirection: 'column',
    },
    mainImage: {
        width: '45%',
        objectFit: 'contain',
        cursor: 'pointer'
    },
    albumImage: {
        width: '45%',
        objectFit: 'contain',
        cursor: 'pointer'
    },
    setting: {
        backgroundColor: theme.palette.action.selected,
        marginLeft: 10,
    },
    disableSocialButton: {
        marginLeft: 10,
        backgroundColor: '#5bc0de',
        color: '#ffffff',
        "&:hover": {
            backgroundColor: '#31b0d5'
        }
    },
    paymentTableRow: {
        display: 'flex',
        width: '100%',
        minHeight: 44,
        borderTop: `1px solid ${theme.palette.divider}`,
        boxSizing: 'border-box'
    },
    paymentTableCategoryBox: {
        minHeight: 44,
        height: '100%',
        width: 80,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.action.hover,
    },
    paymentTableCategory: {
    },
    paymentTableGemBox: {
        width: `calc((100% - 80px) / 2)`,
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        borderLeft: `1px solid ${theme.palette.divider}`
    },
    paymentTableCellGem: {
        height: 44,
        display: 'flex',
        alignItems: 'center',
        width: `calc(100% - 80px)`,
        boxSizing: 'border-box',
        borderRight: `1px solid ${theme.palette.divider}`,
        padding: 12
    },
    creatorImage: {
        width: 80,
        height: 80,
        padding: 12
    },
    userImageBox: {
        width: '100%',
        padding: 12,
        boxSizing: 'border-box',
        display: 'flex',
        borderTop: `1px solid ${theme.palette.divider}`,
        columnGap: `calc(58px / 5)`
    },
    imageBox: {
        display: 'flex',
        flexDirection: 'column',
        width: 80,
        alignItems: 'center'
    },
    imagePendingBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 80,
        height: 80,
        position: 'relative'
    },
    userImage: {
        width: 80,
        height: 80,
        objectFit: 'cover',
        cursor: 'pointer',
    },
    userImageDeleteButton: {
        backgroundColor: theme.palette.action.selected,
        width: 80
    },
    gemVersionBox: {
        backgroundColor: theme.palette.text.primary,
        padding: 12,
        borderRadius: 6
    },
    gemVersion: {
        color: theme.palette.background.paper
    },
    submitButton: {
        fontWeight: 'bold',
        color: '#FFF',
        background: 'green',
        borderRadius: '3px',
        border: 'solid 1px green',
        margin: '5px 0',
        padding: '2px',
        cursor: 'pointer',
    },
}))
