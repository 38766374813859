import IdealType from "../../models/api/enum/ideal";

const idealFilter = (ideal: IdealType) => {
    switch(ideal){
        case 'ID1':
            return '연락 자주 하는 사람';
        case 'ID2':
            return '다정한 사람';
        case 'ID3':
            return '웃는 모습이 예쁜 사람';
        case 'ID4':
            return '운동을 좋아하는 사람';
        case 'ID5':
            return '표현을 잘하는 사람';
        case 'ID6':
            return '적극적인 사람';
        case 'ID7':
            return '목소리가 좋은 사람';
        case 'ID8':
            return '패션 감각이 좋은 사람';
        case 'ID9':
            return '노래 잘하는 사람';
        case 'ID10':
            return '잘 먹는 사람';
        case 'ID11':
            return '피부가 좋은 사람';
        case 'ID12':
            return '대화가 잘 통하는 사람';
        case 'ID13':
            return '본인 일에 충실한 사람';
        case 'ID14':
            return '솔직한 사람';
        case 'ID15':
            return '섹시한 사람';
        case 'ID16':
            return '말을 예쁘게 하는 사람';
        case 'ID17':
            return '배려심 깊은 사람';
        case 'ID18':
            return '나만 좋아해주는 사람';
        case 'ID19':
            return '착한 사람';
        case 'ID20':
            return '예의 바른 사람';
        case 'ID21':
            return '허세 없는 사람';
        case 'ID22':
            return '배울 점이 많은 사람';
        case 'ID23':
            return '유머러스한 사람';
        case 'ID24':
            return '대화가 많은 사람';
        case 'ID25':
            return '함께 많은 시간 보낼 수 있는 사람';
        case 'ID26':
            return '귀여운 사람';
        case 'ID27':
            return '털털한 사람';
        case 'ID28':
            return '애교 많은 사람';
        default:
            return ideal
    }
}

export default idealFilter