import { Box, Button, Checkbox, FormControlLabel, makeStyles, Modal, Paper, Typography, useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { api } from '../../../api/common'
import potatoEndpoints from '../../../api/potato'
import Layout from '../../../components/Layout'
import usePagination from '../../../hooks/usePagination'
import { PotatoPaginationMeta, PotatoResponseType } from '../../../models/api/potato'
import { EventResponse } from '../../../models/api/potato/event'
import { getParams } from '../../../utils/api'
import ServiceEventListItem from '../../../components/Event/Service/ServiceEventListItem'
import { Pagination } from '@material-ui/lab'
import BooleanType from '../../../models/api/enum/boolean'
import TextModal from '../../../components/common/Modal/TextModal'
import ServiceModal from '../../../components/Event/Modal/ServiceModal'
import { env } from '../../../config'

const Service = () => {

    const classes = useStyles()

    const theme = useTheme()

    const { page, setPage, pageLength, setPageLength } = usePagination({
        initalPage: 1
    })
    
    const [ countData, setCountData ] = useState({
        activate: 0,
        deactivate: 0,
        total: 0
    })
    const [ events, setEvents ] = useState<EventResponse[]>([])
    const [ isActivate, setIsActivate ] = useState<BooleanType>('N')
    const [ toggleModalOpened, setToggleModalOpened ] = useState<boolean>(false)
    const [ eventId, setEventId ] = useState<number>(0)
    const [ eventName, setEventName ] = useState<string>('')
    const [ toggleEventData, setToggleEventData ] = useState<{ action: string, isActivate: BooleanType }>({
        action: '',
        isActivate: 'N'
    })
    const [ serviceModalOpened, setServiceModalOpened ] = useState<boolean>(false)
    const [ deleteModalOpened, setDeleteModalOpened ] = useState<boolean>(false)
    const [ modalType, setModalType ] = useState<'create' | 'update'>('create')
    const [ eventData, setEventData ] = useState<EventResponse | null>(null)

    const { isLoading, refetch } = useQuery<PotatoResponseType<EventResponse[], { countActivate: number, countDeactivate: number, pagination: PotatoPaginationMeta }>>(
        ['events', page, isActivate],
        () => api.get(`${potatoEndpoints.getEvents()}${getParams({ page: page, is_activate: isActivate })}`).then(res => res.data),
        {
            onSuccess: (data) => {
                const { countActivate, countDeactivate, pagination } = data.meta
                setCountData({ activate: countActivate || 0, deactivate: countDeactivate || 0, total: (countActivate + countDeactivate) || 0 })
                setEvents(Array.isArray(data.data) ? data.data : [])
                setPageLength(pagination?.totalPages || 0)
            }
        }
    )

    const { mutate: updateEvent } = useMutation(
        () => api.post(`${potatoEndpoints.updateEvent(eventId)}`, toggleEventData).then(res => res.data),
        {
            onSuccess: () => {
                setEvents(events => events.map(event => event.id===eventId ? ({...event, isActivate: toggleEventData.isActivate}) : event))
            }
        }
    )
    const { mutate: deleteEvent } = useMutation(
        () => api.delete(`${potatoEndpoints.updateEvent(eventId)}`).then(res => res.data),
        {
            onMutate: () => {
                handleDeleteModal()
            },
            onSuccess: () => {
                refetch()
            }
        }
    )

    const handleToggleModal = (id?: number, isActivate?: BooleanType) => {
        setEventId(id || 0)
        setToggleEventData({
            action: 'activate',
            isActivate: isActivate || 'N'
        })
        setToggleModalOpened(open => !open)
    }
    const handleToggle = () => {
        setToggleModalOpened(false)
        updateEvent()
    }
    const handleServiceModal = (type?: 'create' | 'update') => {
        setModalType(type || 'create')
        setEventData(null)
        setServiceModalOpened(open => !open)
    }
    const handleDeleteModal = (id?: number, name?: string) => {
        setEventId(id || 0)
        setEventName(name || '')
        setDeleteModalOpened(open => !open)
    }
    const handleUpdateModal = (id?: number, data?: EventResponse) => {
        setEventId(id || 0)
        setModalType('update')
        setEventData(data || null)
        setServiceModalOpened(open => !open)
    }
    const handleCreate = (data: FormData) => {
        let myHeaders = new Headers()
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`)
        let requestOptions: any = {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow'
        };
        fetch(env.BASE_API_URL + potatoEndpoints.createEvent(), requestOptions)
        .then(response => response.text())
        .then(result => {
            handleServiceModal()
            refetch()
        })
        .catch(error => console.log('error', error));
    }
    const handleUpdate = (data: FormData) => {
        let myHeaders = new Headers()
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`)
        let requestOptions: any = {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow'
        };
        fetch(env.BASE_API_URL + potatoEndpoints.updateEvent(eventId), requestOptions)
        .then(response => response.text())
        .then(result => {
            handleServiceModal()
            refetch()
        })
        .catch(error => console.log('error', error));
    }

    return (
        <Layout title='특별 이벤트 관리' level='이벤트 관리' subtitle='추석, 크리스마스 등 특별한 날에 진행되는 이벤트'>
            <Box className={classes.container}>
                <Box className={classes.descBox}>
                    <Typography variant='body2' className={classes.descLink} component='a' href='https://www.notion.so/0b9543045a2145c3ae58a7f0c918097f?v=de4cca9aea9542fda40637f0226576cf' target='_blank'>* 상품 구성 노션 링크</Typography>
                    <Typography color='secondary' variant='body2'>
                        [주의]<br/>
                        * 이벤트는 ON 하지 않아도 기간에 맞춰 자동으로 ON 됩니다.<br/>
                        * ON, OFF 기능은 이벤트 기간과 상관 없이 강제로 켜고 끄는 기능 입니다.<br/>
                        * 이벤트 생성은 시작 5분 전 세팅이 완료되어야 합니다.<br/>
                        * 시간(기간)은 UTC 기준으로 국가마다 자동 계산하여 이벤트가 실행됩니다.<br/>
                        한국 : UTC/GMT: +9:00 | 사우디아라비아, 쿠웨이트, 이슬람 국가 : UTC/GMT: +3:00 | 미국 : UTC/GMT: -5:00 | 터키 : UTC/GMT: +2:00
                    </Typography>
                    <Typography color='textPrimary' variant='body2'>활성 <span style={{ color: theme.palette.secondary.main }}>{countData.activate}</span>건 | 비활성 <span style={{ color: theme.palette.secondary.main }}>{countData.deactivate}</span>건 | 총 <span style={{ color: theme.palette.secondary.main }}>{countData.total}</span>건</Typography>
                </Box>
                <Box className={classes.headerButtonBox}>
                    <Button variant='outlined' onClick={() => handleServiceModal('create')}>이벤트 생성</Button>
                    <FormControlLabel
                        control={<Checkbox checked={isActivate==='Y'} color='primary'/>}
                        label={<Typography color='textPrimary' variant='body1'>활성 이벤트만</Typography>}
                        onChange={() => setIsActivate(activate => activate==='Y' ? 'N' : 'Y')}
                    />
                </Box>
            </Box>
            <Box className={classes.tableContainer}>
                {events.map(event => (
                    <ServiceEventListItem
                        key={event.id}
                        data={event}
                        onClickToggle={handleToggleModal}
                        onClickDelete={handleDeleteModal}
                        onClickUpdate={handleUpdateModal}
                    />
                ))}
            </Box>
            <Box className={classes.paginationContainer}>
                <Pagination
                    page={page}
                    count={pageLength}
                    onChange={(_, value) => setPage(value)}
                />
            </Box>
            <TextModal
                open={toggleModalOpened}
                onClose={handleToggleModal}
                children={(
                    <Box>
                        <Typography color='textPrimary'>이벤트를 {toggleEventData.isActivate==='Y' ? 'on' : 'off'} 하시겠습니까?</Typography>
                        <Typography color='textPrimary'>* {toggleEventData.isActivate==='Y' ? 'on' : 'off'} 할 경우 이벤트 기간이어도 {toggleEventData.isActivate==='Y' ? '보이게' : '안보이게'} 됩니다.</Typography>
                    </Box>
                )}
                onSubmit={handleToggle}
                text='승인'
            />
            <TextModal
                open={deleteModalOpened}
                onClose={handleDeleteModal}
                children={<Typography color='textPrimary'>"{eventName}" 이벤트를 삭제합니다.</Typography>}
                onSubmit={() => deleteEvent()}
                text='승인'
            />
            <Modal className={classes.modalContainer} open={serviceModalOpened} onClose={() => handleServiceModal()}>
                <ServiceModal close={handleServiceModal} onSubmitCreate={handleCreate} onSubmitUpdate={handleUpdate} type={modalType} eventData={eventData}/>
            </Modal>
        </Layout>
    )
}

export default Service

const useStyles = makeStyles((theme) => ({
    searchBoxContainer: {
        backgroundColor: theme.palette.background.default,
        paddingTop: 24,
        position: 'fixed',
        top: 100,
        width: 'calc(100% - 328px)',
        zIndex: 12
    },
    descBox: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 24
    },
    descLink: {
        color: '#337ab7',
        "&:hover": {
            color: '#337ab7'
        }
    },
    container: {
        overflowY: 'scroll',
        boxSizing: 'border-box',
        marginTop: 50
    },
    tableContainer: {
        marginTop: 24
    },
    modalContainer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        backgroundColor: theme.palette.action.selected
    },
    paginationContainer: {
        padding: 24,
        boxSizing: 'border-box',
        float: 'right'
    },
    headerButtonBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 16
    }
}))