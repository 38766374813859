import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { AdminActionType, AppealReportDoneResponse, AppealReportResponse, AppealReportState, AppealResponse } from '../../../../models/api/buzz/appeal'
import { replacePlaceholderImage } from '../../../../utils/common'
import ageFilter from '../../../../utils/filter/ageFilter'
import contextCodeFilter from '../../../../utils/filter/contextCodeFilter'
import dateFilter from '../../../../utils/filter/dateFilter'
import fullPathFilter from '../../../../utils/filter/fullPathFilter'
import kstFilter from '../../../../utils/filter/kstFilter'
import kstReverseFilter from '../../../../utils/filter/kstReverseFilter'
import reportCodeFilter from '../../../../utils/filter/reportCodeFilter'

interface Props extends AppealReportDoneResponse {
    onClickUser: (id: number) => void
    onClickAction: (id: number, state: AdminActionType, lastAdminAction: AppealReportState) => void
    onClickDetail: (id: number) => void
}

const AppealReportDoneListItem: React.FC<Props> = ({
    id, onClickUser, onClickAction, onClickDetail, appeal, context, createdAt, state, isSystemReport, handleAdminInfo, reportUser
}) => {

    const classes = useStyles()

    return (
        <Box className={classes.container}>
            <Typography variant='body2' color='textPrimary' className={classes.reportHead}>게시글신고# {id}</Typography>
            <Box className={classes.header}>
                <Typography variant='body2' color='textPrimary' className={classes.appealId}>게시글# {appeal.id}</Typography>
                <Typography variant='body2' className={classes.topic}>어필</Typography>
            </Box>
            <Button className={classes.detailButton} onClick={() => onClickDetail(appeal.id)}>게시글 상세 보기 (댓글 {appeal.commentsCount}개)</Button>
            {appeal.article && (
                <Box className={classes.articleBox} onClick={() => onClickDetail(appeal.id)}>
                    <Box className={classes.article}>
                        <Typography variant='body2' color='textPrimary' className={classes.articleBody}>{appeal?.article?.body}</Typography>
                    </Box>
                </Box>    
            )}
            <Box className={classes.attachmentBox}>
                {appeal.attachments?.map(attachment => (
                    <Box key={attachment.id} className={classes.attachment} onClick={() => onClickDetail(appeal.id)}>
                        <img src={fullPathFilter(attachment.pathSmall)} className={classes.attachmentImage} onError={replacePlaceholderImage}/>
                    </Box>
                ))}
            </Box>
            <Typography variant='body2' color='textPrimary' className={classes.createdAt}>{dateFilter(appeal.createdAt)} 작성 ({appeal.createdAt})</Typography>
            {Boolean(appeal.reportCount) && <Typography variant='body2' color='textPrimary' className={classes.createdAt} style={{ fontWeight: 'bold' }}>{appeal.reportCount}회 신고됨</Typography>}
            <Box className={classes.userInfoBox}>
                <Box>
                    <Typography variant='subtitle1' color='textPrimary'>유저# {appeal.userId}</Typography>
                    <Typography variant='subtitle1' color='textPrimary' className={classes.username}>{appeal.user?.nickname}</Typography>
                </Box>
                <Box className={classes.userInfo}>
                    <Box>
                        <Typography color='textSecondary' variant='body1'>{appeal.user?.region} | {appeal.user?.age}</Typography>
                        <Typography color='textSecondary' variant='body1'>{appeal.user?.address}</Typography>
                        <Typography color='textSecondary' variant='body1'>{appeal.user?.job}</Typography>
                    </Box>
                    <img onClick={() => onClickUser(appeal.userId)} src={fullPathFilter(appeal.user?.pictures[0]?.pathSmall)} onError={replacePlaceholderImage} className={classes.userImage}/>
                </Box>
                <Typography variant='body1' color='textSecondary'>{dateFilter(appeal.user?.createdAt)} 가입</Typography>
            </Box>
            <Box className={classes.reportInfoBox}>
                <Typography variant='body1' color='textSecondary'>#{id} 신고 사유: {isSystemReport ? '시스템 리포트' : '사용자 리포트'} ({reportCodeFilter(context)})</Typography>
                <Box className={classes.reportUserInfo} onClick={() => onClickUser(appeal.userId)}>
                    <Typography color='textPrimary' variant='body1' style={{ fontWeight: 'bold' }}>{reportUser?.nickname}</Typography>
                    <img src={fullPathFilter(reportUser?.pictures[0]?.pathSmall)} onError={replacePlaceholderImage} className={classes.reportUserImage}/>
                </Box>
                <Typography variant='body1' color='textSecondary'>{createdAt}</Typography>
            </Box>
            <Box className={classes.adminInfoBox}>
                <Typography variant='body1' color='textSecondary'>{handleAdminInfo?.nickname}님이 {state==='Approval' ? '인증' : state==='Hide' ? '숨김' : state==='Delete' ? '삭제' : ''}</Typography>
            </Box>
            <Box className={classes.buttonBox}>
                <Button onClick={() => onClickAction(id, 'Rollback', state)} className={classes.button} style={{ backgroundColor: '#1976d2', color: '#ffffff' }}>되돌리기</Button>
            </Box>
        </Box>
    )
}

export default AppealReportDoneListItem

const useStyles = makeStyles((theme) => ({
    reportHead: {
        fontWeight: 'bold',
        marginTop: 10,
        marginBottom: 20,
        padding: '0 16px',
        boxSizing: 'border-box'
    },
    container: {
        width: `calc((100% - 80px) / 6)`,
        minWidth: 340,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 12,
        boxSizing: 'border-box',
        padding: '16px 0',
        position: 'relative',
        marginBottom: 16
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 16px',
        boxSizing: 'border-box'
    },
    appealId: {
        fontWeight: 'bold'
    },
    topic: {
        color: '#f44336',
        border: `1px solid #f44336`,
        borderRadius: 16,
        boxSizing: 'border-box',
        padding: '4px 12px'
    },
    detailButton: {
        borderRadius: 6,
        border: `1px solid ${theme.palette.divider}`,
        width: 'calc(100% - 32px)',
        marginTop: 8,
        paddingLeft: 16,
        paddingRight: 16,
        boxSizing: 'border-box',
        marginRight: 16,
        marginLeft: 16
    },
    articleBox: {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 8,
        width: '100%',
        padding: '0 16px',
        boxSizing: 'border-box'
    },
    article: {
        width: '100%',
        backgroundColor: theme.palette.action.selected,
        borderRadius: 4,
        boxSizing: 'border-box',
        padding: 10
    },
    articleBody: {

    },
    createdAt: {
        marginTop: 8,
        padding: '0 16px',
        boxSizing: 'border-box'
    },
    attachmentBox: {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 8,
        width: '100%',
        padding: '0 16px',
        boxSizing: 'border-box'
    },
    attachment: {
        width: '100%'
    },
    attachmentImage: {
        width: '100%',
        objectFit: 'contain',
        cursor: 'pointer'
    },
    userInfoBox: {
        marginTop: 16,
        padding: '0 16px',
        boxSizing: 'border-box',
        borderTop: `1px solid ${theme.palette.divider}`,
        paddingTop: 16
    },
    reportInfoBox: {
        marginTop: 16,
        padding: 16,
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 5,
    },
    adminInfoBox: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        marginBottom: 60,
        padding: 16,
    },
    username: {
        fontWeight: 'bold'
    },
    userInfo: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 16,
        position: 'relative',
        marginBottom: 16
    },
    userImage: {
        borderRadius: '50%',
        border: `4px solid green`,
        width: 70,
        height: 70,
        position: 'absolute',
        bottom: 0,
        right: 0,
        cursor: 'pointer'
    },
    buttonBox: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: 8,
        position: 'absolute',
        bottom: 16,
        padding: '0 16px',
        boxSizing: 'border-box'
    },
    button: {
        backgroundColor: theme.palette.action.selected,
    },
    reportUserInfo: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    reportUserImage: {
        width: 18,
        height: 18,
        borderRadius: 9,
        border: `1px solid green`,
        marginLeft: 5
    }
}))