const PATHS = {
    LOGIN: '/login',
    HOME: '/',
    USER: '/user',
    DONE: '/done',
    WAIT: '/wait',
    LIST: '/list',
    SERVICE: '/service',
    ADMIN: '/admin',
    EXCHANGE: '/exchange',
    PICTURE: '/picture',
    FEEDBACK: '/feedback',
    REPORT: '/report',
    LIKE: '/like',
    SPECIAL_LIKE: '/special-like',
    RECOMMEND: '/recommend',
    BLOCK: '/block',
    SUSPICIOUS: '/suspicious',
    SELF_AUTHENTICATION: '/self-authentication',
    SMS_VERIFICATION: '/sms-verification',
    REQUEST: '/request',
    JOIN: '/join',
    REVIEW: '/review',
    PROGRESS: '/progress',
    RATING: '/rating',
    HIDDEN: '/hidden',
    ME: '/me',
    AMBASSADOR: '/ambassador',
    APPEAL: '/appeal',
    INDUCE: '/induce',
    FLAG: '/flag',
    EXCLUSION: '/exclusion',
    UTIL: '/util',
    APPLICATION: '/application',
    ADS: '/ads',
    EVENT: '/event',
    BANNER: '/banner',
    RECEIPT: '/receipt',
    TARGET: '/target',
    COMMENT: '/comment'
}

export default PATHS
