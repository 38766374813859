import React, { useState } from 'react'
import Layout from '../../../components/Layout'
import SearchInput from '../../../components/SearchInput'
import { makeStyles, Box, Typography, Paper, Modal } from '@material-ui/core'
import { useQuery } from 'react-query'
import usePagination from '../../../hooks/usePagination'
import { api } from '../../../api/common'
import buzzEndpoints from '../../../api/buzz'
import { getParams } from '../../../utils/api'
import { BuzzPaginationResponse } from '../../../models/api/buzz'
import { ReceiptResponse } from '../../../models/api/buzz/receipt'
import ReceiptListItem from '../../../components/Service/Receipt/ReceiptListItem'
import useImageModal from '../../../hooks/useImageModal'
import ImageModal from '../../../components/common/Modal/ImageModal'
import UserModal from '../../../components/UserModal'
import { Pagination } from '@material-ui/lab'

const Receipt = () => {

    const classes = useStyles()

    const [ keyword, setKeyword ] = useState<string>('')
    const [ prevSearchKeyword, setPrevSearchKeyword ] = useState<string>('')
    const [ isSearch, setIsSearch ] = useState<boolean>(false)
    const [ receipts, setReceipts ] = useState<ReceiptResponse[]>([])
    const [ userModalOpened, setUserModalOpened ] = useState<boolean>(false)
    const [ userId, setUserId ] = useState<number>(0)

    const { page, setPage, pageLength, setPageLength } = usePagination({
        initalPage: 1
    })

    const { imageModalOpened, imagePath, handleImageModal } = useImageModal()
 
    const handleSearch = () => {
        setPrevSearchKeyword(keyword)
        setIsSearch(true)
        refetch()
    }

    const handleKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value)
    }

    const handleUserModal = (id?: number) => {
        setUserId(id || 0)
        setUserModalOpened(open => !open)
    }

    const { isLoading, refetch } = useQuery<BuzzPaginationResponse<ReceiptResponse>>(
        ['receipt', page],
        async () => {
            return api.get(`${buzzEndpoints.searchReceipt()}${getParams({ k: keyword, page: page })}`).then(res => res.data)
        },
        {
            onSuccess: (data) => {
                setReceipts(data.data)
                setPageLength(data.lastPage)
            },
            enabled: false
        }
    )

    return (
        <Layout title='영수증 조회' level='서비스 관리'>
            <SearchInput
                keyword={keyword}
                onChangeKeyword={handleKeyword}
                onClickSearch={handleSearch}
                placeholder='XXXX-XXXX-XXXX-XXXXX (Android Format)'
            />
            {isSearch && (
                <Box className={classes.searchDescBox}>
                    <Typography color='secondary' variant='h6' className={classes.keyword}>{prevSearchKeyword}</Typography>
                    <Typography color='textPrimary' variant='h6' className={classes.keyword}>&nbsp;조회 결과</Typography>
                </Box> 
            )}
            <Paper className={classes.tableContainer}>
                {receipts.map(receipt => (
                    <ReceiptListItem
                        key={receipt.id}
                        {...receipt}
                        onClickImage={handleImageModal}
                        onClickNickname={handleUserModal}
                    />
                ))}
            </Paper>
            <Box className={classes.paginationContainer}>
                <Pagination
                    page={page}
                    onChange={(_, value) => setPage(value)}
                    count={pageLength}
                />
            </Box>
            <Modal open={imageModalOpened} onClose={() => handleImageModal()} className={classes.modalContainer}>
                <ImageModal close={handleImageModal} source={imagePath}/>
            </Modal>
            <Modal open={userModalOpened} onClose={() => handleUserModal()} className={classes.modalContainer}>
                <UserModal userId={userId}/>
            </Modal>
        </Layout>
    )
}

export default Receipt

const useStyles = makeStyles((theme) => ({
    searchDescBox: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 24
    },
    keyword: {
        fontWeight: 'bold'
    },
    tableContainer: {
        height: `calc(100% - 240px)`,
        borderRadius: 16,
        marginTop: 24,
        padding: 24,
        boxSizing: 'border-box',
        gap: 16,
        overflowY: 'scroll',
    },
    modalContainer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paginationContainer: {
        padding: 24,
        boxSizing: 'border-box',
        float: 'right'
    },
}))