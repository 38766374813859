import { Box, IconButton, makeStyles, TextField } from '@material-ui/core'
import React, { forwardRef, useEffect, useState } from 'react'
import buzzEndpoints from '../../../../api/buzz'
import EditLayout from './EditLayout'
import InterestType from '../../../../models/api/enum/interest'
import AttractionType from '../../../../models/api/enum/attraction'
import IdealType from '../../../../models/api/enum/ideal'
import interestReverseFilter from '../../../../utils/filter/interestReverseFilter'
import attractionReverseFilter from '../../../../utils/filter/attractionReverseFilter'
import idealReverseFilter from '../../../../utils/filter/idealReverseFilter'
import datingReverseFilter from '../../../../utils/filter/datingReverseFilter'
import lifeStyleReverseFilter from '../../../../utils/filter/lifeStyleReverseFilter'
import { Close } from '@material-ui/icons'
import axios from 'axios'
import useAuthToken from '../../../../hooks/useAuthToken'
import { env } from '../../../../config'
import { useMutation } from 'react-query'
import { api } from '../../../../api/common'

interface ItemProps {
    value: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onDelete: () => void
}

const SelectItem: React.FC<ItemProps> = ({
    value, onChange, onDelete
}) => {

    const classes = useStyles()

    return (
        <Box className={classes.selectItem}>
            <TextField
                value={value}
                className={classes.selectInput}
                onChange={onChange}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={onDelete}> 
                            <Close color='inherit' style={{ cursor: 'pointer' }}/>
                        </IconButton>
                    )
                }}
            />
        </Box>
    )
}

interface Props {
    userId: number
    name?: string
    value?: string | number
    close: () => void
    refetch: () => void
}

const MultiSelectEdit: React.FC<Props> = forwardRef(({
    userId, name, value, close, refetch
}, ref) => {

    const classes = useStyles()

    const { getToken } = useAuthToken()

    const [ newData, setNewData ] = useState<string[]>(value?.toString().split(', ')!)

    const { mutate: updateProfile } = useMutation(
        (data: object) => api.post(`${buzzEndpoints.updateUserProfile(userId)}`, data).then(res => res.data),
        {
            onMutate: () => close(),
            onSuccess: () => refetch()
        }
    )

    const handleUpdate = () => {
        let data = {}
        switch(name){
            case '관심사':
                data = { interests: newData.map(data => interestReverseFilter(data)) }; break;
            case '특징':
                data = { attractions: newData.map(data => attractionReverseFilter(data)) }; break;
            case '이상형':
                data = { ideals: newData.map(data => idealReverseFilter(data)) }; break;
            case '데이트 스타일':
                data = { datings: newData.map(data => datingReverseFilter(data)) }; break;
            case '라이프 스타일':
                data = { lifeStyles: newData.map(data => lifeStyleReverseFilter(data)) }; break;
        }
        if(name==='라이프 스타일'){
            axios.post(env.BASE_API_URL + `/management/users/${userId}/edit`, data, {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            })
            .then(res => {
                if(res.data.result==='OK'){
                    close()
                    refetch()
                }
            })
        } else {
            updateProfile(data)
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        setNewData(data => data.map((text, i) => i===index ? e.target.value : text))
    }

    const handleAddItem = () => {
        setNewData(data => [...data, ''])
    }

    const handleDeleteItem = (index: number) => {
        setNewData(data => data.filter((_, i) => i!==index))
    }

    const isDisabled = newData.findIndex(data => data==='')!==-1

    return (
        <EditLayout title={`${name} 설정`} disabled={isDisabled} onSubmit={handleUpdate} multiple onAddItem={handleAddItem} close={close}>
            <Box className={classes.contentBox}>
                {newData.map((data, index) => (
                    <SelectItem 
                        key={index} 
                        value={data} 
                        onChange={(e) => handleChange(e, index)}
                        onDelete={() => handleDeleteItem(index)}
                    />
                ))}
            </Box>
        </EditLayout>
    )
})

export default MultiSelectEdit

const useStyles = makeStyles((theme) => ({
    contentBox: {
        width: '100%',
        padding: 24,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 20,
        maxHeight: '50vw',
        overflowY: 'scroll'
    },
    selectItem: {
        width: '100%'
    },
    selectInput: {

    }
}))