import InterestType from "../../models/api/enum/interest";

const interestFilter = (interest: InterestType) => {
    switch(interest){
        case 'IN1':
            return '자기계발';
        case 'IN2':
            return '미용';
        case 'IN3':
            return '독서';
        case 'IN4':
            return '술';
        case 'IN5':
            return '게임';
        case 'IN6':
            return '스포츠/운동';
        case 'IN7':
            return '댄스';
        case 'IN8':
            return '그림 그리기';
        case 'IN9':
            return '자전거 타기';
        case 'IN10':
            return '악기 연주';
        case 'IN11':
            return '레져';
        case 'IN12':
            return '봉사활동';
        case 'IN13':
            return '노래';
        case 'IN14':
            return '요리';
        case 'IN15':
            return 'IT';
        case 'IN16':
            return '드라이브';
        case 'IN17':
            return '산책';
        case 'IN18':
            return '전시회 보기';
        case 'IN19':
            return '커피';
        case 'IN20':
            return '공연 관람';
        case 'IN21':
            return '애니';
        case 'IN22':
            return '글 쓰기';
        case 'IN23':
            return '피트니스';
        case 'IN24':
            return '인테리어';
        case 'IN25':
            return '재테크';
        case 'IN26':
            return '정치';
        case 'IN27':
            return '영화 보기';
        case 'IN28':
            return '맛집 탐방';
        case 'IN29':
            return '여행';
        case 'IN30':
            return '음악';
        case 'IN31':
            return '반려 동물';
        case 'IN32':
            return '패션';
        case 'IN33':
            return '쇼핑';
        case 'IN34':
            return '드라마 보기';
        case 'IN35':
            return '사진 촬영';
        case 'IN36':
            return '외국어/어학';
        case 'IN37':
            return '문학';
        case 'IN39':
            return '웹툰';
        case 'IN38':
            return '덕질';
        default:
            return interest
    }
}

export default interestFilter