import DrinkingType from "../../models/api/enum/drinking"

const drinkingFilter = (drinking: DrinkingType) => {
    switch(drinking){
        case 'DR00':
            return '자주'
        case 'DR01':
            return '가끔'
        case 'DR02':
            return '안 함'
        default:
            return drinking
    }
}

export default drinkingFilter