import { Box, Button, makeStyles, Paper, TextField, Typography } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import React, { useState, useMemo, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import buzzEndpoints from '../../../api/buzz'
import { api } from '../../../api/common'
import Layout from '../../../components/Layout'
import { InduceResponse } from '../../../models/api/buzz/induce'
import DeviceType from '../../../models/api/enum/device'

interface Induce extends InduceResponse {
    error?: boolean
    helperText?: string
}

const Induce = () => {

    const [ initalInduces, setInitalInduces ] = useState<Induce[]>([])
    const [ androidInduces, setAndroidInduces ] = useState<Induce[]>([])
    const [ iosInduces, setIosInduces ] = useState<Induce[]>([])
    const [ isAndroidEditing, setIsAndroidEditing ] = useState<boolean>(false) 
    const [ isIosEditing, setIsIosEditing ] = useState<boolean>(false)

    const classes = useStyles()

    const { refetch } = useQuery<{ data: InduceResponse[] }>(
        ['induce'],
        () => api.get(`${buzzEndpoints.getInduceUpdate()}`).then(res => res.data),
        {
            onSuccess: (data) => {
                setInitalInduces(data.data.map(induce => ({...induce, error: false, helperText: ''})))
                setAndroidInduces(data.data.filter(induce => induce.deviceType==='A').map(induce => ({...induce, error: undefined, helperText: ''})))
                setIosInduces(data.data.filter(induce => induce.deviceType==='I').map(induce => ({...induce, error: undefined, helperText: ''})))
            },
            refetchInterval: false,
            refetchIntervalInBackground: false,
        }
    )

    const { mutate: updateInduce } = useMutation(
        () => api.post(`${buzzEndpoints.getInduceUpdate()}`, { 
            induceUpdates: [
                ...androidInduces.map(induce => ({ device_type: induce.deviceType, induce_update_type: induce.induceUpdateType, max_app_version: induce.maxAppVersion, min_app_version: induce.minAppVersion })), 
                ...iosInduces.map(induce => ({ device_type: induce.deviceType, induce_update_type: induce.induceUpdateType, max_app_version: induce.maxAppVersion, min_app_version: induce.minAppVersion }))
            ] 
        }).then(res => res.data),
        {
            onSuccess: () => {
                refetch()
                setIsAndroidEditing(false)
                setIsIosEditing(false)
            }
        }
    )

    useEffect(() => {
        if(!isAndroidEditing){
            setAndroidInduces(initalInduces.filter(induce => induce.deviceType==='A'))
        }
        if(!isIosEditing){
            setIosInduces(initalInduces.filter(induce => induce.deviceType==='I'))
        }
    }, [isAndroidEditing, isIosEditing])

    const handleMaxValueChange = (index: number, deviceType: DeviceType, value: string) => {
        let regex = /^[0-9]*$/
        if(regex.test(value)){
            if(deviceType==='A'){
                if(index + 1===androidInduces.length){
                    setAndroidInduces(induces => induces.map((induce, induceIndex) => induceIndex===index ? {...induce, maxAppVersion: Number(value)} : induce))
                } else if(Number(value) + 1 >= androidInduces[index + 1].maxAppVersion){
                    setAndroidInduces(induces => induces.map((induce, induceIndex) => induceIndex===index ? {...induce, error: true, helperText: `*${androidInduces[index + 1].maxAppVersion - 2}보다 작은 값을 입력해주세요`} : induce))
                } else if(Number(value) < androidInduces[index].minAppVersion) {
                    setAndroidInduces(induces => induces.map((induce, induceIndex) => induceIndex===index ? {...induce, maxAppVersion: Number(value), error: true, helperText: `*${androidInduces[index].minAppVersion}와 같거나 큰 값을 입력해주세요`} : induce))
                } else {
                    setAndroidInduces(induces => induces.map((induce, induceIndex) => {
                        if(induceIndex===index){
                            return {
                                ...induce, 
                                maxAppVersion: Number(value), 
                                error: undefined
                            }
                        } else if(induceIndex===index + 1){
                            return {
                                ...induce, 
                                minAppVersion: Number(value) + 1
                            }
                        }
                        return induce
                    }))
                }
            } else {
                if(index + 1===iosInduces.length){
                    setIosInduces(induces => induces.map((induce, induceIndex) => induceIndex===index ? {...induce, maxAppVersion: Number(value)} : induce))
                } else if(Number(value) + 1 >= iosInduces[index + 1].maxAppVersion){
                    setIosInduces(induces => induces.map((induce, induceIndex) => induceIndex===index ? {...induce, error: true, helperText: `*${iosInduces[index + 1].maxAppVersion - 2}보다 작은 값을 입력해주세요`} : induce))
                } else if(Number(value) < iosInduces[index].minAppVersion) {
                    setIosInduces(induces => induces.map((induce, induceIndex) => induceIndex===index ? {...induce, maxAppVersion: Number(value), error: true, helperText: `*${iosInduces[index].minAppVersion}와 같거나 큰 값을 입력해주세요`} : induce))
                } else {
                    setIosInduces(induces => induces.map((induce, induceIndex) => {
                        if(induceIndex===index){
                            return {
                                ...induce, 
                                maxAppVersion: Number(value), 
                                error: undefined,
                                helperText: ''
                            }
                        } else if(induceIndex===index + 1){
                            return {
                                ...induce, 
                                minAppVersion: Number(value) + 1,
                                helperText: ''
                            }
                        }
                        return induce
                    }))
                }
            }
        }
    }
    const handleAdd = (deviceType: DeviceType) => {
        if(deviceType==='A'){
            if(androidInduces.length){
                setAndroidInduces(induces => [...induces, { minAppVersion: induces[induces.length - 1].maxAppVersion + 1, maxAppVersion: induces[induces.length - 1].maxAppVersion + 2, deviceType: 'A', induceUpdateType: 'UT01', id: 0 }])
            } else {
                setAndroidInduces(induces => [...induces, { minAppVersion: 1, maxAppVersion: 2, deviceType: 'A', induceUpdateType: 'UT01', id: 0 }])
            }
        } else if(deviceType==='I'){
            if(iosInduces.length){
                setIosInduces(induces => [...induces, { minAppVersion: induces[induces.length - 1].maxAppVersion + 1, maxAppVersion: induces[induces.length - 1].maxAppVersion + 2, deviceType: 'I', induceUpdateType: 'UT01', id: 0 }])
            } else {
                setIosInduces(induces => [...induces, { minAppVersion: 1, maxAppVersion: 2, deviceType: 'I', induceUpdateType: 'UT01', id: 0 }])
            }
        }
    }
    const handleUpdateTypeChange = (index: number, deviceType: DeviceType, value: string) => {
        if(deviceType==='A'){
            setAndroidInduces(induces => induces.map((induce, induceIndex) => induceIndex===index ? {...induce, induceUpdateType: value} : induce))
        } else if(deviceType==='I'){
            setIosInduces(induces => induces.map((induce, induceIndex) => induceIndex===index ? {...induce, induceUpdateType: value} : induce))
        }
    }
    const handleDeleteLast = (index: number, deviceType: DeviceType) => {
        if(deviceType==='A'){
            setAndroidInduces(induces => induces.filter((induce, induceIndex) => induceIndex!==index))
        } else if(deviceType==='I') {
            setIosInduces(induces => induces.filter((induce, induceIndex) => induceIndex!==index))
        }
    }

    const canUpdate = useMemo(() => {
        const initalAndroidInduces = JSON.stringify(initalInduces.filter(induce => induce.deviceType==='A').map(induce => ({ deviceType: induce.deviceType, id: induce.id, induceUpdateType: induce.induceUpdateType, maxAppVersion: induce.maxAppVersion, minAppVersion: induce.minAppVersion })))
        const initalIosInduces = JSON.stringify(initalInduces.filter(induce => induce.deviceType==='I').map(induce => ({ deviceType: induce.deviceType, id: induce.id, induceUpdateType: induce.induceUpdateType, maxAppVersion: induce.maxAppVersion, minAppVersion: induce.minAppVersion })))
        const currentAndroidInduces = JSON.stringify(androidInduces.map(induce => ({ deviceType: induce.deviceType, id: induce.id, induceUpdateType: induce.induceUpdateType, maxAppVersion: induce.maxAppVersion, minAppVersion: induce.minAppVersion })))
        const currentIosInduces = JSON.stringify(iosInduces.map(induce => ({ deviceType: induce.deviceType, id: induce.id, induceUpdateType: induce.induceUpdateType, maxAppVersion: induce.maxAppVersion, minAppVersion: induce.minAppVersion })))
        const isAndroidChanged = initalAndroidInduces!==currentAndroidInduces
        const isIosChanged = initalIosInduces!==currentIosInduces
        if(isAndroidChanged || isIosChanged){
            return true
        } else {
            return false
        }
    }, [androidInduces, iosInduces])

    return (
        <Layout title='업데이트 유도' level='서비스 관리'>
            <Box>
                <Typography style={{ display: 'flex' }} color='textPrimary' variant='body2'>* 팝업: 업데이트 하지 않으면 앱을 사용할 수 없음. <Typography color='secondary' variant='body2'> 강제 업데이트</Typography></Typography>
                <Typography color='textPrimary' variant='body2'>* 알림 태그: 하단에 토스트 팝업 노출. 강제 업데이트 아님</Typography>
                <Typography color='textPrimary' variant='body2'>* 노트: 상단에 노출. 강제 업데이트 아님</Typography>
            </Box>
            <Paper className={classes.container}>
                <Box className={classes.categoryBox}>
                    <Typography variant='subtitle1' color='textPrimary' className={classes.os}>Android</Typography>
                    <Box className={classes.itemBox}>
                        <Button className={classes.editButton} onClick={() => setIsAndroidEditing(editing => !editing)}>{isAndroidEditing ? '취소' : '수정'}</Button>
                        {androidInduces.map((induce, index) => isAndroidEditing ? (
                            <Box className={classes.editInputBox} key={index}>
                                <Typography variant='body1' color='textPrimary' key={induce.id}><b>{induce.minAppVersion || '최초'}</b>부터</Typography>  
                                <TextField 
                                    value={induce.maxAppVersion} 
                                    className={classes.maxInput}
                                    onChange={e => handleMaxValueChange(index, induce.deviceType, e.target.value)}
                                    error={induce.error}
                                    helperText={induce.error ? induce.helperText || '' : ''}
                                />
                                <Typography variant='body1' color='textPrimary'>까지</Typography>
                                <ToggleButtonGroup value={induce.induceUpdateType} className={classes.editButtonGroup} onChange={(e, value) => handleUpdateTypeChange(index, induce.deviceType, value)} exclusive>
                                    <ToggleButton value='UT01' className={classes.buttonGroup}>팝업</ToggleButton>    
                                    <ToggleButton value='UT02' className={classes.buttonGroup}>노트</ToggleButton>    
                                    <ToggleButton value='UT03' className={classes.buttonGroup}>알림 태그</ToggleButton>    
                                </ToggleButtonGroup>  
                                {index + 1===androidInduces.length && (
                                    <Box marginLeft='5px' style={{ cursor: 'pointer' }} onClick={() => handleDeleteLast(index, induce.deviceType)}>
                                        <Typography color='textPrimary' variant='subtitle1'>X</Typography>
                                    </Box>
                                )}
                            </Box>
                        ) : (
                            <Typography variant='body1' color='textPrimary' key={induce.id}><b>{induce.minAppVersion || '최초'}</b>부터 <b>{induce.maxAppVersion}</b>까지 <b>{induce.induceUpdateType==='UT01' ? '팝업' : induce.induceUpdateType==='UT02' ? '노트' : '알림 태그'}</b></Typography>
                        ))}
                        {isAndroidEditing && (
                            <Button className={classes.addButton} onClick={() => handleAdd('A')}>
                                <Typography color='secondary' variant='body2'>+ 추가</Typography>
                            </Button>
                        )}
                    </Box>
                </Box>
                <Box className={classes.categoryBox}>
                    <Typography variant='subtitle1' color='textPrimary' className={classes.os}>iOS</Typography>
                    <Box className={classes.itemBox}>
                        <Button className={classes.editButton} onClick={() => setIsIosEditing(editing => !editing)}>{isIosEditing ? '취소' : '수정'}</Button>
                        {iosInduces.map((induce, index) => isIosEditing ? (
                            <Box className={classes.editInputBox} key={index}>
                                <Typography variant='body1' color='textPrimary' key={induce.id}><b>{induce.minAppVersion || '최초'}</b>부터</Typography>  
                                <TextField 
                                    value={induce.maxAppVersion} 
                                    className={classes.maxInput}
                                    onChange={e => handleMaxValueChange(index, induce.deviceType, e.target.value)}
                                    error={induce.error}
                                    helperText={induce.error ? induce.helperText || '' : ''}
                                />
                                <Typography variant='body1' color='textPrimary'>까지</Typography>
                                <ToggleButtonGroup value={induce.induceUpdateType} className={classes.editButtonGroup} onChange={(e, value) => handleUpdateTypeChange(index, induce.deviceType, value)} exclusive>
                                    <ToggleButton value='UT01' className={classes.buttonGroup}>팝업</ToggleButton>    
                                    <ToggleButton value='UT02' className={classes.buttonGroup}>노트</ToggleButton>    
                                    <ToggleButton value='UT03' className={classes.buttonGroup}>알림 태그</ToggleButton>    
                                </ToggleButtonGroup>  
                                {index + 1===iosInduces.length && (
                                    <Box marginLeft='5px' style={{ cursor: 'pointer' }} onClick={() => handleDeleteLast(index, induce.deviceType)}>
                                        <Typography color='textPrimary' variant='subtitle1'>X</Typography>
                                    </Box>
                                )}
                            </Box>
                        ) : (
                            <Typography variant='body1' color='textPrimary' key={induce.id}><b>{induce.minAppVersion || '최초'}</b>부터 <b>{induce.maxAppVersion}</b>까지 <b>{induce.induceUpdateType==='UT01' ? '팝업' : induce.induceUpdateType==='UT02' ? '노트' : '알림 태그'}</b></Typography>
                        ))}
                        {isIosEditing && (
                            <Button className={classes.addButton} onClick={() => handleAdd('I')}>
                                <Typography color='secondary' variant='body2'>+ 추가</Typography>
                            </Button>
                        )}
                    </Box>
                </Box>
                <Box className={classes.buttonBox}>
                    <Button className={classes.submitButton} disabled={!canUpdate} onClick={() => updateInduce()}>적용</Button>
                </Box>
            </Paper>
        </Layout>
    )
}

export default Induce

const useStyles = makeStyles((theme) => ({
    description: {

    },
    container: {
        height: `calc(100% - 180px)`,
        borderRadius: 16,
        marginTop: 24,
        padding: 24,
        boxSizing: 'border-box',
        rowGap: 24,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
    },
    categoryBox: {
    },
    os: {
        backgroundColor: theme.palette.divider,
        padding: 12,
        fontWeight: 'bold',
        boxSizing: 'border-box'
    },
    itemBox: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 10,
        marginTop: 10,
        marginLeft: 10,
        position: 'relative'
    },
    editButton: {
        position: 'absolute',
        top: 10,
        right: 10,
        border: `1px solid ${theme.palette.divider}`,
        padding: '5px 12px'
    },
    addButton: {
        alignSelf: 'flex-start'
    },
    buttonBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 40
    },
    submitButton: {
        color: '#ffffff',
        backgroundColor: '#008c00',
        "&:hover": {
            backgroundColor: '#008c00'
        },
        "&:disabled": {
            backgroundColor: theme.palette.action.disabled
        },
        padding: '5px 30px'
    },
    editInputBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    editButtonGroup: {
        marginLeft: 12
    },
    buttonGroup: {
        padding: '5px 10px'
    },
    maxInput: {
        margin: '0 10px'
    }
}))