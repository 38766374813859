import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import React from 'react'

const Loading = () => {

    const classes = useStyles()

    return (
        <Box className={classes.progressContainer}>
            <CircularProgress size={80}/>
        </Box>
    )
}

export default Loading

const useStyles = makeStyles((theme) => ({
    progressContainer: {
        height: '60vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}))