import { Box, makeStyles, Typography, useTheme } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import React, { forwardRef, useEffect, useState } from 'react'
import Profile from './Profile'
import History from './History'
import Connection from './Connection'
import Introduction from './Introduction'
import Recommend from './Recommend'
import adminPermissionFilter from '../../utils/filter/adminPermissionFilter'


const defaultHeader = [
    { name: '프로필', permission: 'UserAll', disabled: false },
    { name: '추천 내역', permission: 'UserProfileIntroduction', disabled: false },
    { name: '연결 내역', permission: 'UserProfileConnection', disabled: false },
    { name: '젬 이용 내역', permission: 'UserProfileStoreHistory', disabled: false },
    { name: '친구 초대 내역', permission: 'UserRecommend', disabled: false },
]

interface Props {
    userId: number
    initalToggleIndex?: number
    initalConnectionUserId?: number
}

const UserModal: React.FC<Props> = forwardRef(({
    userId, initalToggleIndex, initalConnectionUserId
}, ref) => {

    const classes = useStyles()

    const theme = useTheme()

    const [ header, setHeader ] = useState<{ name: string, permission: string, disabled: boolean }[]>(defaultHeader)
    const [ toggleIndex, setToggleIndex ] = useState<number>(initalToggleIndex || 0)
    const [ connectionUserId, setConnectionUserId ] = useState<number>(initalConnectionUserId || 0)
    const [ id, setId ] = useState<number>(userId)

    const handleChangeModalUser = (id: number) => {
        setId(id)
    }

    const handleToggleIndex = (index: number) => {
        setToggleIndex(index)
        setConnectionUserId(0)
    }

    useEffect(() => {
        if(id!==userId){
            setToggleIndex(0)
        }
    }, [id])

    return (
        <Box className={classes.container}>
            <Typography variant='h6' color='textPrimary' className={classes.title}>회원 정보</Typography>
            <Box className={classes.contentBox}>
                <ToggleButtonGroup value={toggleIndex} className={classes.toggleGroup}>
                    {header.map((item, index) => {
                        if(adminPermissionFilter(item.permission)){
                            return (
                                <ToggleButton disabled={item.disabled} onClick={() => handleToggleIndex(index)} value={index} className={classes.toggleButton} key={index}>
                                    <Typography className={classes.text} variant='body2' color='textPrimary' style={item.disabled ? { color: theme.palette.text.disabled } : {}}>{item.name}</Typography>
                                </ToggleButton>
                            )
                        } else {
                            return null
                        }
                    })}
                </ToggleButtonGroup>
                {toggleIndex===0 ? (
                    <Profile userId={id}/>
                ) : toggleIndex===1 ? (
                    <Introduction setUserId={handleChangeModalUser} userId={id}/>
                ) : toggleIndex===2 ? (
                    <Connection userId={id} setUserId={handleChangeModalUser} connectionUserId={connectionUserId}/>
                ) : toggleIndex===3 ? (
                    <History userId={id}/>
                ) : (
                    <Recommend userId={id} setUserId={handleChangeModalUser}/>
                )}
            </Box>
        </Box>
    )
})

export default UserModal

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.background.paper,
        maxWidth: 1600,
        width: '80vw',
        boxSizing: 'border-box',
        borderRadius: 16,
        position: 'absolute',
        top: 100
    },
    title: {
        fontWeight: 'bold',
        width: '100%',
        padding: 24,
        boxSizing: 'border-box'
    },
    contentBox: {
        padding: 24,
        boxSizing: 'border-box',
        borderTop: `1px solid ${theme.palette.divider}`
    },
    toggleGroup: {
        width: '100%'
    },
    toggleButton: {
        boxSizing: 'border-box',
        width: '100%',
        "&:disabled": {
            backgroundColor: theme.palette.action.disabledBackground
        }
    },
    text: {
        fontWeight: 'bold',
    }
}))
