import { Box, Button, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import React, { forwardRef, useState } from 'react'
import useInputs from '../../../../../hooks/useInputs'
import { AdminEmployType, CreateAdminRequest } from '../../../../../models/api/buzz/admin'
import CryptoJS from 'crypto-js'
import { useMutation } from 'react-query'
import { api } from '../../../../../api/common'
import buzzEndpoints from '../../../../../api/buzz'

interface Props {
    close: () => void
}

const CreateAdminModal: React.FC<Props> = forwardRef(({
    close
}, ref) => {

    const classes = useStyles()

    const [ { identification, email, nickname, password }, onChange ] = useInputs({
        identification: '',
        password: '000000',
        email: '',
        nickname: '',
    })

    const [ employType, setEmployType ] = useState<AdminEmployType>('internal')
    const [ adminLevelId, setAdminLevelId ] = useState<number>(5)

    const { mutate: createAdmin } = useMutation(
        (data: CreateAdminRequest) => api.post(`${buzzEndpoints.createAdmin()}`, data),
        {
            onSuccess: close
        }
    )

    const requestCreateAdmin = async () => {
        createAdmin({
            identification: identification,
            nickname: nickname,
            email: email,
            employType: employType,
            adminLevelId: adminLevelId,
            password: CryptoJS.enc.Hex.stringify(CryptoJS.SHA1(password))
        })
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.titleBox}>
                <Typography className={classes.title} variant='h6' color='textPrimary'>관리자 계정 등록</Typography>
            </Box>
            <Box className={classes.contentBox}>
                <TextField placeholder='ID' value={identification} name='identification' onChange={onChange}/>
                <TextField placeholder='Nickname' value={nickname} name='nickname' onChange={onChange}/>
                <TextField placeholder='Email' value={email} name='email' onChange={onChange}/>
                <Select defaultValue={5} onChange={(e: any) => setAdminLevelId(e.target.value)}>
                    {Number(localStorage.getItem('levelId'))<=0 && <MenuItem value={1}>1. 매니저</MenuItem>}
                    {Number(localStorage.getItem('levelId'))<=1 && <MenuItem value={2}>2. 리더</MenuItem>}
                    {Number(localStorage.getItem('levelId'))<=2 && <MenuItem value={3}>3. CR팀</MenuItem>}
                    {Number(localStorage.getItem('levelId'))<=3 && <MenuItem value={4}>4. 직원</MenuItem>}
                    {Number(localStorage.getItem('levelId'))<=4 && <MenuItem value={5}>5. 운영요원</MenuItem>}                    
                </Select>
                <Select defaultValue='internal' onChange={(e: any) => setEmployType(e.target.value)}>
                    <MenuItem value='internal'>내부 직원</MenuItem>
                    <MenuItem value='external'>외부 직원</MenuItem>
                </Select>
                <TextField disabled placeholder='최초 등록시 비밀번호는 000000으로 고정 됩니다.'/>
            </Box>
            <Box className={classes.buttonBox}>
                <Button className={classes.submitButton} onClick={requestCreateAdmin}>확인</Button>
            </Box>
        </Box>
    )
})

export default CreateAdminModal

const useStyles = makeStyles((theme) => ({
    container: {
        width: '60vw',
        maxWidth: 800,
        borderRadius: 16,
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.paper
    },
    titleBox: {
        padding: '15px 24px',
        width: '100%',
        boxSizing: 'border-box',
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    title: {
        fontWeight: 'bold'
    },
    buttonBox: {
        padding: '15px 24px',
        boxSizing: 'border-box',
        borderTop: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    cancelButton: {

    },
    submitButton: {

    },
    contentBox: {
        padding: 24,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 24
    },
}))