const interestReverseFilter = (interest: string) => {
    switch (interest.replace(/ /gi, "")) {
        case '자기계발':
            return 'IN1';
        case '미용':
            return 'IN2';
        case '독서':
            return 'IN3';
        case '술':
            return 'IN4';
        case '게임':
            return 'IN5';
        case '스포츠/운동':
            return 'IN6';
        case '댄스':
            return 'IN7';
        case '그림그리기':
            return 'IN8';
        case '자전거타기':
            return 'IN9';
        case '악기연주':
            return 'IN10';
        case '레져':
            return 'IN11';
        case '봉사활동':
            return 'IN12';
        case '노래':
            return 'IN13';
        case '요리':
            return 'IN14';
        case 'IT':
            return 'IN15';
        case '드라이브':
            return 'IN16';
        case '산책':
            return 'IN17';
        case '전시회보기':
            return 'IN18';
        case '커피':
            return 'IN19';
        case '공연관람':
            return 'IN20';
        case '애니':
            return 'IN21';
        case '글쓰기':
            return 'IN22';
        case '피트니스':
            return 'IN23';
        case '인테리어':
            return 'IN24';
        case '재테크':
            return 'IN25';
        case '정치':
            return 'IN26';
        case '영화보기':
            return 'IN27';
        case '맛집탐방':
            return 'IN28';
        case '여행':
            return 'IN29';
        case '음악':
            return 'IN30';
        case '반려동물':
            return 'IN31';
        case '패션':
            return 'IN32';
        case '쇼핑':
            return 'IN33';
        case '드라마보기':
            return 'IN34';
        case '사진촬영':
            return 'IN35';
        case '외국어/어학':
            return 'IN36';
        case '문학':
            return 'IN37';
        case '웹툰':
            return 'IN39';
        case '덕질':
            return 'IN38';
        default:
            return interest;
    }
}

export default interestReverseFilter