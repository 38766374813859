import { Box, TableCell, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import { env } from '../../../../config'
import { RecommendationResponse } from '../../../../models/api/buzz/recommend'
import activeFilter from '../../../../utils/filter/activeFilter'
import fullPathFilter from '../../../../utils/filter/fullPathFilter'
import genderFilter from '../../../../utils/filter/genderFilter'
import kstFilter from '../../../../utils/filter/kstFilter'
import Placeholder from '../../../../assets/placeholder.png'
import ageFilter from '../../../../utils/filter/ageFilter'
import { getActiveColor, replacePlaceholderImage } from '../../../../utils/common'

interface Props extends RecommendationResponse {
    onClickImage: (path: string) => void
    onClickNickname: (id: number) => void
}

const RecommendItem: React.FC<Props> = ({
    userRecommand, isConfirm, createdAt, onClickImage, onClickNickname
}) => {
    return (
        <TableRow>
            <TableCell>
                <Typography variant='body2' color='secondary' style={{ cursor: 'pointer' }} onClick={() => onClickNickname(userRecommand.id || 0)}>{userRecommand.nickname}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant='body2'>{ageFilter(userRecommand.birthday || '')}세 | {genderFilter(userRecommand.gender!)} | {userRecommand.region} | <b style={{ color: getActiveColor(activeFilter(userRecommand.isOut!, userRecommand.isOutPending!, userRecommand.isBlock!, userRecommand.isDeactivate!)) }}>{activeFilter(userRecommand.isOut!, userRecommand.isOutPending!, userRecommand.isBlock!, userRecommand.isDeactivate!)}</b></Typography>
            </TableCell>
            <TableCell>
                <Box style={{ display: 'flex', flexDirection: 'row', columnGap: 12 }}>
                    {userRecommand?.pictures?.map(picture => (
                        <img key={picture.id} src={fullPathFilter(picture.pathSmall) || Placeholder} onClick={() => onClickImage(picture.path)} style={{ width: 60, height: 60, objectFit: 'cover', borderRadius: 8, cursor: 'pointer' }} onError={replacePlaceholderImage}/>
                    ))}
                </Box>
            </TableCell>
            <TableCell>{kstFilter(createdAt)}</TableCell>
            <TableCell>
                <Typography variant='body2' style={{ color: isConfirm==='Y' ? '#008C00' : '#D92E00' }}>{isConfirm==='Y' ? '승인' : '미승인'}</Typography>
            </TableCell>
        </TableRow>
    )
}

export default RecommendItem