import { Box, Button, makeStyles, Paper, Switch, TextField, Typography } from '@material-ui/core'
import { AndroidSharp } from '@material-ui/icons'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import buzzEndpoints from '../../../api/buzz'
import { api } from '../../../api/common'
import Layout from '../../../components/Layout'
import { AdsResponse } from '../../../models/api/buzz/ads'
import DeviceType from '../../../models/api/enum/device'

const Ads = () => {

    const classes = useStyles()

    const [ androidAds, setAndroidAds ] = useState<AdsResponse[]>([])
    const [ iosAds, setIosAds ] = useState<AdsResponse[]>([])

    const { isLoading, refetch } = useQuery<{ data: AdsResponse[] }>(
        ['ads'],
        () => api.get(`${buzzEndpoints.getAds()}`).then(res => res.data),
        {
            onSuccess: (data) => {
                setAndroidAds(data.data.filter(ad => (ad.deviceType==='A' && ad.category==='appeal_ad')))
                setIosAds(data.data.filter(ad => (ad.deviceType==='I' && ad.category==='appeal_ad')))
            }
        }
    )

    const { mutate: updateAds } = useMutation(
        (data: any) => api.post(`${buzzEndpoints.updateAds()}`, data).then(res => res.data)
    )

    const handleChangeSwitch = (deviceType: DeviceType, checked: boolean) => {
        if(deviceType==='A'){
            setAndroidAds(androidAds => [{...androidAds[0], isActive: checked}])
        } else {
            setIosAds(iosAds => [{...iosAds[0], isActive: checked}])
        }
    }
    const handleChange = (deviceType: DeviceType, type: 'first' | '', value: string) => {
        if(deviceType==='A'){
            let adsData = {
                firstInterval: androidAds[0].data.firstInterval,
                interval: androidAds[0].data.interval 
            }
            if(type==='first'){
                adsData.firstInterval = Number(value)
            } else {
                adsData.interval = Number(value)
            }
            console.log(adsData)
            setAndroidAds(androidAds => [{...androidAds[0], data: adsData }])
        } else {
            let adsData = {
                firstInterval: iosAds[0].data.firstInterval,
                interval: iosAds[0].data.interval 
            }
            if(type==='first'){
                adsData.firstInterval = Number(value)
            } else {
                adsData.interval = Number(value)
            }
            setIosAds(iosAds => [{...iosAds[0], data: adsData }])
        }
    }
    const handleUpdate = (deviceType: DeviceType) => {
        if(deviceType==='A'){
            updateAds({...androidAds[0], data: { first_interval: androidAds[0].data.firstInterval, interval: androidAds[0].data.interval }})
        } else if(deviceType==='I'){
            updateAds({...iosAds[0], data: { first_interval: iosAds[0].data.firstInterval, interval: iosAds[0].data.interval }})
        }
    }

    return (
        <Layout title='광고 설정' level='서비스 관리'>
            <Box className={classes.container}>
                <Paper className={classes.tableContainer}>
                    <Box className={classes.titleBox}>
                        <Typography variant='h6' color='textPrimary'>Android 네이티브 광고</Typography>
                    </Box>
                    <Box className={classes.itemBox}>
                        <Typography variant='body1' color='textPrimary' className={classes.itemTitle}>커뮤니티</Typography>
                        <Box className={classes.itemContentBox}>
                            <Box className={classes.itemSwitchBox}>
                                <Switch
                                    onChange={e => handleChangeSwitch('A', e.target.checked)}
                                    checked={Boolean(androidAds?.[0]?.isActive)}
                                    color={Boolean(androidAds?.[0]?.isActive) ? 'primary' : 'default'}
                                />
                                <Typography color='textSecondary' variant='body1'>활성</Typography>
                            </Box>
                            <Box className={classes.itemInputBox}>
                                <Typography variant='body2' color='textSecondary'>최초</Typography>
                                <TextField
                                    value={androidAds?.[0]?.data.firstInterval || ''}
                                    inputProps={{
                                        className: classes.input
                                    }}
                                    onChange={e => handleChange('A', 'first', e.target.value)}
                                />
                                <Typography variant='body2' color='textSecondary'>개 마다, 이후</Typography>
                                <TextField
                                    value={androidAds?.[0]?.data.interval || ''}
                                    inputProps={{
                                        className: classes.input
                                    }}
                                    onChange={e => handleChange('A', '', e.target.value)}
                                />
                                <Typography variant='body2' color='textSecondary'>개 마다 노출</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.buttonBox}>
                        <Button className={classes.submitButton} disabled={(!Boolean(androidAds?.[0]?.data.firstInterval) || !Boolean(androidAds?.[0]?.data.interval))} onClick={() => handleUpdate('A')}>안드로이드 변경</Button>
                    </Box>
                </Paper>
                <Paper className={classes.tableContainer}>
                    <Box className={classes.titleBox}>
                        <Typography variant='h6' color='textPrimary'>iOS 네이티브 광고</Typography>
                    </Box>
                    <Box className={classes.itemBox}>
                        <Typography variant='body1' color='textPrimary' className={classes.itemTitle}>커뮤니티</Typography>
                        <Box className={classes.itemContentBox}>
                            <Box className={classes.itemSwitchBox}>
                                <Switch
                                    onChange={e => handleChangeSwitch('I', e.target.checked)}
                                    checked={Boolean(iosAds?.[0]?.isActive)}
                                    color={Boolean(iosAds?.[0]?.isActive) ? 'primary' : 'default'}
                                />
                                <Typography color='textSecondary' variant='body1'>활성</Typography>
                            </Box>
                            <Box className={classes.itemInputBox}>
                                <Typography variant='body2' color='textSecondary'>최초</Typography>
                                <TextField
                                    value={iosAds?.[0]?.data.firstInterval || ''}
                                    inputProps={{
                                        className: classes.input
                                    }}
                                    onChange={e => handleChange('I', 'first', e.target.value)}
                                />
                                <Typography variant='body2' color='textSecondary'>개 마다, 이후</Typography>
                                <TextField
                                    value={iosAds?.[0]?.data.interval || ''}
                                    inputProps={{
                                        className: classes.input
                                    }}
                                    onChange={e => handleChange('I', '', e.target.value)}
                                />
                                <Typography variant='body2' color='textSecondary'>개 마다 노출</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.buttonBox}>
                        <Button className={classes.submitButton} disabled={(!Boolean(iosAds?.[0]?.data.firstInterval) || !Boolean(iosAds?.[0]?.data.interval))} onClick={() => handleUpdate('I')}>아이폰 변경</Button>
                    </Box>
                </Paper>
            </Box>
        </Layout>
    )
}

export default Ads

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: 24
    },
    tableContainer: {
        borderRadius: 16,
        marginTop: 24,
        width: 800
    },
    titleBox: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: 24,
        boxSizing: 'border-box'
    },
    title: {
        fontWeight: 'bold'
    },
    itemBox: {
        display: 'flex',
        flexDirection: 'row',
        padding: 24,
        boxSizing: 'border-box'
    },
    itemTitle: {
        fontWeight: 'bold'
    },
    itemContentBox: {
        marginLeft: 30
    },
    itemSwitchBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    itemInputBox: {
        marginTop: 30,
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 12,
        alignItems: 'center'
    },
    input: {
        textAlign: 'center',
        width: 60
    },
    buttonBox: {
        borderTop: `1px solid ${theme.palette.divider}`,
        padding: 12,
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    submitButton: {
        backgroundColor: '#4caf50',
        color: '#ffffff',
        "&:hover": {
            backgroundColor: '#4caf50',
        },
        "&:disabled": {
            backgroundColor: theme.palette.action.disabled
        }
    },
}))