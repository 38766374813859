import { Box, makeStyles, Modal, Typography } from '@material-ui/core'
import React, { forwardRef } from 'react'
import useImageModal from '../../../../../hooks/useImageModal'
import fullPathFilter from '../../../../../utils/filter/fullPathFilter'
import ImageModal from '../../../../common/Modal/ImageModal'

interface Props {
    sources: string[] 
}

const FacebookImage: React.FC<Props> = forwardRef(({
    sources
}, ref) => {

    const classes = useStyles()

    const { imagePath, imageModalOpened, handleImageModal } = useImageModal()
    
    return (
        <Box className={classes.container}>
            <Typography variant='subtitle1' color='textPrimary' className={classes.title}>Facebook Albums</Typography>
            <Box className={classes.imageBox}>
                {sources.map(source => (
                    <img src={fullPathFilter(source)} onClick={() => handleImageModal(source)} className={classes.image}/>
                ))}
            </Box>
            <Modal open={imageModalOpened} className={classes.modalContainer} onClose={() => handleImageModal()}>
                <ImageModal source={imagePath} close={handleImageModal}/>
            </Modal>
        </Box>
    )
})

export default FacebookImage

const useStyles = makeStyles((theme) => ({
    modalContainer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        width: 300,
        padding: 20,
        boxSizing: 'border-box'
    },
    title: {
    },
    imageBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 3,
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: 300
    },
    image: {
        width: '20%',
        height: '20%',
        objectFit: 'cover',
        cursor: 'pointer'
    }
}))