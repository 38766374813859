import { Box, IconButton, makeStyles, TextField, Typography } from '@material-ui/core'
import { Done } from '@material-ui/icons'
import React, { forwardRef, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import buzzEndpoints from '../../../../api/buzz'
import { api } from '../../../../api/common'
import useInputs from '../../../../hooks/useInputs'
import EditLayout from './EditLayout'

interface Props {
    userId: number
    close: () => void
    refetch: () => void
    value: string
}

const EmailEdit: React.FC<Props> = forwardRef(({
    userId, close, refetch, value
}, ref) => {

    const [ email, setEmail ] = useState<string>(value)
    const [ password, setPassword ] = useState<string>('')
    const [ isEmailValidated, setIsEmailValidated ] = useState<boolean>(false)
    const [ isDuplicated, setIsDuplicated ] = useState<boolean>(false)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(isDuplicated || isEmailValidated){
            setIsDuplicated(false)
            setIsEmailValidated(false)
        }
        setEmail(e.target.value)
    }

    const classes = useStyles()

    const { mutate: validateEmail } = useMutation(
        () => api.post(`${buzzEndpoints.validationEmail()}`, { identification: email }),
        {
            onSuccess: () => {
                setIsEmailValidated(true)
                setIsDuplicated(false)
            },
            onError: () => {
                setIsEmailValidated(true)
                setIsDuplicated(true)
            }
        }
    )
    const { mutate: updateEmail } = useMutation(
        () => api.post(`${buzzEndpoints.userChangeEmail(userId)}`, { identification: email }),
        {
            onSuccess: () => {
                refetch()
                close()
            }
        }
    )
    const { mutate: createEmail } = useMutation(
        () => api.post(`${buzzEndpoints.addEmail(userId)}`, { identification: email }),
        {
            onSuccess: () => {
                refetch()
                close()
            }
        }
    )

    const handleUpdate = () => {
        if(Boolean(value)){
            updateEmail()
        } else {
            createEmail()
        }
    }

    return (
        <EditLayout title='이메일 설정' disabled={(!isEmailValidated || isDuplicated)} onSubmit={handleUpdate} close={close}>
            <Box className={classes.contentBox}>
                <Box className={classes.inputBox}>
                    <TextField 
                        placeholder='example@cupist.com'
                        value={email}
                        onChange={handleChange}
                        fullWidth
                        label='이메일'
                    />
                    <IconButton onClick={() => validateEmail()} disabled={email===''}>
                        <Done color={email ? 'action' : 'disabled'}/>
                    </IconButton>
                </Box>
                <Box className={classes.validateBox}>
                    <Typography color='textPrimary' style={isEmailValidated ? isDuplicated ? { color: '#d92e00' } : { color: '#008c00' } : {}}>{isEmailValidated ? isDuplicated ? '이미 존재하는 이메일입니다' : '등록 가능한 이메일 계정입니다' : '이메일 계정을 설정해주세요'}</Typography>
                </Box>
                {!Boolean(value) && (
                    <Box>
                         <Box className={classes.inputBox}>
                            <TextField 
                                placeholder='000000'
                                fullWidth
                                label='000000'
                                disabled
                            />
                            <IconButton onClick={() => validateEmail()} disabled={password===''}>
                                <Done color={email ? 'action' : 'disabled'}/>
                            </IconButton>
                        </Box>
                        <Box className={classes.validateBox}>
                            <Typography color='textPrimary' style={{ color: '#d92e00' }}>비밀번호는 000000으로 설정됩니다</Typography>
                        </Box>
                    </Box>
                )}
                
            </Box>
        </EditLayout>
    )
})

export default EmailEdit

const useStyles = makeStyles((theme) => ({
    contentBox: {
        display: 'flex',
        flexDirection: 'column',
        padding: 24,
        boxSizing: 'border-box'
    },
    inputBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 10
    },
    validateBox: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: 10
    }
}))